import './App.css';
import { BrowserRouter as Router, Routes, Navigate, Route } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';

// import BuyerHome from './Buyer_module/Buyer_home/BuyerHome';
import ScrollToTop from './ScrollToTop';
import SignUp from './Buyer_module/Auth/Sign_up/SignUp';
import ForgetPassword from './Buyer_module/Auth/ForgetPassword/ForgetPassword';
import OtpVerification from './Buyer_module/Auth/OtpVerification/OtpVerification';
import OtpVerificationEmail from './Buyer_module/Auth/OtpVerificationEmail/OtpVerificationEmail';
import SignInWithOtp from './Buyer_module/Auth/SignIn-with-otp/SignInWithOtp';
import CreateAccount from './Buyer_module/Auth/CreateAccount/CreateAccount';
import ResetPassword from './Buyer_module/Auth/ResetPassword/ResetPassword';
import PasswordChange from './Buyer_module/Auth/PasswordChange/PasswordChange';
// import LandingWithoutLoginPageHome from './Buyer_module/LandingWithoutLoginPage/LandingWithoutLoginPageHome';
import BuyerLandingPageHome from './Buyer_module/BuyerLandingPage/BuyerLandingPageHome';
import BuyerWishlist from './Buyer_module/BuyerWishlist/BuyerWishlist';
import BuyerCart from './Buyer_module/BuyerCart/BuyerCart';
import BuyerCheckout from './Buyer_module/BuyerCheckout/BuyerCheckout';
import BuyerAccount from './Buyer_module/BuyerAccount/BuyerAccount';

import BuyerOrderItAgain from './Buyer_module/BuyerOrderItAgain/BuyerOrderItAgain';

import BuyerTrackOrder from './Buyer_module/BuyerTrackOrder/BuyerTrackOrder';
import BuyerProductListingHome from './Buyer_module/BuyerProducts/BuyerProductListing/BuyerProductListingHome';
import BuyerProductDetails from './Buyer_module/BuyerProducts/BuyerProductDetails/BuyerProductDetails';
import BuyerWriteReview from './Buyer_module/BuyerWriteReview/BuyerWriteReview';
import BuyerLeaveSellerFeedback from './Buyer_module/BuyerLeaveSellerFeedback/BuyerLeaveSellerFeedback';
import BuyerProfile from './Buyer_module/BuyerAccount/BuyerProfile/BuyerProfile';
import BuyerProfileChangeEmail from './Buyer_module/BuyerAccount/BuyerProfileChangeEmail/BuyerProfileChangeEmail';
import BuyerProfileChangePassword from './Buyer_module/BuyerAccount/BuyerProfileChangePassword/BuyerProfileChangePassword';
import BuyerProfileChangeMobile from './Buyer_module/BuyerAccount/BuyerProfileChangeMobile/BuyerProfileChangeMobile';
import BuyerYourAddress from './Buyer_module/BuyerAccount/BuyerYourAddress/BuyerYourAddress';
import BuyerAddNewAddress from './Buyer_module/BuyerAccount/BuyerAddNewAddress/BuyerAddNewAddress';
import BuyerProfileChangeName from './Buyer_module/BuyerAccount/BuyerProfileChangeName/BuyerProfileChangeName';
import BuyerContactUs from './Buyer_module/BuyerAccount/BuyerContactUs/BuyerContactUs';
import BuyerPrivacyPolicy from './Buyer_module/BuyerPolicies/BuyerPrivacyPolicy/BuyerPrivacyPolicy';
import BuyerRefundPolicy from './Buyer_module/BuyerPolicies/BuyerRefundPolicy/BuyerRefundPolicy';
import BuyerShippingPolicy from './Buyer_module/BuyerPolicies/BuyerShippingPolicy/BuyerShippingPolicy';
import BuyerFaq from './Buyer_module/BuyerFaq/BuyerFaq';

import BuyerPurchaseProtection from './Buyer_module/BuyerPolicies/BuyerPurchaseProtection/BuyerPurchaseProtection';
import BuyerOrderDetails from './Buyer_module/BuyerOrderDetails/BuyerOrderDetails';
import BuyerReturnOrder from './Buyer_module/BuyerReturnOrder/BuyerReturnOrder';
import BuyerTermsAndConditions from './Buyer_module/BuyerPolicies/BuyerTermsAndConditions/BuyerTermsAndConditions';
import BuyerOrders from './Buyer_module/BuyerOrders/BuyerOrders';
import BuyerUpdateAddress from './Buyer_module/BuyerAccount/BuyerUpdateAddress/BuyerUpdateAddress';
import BuyerHomeFourSectionProductListingHome from './Buyer_module/BuyerProducts/BuyerHomeFourSectionProductListing/BuyerHomeFourSectionProductListingHome';
import BuyerHomeLastSectionProductListingHome from './Buyer_module/BuyerProducts/BuyerHomeLastSectionProductListing/BuyerHomeLastSectionProductListingHome';
import BuyerAboutUs from './Buyer_module/BuyerPolicies/BuyerAboutUs/BuyerAboutUs';
import BuyerNewReleaseProductListingHome from './Buyer_module/BuyerProducts/BuyerNewReleaseProductListing/BuyerNewReleaseProductListingHome';
import BuyerProfileChangeAddress from './Buyer_module/BuyerAccount/BuyerProfileChangeAddress/BuyerProfileChangeAddress';
import Login from './Seller_module/Auth/Login';
import Signup from './Seller_module/Auth/Signup';
import Forgetpassword from './Seller_module/Auth/Forgetpassword';
import Changepassword from './Seller_module/Auth/Changepassword';
import CreateSellerAccount from './Seller_module/Auth/CreateSellerAccount';
import SellerDashboardContent from './Seller_module/SellerDashboard/SellerDashboardContent';
import SellerCheckout from './Seller_module/Auth/SellerCheckout/SellerCheckout';
import BillingAddressAdd from './Seller_module/Auth/BillingAddressAdd';
import BillingAddressList from './Seller_module/Auth/BillingAddressList';
import AllOrders from './Seller_module/SellerOrders/SellerAllOrders';
import SellerDeliveredOrderDetails from './Seller_module/SellerOrders/SellerDeliveredOrders/SellerDeliveredOrderDetails';
import SellerPendingOrderDetails from './Seller_module/SellerOrders/SellerPendingOrders/SellerPendingOrderDetails';
import StoreDetails from './Seller_module/Auth/StoreDetails';
import BankDetails from './Seller_module/Auth/BankDetails';
import IdentificationNumber from './Seller_module/Auth/IdentificationNumber';
import SubscriptionPlan from './Seller_module/Auth/SubscriptionPlan';
import PaymentSuccess from './Seller_module/Auth/PaymentSuccess';
import Homepage from './Seller_module/Home/Homepage';
import ConfidentialityPolicy from './Seller_module/Home/CMS/ConfidentialityPolicy';
import TermsOfUse from './Seller_module/Home/CMS/TermsOfUse';
import SellerFAQ from './Seller_module/Home/CMS/SellerFAQ';
import SellerCancelledOrders from './Seller_module/SellerOrders/SellerCancelledOrders/SellerCancelledOrders';
import ProductList from './Seller_module/SellerProducts/List/ProductList';
import ProductIdentity from './Seller_module/SellerProducts/Add/ProductIdentity';
import ProductDescription from './Seller_module/SellerProducts/Add/ProductDescription';
import ProductVariation from './Seller_module/SellerProducts/Add/ProductVariation';
import ProductDetails from './Seller_module/SellerProducts/Add/ProductDetails';
import ProductView from './Seller_module/SellerProducts/List/ProductView';
import SellerCancelledOrderDetails from './Seller_module/SellerOrders/SellerCancelledOrders/SellerCancelledOrderDetails';
import SellerReturnExchangeList from './Seller_module/SellerOrders/SellerReturnExchange/SellerReturnExchangeList';
import SellerReturnExchangeAccept from './Seller_module/SellerOrders/SellerReturnExchange/SellerReturnExchangeAccept';
import SellerReturnExchangeDetail from './Seller_module/SellerOrders/SellerReturnExchange/SellerReturnExchangeDetail';
import ProductReview from './Seller_module/SellerProducts/Add/ProductReview';
import ProductReviewEdit from './Seller_module/SellerProducts/Add/ProductReviewEdit';
import SellerProfile from './Seller_module/SellerProfile/SellerProfile';
import SellerMySubscription from './Seller_module/SellerProfile/SellerMySubscription';
import SellerMyAccount from './Seller_module/SellerProfile/SellerMyAccount';
import SellerAccountManagerView from './Seller_module/SellerProfile/SellerAccountManagerView';
import SellerAccountManagerEdit from './Seller_module/SellerProfile/SellerAccountManagerEdit';
import SellerAccountManagerSaveChanges from './Seller_module/SellerProfile/SellerAccountManagerSaveChanges';
import SellerProfileBankDetails from './Seller_module/SellerProfile/SellerProfileBankDetails';
import SellerInventory from './Seller_module/SellerInventory/SellerInventory';
import SellerInventoryProductDetails from './Seller_module/SellerInventory/SellerInventoryProductDetails';
import SellerAuthentication from './Seller_module/SellerAuthentication/SellerAuthentication';
import SellerAuthenticationVerifyMobile from './Seller_module/SellerAuthentication/SellerAuthenticationVerifyMobile';
import SellerContactUs from './Seller_module/SellerContactUs/SellerContactUs';
import SellerNewOrderAcceptReject from './Seller_module/SellerOrders/SellerNewOrders/SellerNewOrderAcceptReject';
import SellerNewOrderShipment from './Seller_module/SellerOrders/SellerNewOrders/SellerNewOrderShipment';
import SellerNewOrderDispatchDelivered from './Seller_module/SellerOrders/SellerNewOrders/SellerNewOrderDispatchDelivered';
import SellerNewOrdersAfterDelivered from './Seller_module/SellerOrders/SellerNewOrders/SellerNewOrdersAfterDelivered';
import SellerNewOrderStatus from './Seller_module/SellerOrders/SellerNewOrders/SellerNewOrderStatus';
import BillingAddressEdit from './Seller_module/Auth/BillingAddressEdit';

function App() {
  return (
    <>
      <Router>
        <div className="App">
          <Routes>
            <Route exact path='/' element={<BuyerLandingPageHome />}></Route>
            <Route path="*" element={<Navigate to="/" />} />

            <Route exact path='/buyer/*' element={<BuyerLandingPageHome />}></Route>

            <Route exact path='buyer/sign-in' element={<SignUp />}></Route>
            <Route exact path='buyer/forget-password' element={<ForgetPassword />}></Route>
            <Route exact path='buyer/otp-verification' element={<OtpVerification />}></Route>
            <Route exact path='buyer/otp-verification-email' element={<OtpVerificationEmail />}></Route>
            <Route exact path='buyer/sign-in-with-otp' element={<SignInWithOtp />}></Route>
            <Route exact path='buyer/create-account' element={<CreateAccount />}></Route>
            <Route exact path='buyer/reset-password' element={<ResetPassword />}></Route>
            <Route exact path='buyer/password-change' element={<PasswordChange />}></Route>
            <Route exact path='buyer/product-listing' element={<BuyerProductListingHome />}></Route>
            <Route exact path='buyer/home-four-product-listing/:name' element={<BuyerHomeFourSectionProductListingHome />}></Route>
            <Route exact path='buyer/home-last-product-listing' element={<BuyerHomeLastSectionProductListingHome />}></Route>
            <Route exact path='buyer/new-release-product-listing' element={<BuyerNewReleaseProductListingHome />}></Route>
            <Route exact path='buyer/product-details/:name' element={<BuyerProductDetails />}></Route>
            <Route exact path='buyer/wish-list' element={<BuyerWishlist />}></Route>
            <Route exact path='buyer/cart' element={<BuyerCart />}></Route>
            <Route exact path='buyer/checkout' element={<BuyerCheckout />}></Route>
            <Route exact path='buyer/your-account' element={<BuyerAccount />}></Route>
            <Route exact path='buyer/your-orders' element={<BuyerOrders />}></Route>
            {/* <Route exact path='buyer/your-orders' element={<BuyerYourOrders />}></Route> */}
            <Route exact path='buyer/order-details/:id' element={<BuyerOrderDetails />}></Route>
            <Route exact path='buyer/buy-it-again' element={<BuyerOrderItAgain />}></Route>
            {/* <Route exact path='buyer/cancel-order' element={<BuyerCancelOrder />}></Route> */}
            <Route exact path='buyer/track-order/:id' element={<BuyerTrackOrder />}></Route>
            <Route exact path='buyer/return-order/:id' element={<BuyerReturnOrder />}></Route>
            <Route exact path='buyer/write-review/:id' element={<BuyerWriteReview />}></Route>
            <Route exact path='buyer/leave-seller-feedback' element={<BuyerLeaveSellerFeedback />}></Route>
            <Route exact path='buyer/profile' element={<BuyerProfile />}></Route>
            <Route exact path='buyer/profile-change-name' element={<BuyerProfileChangeName />}></Route>
            <Route exact path='buyer/profile-change-email' element={<BuyerProfileChangeEmail />}></Route>
            <Route exact path='buyer/change-password' element={<BuyerProfileChangePassword />}></Route>
            <Route exact path='buyer/change-primary-number' element={<BuyerProfileChangeMobile />}></Route>
            <Route exact path='buyer/change-address' element={<BuyerProfileChangeAddress />}></Route>
            <Route exact path='buyer/your-address' element={<BuyerYourAddress />}></Route>
            <Route exact path='buyer/add-new-address' element={<BuyerAddNewAddress />}></Route>
            <Route exact path='buyer/update-address/:name' element={<BuyerUpdateAddress />}></Route>
            <Route exact path='buyer/contact-us' element={<BuyerContactUs />}></Route>
            <Route exact path='buyer/about-us' element={<BuyerAboutUs />}></Route>
            <Route exact path='buyer/privacy-policy' element={<BuyerPrivacyPolicy />}></Route>
            <Route exact path='buyer/refund-policy' element={<BuyerRefundPolicy />}></Route>
            <Route exact path='buyer/shipping-policy' element={<BuyerShippingPolicy />}></Route>
            <Route exact path='buyer/terms-and-conditions' element={<BuyerTermsAndConditions />}></Route>
            <Route exact path='buyer/purchase-protection' element={<BuyerPurchaseProtection />}></Route>
            <Route exact path='buyer/faq' element={<BuyerFaq />}></Route>


            {/* CMS */}



            <Route exact path='/seller/confidentiality-policy' element={<ConfidentialityPolicy />}></Route>
            <Route exact path='/seller/terms-of-use' element={<TermsOfUse />}></Route>
            <Route exact path='/seller/*' element={<SellerDashboardContent />}></Route>
            <Route exact path='/seller/faq' element={<SellerFAQ />}></Route>
            <Route exact path='/seller/home' element={<Homepage />}></Route>
            <Route exact path='/seller/sign-in' element={<Login />}></Route>
            <Route exact path='/seller/signup' element={<Signup />}></Route>
            <Route exact path='/seller/forget-password' element={<Forgetpassword />}></Route>
            <Route exact path='/seller/dashboard' element={<SellerDashboardContent />}></Route>
            <Route exact path='/seller/change-password' element={<Changepassword />}></Route>
            <Route exact path='/seller/create-account' element={<CreateSellerAccount />}></Route>
            <Route exact path='/seller/add-billing-address' element={<BillingAddressAdd />}></Route>
            <Route exact path='/seller/edit-billing-address/:name' element={<BillingAddressEdit />}></Route>
            <Route exact path='/seller/billing-address-list' element={<BillingAddressList />}></Route>
            <Route exact path='/seller/store-details' element={<StoreDetails />}></Route>
            <Route exact path='/seller/bank-details' element={<BankDetails />}></Route>
            <Route exact path='/seller/tazkira-details' element={<IdentificationNumber />}></Route>
            <Route exact path='/seller/subscription-plan' element={<SubscriptionPlan />}></Route>
            <Route exact path='/seller/checkout' element={< SellerCheckout />}></Route>
            <Route exact path='/seller/payment-success' element={< PaymentSuccess />}></Route>
            {/* <Route exact path='/seller/subscription-checkout' element={<Checkout />}></Route> */}
            <Route exact path='/seller/dashboard' element={<Forgetpassword />}></Route>
            <Route exact path='/seller/all_orders' element={< AllOrders />}></Route>
            <Route exact path='/seller/cancelled_view_information' element={< SellerCancelledOrderDetails />}></Route>
            <Route exact path='/seller/delivered_view_information' element={< SellerDeliveredOrderDetails />}></Route>
            <Route exact path='/seller/accept_reject_view_information' element={< SellerNewOrderAcceptReject />}></Route>
            <Route exact path='/seller/new-order-approval-details' element={< SellerNewOrderDispatchDelivered />}></Route>
            <Route exact path='/seller/new-order-details-after-deliver' element={< SellerNewOrdersAfterDelivered />}></Route>
            <Route exact path='/seller/new_order_shipment' element={< SellerNewOrderShipment />}></Route>
            <Route exact path='/seller/new_order_status' element={< SellerNewOrderStatus />}></Route>
            <Route exact path='/seller/pending_view_information' element={< SellerPendingOrderDetails />}></Route>
            <Route exact path='/seller/return_exchange' element={< SellerReturnExchangeList />}></Route>
            <Route exact path='/seller/exchange_return_order_accept' element={< SellerReturnExchangeAccept />}></Route>
            <Route exact path='/seller/exchange_return_order_detail' element={< SellerReturnExchangeDetail />}></Route>
            <Route exact path='/seller/inventory_alert_1' element={< SellerInventory />}></Route>
            <Route exact path='/seller/inventory_alert_2' element={< SellerInventoryProductDetails />}></Route>
            <Route exact path='/seller/authentication' element={< SellerAuthentication />}></Route>
            <Route exact path='/seller/authentication_step_1' element={< SellerAuthenticationVerifyMobile />}></Route>
            <Route exact path='/seller/contact_us' element={< SellerContactUs />}></Route>
            {/* <Route exact path='/seller/*' element={<ProfileTabs />}></Route> */}


            {/* seller profile */}
            <Route exact path='/seller/profile' element={< SellerProfile />}></Route>
            <Route exact path='/seller/my_subscription' element={< SellerMySubscription />}></Route>
            <Route exact path='/seller/my_account' element={< SellerMyAccount />}></Route>
            <Route exact path='/seller/business-billing-address' element={< SellerAccountManagerView />}></Route>
            <Route exact path='/seller/business-billing-address_edit' element={< SellerAccountManagerEdit />}></Route>
            <Route exact path='/seller/sucessfuly_save_changes' element={< SellerAccountManagerSaveChanges />}></Route>
            <Route exact path='/seller/profile_bank_details' element={< SellerProfileBankDetails />}></Route>


            {/* Proudcts */}
            <Route exact path='/seller/products' element={< ProductList />}></Route>
            <Route exact path='/seller/products/view' element={< ProductView />}></Route>
            <Route exact path='/seller/products/review' element={< ProductReview />}></Route>
            <Route exact path='/seller/products/review/edit' element={< ProductReviewEdit />}></Route>
            <Route exact path='/seller/products/add/identity' element={< ProductIdentity />}></Route>
            <Route exact path='/seller/products/add/description' element={< ProductDescription />}></Route>
            <Route exact path='/seller/products/add/variation' element={< ProductVariation />}></Route>
            <Route exact path='/seller/products/add/details' element={< ProductDetails />}></Route>

          </Routes>
          <ScrollToTop />
        </div>
      </Router>
      <Toaster
        position="top-right"
        reverseOrder={false}
      />
    </>
  );
}

export default App;
