import { React, useEffect, useState } from 'react'

import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../../Loader';
import ErrorLoader from '../../../Error/ErrorLoader';
import BuyerProductListing from './BuyerNewReleaseProductListing'
import BuyerHeader from '../../BuyerHeader/BuyerHeader'
import BuyerFooter from '../../BuyerFooter/BuyerFooter'
import { getBrandData, getColourData } from '../../../Redux/action/MasterAction';
import BuyerHeaderWithoutLogin from '../../BuyerHeader/BuyerHeaderWithoutLogin';


const BuyerNewReleaseProductListingHome = () => {


    const dispatch = useDispatch();


    useEffect(() => {
        dispatch(getBrandData());
        dispatch(getColourData());

    }, []);
    const {

        loadingColourDataListApi,
        ColourDataListApiData,
        ColourDataListApiError,

        loadingBrandDataListApi,
        BrandDataListApiData,
        BrandDataListApiError,


    } = useSelector(state => state?.MasterReducer);
    const authToken = localStorage.getItem("buyer_token")

    return (
        <>
            {authToken ?

                <BuyerHeader />
                :
                <BuyerHeaderWithoutLogin />
            }
            <BuyerProductListing ColourDataListApiData={ColourDataListApiData} BrandDataListApiData={BrandDataListApiData} />
            <BuyerFooter />
        </>
    )
}

export default BuyerNewReleaseProductListingHome