import React from 'react'
import '../BuyerPrivacyPolicy/scss/BuyerPrivacyPolicyContent.css'
import { Link } from 'react-router-dom'
import BuyerPolicySidebar from '../BuyerPolicySidebar'
import parse from 'html-react-parser'
let host = process.env.REACT_APP_API_SERVER;
const BuyerPrivacyPolicyContent = ({ CmsDataListApiData }) => {
    return (
        <div className='buyer-privacy-policy'>
            <>
                {/* Privacy Policy */}
                <div className="privacy_policy_section">
                    <div className="container-fluid">
                        <div className="row align-items-center">
                            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                                <div className="page_titile">
                                    <h2>Refund Policy</h2>
                                    <p>{CmsDataListApiData?.subtitle}</p>
                                </div>
                            </div>
                            <div className="col-xl-5 col-lg-5 col-md-5 col-sm-5 col-5">
                                <div className="page_titile_img text-center">
                                    <img src={CmsDataListApiData?.image ? `${host}/${CmsDataListApiData?.image}` : ""} className="img-fluid" />
                                </div>
                            </div>
                            <div className="col-xl-1 col-lg-1 col-md-1 col-sm-1 col-1" />
                        </div>
                    </div>
                </div>
                <div className="policy_content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-xl-3 col-lg-3 col-md-4 col-sm-4 col-12">
                                <BuyerPolicySidebar />
                            </div>
                            <div className="col-xl-9 col-lg-9 col-md-8 col-sm-8 col-12">
                                <div className="policy_content_inner">
                                    {CmsDataListApiData?.description ? parse(CmsDataListApiData?.description) : ""}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Privacy Policy */}
            </>

        </div>

    )
}

export default BuyerPrivacyPolicyContent