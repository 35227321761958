import React from 'react'
import BuyerProfileChangePasswordContent from './BuyerProfileChangePasswordContent'
import BuyerHeader from '../../BuyerHeader/BuyerHeader'
import BuyerFooter from '../../BuyerFooter/BuyerFooter'

const BuyerProfileChangePassword = () => {
    return (
        <>
            <BuyerHeader />
            <BuyerProfileChangePasswordContent />
            <BuyerFooter />
        </>
    )
}

export default BuyerProfileChangePassword