import React from 'react';
import "./Scss/Homepage.css";
import { Link } from 'react-router-dom';
const host = process.env.PUBLIC_URL
const HomeFooter = () => {
    return (
        <>
            <footer className="bg_footer_sec">
                <section>
                    <div className="container">
                        <div className="main_detail_footer">
                            <div className="row align-items-center">
                                <div className="col-lg-2 col-md-4 col-sm-4">
                                    <div className="main_footer_logo">
                                        <img src={`${host}/assets/images/seller_images/logoipsum.png`} alt="footer logo" />
                                    </div>
                                </div>
                                <div className="col-lg-5 col-md-8 col-sm-8">
                                    <div className="main_listing_footer">
                                        <ul>
                                            <li>
                                                <Link to="/seller/confidentiality-policy">
                                                    Confidentiality Policy
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="/seller/terms-of-use">Terms of Use</Link>
                                            </li>
                                            <li>
                                                <Link to="/seller/faq">Seller FAQ</Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-lg-5 col-md-12">
                                    <div className="copy_right_main">
                                        <a href="#">© 2024 Afghan Bazaar. All rights reserved</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </footer>
        </>
    )
}

export default HomeFooter