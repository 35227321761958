import React from 'react';
import "./Scss/Homepage.css";

const HomeWhySeller = () => {
    return (
        <>
            <section className="padding-b-50">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="main_heading_sec">
                                <h3>Why Seller Choose Afghan Bazaar ?</h3>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-4 col-md-6 col-sm-6">
                            <div className="why_three-col-wrap">
                                <img src={`${process.env.PUBLIC_URL}/assets/images/seller_images/Crores.png`} alt="Crores" />
                                <div className="book_area_why">
                                    <h4>Crores of Customers</h4>
                                    <p>
                                        Lorem ipsum dolor sit amet consectetur. Ut ut neque et
                                        facilisis commodo. Nec et tempor urna quam elit ut cras
                                        blandit vel. Enim purus scelerisque at turpis leo.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-6">
                            <div className="why_three-col-wrap">
                                <img src={`${process.env.PUBLIC_URL}/assets/images/seller_images/k+.png`} alt="Crores" />
                                <div className="book_area_why">
                                    <h4>5.1K+</h4>
                                    <p>
                                        Lorem ipsum dolor sit amet consectetur. Ut ut neque et
                                        facilisis commodo. Nec et tempor urna quam elit ut cras
                                        blandit vel. Enim purus scelerisque at turpis leo.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-6">
                            <div className="why_three-col-wrap">
                                <img src={`${process.env.PUBLIC_URL}/assets/images/seller_images/Unbeatable.png`} alt="Crores" />
                                <div className="book_area_why">
                                    <h4>Unbeatable Reach</h4>
                                    <p>
                                        Lorem ipsum dolor sit amet consectetur. Ut ut neque et
                                        facilisis commodo. Nec et tempor urna quam elit ut cras
                                        blandit vel. Enim purus scelerisque at turpis leo.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-6">
                            <div className="why_three-col-wrap">
                                <img src={`${process.env.PUBLIC_URL}/assets/images/seller_images/Crores.png`} alt="Crores" />
                                <div className="book_area_why">
                                    <h4>Secure Timely Payments</h4>
                                    <p>
                                        Lorem ipsum dolor sit amet consectetur. Ut ut neque et
                                        facilisis commodo. Nec et tempor urna quam elit ut cras
                                        blandit vel. Enim purus scelerisque at turpis leo.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-6">
                            <div className="why_three-col-wrap">
                                <img src={`${process.env.PUBLIC_URL}/assets/images/seller_images/k+.png`} alt="Crores" />
                                <div className="book_area_why">
                                    <h4>Ease of Starting</h4>
                                    <p>
                                        Lorem ipsum dolor sit amet consectetur. Ut ut neque et
                                        facilisis commodo. Nec et tempor urna quam elit ut cras
                                        blandit vel. Enim purus scelerisque at turpis leo.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-6">
                            <div className="why_three-col-wrap">
                                <img src={`${process.env.PUBLIC_URL}/assets/images/seller_images/Unbeatable.png`} alt="Crores" />
                                <div className="book_area_why">
                                    <h4>Sell to Customers Worldwide</h4>
                                    <p>
                                        Lorem ipsum dolor sit amet consectetur. Ut ut neque et
                                        facilisis commodo. Nec et tempor urna quam elit ut cras
                                        blandit vel. Enim purus scelerisque at turpis leo.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default HomeWhySeller