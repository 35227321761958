import React, { useEffect, useState } from 'react';
import parse from 'html-react-parser'
import { v4 as uuidv4 } from 'uuid';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Thumbs, Controller } from 'swiper/modules'; // Correct import paths for Swiper modules
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';
import axios from 'axios';
import './scss/BuyerProductDetailsContent.css';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import BuyerRelatedProducts from '../BuyerRelatedProducts/BuyerRelatedProducts'
import BuyerFrequentlyBroughtTogether from '../BuyerFrequentlyBroughtTogether/BuyerFrequentlyBroughtTogether'
import BuyerTechnicalDetails from '../BuyerTechnicalDetails/BuyerTechnicalDetails'
import BuyerCustomerRatingReviews from '../BuyerCustomerRatingReviews/BuyerCustomerRatingReviews'
import { getFrequentlyBroughtTogetherData, getProductDetailData, getProductImageData, getProductVariantColourSizeData, getProductVariationDetailData, getRelatedProductsData } from '../../../Redux/action/BuyerAction'

import Slider from "react-slick";
import Loader from '../../../Loader';
import ErrorLoader from '../../../Error/ErrorLoader';
import { useDispatch, useSelector } from 'react-redux';
import toast from 'react-hot-toast';

let host = process.env.REACT_APP_API_SERVER;


const BuyerProductDetailsContent = ({ ColourDataListApiData, AssignSizeDataListApiData }) => {



    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const { name } = useParams();
    const [variant, setVariant] = useState(null);
    const [productId, setProductId] = useState(null);

    const authToken = localStorage.getItem('buyer_token');
    const guestToken = localStorage.getItem('guest_token');


    useEffect(() => {

        // dispatch(getProductDetailData(name));
        if (authToken) {
            dispatch(getRelatedProductsData(name, authToken));
        }
        else {
            dispatch(getRelatedProductsData(name));
        }
        // dispatch(getProductVariantColourSizeData(name));
        dispatch(getFrequentlyBroughtTogetherData(name))

    }, [name]);




    const [selectedColor, setSelectedColor] = useState('');
    const [selectedSize, setSelectedSize] = useState('');
    const [currentOriginalPrice, setCurrentOriginalPrice] = useState('');
    const [currentSellingPrice, setCurrentSellingPrice] = useState('');
    const [currentDiscountPercentage, setCurrentDiscountPercentage] = useState('');



    useEffect(() => {
        const query = new URLSearchParams(location.search);
        const colorId = query.get('colorId') || '';
        const sizeId = query.get('sizeId') || '';

        setSelectedColor(colorId);
        setSelectedSize(sizeId);
        if (authToken) {
            dispatch(getProductDetailData(name, colorId, sizeId, 'auth-token', authToken)); // Fetch product details based on colorId
        }
        else if (guestToken) {
            dispatch(getProductDetailData(name, colorId, sizeId, 'guest-token', guestToken)); // Fetch product details based on colorId
        }
        else {
            const guestId = uuidv4();
            localStorage.setItem('guest_token', guestId);
            dispatch(getProductDetailData(name, colorId, sizeId, 'guest-token', guestToken)); // Fetch product details based on colorId
        }
        dispatch(getProductImageData(name, colorId, sizeId));
        dispatch(getProductVariationDetailData(name, colorId, sizeId));
    }, [location.search, dispatch, name, authToken, guestToken]);



    const {
        loadingProductDetailDataListApi,
        ProductDetailDataListApiData,
        ProductDetailDataListApiError,

        loadingRelatedProductDataListApi,
        RelatedProductDataListApiData,
        RelatedProductDataListApiError,

        loadingProductVariantSizeColourListApi,
        ProductVariantSizeColourListApiData,
        ProductVariantSizeColourListApiError,

        loadingProductImageDataListApi,
        ProductImageDataListApiData,
        ProductImageDataListApiError,

        loadingFrequentlyBroughtTogetherDataListApi,
        FrequentlyBroughtTogetherDataListApiData,
        FrequentlyBroughtTogetherDataListApiError,

        loadingProductVariationDetailDataListApi,
        ProductVariationDetailDataListApiData,
        ProductVariationDetailDataListApiError,

    } = useSelector(state => state?.BuyerReducer);

    const [quantity, setQuantity] = useState(1);
    useEffect(() => {
        setQuantity(ProductDetailDataListApiData?.quantityInCart ? Number(ProductDetailDataListApiData?.quantityInCart) : 1)
    }, [ProductDetailDataListApiData])
    const [isDataReady, setIsDataReady] = useState(false);

    useEffect(() => {
        if (ProductDetailDataListApiData && ProductVariationDetailDataListApiData) {
            setIsDataReady(true);
            setProductId(ProductDetailDataListApiData?.id)
            setVariant(ProductVariationDetailDataListApiData?.id);
        }
    }, [ProductDetailDataListApiData, ProductVariationDetailDataListApiData]);



    useEffect(() => {
        if (ProductVariationDetailDataListApiData) {

            setCurrentOriginalPrice(ProductVariationDetailDataListApiData.originalPrice);
            setCurrentSellingPrice(ProductVariationDetailDataListApiData.sellingPrice);
            setCurrentDiscountPercentage(ProductVariationDetailDataListApiData.discountPercentage);
        } else if (ProductDetailDataListApiData) {

            setCurrentOriginalPrice(ProductDetailDataListApiData.originalPrice);
            setCurrentSellingPrice(ProductDetailDataListApiData.sellingPrice);
            setCurrentDiscountPercentage(ProductDetailDataListApiData.discountPercentage);
        }
    }, [ProductVariationDetailDataListApiData, ProductDetailDataListApiData]);

    const handleColorChange = (colorId) => {
        const query = new URLSearchParams(location.search);
        query.set('colorId', colorId);
        query.delete('sizeId'); // Reset sizeId when color changes
        navigate({ search: query.toString() });

        setSelectedColor(colorId);
        setSelectedSize(''); // Reset size when color changes
    };

    const handleSizeChange = (e) => {
        const sizeId = e.target.value;
        const query = new URLSearchParams(location.search);
        query.set('colorId', selectedColor);
        query.set('sizeId', sizeId);
        navigate({ search: query.toString() });

        setSelectedSize(sizeId);
    };


    const features = ProductDetailDataListApiData?.features ? JSON.parse(ProductDetailDataListApiData?.features) : [];




    const [slider1, setSlider1] = useState(null);
    const [slider2, setSlider2] = useState(null);

    // Configuration for the main slider
    const SliderForSettings = {
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        asNavFor: slider2, // Link to the navigation slider
        infinite: ProductImageDataListApiData?.length > 1,
        spaceBetween: 0,
    };

    // Configuration for the navigation slider (with thumbnails)
    const SliderNavSettings = {
        dots: false,
        arrows: true,
        infinite: ProductImageDataListApiData?.length > 4,
        autoplay: true,
        autoplaySpeed: 5000,
        slidesToShow: 4,
        slidesToScroll: 1,

        centerMode: false,
        focusOnSelect: true,
        asNavFor: slider1, // Link to the main slider
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 780,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    arrows: false,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    arrows: false,
                }
            }
        ]
    };


    useEffect(() => {
        if (isDataReady) {
            const config = {
                headers: {

                    'content-type': 'application/json',
                    "auth-token": localStorage.getItem("buyer_token")
                }
            };
            const storeProductInterest = async () => {
                try {
                    // console.log('productId', ProductDetailDataListApiData?.id, 'variantid', ProductVariationDetailDataListApiData?.id);

                    await axios.post(`${host}/buyer/store-more-of-what-you-like`, {
                        productId: ProductDetailDataListApiData?.id,
                        productVarientId: ProductDetailDataListApiData?.variation ? ProductVariationDetailDataListApiData?.id : undefined
                    }, config)
                        .then(function (response) {
                            if (response.status === 200) {
                                // toast.success("More of what you like updated successfully");
                            } else {
                                // toast.error(response.data.message);
                            }
                        })
                        .catch(function (error) {
                            console.log(error);
                            // toast.error(error.response.data.message);
                        });
                } catch (error) {
                    console.error('Error storing product interest:', error);
                }
            };

            const timer = setTimeout(() => {
                storeProductInterest();
            }, 2000);


            return () => clearTimeout(timer);
        }
    }, [isDataReady, ProductDetailDataListApiData?.id, ProductVariationDetailDataListApiData?.id]);

    const handleCartClick = async () => {


        if (ProductDetailDataListApiData?.availability == '1') {
            try {
                const value = {
                    productId: ProductDetailDataListApiData?.id,
                    productVarientId: ProductVariationDetailDataListApiData?.id ? ProductVariationDetailDataListApiData?.id : undefined,
                    quantity,

                }
                if (authToken) {
                    const config = {
                        headers: {

                            'content-type': 'application/json',
                            "auth-token": localStorage.getItem("buyer_token")
                        }
                    };
                    if (quantity > 0) {
                        await axios.post(`${host}/buyer/add-product-buyer-cart`, value, config)
                            .then(function (response) {
                                if (response.status == 200) {

                                    toast.success("Product added to cart");
                                    setQuantity(0)
                                    navigate('/buyer/cart')

                                } else {

                                    toast.error(response.data.message);
                                }

                            })
                            .catch(function (error) {

                                console.log(error)
                                toast.error(error.response.data.message);
                            })
                    }
                    else {
                        toast.error('please add product to add to cart')
                    }
                }
                else {
                    if (guestToken) {
                        const config = {
                            headers: {

                                'content-type': 'application/json',
                                "guest-token": localStorage.getItem("guest_token")
                            }
                        };
                        if (quantity > 0) {
                            await axios.post(`${host}/buyer/add-product-buyer-cart`, value, config)
                                .then(function (response) {
                                    if (response.status == 200) {

                                        toast.success("Product added to cart");
                                        setQuantity(0)
                                        navigate('/buyer/cart')

                                    } else {

                                        toast.error(response.data.message);
                                    }

                                })
                                .catch(function (error) {

                                    console.log(error)
                                    toast.error(error.response.data.message);
                                })
                        }
                        else {
                            toast.error('please add product to add to cart')
                        }
                    }
                    else {
                        const guestId = uuidv4();
                        localStorage.setItem('guest_token', guestId);
                        const config = {
                            headers: {

                                'content-type': 'application/json',
                                "guest-token": localStorage.getItem("guest_token")
                            }
                        };
                        if (quantity > 0) {
                            await axios.post(`${host}/buyer/add-product-buyer-cart`, value, config)
                                .then(function (response) {
                                    if (response.status == 200) {

                                        toast.success("Product added to cart");
                                        // setQuantity(1)
                                        navigate('/buyer/cart')

                                    } else {

                                        toast.error(response.data.message);
                                    }

                                })
                                .catch(function (error) {

                                    console.log(error)
                                    toast.error(error.response.data.message);
                                })
                        }
                        else {
                            toast.error('please add product to add to cart')
                        }
                    }
                }
            } catch (error) {
                console.error(error);
            }
        }
        else {
            toast.error('Product not in stock right now')
        }
    };


    const handleBuyNowClick = () => {
        if (ProductDetailDataListApiData?.availability == '1') {
            const cartItem = {
                productId: ProductDetailDataListApiData?.id,
                name: ProductDetailDataListApiData?.name,
                productVarientId: ProductVariationDetailDataListApiData?.id,
                quantity: quantity > 0 ? quantity : 1,
                originalPrice: currentOriginalPrice,
                sellingPrice: currentSellingPrice,
                discountPercentage: currentDiscountPercentage,
                image: ProductImageDataListApiData?.[0]?.image,
                colourId: selectedColor,
                sizeId: selectedSize,

            };

            if (localStorage.getItem('cartItems')) {
                localStorage.removeItem('cartItems');
                localStorage.setItem('cartItems', JSON.stringify([cartItem]));
                navigate('/buyer/checkout');
            }
        }
        else {
            toast.error('Product not in stock right now')
        }

    };

    // useEffect(() => {
    //     if (productId && quantity && variant) {
    //         console.log('inside all')
    //         updateCart(quantity);
    //     }
    //     else if (productId && quantity) {
    //         console.log('inside both')
    //         updateCart(quantity);
    //     }

    // }, [quantity, productId, variant]);



    const handleIncrease = () => {

        setQuantity(prevQuantity => prevQuantity + 1);
    };

    const handleDecrease = () => {
        if (quantity > 1) {

            setQuantity(prevQuantity => prevQuantity - 1);
        }
    };


    const renderStars = (rating) => {
        const stars = [];
        for (let i = 1; i <= 5; i++) {
            if (i <= rating) {
                stars.push(<i key={i} className="fa fa-star filled-star" />);
            } else {
                stars.push(<i key={i} className="fa fa-star empty-star" />);
            }
        }
        return stars;
    };

    // if (loadingProductDetailDataListApi) {
    //     return <div className="overlay-form-submission">
    //         <Loader />
    //     </div>
    // }

    if (ProductDetailDataListApiError) {
        return <ErrorLoader message={ProductDetailDataListApiError} />;
    }

    //  if (loadingRelatedProductDataListApi) {
    //     return <div className="overlay-form-submission">
    // <Loader />
    //     </div>
    // }

    if (RelatedProductDataListApiError) {
        return <ErrorLoader message={RelatedProductDataListApiError} />;
    }


    return (
        <>
            <div className="buyer-product-details">

                {/* Breadcrumb */}
                {/* <div className="breadcrumb_section">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-xl-12 col-lg-12 col-md-6 col-sm-12 col-12">
                                <div className="breadcrumb_section_content">
                                    <h4 className="mb-0">
                                        1-16 of over 2,000 results for <span>“phone”</span>
                                    </h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
                {/* Breadcrumb */}
                {/* Product Details */}
                <div className="product_details">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-xl-6 col-lg-6 col-md-6 col-12 mb-0">
                                <div className="section-one-parent-one">
                                    <div className="card border-0">
                                        <div className="card-body ">
                                            <Slider
                                                {...SliderForSettings}
                                                asNavFor={slider2}
                                                ref={(slider) => (setSlider1(slider))}
                                                className="slider-for product_main_slider img-sec"
                                            >

                                                {ProductImageDataListApiData?.map((slider1img) => (
                                                    <div>
                                                        <img
                                                            src={slider1img?.image ? `${host}/${slider1img?.image}` : ''}
                                                            alt="Product 1"
                                                            className="img-fluid"
                                                        />
                                                    </div>
                                                ))}





                                            </Slider>
                                            <div className="slider-sec px-5">
                                                <Slider
                                                    {...SliderNavSettings}

                                                    asNavFor={slider1}
                                                    ref={(slider) => (setSlider2(slider))}
                                                    className="row slider-nav"
                                                >

                                                    {ProductImageDataListApiData?.map((slider2img) => (
                                                        <div className="col-xl-3 col-lg-3">
                                                            <div className="card">
                                                                <div className="card-body">
                                                                    <div className="img-sec">
                                                                        <img
                                                                            src={slider2img?.image ? `${host}/${slider2img?.image}` : ''}
                                                                            alt="Product 1"
                                                                            className="img-fluid"
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}


                                                </Slider>
                                                {/* Navigation Buttons */}
                                                <div className="button_section">
                                                    <div className="swiper-button-next" onClick={() => slider1.slickNext()}>
                                                        {/* Next Button */}
                                                        <i className="fa-solid fa-arrow-right" />
                                                    </div>
                                                    <div className="swiper-button-prev" onClick={() => slider1.slickPrev()}>
                                                        {/* Previous Button */}
                                                        <i className="fa-solid fa-arrow-left" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                                <div className="product_details_content">
                                    <ul>
                                        {renderStars(ProductDetailDataListApiData?.avgRating)}
                                        <li>
                                            {ProductDetailDataListApiData?.avgRating} Star Rating <span>({ProductDetailDataListApiData?.reviewCount ? ProductDetailDataListApiData?.reviewCount : '0'} User feedback)</span>
                                        </li>
                                    </ul>
                                    <h3>
                                        {ProductDetailDataListApiData?.name}
                                    </h3>
                                    <div className="sku_section">
                                        <table>
                                            <tbody>
                                                <tr>
                                                    {/* <td>
                                                        variant: <strong>{variant}</strong>
                                                    </td> */}
                                                    <td>
                                                        Availability:{" "}
                                                        <strong className={ProductDetailDataListApiData?.availability == '1' ? 'green_stock' : 'red_stock'}>{ProductDetailDataListApiData?.availability == '1' ? 'In Stock' : 'Out of Stock'}</strong>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        Brand: <strong>{ProductDetailDataListApiData?.brand?.name}</strong>
                                                    </td>
                                                    <td>
                                                        Category: <strong>{ProductDetailDataListApiData?.Category?.name}</strong>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="product_details_price">
                                        <h2>
                                            AFN.{currentSellingPrice} <del>AFN.{currentOriginalPrice}</del> <span>{currentDiscountPercentage}% OFF</span>
                                        </h2>
                                    </div>
                                    {ProductDetailDataListApiData?.variation && (
                                        <div className="product-details_color mt-3">
                                            {ProductDetailDataListApiData?.typeOfVarient !== "Size" && (
                                                <div className="details_color_left">
                                                    <p>Color</p>
                                                    <ul>
                                                        {ProductDetailDataListApiData?.distinctColors?.map((color) => (
                                                            <li key={color.id}>
                                                                <span
                                                                    className={`color-span ${selectedColor == color.id ? 'active' : ''}`}
                                                                    style={{ backgroundColor: color.name.toLowerCase() }}
                                                                    onClick={() => handleColorChange(color.id)}
                                                                />
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </div>


                                            )}
                                            {ProductDetailDataListApiData?.typeOfVarient !== "Colour" && (
                                                <div className="details_color_right">
                                                    <p>Size</p>
                                                    <select name="product-size" value={selectedSize} onChange={handleSizeChange}>
                                                        <option value="">Select Size</option>
                                                        {ProductDetailDataListApiData?.sizes?.map((size) => (
                                                            <option key={size.id} value={size.id}>
                                                                {size.name}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                            )}
                                            {ProductDetailDataListApiData?.typeOfVarient !== "Colour" &&
                                                <div className="size_chart">
                                                    <p><a style={{ cursor: 'pointer' }} data-bs-toggle="modal" data-bs-target="#exampleModal1"><img src='/assets/images/size_chart.png' />Size Chart</a></p>

                                                </div>}
                                        </div>
                                    )}
                                    <div className="quantity_section">
                                        <div className="quantity_number">
                                            <button className="btn btn--minus" type="button" name="button" onClick={handleDecrease}>
                                                <span>-</span>
                                            </button>
                                            <input
                                                className="quantity"
                                                type="text"
                                                name="quantity"
                                                value={quantity}
                                                readOnly
                                            />
                                            <button className="btn btn--plus" type="button" name="button" onClick={handleIncrease}>
                                                <span>+</span>
                                            </button>
                                        </div>
                                        <div className="add_to_cart">
                                            <a
                                                onClick={handleCartClick}
                                            >
                                                Add to Cart <i className="fa fa-shopping-cart" />
                                            </a>
                                        </div>
                                        <div className="buy_now_btn">
                                            <a
                                                onClick={handleBuyNowClick}
                                            >
                                                Buy now
                                            </a>
                                        </div>
                                    </div>
                                    <div className="vender_list">
                                        <h4>Additional Info.</h4>
                                        <p>{ProductDetailDataListApiData?.additionalInformation ? parse(ProductDetailDataListApiData?.additionalInformation) : ''}
                                        </p>
                                    </div>
                                    {/* <div className="payment_option">
                  <p>100% Guarantee Safe Checkout</p>
                  <img src="/assets/images/payment-method.png" className="img-fluid">
              </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Product Details */}
                {/* Description */}
                <div className="description_section">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                <div className="description_content description_list">
                                    <h2 className='mb-3'>Manufacturing Info</h2>
                                    <ul>
                                        {ProductDetailDataListApiData?.manufacturingInfo ? parse(ProductDetailDataListApiData?.manufacturingInfo) : ''}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Description */}
                {/* Description */}
                <div className="description_section">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                <div className="description_content">
                                    <h2 className='mb-3'>Description</h2>
                                    <p>
                                        {ProductDetailDataListApiData?.productDescription ? parse(ProductDetailDataListApiData?.productDescription) : ''}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Description */}
                {/* Related Products */}
                <BuyerRelatedProducts RelatedProductDataListApiData={RelatedProductDataListApiData} />
                {/* Related Products */}
                {/* Frequently bought together */}
                <BuyerFrequentlyBroughtTogether FrequentlyBroughtTogetherDataListApiData={FrequentlyBroughtTogetherDataListApiData} />
                {/* Frequently bought together */}
                {/* Features */}
                <BuyerTechnicalDetails features={features} />
                {/* Features */}
                {/* Customer reviews */}
                <BuyerCustomerRatingReviews variantId={variant} productId={ProductDetailDataListApiData?.id} />
                {/* Customer reviews */}


            </div>

            <div
                className="modal fade"
                id="exampleModal1"
                tabIndex={-1}
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <button type="button" hidden={true} className="btn-close" id='close-modal2' data-bs-dismiss="modal" aria-label="Close" />
                        <div className="modal-body">
                            <div className="order-place text-center">

                                <div className="order-image">
                                    <img
                                        src={ProductDetailDataListApiData?.sizeChart ? `${host}/${ProductDetailDataListApiData?.sizeChart}` : ''}
                                        alt="order-place"
                                        className="img-fluid"
                                    />
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>

    )
}

export default BuyerProductDetailsContent