import React from 'react'
import { Link } from 'react-router-dom';
import "./Scss/Homepage.css";

const HomeStartSellerJourney = () => {
    return (
        <>
            <section className="padding-b-t-50">
                <div className="container">
                    <div className="row main_row_xs">
                        <div className="col-lg-6 col-md-6">
                            <div className="aboutus_content_banner">
                                <h2>
                                    Start Your{" "}
                                    <a href="#" className="color_bazar">
                                        Seller{" "}
                                    </a>{" "}
                                    Journey{" "}
                                </h2>
                                <p>
                                    Join our family of 14 Lakh+ businesses who sell on Afghan Bazaar{" "}
                                </p>
                                <div className="main_banner_button">
                                    <Link to="/seller/sign-in" className="Start-color">
                                        Start Selling
                                    </Link>
                                </div>
                                <p>It takes only 15 minutes to setup your account</p>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6">
                            <div className="banner_images">
                                <img src={`${process.env.PUBLIC_URL}/assets/images/seller_images/Seller.png`} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default HomeStartSellerJourney