import React from 'react'
import '../scss/OtpVerification.css';
import { Link } from 'react-router-dom';

const OtpVerification = () => {
    return (
        <section className="otp">
            <div className="container-fluid">
                <div className="row welcome-login-main">
                    <div className="col-lg-6 col-md-5 px-5 form-section">
                        <div className="fields-section">
                            <div className="image-sec text-center pt-3">
                                <img src="/assets/images/otp-verification.png" alt="" />
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-7 p-0">
                        <div className="otp-banner-sec px-5 pt-5">
                            <div className="opt-number-sec text-center">
                                <h3>Verification Code</h3>
                                <p>
                                    We have sent the verification code
                                    <br /> to your email address
                                </p>
                                <div className="enter-opt d-flex justify-content-center">
                                    <div className="col-md-6 ">
                                        <input

                                            type="text"
                                            className='form-control p-2'
                                            aria-label="first digit"
                                        />
                                    </div>
                                    {/* <input
                                        id="otp-first"
                                        type="number"
                                        min={0}
                                        max={9}
                                        step={1}
                                        aria-label="first digit"
                                    />
                                    <input
                                        id="otp-second"
                                        type="number"
                                        min={0}
                                        max={9}
                                        step={1}
                                        aria-label="second digit"
                                    />
                                    <input
                                        id="otp-third"
                                        type="number"
                                        min={0}
                                        max={9}
                                        step={1}
                                        aria-label="third digit"
                                    />
                                    <input
                                        id="otp-fourth"
                                        type="number"
                                        min={0}
                                        max={9}
                                        step={1}
                                        aria-label="fourth digit"
                                    /> */}
                                </div>
                                <div className="resend-sec">
                                    <p>Don't receive OTP code ?</p>
                                    <h6>
                                        <a href="#">Resend Code</a>
                                    </h6>
                                </div>
                                <div className="verify-btn">
                                    <Link href="/buyer/home">
                                        <button>Verify &amp; Proceed</button>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    )
}

export default OtpVerification