import React, { useEffect } from 'react';
import { Link } from 'react-router-dom'
import AOS from 'aos';
import 'aos/dist/aos.css'; // Import AOS styles
let host = process.env.REACT_APP_API_SERVER;
const ShopByCategories = ({ HomeShopByCategorySectionDataListApiData }) => {
    useEffect(() => {
        // Initialize AOS animations
        AOS.init({
            duration: 1000, // Duration of animations
            offset: 0,
            once: false, // Whether animation should happen only once - while scrolling down
            mirror: true, // Whether elements should animate out while scrolling past them
        });

        // Refresh AOS to recalculate positions
        AOS.refresh();

        return () => {
            // Cleanup function to reset AOS if needed
            AOS.refreshHard(); // Reset AOS to avoid any memory leaks
        };
    }, []);
    return (
        <div className="shop_categories">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mb-3">
                        <div className="headeing">
                            <h1>Shop by Categories</h1>
                        </div>
                    </div>
                </div>
                {HomeShopByCategorySectionDataListApiData?.length > 0 && (
                    <div className="row">
                        {/* First item */}
                        <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12 mb-3">
                            <div className="category_section category_section_1" data-aos="fade-up" data-aos-delay="200">
                                <div className="category_section_content">
                                    <h2>{HomeShopByCategorySectionDataListApiData[0]?.name}</h2>
                                    <Link
                                        to={`/buyer/product-listing?subcatslug=${HomeShopByCategorySectionDataListApiData[0]?.slug}`}>
                                        Explore <i className="fa-solid fa-arrow-right-long" />
                                    </Link>
                                </div>
                                <img src={HomeShopByCategorySectionDataListApiData[0]?.image ?
                                    `${host}${HomeShopByCategorySectionDataListApiData[0]?.image}` : ''}
                                    alt={HomeShopByCategorySectionDataListApiData[0]?.name} className="img-fluid" />
                            </div>
                        </div>

                        {/* Second column with next two items */}
                        <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12">
                            {HomeShopByCategorySectionDataListApiData?.slice(1, 3).map((item, index) => (
                                <div className="category_section category_section_2 mb-3" key={index} data-aos="fade-up"
                                    data-aos-delay={(index + 1) * 100}>
                                    <div className="category_section_content">
                                        <h2>{item?.name}</h2>
                                        <Link to={`/buyer/product-listing?subcatslug=${item?.slug}`}> Explore <i
                                            className="fa-solid fa-arrow-right-long" />
                                        </Link>
                                    </div>
                                    <img src={item?.image ? `${host}${item?.image}` : ''} alt={item?.name} className="img-fluid" />
                                </div>
                            ))}
                        </div>

                        {/* Last column with remaining items in nested row */}
                        <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                            <div className="row">
                                {HomeShopByCategorySectionDataListApiData.slice(3).map((item, index) => (
                                    <div className="category_section category_section_2 mb-3" key={index} data-aos="fade-up"
                                        data-aos-delay={(index + 1) * 50}>
                                        <div className="category_section_content">
                                            <h2>{item?.name}</h2>
                                            <Link to={`/buyer/product-listing?subcatslug=${item?.slug}`}> Explore <i
                                                className="fa-solid fa-arrow-right-long" />
                                            </Link>
                                        </div>
                                        <img src={item?.image ? `${host}${item?.image}` : ''} alt={item?.name} className="img-fluid" />
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                )}

            </div>
        </div>
    )
}

export default ShopByCategories