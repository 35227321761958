import React from 'react'
import BuyerAddNewAddressContent from './BuyerAddNewAddressContent'
import BuyerHeader from '../../BuyerHeader/BuyerHeader'
import BuyerFooter from '../../BuyerFooter/BuyerFooter'

const BuyerAddNewAddress = () => {
    return (
        <>
            <BuyerHeader />
            <BuyerAddNewAddressContent />
            <BuyerFooter />
        </>
    )
}

export default BuyerAddNewAddress