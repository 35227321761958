import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css'; // Import AOS styles
import BuyerHeader from '../BuyerHeader/BuyerHeader';
import BuyerHeaderWithoutLogin from '../BuyerHeader/BuyerHeaderWithoutLogin';
import BuyerFooter from '../BuyerFooter/BuyerFooter';
import BannerSlider from './BannerSlider';

const BuyerLandingPageHome = () => {
    const authToken = localStorage.getItem("buyer_token");

    useEffect(() => {
        // Initialize AOS animations
        AOS.init({
            duration: 1000, // Duration of animations
            offset:0,
            once: true, // Whether animation should happen only once - while scrolling down
            mirror: false, // Whether elements should animate out while scrolling past them
        }); 

        // Refresh AOS to recalculate positions
        AOS.refresh();

        return () => {
            // Cleanup function to reset AOS if needed
            AOS.refreshHard(); // Reset AOS to avoid any memory leaks
        };
    }, [  AOS.init  ]); // Empty dependency array means this runs once on mount

    return (
        <div key={authToken ? 'logged-in' : 'logged-out'}>
            {authToken ? (
                <BuyerHeader />
            ) : (
                <BuyerHeaderWithoutLogin />
            )}
            <BannerSlider />
            <BuyerFooter />
        </div>
    );
};

export default BuyerLandingPageHome;
