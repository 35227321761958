import React, { useEffect, useState } from 'react'

import { Link, useNavigate } from 'react-router-dom'
import axios from 'axios'
import toast from 'react-hot-toast';
import Slider from "react-slick";
import { useDispatch, useSelector } from 'react-redux';
import './scss/BuyerOrdersContent.css'

import BuyerYourOrdersContent from './BuyerYourOrders/BuyerYourOrdersContent'

import BuyerCompletedOrdersContent from './BuyerCompletedOrders/BuyerCompletedOrdersContent'
import BuyerCancelledOrdersContent from './BuyerCancelledOrders/BuyerCancelledOrdersContent'
import BuyerInProgessOrdersContent from './BuyerInProgessOrders/BuyerInProgessOrdersContent'
import BuyerReturnOrderListContent from './BuyerReturnOrderList/BuyerReturnOrderListContent'
import { getBuyerOrderList } from '../../Redux/action/BuyerAction'




const BuyerOrdersContent = () => {


    const dispatch = useDispatch();
    const navigate = useNavigate();
    const authToken = localStorage.getItem("buyer_token")

    useEffect(() => {
        if (authToken) {
            dispatch(getBuyerOrderList(authToken, ''));

        }
        else {
            // toast.error('Please Sign In To Continue');
            navigate('/Buyer/sign-in');
        }

    }, [authToken]);


    const {
        //Buyer order list
        loadingBuyerOrderListApi,
        BuyerOrderListApiData,
        BuyerOrderListApiError,

    } = useSelector(state => state?.BuyerReducer);
    const OrderData = BuyerOrderListApiData?.data
    let InProgressOrdersData = [], CompletedOrdersData = [], CancelledOrdersData = [], ReturnOrdersData = []
    useEffect(() => {
        if (OrderData) {
            InProgressOrdersData = OrderData?.filter(order =>
                [2, 3, 4, 5].includes(Number(order.orderStatus))
            );

            CompletedOrdersData = OrderData?.filter(order =>
                [6].includes(Number(order.orderStatus))
            );
            CancelledOrdersData = OrderData?.filter(order =>
                [11].includes(Number(order.orderStatus))
            );
            ReturnOrdersData = OrderData?.filter(order =>
                [7].includes(Number(order.orderStatus))
            );
        }
    }, [OrderData]);


    const handleSearch = (e) => {

        const searchString = e.target.value;
        if (searchString !== '' || searchString !== undefined || searchString !== null) {
            dispatch(getBuyerOrderList(authToken, searchString));
        }
        else {
            dispatch(getBuyerOrderList(authToken, ''));
        }
    }
    return (
        <div className='buyer-orders'>
            <>
                {/* Breadcrumb */}
                <div className="breadcrumb_section">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                <div className="breadcrumb_section_content">
                                    <ul>
                                        <li>
                                            <Link to="/buyer/your-account"> Your Account</Link>
                                        </li>
                                        <li>
                                            <i className="fa-solid fa-angle-right" />
                                        </li>
                                        <li>
                                            <Link to="/buyer/orders">Orders</Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Breadcrumb */}
                {/* Your order */}
                <div className="order_section">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                <div className="cancel_order_heading">
                                    <div className="cancel_order_heading_left">
                                        <h2>Your Orders</h2>
                                    </div>
                                    <div className="cancel_order_heading_left">
                                        <div className="cancel_order_search">
                                            <input
                                                className="search-txt"
                                                type="text"
                                                name=""
                                                onChange={handleSearch}
                                                placeholder="Search all orders"
                                            />
                                            <i className="fa-solid fa-magnifying-glass" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {BuyerOrderListApiData?.message == 'Your account is inactivated.' ?
                            <div className=" container-fluid my-5 py-5 main-wish-list">

                                <div className="d-flex justify-content-center pt-4 py-5 heading-sec px-4">
                                    <img src="/assets/images/inactive-account.png" width={200} alt="" srcSet="" />

                                </div>

                                <h1 className='text-center text-danger'>Your account is inacitvated</h1>
                            </div>

                            :
                            <div className="row">
                                <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link active" id="your-orders-tab" data-bs-toggle="pill" data-bs-target="#your-orders" type="button" role="tab" aria-controls="your-orders" aria-selected="true">Your Orders</button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link" id="in-progress-orders-tab" data-bs-toggle="pill" data-bs-target="#in-progress-orders" type="button" role="tab" aria-controls="in-progress-orders" aria-selected="false">In Progress</button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link" id="completed-orders-tab" data-bs-toggle="pill" data-bs-target="#completed-orders" type="button" role="tab" aria-controls="completed-orders" aria-selected="false">Completed</button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link" id="cancelled-orders-tab" data-bs-toggle="pill" data-bs-target="#cancelled-orders" type="button" role="tab" aria-controls="cancelled-orders" aria-selected="false">Cancelled</button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link" id="return-order-list-tab" data-bs-toggle="pill" data-bs-target="#return-order-list" type="button" role="tab" aria-controls="return-order-list" aria-selected="false">Return Order</button>
                                    </li>

                                </ul>
                                <div className="tab-content" id="pills-tabContent">
                                    <div className="tab-pane fade show active" id="your-orders" role="tabpanel" aria-labelledby="your-orders-tab">
                                        <BuyerYourOrdersContent BuyerOrderListApiData={OrderData} />
                                    </div>
                                    <div className="tab-pane fade" id="in-progress-orders" role="tabpanel" aria-labelledby="in-progress-orders-tab">
                                        <BuyerInProgessOrdersContent InProgressOrdersData={InProgressOrdersData} />
                                    </div>
                                    <div className="tab-pane fade" id="completed-orders" role="tabpanel" aria-labelledby="completed-orders-tab">
                                        <BuyerCompletedOrdersContent CompletedOrdersData={CompletedOrdersData} />
                                    </div>
                                    <div className="tab-pane fade" id="cancelled-orders" role="tabpanel" aria-labelledby="cancelled-orders-tab">
                                        <BuyerCancelledOrdersContent CancelledOrdersData={CancelledOrdersData} />
                                    </div>
                                    <div className="tab-pane fade" id="return-order-list" role="tabpanel" aria-labelledby="return-order-list-tab">
                                        <BuyerReturnOrderListContent ReturnOrdersData={ReturnOrdersData} />
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
                {/* Your order */}
            </>



        </div>

    )
}

export default BuyerOrdersContent