import React from 'react'
import BuyerProfileChangeEmailContent from './BuyerProfileChangeEmailContent'
import BuyerHeader from '../../BuyerHeader/BuyerHeader'
import BuyerFooter from '../../BuyerFooter/BuyerFooter'

const BuyerProfileChangeEmail = () => {
    return (
        <>
            <BuyerHeader />
            <BuyerProfileChangeEmailContent />
            <BuyerFooter />
        </>
    )
}

export default BuyerProfileChangeEmail