const sellerInitialState = {
    //home banner
    loadingSellerRegistrationDataListApi: false,
    SellerRegistrationDataListApiData: [],
    SellerRegistrationDataListApiError: '',

    //home banner
    loadingSellerAllBillingAddressListApi: false,
    SellerAllBillingAddressListApiData: [],
    SellerAllBillingAddressListApiError: '',

    //home banner
    loadingSellerBillingAddressListApi: false,
    SellerBillingAddressListApiData: [],
    SellerBillingAddressListApiError: '',

}

const BuyerReducer = (state = sellerInitialState, action) => {
    switch (action.type) {

        case 'FETCH_SELLER_REGISTRATION_DETAILS_REQUEST':
            return {
                ...state,
                loadingSellerRegistrationDataListApi: true,
                SellerRegistrationDataListApiError: '',
            };
        case 'FETCH_SELLER_REGISTRATION_DETAILS_SUCCESS':
            return {
                ...state,
                loadingSellerRegistrationDataListApi: false,
                SellerRegistrationDataListApiData: action.payload,
            };
        case 'FETCH_SELLER_REGISTRATION_DETAILS_FAILURE':

            return {
                ...state,
                loadingSellerRegistrationDataListApi: false,
                SellerRegistrationDataListApiError: action.payload,
            };


        case 'FETCH_SELLER_ALL_BILLING_ADDRESS_REQUEST':
            return {
                ...state,
                loadingSellerAllBillingAddressListApi: true,
                SellerAllBillingAddressListApiError: '',
            };
        case 'FETCH_SELLER_ALL_BILLING_ADDRESS_SUCCESS':
            return {
                ...state,
                loadingSellerAllBillingAddressListApi: false,
                SellerAllBillingAddressListApiData: action.payload,
            };
        case 'FETCH_SELLER_ALL_BILLING_ADDRESS_FAILURE':

            return {
                ...state,
                loadingSellerAllBillingAddressListApi: false,
                SellerAllBillingAddressListApiError: action.payload,
            };


        case 'FETCH_SELLER_BILLING_ADDRESS_REQUEST':
            return {
                ...state,
                loadingSellerBillingAddressListApi: true,
                SellerBillingAddressListApiError: '',
            };
        case 'FETCH_SELLER_BILLING_ADDRESS_SUCCESS':
            return {
                ...state,
                loadingSellerBillingAddressListApi: false,
                SellerBillingAddressListApiData: action.payload,
            };
        case 'FETCH_SELLER_BILLING_ADDRESS_FAILURE':

            return {
                ...state,
                loadingSellerBillingAddressListApi: false,
                SellerBillingAddressListApiError: action.payload,
            };



        default:
            return state;
    }
}
export default BuyerReducer;