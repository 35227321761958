import React from 'react'
import SellerHeader from '../SellerEssentials/SellerHeader'
import SellerSidebar from '../SellerEssentials/SellerSidebar'

const SellerContactUs = () => {
    return (
        <div className='seller'>
            <div className="page-wrapper chiller-theme toggled">
                <SellerSidebar />
                {/* sidebar-wrapper  */}
                <main className="page-content container-fluid">
                    {/* ==================header================== */}
                    <SellerHeader />
                    {/* ==================header================== */}
                    <div className="row">
                        <div className="col-md-12">
                            <div className="main-nav-Order">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <h2>Help &amp; Contact Us</h2>
                                    </div>
                                </div>
                                <div className="card_order_main">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="product-card-main">
                                                <div className="row">
                                                    <div className="col-md-6 border-right">
                                                        <div className="main_contact_info">
                                                            <h4>Contact Information</h4>
                                                            <p>
                                                                Lorem Ipsum&nbsp;is simply dummy text of the <br />
                                                                printing and typesetting industry.{" "}
                                                            </p>
                                                            <a href="#">
                                                                <span>
                                                                    <img src="/assets/images/seller_images/phone_g.png" alt="phone" />
                                                                </span>
                                                                +91 1234567890
                                                            </a>
                                                            <a href="#">
                                                                <span>
                                                                    <img src="/assets/images/seller_images/email_g.png" alt="email_g" />
                                                                </span>
                                                                dummy@gmail.com
                                                            </a>
                                                            <a href="#">
                                                                <span>
                                                                    <img src="/assets/images/seller_images/loc_icon.png" alt="loc_icon" />
                                                                </span>
                                                                Lorem Ipsum&nbsp;is simply dummy text of the
                                                                printing and type <br /> setting industry.
                                                            </a>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="row">
                                                            <div className="col-md-6">
                                                                <div className="form-group log_in_main">
                                                                    <label>Name</label>
                                                                    <input
                                                                        type="text"
                                                                        defaultValue=""
                                                                        placeholder="Enter Name"
                                                                        className="form-control"
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <div className="form-group log_in_main">
                                                                    <label>Email</label>
                                                                    <input
                                                                        type="text"
                                                                        defaultValue=""
                                                                        placeholder="Enter Email"
                                                                        className="form-control"
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-12">
                                                                <div className="form-group log_in_main">
                                                                    <label>Message</label>
                                                                    <textarea
                                                                        name="Message"
                                                                        className="form-control mt-2"
                                                                        placeholder="Message..."
                                                                        defaultValue={""}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>

            {/* ==============script linking start=============== */}
            {/* ==============script linking end=============== */}
        </div>

    )
}

export default SellerContactUs