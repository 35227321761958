import { combineReducers } from "redux"

import SellerReducer from './SellerReducer';
import BuyerReducer from './BuyerReducer';
import MasterReducer from './MasterReducer';


const rootReducer = combineReducers({
    SellerReducer,
    BuyerReducer,
    MasterReducer

})

export default rootReducer;