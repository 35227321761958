import { React, useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { useFormik } from "formik";
import { toast } from 'react-hot-toast';
import * as Yup from "yup";
import axios from 'axios';
import './scss/BuyerContactUsContent.css'
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../../Loader';
import ErrorLoader from '../../../Error/ErrorLoader';
import { getWebsiteSettingData } from '../../../Redux/action/MasterAction';

const host = process.env.REACT_APP_API_SERVER
const BuyerContactUsContent = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getWebsiteSettingData());
    }, []);
    const {

        loadingWebsiteSettingDataListApi,
        WebsiteSettingDataListApiData,
        WebsiteSettingDataListApiError,


    } = useSelector(state => state?.MasterReducer);

    const config = {
        headers: {

            'content-type': 'application/json',
            "auth-token": localStorage.getItem("buyer_token")
        }
    };
    const formik = useFormik({
        initialValues: {
            firstName: "",
            lastName: "",
            email: "",
            phone: "",
            subject: "",
            message: "",

        },
        validationSchema: Yup.object().shape({
            firstName: Yup.string().required("First Name is required").matches(/^[a-zA-Z .]+$/, "Name can only contain letters."),
            lastName: Yup.string().matches(/^[a-zA-Z .]+$/, "Name can only contain letters."),
            phone: Yup.string().required("Phone Number is required").matches(/^[0-9]{8,15}$/, "Please enter a valid Mobile Number."),
            email: Yup.string().required("Email is required.").matches(/^\w+[-\.\w]*@(?!(?:)\.com$)\w+[-\.\w]*?\.\w{2,4}$/, "Enter a valid E-mail"),

            subject: Yup.string().required("Subject is required"),
            message: Yup.string().required("Message is required"),
        }),
        onSubmit: async (values, { setSubmitting, resetForm }) => {
            try {
                setSubmitting(true);


                await axios.post(`${host}/buyer/buyer-contact-us`, values, config)
                    .then(function (response) {
                        if (response.status == 200) {
                            setSubmitting(false)
                            toast.success("Your query subitted successfully");
                            resetForm();

                        } else {
                            setSubmitting(false)
                            toast.error(response.data.message);
                        }

                    })
                    .catch(function (error) {
                        setSubmitting(false)
                        console.log(error)
                        toast.error(error.response.data.message);
                    })
            }
            catch (error) {
                setSubmitting(false)
                toast.error('Error submitting form. Please try again.');
            }
        }
    })

    if (loadingWebsiteSettingDataListApi) {
        return <div className="overlay-form-submission">
            <Loader />
        </div>
    }

    if (WebsiteSettingDataListApiError) {
        return <ErrorLoader message={WebsiteSettingDataListApiError} />;
    }

    return (
        <div className='buyer-contact-us'>
            <>
                {/* Breadcrumb */}
                <div className="breadcrumb_section">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                <div className="breadcrumb_section_content">
                                    <ul>
                                        <li>
                                            <Link to="/buyer/your-account"> Your Account</Link>
                                        </li>
                                        <li>
                                            <i className="fa-solid fa-angle-right" />
                                        </li>
                                        <li>
                                            <Link to="/buyer/contact-us">Contact us</Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Breadcrumb */}
                {/* Profile */}
                <div className="profile_section">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                <div className="profile_heading border-bottom pb-2 mb-4">
                                    <h2>Contact us</h2>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                <div className="contact_us_section">
                                    <div className="row">
                                        <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                                            <div className="contact_us_section_left h-100">
                                                <h4>Contact Information</h4>
                                                {/* <p>
                                                    Lorem Ipsum&nbsp;is simply dummy text of the printing and
                                                    typesetting industry.
                                                </p> */}
                                                <ul>
                                                    <li>
                                                        <a className='text-decoration-none' href={`tel:${WebsiteSettingDataListApiData?.mobile ? WebsiteSettingDataListApiData?.mobile : ""}`}>
                                                            <i className="fa-solid fa-phone-volume" />
                                                            {WebsiteSettingDataListApiData?.mobile ? WebsiteSettingDataListApiData?.mobile : ""}</a>
                                                    </li>
                                                    <li>
                                                        <a className='text-decoration-none' href={`mailto:${WebsiteSettingDataListApiData?.email ? WebsiteSettingDataListApiData?.email : ""}`}>
                                                            <i className="fa-solid fa-envelope" />
                                                            {WebsiteSettingDataListApiData?.email ? WebsiteSettingDataListApiData?.email : ""}</a>
                                                    </li>
                                                    <li>
                                                        <a className='text-decoration-none'>
                                                            <i className="fa-solid fa-location-dot" />
                                                            {WebsiteSettingDataListApiData?.location}
                                                        </a>
                                                    </li>
                                                </ul>
                                                <div className="social_media_icon">
                                                    <ul>
                                                        <li>
                                                            <a href={WebsiteSettingDataListApiData?.facebook} target='_blank'>
                                                                <i className="fa-brands fa-facebook" />
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href={WebsiteSettingDataListApiData?.instagram} target='_blank'>
                                                                <i className="fa-brands fa-instagram" />
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href={`https://wa.me/${WebsiteSettingDataListApiData?.whatsapp ? WebsiteSettingDataListApiData?.whatsapp : ""}`}>
                                                                <i className="fa-brands fa-whatsapp" />

                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href={WebsiteSettingDataListApiData?.twitter} target='_blank'>
                                                                <i className="fa-brands fa-x-twitter" />
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div className="circle" />
                                            </div>
                                        </div>
                                        <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12">
                                            <div className="contact_us_section_right">
                                                <form onSubmit={formik.handleSubmit}>
                                                    <div className="row">
                                                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 mb-3">
                                                            <div className="contact_us_section_right_field">
                                                                <label htmlFor="fname">First Name</label>
                                                                <input type="text" id="fname"
                                                                    name="firstName"
                                                                    placeholder='Enter your first name'
                                                                    onChange={formik.handleChange}
                                                                    onBlur={formik.handleBlur}
                                                                    value={formik.values.firstName} />

                                                                <div className="help-block with-errors">
                                                                    {formik.touched.firstName && formik.errors.firstName ? (
                                                                        <div className='text-danger'>{formik.errors.firstName}</div>
                                                                    ) : null}
                                                                </div>

                                                            </div>

                                                        </div>
                                                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 mb-3">
                                                            <div className="contact_us_section_right_field">
                                                                <label htmlFor="lname">Last Name</label>
                                                                <input type="text" id="lname" name="lastName"
                                                                    placeholder="Enter your last name"
                                                                    onChange={formik.handleChange}
                                                                    onBlur={formik.handleBlur}
                                                                    value={formik.values.lastName} />
                                                                <div className="help-block with-errors">
                                                                    {formik.touched.lastName && formik.errors.lastName ? (
                                                                        <div className='text-danger'>{formik.errors.lastName}</div>
                                                                    ) : null}
                                                                </div>
                                                            </div>

                                                        </div>
                                                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 mb-3">
                                                            <div className="contact_us_section_right_field">
                                                                <label htmlFor="email">Email</label>
                                                                <input type="email" id="email" name="email"
                                                                    placeholder="Enter your email"
                                                                    onChange={formik.handleChange}
                                                                    onBlur={formik.handleBlur}
                                                                    value={formik.values.email} />

                                                            </div>
                                                            <div className="help-block with-errors">
                                                                {formik.touched.email && formik.errors.email ? (
                                                                    <div className='text-danger'>{formik.errors.email}</div>
                                                                ) : null}
                                                            </div>
                                                        </div>
                                                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 mb-3">
                                                            <div className="contact_us_section_right_field">
                                                                <label htmlFor="phone_number">Phone Number</label>
                                                                <input
                                                                    type="tel"
                                                                    id="phone_number"
                                                                    name="phone"
                                                                    placeholder="Enter your phone number"
                                                                    onChange={formik.handleChange}
                                                                    onBlur={formik.handleBlur}
                                                                    value={formik.values.phone} />

                                                            </div>
                                                            <div className="help-block with-errors">
                                                                {formik.touched.phone && formik.errors.phone ? (
                                                                    <div className='text-danger'>{formik.errors.phone}</div>
                                                                ) : null}
                                                            </div>
                                                        </div>
                                                        <h2>Select Subject?</h2>
                                                    </div>
                                                    <div className="row">
                                                        <div className="radio_buttom_section">
                                                            <div className="form-check">
                                                                <input
                                                                    className="form-check-input"
                                                                    type="radio"
                                                                    value="Order Issues"
                                                                    id="general_inquiry"
                                                                    checked={formik.values.subject === 'Order Issues'}
                                                                    name='subject'
                                                                    onChange={formik.handleChange}
                                                                    onBlur={formik.handleBlur}
                                                                />
                                                                <label
                                                                    className="form-check-label"
                                                                    htmlFor="general_inquiry"
                                                                >
                                                                    Order Issues
                                                                </label>
                                                            </div>
                                                            <div className="form-check">
                                                                <input
                                                                    className="form-check-input"
                                                                    type="radio"
                                                                    value="Delivery Problems"
                                                                    checked={formik.values.subject === 'Delivery Problems'}
                                                                    name='subject'
                                                                    onChange={formik.handleChange}
                                                                    onBlur={formik.handleBlur}
                                                                    id="general_inquiry_1"
                                                                />
                                                                <label
                                                                    className="form-check-label"
                                                                    htmlFor="general_inquiry_1"
                                                                >
                                                                    Delivery Problems
                                                                </label>
                                                            </div>
                                                            <div className="form-check">
                                                                <input
                                                                    className="form-check-input"
                                                                    type="radio"
                                                                    value="Payment Issues"
                                                                    checked={formik.values.subject === 'Payment Issues'}
                                                                    name='subject'
                                                                    onChange={formik.handleChange}
                                                                    onBlur={formik.handleBlur}
                                                                    id="general_inquiry_2"
                                                                />
                                                                <label
                                                                    className="form-check-label"
                                                                    htmlFor="general_inquiry_2"
                                                                >
                                                                    Payment Issues
                                                                </label>
                                                            </div>
                                                            <div className="form-check">
                                                                <input
                                                                    className="form-check-input"
                                                                    type="radio"
                                                                    value="Product Inquiries"
                                                                    checked={formik.values.subject === 'Product Inquiries'}
                                                                    name='subject'
                                                                    onChange={formik.handleChange}
                                                                    onBlur={formik.handleBlur}
                                                                    id="general_inquiry_3"
                                                                />
                                                                <label
                                                                    className="form-check-label"
                                                                    htmlFor="general_inquiry_3"
                                                                >
                                                                    Product Inquiries
                                                                </label>
                                                            </div>

                                                        </div>
                                                        <div className="help-block with-errors">
                                                            {formik.touched.subject && formik.errors.subject ? (
                                                                <div className='text-danger'>{formik.errors.subject}</div>
                                                            ) : null}
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                                            <div className="contact_us_section_right_field">
                                                                <label htmlFor="message" className="message">
                                                                    Message
                                                                </label>
                                                                <textarea
                                                                    className="w-100 remove-border"
                                                                    name="message"
                                                                    id="message"
                                                                    onChange={formik.handleChange}
                                                                    onBlur={formik.handleBlur}
                                                                    value={formik.values.message}
                                                                />
                                                                <div className="help-block with-errors">
                                                                    {formik.touched.message && formik.errors.message ? (
                                                                        <div className='text-danger'>{formik.errors.message}</div>
                                                                    ) : null}
                                                                </div>
                                                                <div className="text-end">
                                                                    <button type="submit" disabled={formik.isSubmitting}>Send Message</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Profile */}
            </>





        </div>

    )
}

export default BuyerContactUsContent