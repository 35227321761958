import React from 'react'
import BuyerHeader from '../BuyerHeader/BuyerHeader'
import BuyerFooter from '../BuyerFooter/BuyerFooter'
import BuyerOrderDetailsContent from './BuyerOrderDetailsContent'

const BuyerOrderDetails = () => {
    return (
        <>
            <BuyerHeader />
            <BuyerOrderDetailsContent />
            <BuyerFooter />
        </>
    )
}

export default BuyerOrderDetails