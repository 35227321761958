import React from 'react'
import SellerHeader from '../SellerEssentials/SellerHeader'
import SellerSidebar from '../SellerEssentials/SellerSidebar'
import { Link } from 'react-router-dom'
import '../Scss/Seller.css'
const SellerMySubscription = () => {
    return (
        <div className='seller'>
            <div className="page-wrapper chiller-theme toggled">
                <SellerSidebar />
                {/* sidebar-wrapper  */}
                <main className="page-content container-fluid">
                    {/* ==================header================== */}
                    <SellerHeader />
                    {/* ==================header================== */}
                    <div className="row">
                        <div className="col-md-12">
                            <div className="main-nav-Order">
                                <div className="main_detail_head">
                                    <h2>
                                        {" "}
                                        <Link to="/seller/profile">My Subscription </Link>
                                    </h2>
                                </div>
                                <div className="detail_heading border-buttom">
                                    <div className="row">
                                        <div className="col-lg-3 ">
                                            <div className="main_detail_order main_detail_section">
                                                <p>MEMBERSHIP AND BILLING</p>
                                                <Link
                                                    type="button"
                                                    data-bs-toggle="modal"
                                                    data-bs-target="#send_approval"
                                                    className="return_order-main-new"
                                                >
                                                    cancel Subscription
                                                </Link>
                                            </div>
                                        </div>
                                        <div className="col-lg-9">
                                            <div className="main_detail_order main_detail_section">
                                                <p>alexthomas@gmail.com</p>
                                                <p>Password: ***********</p>
                                                <p>phone : +1-541-754-3010</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="detail_heading border-buttom">
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <div className="main_detail_order main_detail_section text-center">
                                                <p>Purchasing Date: April 31, 2024.</p>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="main_detail_order main_detail_section text-right">
                                                <p>Expiry Date: April 31, 2024.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="detail_heading border-buttom">
                                    <div className="row">
                                        <div className="col-lg-3 col-md-4 col-sm-3">
                                            <div className="main_detail_order main_detail_section text-left">
                                                <Link to="">4932********1029</Link>
                                            </div>
                                        </div>
                                        <div className="col-lg-5 col-md-4 col-sm-3">
                                            <div className="main_detail_order main_detail_section text-center">
                                                <Link to="">Basic</Link>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-4 col-sm-3">
                                            <div className="main_detail_order main_detail_section text-right">
                                                <Link to="">Change Plan</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="detail_heading">
                                    <div className="row">
                                        <div className="col-lg-9">
                                            <h3 className="main_det_header">Active Plan</h3>
                                            <div className="table_detail_tariff table_d_main table-responsive  border bg-light">
                                                <table className="posts-table geeks">
                                                    <tbody className="text-cen">
                                                        <tr className="main_table_row_new gfg">
                                                            <td
                                                                width="50%"
                                                                className="main_access_head posts-table-b"
                                                            >
                                                                <h3>Access to All Content </h3>
                                                                <p>Lorem Ipsum&nbsp;is simply dummy text</p>
                                                            </td>
                                                            <td width="50%" className="posts-table-w">
                                                                <h5>Basic Plan</h5>
                                                                <h4> AFN. 9.99/Month</h4>
                                                                <h6>Lorem Ipsum&nbsp;is simply dummy text</h6>
                                                            </td>
                                                        </tr>
                                                        <tr className="main_table_row_new gfg">
                                                            <td
                                                                width="50%"
                                                                className="main_access_head posts-table-b posts-detail-text"
                                                            >
                                                                <h6>Lorem Ipsum&nbsp;is simply</h6>
                                                            </td>
                                                            <td width="50%" className="posts-table-w">
                                                                <div className="text-center">
                                                                    <img src="/assets/images/seller_images/icon_1.png" alt="icon" />
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <tr className="main_table_row_new gfg">
                                                            <td
                                                                width="50%"
                                                                className="main_access_head posts-table-b posts-detail-text"
                                                            >
                                                                <p>
                                                                    Lorem Ipsum&nbsp;is simply dummy text of the
                                                                    printing and typesetting industry.&nbsp;
                                                                </p>
                                                            </td>
                                                            <td width="50%" className="posts-table-w">
                                                                <div className="text-center">
                                                                    <img src="/assets/images/seller_images/into.png" alt="icon" />
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <tr className="main_table_row_new gfg">
                                                            <td
                                                                width="50%"
                                                                className="main_access_head posts-table-b posts-detail-text"
                                                            >
                                                                <p>
                                                                    Lorem Ipsum&nbsp;is simply dummy text of the
                                                                    printing and typesetting industry.&nbsp;
                                                                </p>
                                                            </td>
                                                            <td width="50%" className="posts-table-w">
                                                                <div className="text-center">
                                                                    <img src="/assets/images/seller_images/icon_1.png" alt="icon" />
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <tr className="main_table_row_new gfg">
                                                            <td
                                                                width="50%"
                                                                className="main_access_head posts-table-b posts-detail-text"
                                                            >
                                                                <p>
                                                                    Lorem Ipsum&nbsp;is simply dummy text of the
                                                                    printing and typesetting industry.&nbsp;
                                                                </p>
                                                            </td>
                                                            <td width="50%" className="posts-table-w">
                                                                <div className="text-center">
                                                                    <img src="/assets/images/seller_images/icon_1.png" alt="icon" />
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <tr className="main_table_row_new gfg">
                                                            <td
                                                                width="50%"
                                                                className="main_access_head posts-table-b posts-detail-text"
                                                            >
                                                                <p>
                                                                    Lorem Ipsum&nbsp;is simply dummy text of the
                                                                    printing and typesetting industry.&nbsp;
                                                                </p>
                                                            </td>
                                                            <td width="50%" className="posts-table-w">
                                                                <div className="text-center">
                                                                    <img src="/assets/images/seller_images/into.png" alt="icon" />
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
            <div
                className="modal fade"
                id="send_approval"
                data-bs-backdrop="static"
                data-bs-keyboard="false"
                tabIndex={-1}
                aria-labelledby="staticBackdropLabel"
                style={{ display: "none" }}
                aria-hidden="true"
            >
                <div className="modal-dialog">
                    <div className="modal-content main-in-popup-space mt-main-10 ">
                        <div className="modal-body">
                            <div className="request_send_for_modul">
                                <h6>Are you sure you Cancel your subscriptions Plan</h6>
                                <p>
                                    Do you really want to cancel this plan? This process can not be
                                    undone.
                                </p>
                                <div className="text-center">
                                    <Link type="button" className="btn send-approval-butt me-2">
                                        Keep Plan
                                    </Link>
                                    <Link
                                        type="button"
                                        className="btn btn-secondary"
                                        data-bs-dismiss="modal"
                                    >
                                        Cancel Subscription
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* ==============script linking start=============== */}
            {/* ==============script linking end=============== */}
        </div>

    )
}

export default SellerMySubscription