import { React, useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useFormik } from "formik";
import { toast } from 'react-hot-toast';
import * as Yup from "yup";
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { getSellerRegistrationDetails } from '../../Redux/action/SellerAction';

const host = process.env.REACT_APP_API_SERVER
const BillingAddressAdd = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const sellerIncompleteToken = localStorage.getItem('seller_incomplete_token')
    useEffect(() => {
        if (!sellerIncompleteToken) {

            toast.error('Please register to continue')
            navigate('/seller/create-account')
        }
    }, [sellerIncompleteToken])


    let [initialValues, setInitialValues] = useState({
        firstName: "",
        lastName: "",

        address: "",
        pincode: "",

        city: "",
        state: "",
        TypeOfAddress: "",


    })

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: Yup.object().shape({


            firstName: Yup.string().required("Name is required").matches(/^[a-zA-Z .]+$/, "Name can only contain letters."),
            lastName: Yup.string().matches(/^[a-zA-Z .]+$/, "Name can only contain letters."),
            pincode: Yup.string().required("Zip code is required").matches(/^[0-9]+$/, 'Zip code must contain only numbers')
                .min(4, 'Enter a valid Zip code')
                .required('Zip code is required'),
            typeOfAddress: Yup.string().required("Type of address is required"),
            state: Yup.string().required("Province is required"),
            city: Yup.string().required("City is required"),
            address: Yup.string().required("Address is required"),
        }),
        onSubmit: async (values, { setSubmitting, resetForm }) => {

            try {
                if (sellerIncompleteToken) {

                    const config = {
                        headers: {

                            'content-type': 'application/json',
                            "auth-token": sellerIncompleteToken
                        }
                    };
                    setSubmitting(true);
                    values.fullDetails = 0;
                    await axios.post(`${host}/seller/seller-add-billing-address`, values, config)
                        .then(function (response) {
                            if (response.status == 200) {
                                setSubmitting(false)

                                toast.success(response.data.message);
                                resetForm();
                                navigate('/seller/billing-address-list')

                            } else {

                                setSubmitting(false)

                                toast.error(response.data.message);
                                if (response.data.delAccount) {
                                    localStorage.removeItem('seller_incomplete_token')
                                }

                            }

                        })
                        .catch(function (error) {
                            setSubmitting(false)
                            toast.error(error.message);

                        })


                }
                else {
                    navigate('/seller/create-account')
                }

            }
            catch (error) {


                setSubmitting(false)
                toast.error('Error submitting form. Please try again.');

            }

        }
    });



    return (
        <div className='seller'>
            <main className="main_login_section container-fluid">
                <div className="row">
                    <div className="col-lg-7 main_bg_detail">
                        <div className="main_login_detail detail_form_Shipping">
                            <form method='post' onSubmit={formik.handleSubmit}>
                                <div className="logo_login">
                                    <img src={`${process.env.PUBLIC_URL}/assets/images/seller_images/login_logo.png`} alt="login_logo" />
                                </div>
                                <div className="detail_form_log detail_form_Shipping">
                                    <h3>Billing/Business Address</h3>
                                    <p>
                                        Please enter your data to complete your account registration
                                        process
                                    </p>
                                    <div className="main_detail_address mb-5">
                                        <div className="row">
                                            <div className="col-lg-6 col-md-6 col-sm-6">
                                                <div className="form-group log_in_main">
                                                    <label>First Name</label>
                                                    <input
                                                        type="text"

                                                        name='firstName'
                                                        className='form-control'
                                                        placeholder='Enter your first name'
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        value={formik.values.firstName}
                                                    />
                                                    <div className="help-block with-errors">
                                                        {formik.touched.firstName && formik.errors.firstName ? (
                                                            <div className='text-danger'>{formik.errors.firstName}</div>
                                                        ) : null}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-sm-6">
                                                <div className="form-group log_in_main">
                                                    <label>Last Name</label>
                                                    <input
                                                        type="text"
                                                        name='lastName'
                                                        className='form-control'
                                                        placeholder='Enter last name'
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        value={formik.values.lastName}
                                                    />
                                                    <div className="help-block with-errors">
                                                        {formik.touched.lastName && formik.errors.lastName ? (
                                                            <div className='text-danger'>{formik.errors.lastName}</div>
                                                        ) : null}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-12 col-md-12 col-sm-12">
                                                <div className="form-group log_in_main">
                                                    <label>Enter Full Address</label>
                                                    <input
                                                        type="text"
                                                        name='address'
                                                        className='form-control'
                                                        placeholder='Enter your full address'
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        value={formik.values.address}
                                                    />
                                                    <div className="help-block with-errors">
                                                        {formik.touched.address && formik.errors.address ? (
                                                            <div className='text-danger'>{formik.errors.address}</div>
                                                        ) : null}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-12 col-md-12 col-sm-12">
                                                <div className="form-group log_in_main">
                                                    <label>Zip Code</label>
                                                    <input
                                                        type="text"
                                                        name='pincode'
                                                        className='form-control'
                                                        placeholder='Enter your zip code'
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        value={formik.values.pincode}
                                                    />
                                                    <div className="help-block with-errors">
                                                        {formik.touched.pincode && formik.errors.pincode ? (
                                                            <div className='text-danger'>{formik.errors.pincode}</div>
                                                        ) : null}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-6 col-md-6 col-sm-6">
                                                <div className="form-group log_in_main">
                                                    <label>Province</label>
                                                    <input
                                                        type="text"
                                                        name='state'
                                                        className='form-control'

                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        value={formik.values.state}
                                                        placeholder="Enter your province"

                                                    />
                                                </div>
                                                <div className="help-block with-errors">
                                                    {formik.touched.state && formik.errors.state ? (
                                                        <div className='text-danger'>{formik.errors.state}</div>
                                                    ) : null}
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-sm-6">
                                                <div className="form-group log_in_main">
                                                    <label>City</label>
                                                    <input
                                                        type="text"
                                                        defaultValue=""
                                                        placeholder="Enter your city"
                                                        name='city'
                                                        className='form-control'
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        value={formik.values.city}
                                                    />
                                                </div>
                                                <div className="help-block with-errors">
                                                    {formik.touched.city && formik.errors.city ? (
                                                        <div className='text-danger'>{formik.errors.city}</div>
                                                    ) : null}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-12 col-md-12 col-sm-12">
                                                <div className="Shipping_detail">
                                                    <label>
                                                        <input
                                                            type="radio"
                                                            className="input-radio off"

                                                            value="Home"

                                                            name='typeOfAddress'
                                                            onChange={formik.handleChange}
                                                            onBlur={formik.handleBlur}
                                                        />{" "}
                                                        Home
                                                    </label>
                                                    <label>
                                                        <input
                                                            type="radio"
                                                            className="input-radio on"

                                                            value="Office"

                                                            name='typeOfAddress'
                                                            onChange={formik.handleChange}
                                                            onBlur={formik.handleBlur}
                                                        />{" "}
                                                        Office
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="detail_address_button mt-2">
                                                <button type='submit' disabled={formik.isSubmitting}>Save and Continue</button>
                                                {/* <Link to="/seller/billing-address-list">Save and Continue</Link> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className="col-lg-5 main_login_img">
                        <img src={`${process.env.PUBLIC_URL}/assets/images/seller_images/Shipping.png`} alt="login" />
                    </div>
                </div>
            </main>
        </div>
    )
}

export default BillingAddressAdd