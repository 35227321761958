import { React, useEffect, useState } from 'react'
import './scss/BuyerHomeFourSectionProductListing.css'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import Typography from '@mui/material/Typography';
import { getHomeFourSectionProductData } from '../../../Redux/action/BuyerAction';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import ReactPaginate from 'react-paginate';
import { useFormik } from 'formik';
import toast from 'react-hot-toast';
import Loader from '../../../Loader';
import ErrorLoader from '../../../Error/ErrorLoader';
let host = process.env.REACT_APP_API_SERVER;

const BuyerHomeFourSectionProductListing = ({ BrandDataListApiData, ColourDataListApiData }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const { name } = useParams();
    const authToken = localStorage.getItem("buyer_token")
    const queryParams = new URLSearchParams(location.search);
    // const queryParams = new URLSearchParams(location.search);
    // const subcatslug = queryParams.get('subcatslug');
    // const childcatslug = queryParams.get('childslug');
    const [highestPrice, setHighestPrice] = useState(0);
    const [lowestPrice, setLowestPrice] = useState(10000);


    const {

        loadingHomeFourSectionProductDataListApi,
        HomeFourSectionProductDataListApiData,
        HomeFourSectionProductDataListApiError,


    } = useSelector(state => state?.BuyerReducer);

    const HomeFourSectionProductDataListApi = HomeFourSectionProductDataListApiData?.data

    useEffect(() => {

        dispatch(getHomeFourSectionProductData(authToken, name, queryParams));

    }, [dispatch, name]);


    useEffect(() => {

        if (HomeFourSectionProductDataListApiData) {
            setHighestPrice(HomeFourSectionProductDataListApiData?.highestPrice);
            setLowestPrice(HomeFourSectionProductDataListApiData?.lowestPrice)
        }
    }, [HomeFourSectionProductDataListApiData]);

    const formik = useFormik({
        initialValues: {
            searchString: '',
            sortCriteria: '',
            selectedBrands: [],
            selectedColors: [],
            selectedDiscounts: [],
            priceRange: [lowestPrice, highestPrice],
        },
        onSubmit: (values) => {
            const queryParams = new URLSearchParams();
            if (values.searchString) queryParams.set('search', values.searchString);
            if (values.sortCriteria) queryParams.set('sort', values.sortCriteria);
            if (values.selectedBrands.length) queryParams.set('brands', values.selectedBrands.join(','));
            if (values.selectedColors.length) queryParams.set('colors', values.selectedColors.join(','));
            if (values.selectedDiscounts.length) queryParams.set('discounts', values.selectedDiscounts.join(','));
            if (values.priceRange) {
                queryParams.set('minPrice', values.priceRange[0]);
                queryParams.set('maxPrice', values.priceRange[1]);
            }

            navigate(`/buyer/home-four-product-listing/${name}?${queryParams}`)
            dispatch(getHomeFourSectionProductData(authToken, name, queryParams));


        },
    });

    useEffect(() => {
        const initialValues = {
            searchString: queryParams.get('search') || '',
            sortCriteria: queryParams.get('sort') || '',
            selectedBrands: queryParams.get('brands') ? queryParams.get('brands').split(',') : [],
            selectedColors: queryParams.get('colors') ? queryParams.get('colors').split(',') : [],
            selectedDiscounts: queryParams.get('discounts') ? queryParams.get('discounts').split(',') : [],
            priceRange: [
                Number(queryParams.get('minPrice')) || lowestPrice,
                Number(queryParams.get('maxPrice')) || highestPrice,
            ],
        };

        if (JSON.stringify(formik.values) !== JSON.stringify(initialValues)) {
            formik.setValues(initialValues);
        }
    }, [location.search, lowestPrice, highestPrice]); // Only trigger on location.search change or price range change

    const handleSliderChange = (event, newValue) => {
        formik.setFieldValue('priceRange', newValue);
        formik.handleSubmit();
    };

    const handleClearFilter = () => {

        formik.resetForm();

        navigate(`/buyer/home-four-product-listing/${name}`)
        dispatch(getHomeFourSectionProductData(authToken, name, queryParams));


    }


    // Pagination
    const [currentData, setCurrentData] = useState([]);
    const [pageCount, setPageCount] = useState(0);
    const [dataOffset, setDataOffset] = useState(0);
    const dataPerPage = 20;

    useEffect(() => {
        const endOffset = dataOffset + dataPerPage;
        setCurrentData(HomeFourSectionProductDataListApi?.slice(dataOffset, endOffset));
        setPageCount(Math.ceil(Number(HomeFourSectionProductDataListApi?.length) / dataPerPage));
    }, [dataOffset, dataPerPage, HomeFourSectionProductDataListApi]);

    const handlePageClick = (event) => {
        const newOffset = (event.selected * dataPerPage) % Number(HomeFourSectionProductDataListApi?.length);
        setDataOffset(newOffset);
        window.scrollTo(0, 0);
    };

    const handleClick = (slug, productVariant) => {

        if (productVariant?.colourId && productVariant?.sizeId) {

            navigate(`/buyer/product-details/${slug}?colorId=${productVariant?.colourId}&sizeId=${productVariant?.sizeId}`)
        }
        else if (productVariant?.colourId == null && productVariant?.sizeId) {

            navigate(`/buyer/product-details/${slug}?sizeId=${productVariant?.sizeId}`)
        }
        else if (productVariant?.colourId && productVariant?.sizeId == null) {

            navigate(`/buyer/product-details/${slug}?colorId=${productVariant?.colourId}`)
        }
        else {

            navigate(`/buyer/product-details/${slug}`)

        }
    }
    const renderStars = (rating) => {
        const stars = [];
        for (let i = 1; i <= 5; i++) {
            if (i <= rating) {
                stars.push(<i key={i} className="fa fa-star filled-star" />);
            } else {
                stars.push(<i key={i} className="fa fa-star empty-star" />);
            }
        }
        return stars;
    };

    const handleWishlistClick = (id, varientId) => {
        if (authToken) {
            try {
                let values = {
                    productId: id,
                    productVarientId: varientId
                };
                let config = { headers: { "auth-token": authToken } };

                axios.post(`${host}/buyer/buyer-wishlist-product`, values, config)
                    .then((response) => {
                        if (response.status === 200) {
                            toast.success(response?.data?.message);
                            console.log('in wishlist call');

                            // Handle query parameters
                            const newQueryParams = new URLSearchParams(location.search);
                            // newQueryParams.delete('minPrice'); // Clear out any duplicate parameters
                            // newQueryParams.delete('maxPrice');

                            // Navigate with cleaned query parameters
                            navigate(`/buyer/home-four-product-listing/${name}?${newQueryParams.toString()}`);
                            dispatch(getHomeFourSectionProductData(authToken, name, newQueryParams.toString()));
                        } else {
                            toast.error(response?.data?.message);
                        }
                    })
                    .catch((error) => {
                        toast.error(error?.response?.data?.message);
                    });
            } catch (error) {
                toast.error('An error occurred while adding to wishlist');
            }
        } else {
            navigate('/buyer/sign-in');
            toast.error('Please sign in to continue');
        }
    };


    if (loadingHomeFourSectionProductDataListApi) {
        return <div className="overlay-form-submission">
            <Loader />
        </div>
    }

    if (HomeFourSectionProductDataListApiError) {
        return <ErrorLoader message={HomeFourSectionProductDataListApiError} />;
    }

    return (
        <>
            <div className="buyer-product-listing">
                {/* Breadcrumb */}
                {/* <div className="breadcrumb_section">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                <div className="breadcrumb_section_content">
                                    <h4 className="mb-0">
                                        Over {HomeFourSectionProductDataListApi?.length} results for <span>“phone”</span>
                                    </h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
                {/* Breadcrumb */}
                <div className="product_listing">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-xl-3 col-lg-3 col-md-5 col-sm-12 col-12">
                                <div className="product_listing_left">
                                    <div className="filetr_heading">
                                        <h4>Filters</h4>
                                        <div onClick={handleClearFilter} style={{ cursor: 'pointer' }}>Clear all</div>
                                    </div>
                                    {/* <div className="filter_badge">
                                        <span className="badge badge-secondary">
                                            <i className="fa-solid fa-xmark" /> Tag for Brand
                                        </span>
                                        <span className="badge badge-secondary">
                                            <i className="fa-solid fa-xmark" /> Tag for Clothes
                                        </span>
                                        <span className="badge badge-secondary">
                                            <i className="fa-solid fa-xmark" /> Tag for Clothes Size
                                        </span>
                                    </div> */}
                                    <div className="brand_section">
                                        <div className="brand_heading">
                                            <h4>Brand</h4>
                                        </div>
                                        <div className="brand_section_left brand_section_scroll">
                                            {BrandDataListApiData?.map((brandData) => (
                                                <div className="form-check" key={brandData.id}>
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        value={brandData.id}
                                                        checked={formik.values.selectedBrands.includes(String(brandData.id))}
                                                        onChange={(e) => {
                                                            const isChecked = e.target.checked;
                                                            const selectedBrands = formik.values.selectedBrands;
                                                            if (isChecked) {
                                                                formik.setFieldValue('selectedBrands', [...selectedBrands, String(brandData.id)]);
                                                            } else {
                                                                formik.setFieldValue('selectedBrands', selectedBrands.filter(id => id !== String(brandData.id)));
                                                            }
                                                            formik.handleSubmit();
                                                        }}
                                                    />
                                                    <label className="form-check-label" htmlFor={brandData.id}>
                                                        {brandData.name}
                                                    </label>
                                                </div>
                                            ))}
                                        </div>
                                    </div>

                                    <div className="brand_section">
                                        <div className="discount_range">
                                            <h4>Price Range</h4>

                                            <Box>
                                                <Slider
                                                    getAriaLabel={() => 'Price range'}
                                                    value={formik.values.priceRange}
                                                    onChange={handleSliderChange}
                                                    valueLabelDisplay="auto"
                                                    getAriaValueText={(value) => `${value}`}
                                                    min={lowestPrice}
                                                    max={highestPrice}
                                                />
                                                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                                    <Typography
                                                        variant="body2"
                                                        sx={{ cursor: 'pointer' }}
                                                        onClick={() => {
                                                            const newRange = [lowestPrice, formik.values.priceRange[1]];
                                                            formik.setFieldValue('priceRange', newRange);
                                                            formik.handleSubmit(); // Ensure Formik submits the updated value
                                                        }}
                                                    >
                                                        {lowestPrice} min
                                                    </Typography>
                                                    <Typography
                                                        variant="body2"
                                                        sx={{ cursor: 'pointer' }}
                                                        onClick={() => {
                                                            const newRange = [formik.values.priceRange[0], highestPrice];
                                                            formik.setFieldValue('priceRange', newRange);
                                                            formik.handleSubmit(); // Ensure Formik submits the updated value
                                                        }}
                                                    >
                                                        {highestPrice} max
                                                    </Typography>
                                                </Box>
                                            </Box>

                                        </div>
                                    </div>

                                    <div className="brand_section">
                                        <div className="brand_heading">
                                            <h4>Color</h4>
                                        </div>
                                        <div className="brand_section_left brand_section_scroll">
                                            {ColourDataListApiData?.map((colourData) => (
                                                <div className="form-check" key={colourData.id}>
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        value={colourData.id}
                                                        checked={formik.values.selectedColors.includes(String(colourData.id))}
                                                        onChange={(e) => {
                                                            const isChecked = e.target.checked;
                                                            const selectedColors = formik.values.selectedColors;
                                                            if (isChecked) {
                                                                formik.setFieldValue('selectedColors', [...selectedColors, String(colourData.id)]);
                                                            } else {
                                                                formik.setFieldValue('selectedColors', selectedColors.filter(id => id !== String(colourData.id)));
                                                            }
                                                            formik.handleSubmit();
                                                        }}
                                                    />
                                                    <label className="form-check-label" htmlFor={colourData.id}>
                                                        {colourData.name}
                                                    </label>
                                                </div>
                                            ))}
                                        </div>
                                    </div>

                                    <div className="brand_section">
                                        <div className="discount_range">
                                            <h4>Discount Range</h4>
                                        </div>
                                        <div className="brand_section_left">
                                            {[10, 20, 30, 40, 50].map(discount => (
                                                <div className="form-check" key={discount}>
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        value={discount}
                                                        checked={formik.values.selectedDiscounts.includes(String(discount))}
                                                        onChange={(e) => {
                                                            const isChecked = e.target.checked;
                                                            const selectedDiscounts = formik.values.selectedDiscounts;
                                                            if (isChecked) {
                                                                formik.setFieldValue('selectedDiscounts', [...selectedDiscounts, String(discount)]);
                                                            } else {
                                                                formik.setFieldValue('selectedDiscounts', selectedDiscounts.filter(d => d !== String(discount)));
                                                            }
                                                            formik.handleSubmit();
                                                        }}
                                                    />
                                                    <label className="form-check-label" htmlFor={discount}>
                                                        {discount}% and above
                                                    </label>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-9 col-lg-9 col-md-7 col-sm-12 col-12">
                                <div className="product_listing_right">
                                    <div className="search_bar_product_listing">
                                        <div className="input_search">
                                            <input
                                                className="search-txt"
                                                type="text"
                                                name="searchString"
                                                placeholder="Search for anything..."
                                                value={formik.values.searchString}
                                                onChange={(e) => {
                                                    formik.setFieldValue('searchString', e.target.value);
                                                    formik.handleSubmit();
                                                }}
                                            />
                                        </div>
                                        <div className="input_search">
                                            <label htmlFor="product-type" className="filter">
                                                Sort by
                                                <select
                                                    name="sortCriteria"
                                                    id="sortCriteria"
                                                    value={formik.values.sortCriteria}
                                                    onChange={(e) => {
                                                        formik.setFieldValue('sortCriteria', e.target.value);
                                                        formik.handleSubmit();
                                                    }}
                                                >
                                                    <option value="lowToHigh">Price: Low to High</option>
                                                    <option value="highToLow">Price: High to Low</option>
                                                </select>
                                            </label>
                                        </div>
                                    </div>
                                    {HomeFourSectionProductDataListApi?.length > 0 ?
                                        <><div className="row">

                                            {currentData?.map((productData) => (<div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12">
                                                <div className="shop-page-product" key={productData.id}>
                                                    <a >
                                                        <img onClick={() => { handleClick(productData?.slug, productData?.productVariant) }}
                                                            src={productData?.image ? `${host}${productData?.image}` : ''} />
                                                        <div className=" d-flex justify-content-between">
                                                            <ul>
                                                                {renderStars(productData?.avgRating)}
                                                                <li>
                                                                    <span>({productData?.ratingCount})</span>
                                                                </li>
                                                            </ul>
                                                            <Link>
                                                                {productData?.isWishlisted == true ?
                                                                    // <img src='/assets/images/wish-icon.png' onClick={() => { handleWishlistClick(data?.id, data?.productVariant?.id) }} className=' heart'></img>
                                                                    <i className="fa fa-heart filled-heart" aria-hidden="true" onClick={() => { handleWishlistClick(productData?.id, productData?.productVariant?.id) }} />


                                                                    :
                                                                    <i className="fa-sharp fa-regular fa-heart text-secondary" onClick={() => { handleWishlistClick(productData?.id, productData?.productVariant?.id) }} />
                                                                }
                                                            </Link>
                                                        </div>
                                                        <div onClick={() => { handleClick(productData?.slug, productData?.productVariant) }}>
                                                            <h4>
                                                                {productData?.name}
                                                            </h4>
                                                            <p>
                                                                <del>AFN.{productData?.originalPrice}</del> <span>AFN.{productData?.sellingPrice}</span>
                                                            </p>
                                                            <div className="hot_product">{productData?.discountPercentage}% OFF</div>
                                                            {/* <div className="best_product">SALE</div> */}
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                            ))}




                                        </div>
                                            <section className='pagination-section'>
                                                {/* <div className='container'> */}
                                                <nav aria-label="Page navigation example" className='nav-class'>
                                                    <ReactPaginate
                                                        breakLabel="..."
                                                        nextLabel={<i className="fa-solid fa-arrow-right" />}
                                                        onPageChange={handlePageClick}
                                                        pageRangeDisplayed={5}
                                                        pageCount={pageCount}
                                                        previousLabel={<i className="fa-solid fa-arrow-left" />}
                                                        renderOnZeroPageCount={null}
                                                        containerClassName='pagination'
                                                        pageLinkClassName='page-num'
                                                        previousLinkClassName='page-num'
                                                        nextLinkClassName='page-num'
                                                        activeClassName='active' />
                                                </nav>
                                                {/* </div> */}
                                            </section></>
                                        :
                                        <div className="main-wish-list">
                                            <Link className='text-decoration-none' to='/buyer/home'>
                                                <div className="d-flex justify-content-center pt-4 py-5 heading-sec px-4">
                                                    <img src="/assets/images/purchase-protection.png" alt="" srcSet="" className='img-fluid' />


                                                </div>
                                            </Link>
                                            <h1 className='text-center'><Link className='text-decoration-none empty_data' to='/buyer/home'>No Products Shop Now</Link></h1>
                                        </div>
                                    }
                                    {/* <div className="row">
                                        <div className="col-xl-12  col-lg-12 col-md-12 col-sm-12 col-12">
                                            <div className="pagination">
                                                <button id="previous">
                                                    <i className="fa-solid fa-arrow-left" />
                                                </button>
                                                <ul>
                                                    <li className="active">1</li>
                                                    <li>2</li>
                                                    <li>3</li>
                                                    <li>4</li>
                                                    <li>5</li>
                                                </ul>
                                                <button id="next">
                                                    <i className="fa-solid fa-arrow-right" />
                                                </button>
                                            </div>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>

    )
}

export default BuyerHomeFourSectionProductListing