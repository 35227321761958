import React from 'react'
import BuyerHeader from '../BuyerHeader/BuyerHeader'
import BuyerFooter from '../BuyerFooter/BuyerFooter'
import BuyerLeaveSellerFeedbackContent from './BuyerLeaveSellerFeedbackContent'

const BuyerLeaveSellerFeedback = () => {
    return (
        <>
            <BuyerHeader />
            <BuyerLeaveSellerFeedbackContent />
            <BuyerFooter />
        </>
    )
}

export default BuyerLeaveSellerFeedback