import React, { useState } from 'react';
import "./Scss/Homepage.css";
import Slider from "react-slick";
import { Link } from 'react-router-dom';
import HomeHeader from './HomeHeader';
import HomeBanner from './HomeBanner';
import HomeWhySeller from './HomeWhySeller';
import Testimonial from './Testimonial';
import HomeFooter from './HomeFooter';
import HomeHowtoSell from './HomeHowtoSell';
import HomeStartSellerJourney from './HomeStartSellerJourney';

const Homepage = () => {
    const [currentSlide, setCurrentSlide] = useState(0);

    var settings = {
        dots: false,
        prevArrow: false,
        nextArrow: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        beforeChange: (oldIndex, newIndex) => setCurrentSlide(newIndex), 
    };
    return (
        <>
            <>
                <HomeHeader />
                <main>
                    {/* =============banner ================= */}
                    <HomeBanner />
                    {/* =============banner ================= */}
                    {/* ===========Why Seller========== */}
                    <HomeWhySeller />
                    {/* ===========Why Seller========== */}
                    {/* ===========How to Sell========== */}
                    <HomeHowtoSell />
                    {/* ===========How to Sell========== */}
                    {/* ===========Seller Success Stories========== */}
                    <Testimonial />
                    {/* ===========Seller Success Stories========== */}
                    {/* =============banner ================= */}
                    <HomeStartSellerJourney />
                    {/* =============banner ================= */}
                </main>
                <HomeFooter />
            </>

        </>
    )
}

export default Homepage