import React from 'react'
// import './scss/BuyerRelatedProducts.css'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { getRelatedProductsData } from '../../../Redux/action/BuyerAction';
import axios from 'axios';
import toast from 'react-hot-toast';
import Slider from "react-slick";
import { useDispatch } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
let host = process.env.REACT_APP_API_SERVER;
const BuyerRelatedProducts = ({ RelatedProductDataListApiData }) => {
    const { name } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const authToken = localStorage.getItem("buyer_token")
    const guestToken = localStorage.getItem("guest_token")

    const handleClick = (id, varientId) => {

        if (authToken) {
            try {
                let values = {
                    productId: id,
                    productVarientId: varientId
                }
                let config = { headers: { "auth-token": authToken } };
                axios.post(`${host}/buyer/buyer-wishlist-product`, values, config)
                    .then((response) => {
                        if (response.status === 200) {

                            toast.success(response?.data?.message);
                            dispatch(getRelatedProductsData(name, authToken));
                        } else {

                            toast.error(response?.data?.message);
                        }
                    })
            } catch (error) {

                toast.error(error?.response?.data?.message);

            }
        }
        else {
            toast.error('Please sign in to continue')
            navigate('/buyer/sign-in')
        }

    }


    const handleCartClick = async (id, varientId) => {
        try {

            const value = {
                productId: id,
                productVarientId: varientId ? varientId : undefined,

            }
            if (authToken) {
                console.log('authtoken', authToken)
                const config = {
                    headers: {

                        'content-type': 'application/json',
                        "auth-token": localStorage.getItem("buyer_token")
                    }
                };
                await axios.post(`${host}/buyer/add-through-more-of-what-you-like`, value, config)
                    .then(function (response) {
                        if (response.status == 200) {

                            toast.success("Product added to cart");
                            navigate('/buyer/cart')


                        } else {

                            toast.error(response.data.message);
                        }

                    })
                    .catch(function (error) {

                        console.log(error)
                        toast.error(error.response.data.message);
                    })
            }
            else {
                if (guestToken) {
                    console.log('guestToken', guestToken)
                    const config = {
                        headers: {

                            'content-type': 'application/json',
                            "guest-token": localStorage.getItem("guest_token")
                        }
                    };
                    await axios.post(`${host}/buyer/add-through-more-of-what-you-like`, value, config)
                        .then(function (response) {
                            if (response.status == 200) {

                                toast.success("Product added to cart");
                                navigate('/buyer/cart')


                            } else {

                                toast.error(response.data.message);
                            }

                        })
                        .catch(function (error) {

                            console.log(error)
                            toast.error(error.response.data.message);
                        })
                }
                else {


                    const guestId = uuidv4();
                    localStorage.setItem('guest_token', guestId);
                    const config = {
                        headers: {

                            'content-type': 'application/json',
                            "guest-token": localStorage.getItem("guest_token")
                        }
                    };
                    console.log('no token', guestToken)
                    await axios.post(`${host}/buyer/add-through-more-of-what-you-like`, value, config)
                        .then(function (response) {
                            if (response.status == 200) {

                                toast.success("Product added to cart");
                                navigate('/buyer/cart')


                            } else {

                                toast.error(response.data.message);
                            }

                        })
                        .catch(function (error) {

                            console.log(error)
                            toast.error(error.response.data.message);
                        })

                }
            }
        } catch (error) {
            console.error(error);
        }
    };

    const handleNavigateClick = (slug, productVariant) => {

        if (productVariant?.colourId && productVariant?.sizeId) {

            navigate(`/buyer/product-details/${slug}?colorId=${productVariant?.colourId}&sizeId=${productVariant?.sizeId}`)
        }
        else if (productVariant?.colourId == null && productVariant?.sizeId) {

            navigate(`/buyer/product-details/${slug}?sizeId=${productVariant?.sizeId}`)
        }
        else if (productVariant?.colourId && productVariant?.sizeId == null) {

            navigate(`/buyer/product-details/${slug}?colorId=${productVariant?.colourId}`)
        }
        else {

            navigate(`/buyer/product-details/${slug}`)

        }
    }

    const Relatedsettings = {
        dots: false,
        arrows: false,
        infinite: RelatedProductDataListApiData?.length > 4,
        autoplay: true,
        autoplaySpeed: 3000,
        slidesToShow: 3,
        slidesToScroll: 1,
        centerMode: false,
        centerPadding: '60px',
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false
                }
            },
            {
                breakpoint: 780,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false,
                    arrows: false
                }
            },
            {
                breakpoint: 640,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false,
                    centerMode: false,
                    arrows: false
                }
            }
        ]
    };

    return (
        <div className="related_products">
            {RelatedProductDataListApiData && RelatedProductDataListApiData?.length > 0 && <div className="container-fluid">
                <div className="row">
                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                        <div className="related_products_heading">
                            <h2>Related Products</h2>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <Slider {...Relatedsettings} className='slider'>
                        {RelatedProductDataListApiData?.map((data) => (
                            <div className="" key={data?.id}>
                                <div className="related_products_inner" >
                                    <img onClick={() => { handleNavigateClick(data?.slug, data?.productVariant) }} style={{ cursor: 'pointer' }}
                                        src={data?.image ? `${host}/${data?.image}` : ''}
                                        className="img-fluid"
                                    />
                                    <p>{data?.name}</p>
                                    <div className="related_products_add_to_cart">
                                        <div className="add_to_cart">
                                            <a onClick={() => { handleCartClick(data?.id, data?.productVariant?.id) }} style={{ cursor: 'pointer' }}>
                                                Add to cart <i className="fa fa-shopping-cart" />
                                            </a>
                                        </div>
                                        <div className="product_details_wish_list">
                                            <Link to="">
                                                {data?.isWishlisted ?
                                                    <i className="fa fa-heart" onClick={() => { handleClick(data?.id, data?.productVariant?.id) }} />

                                                    :
                                                    <i className="fa-sharp fa-regular fa-heart" onClick={() => { handleClick(data?.id, data?.productVariant?.id) }} />
                                                }
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </Slider>

                </div>
            </div>}
        </div>

    )
}

export default BuyerRelatedProducts