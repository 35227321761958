import React from 'react'
import SellerHeader from '../SellerEssentials/SellerHeader'
import SellerSidebar from '../SellerEssentials/SellerSidebar'
import { Link } from 'react-router-dom'
const SellerAuthentication = () => {
    return (
        <div className='seller'>
            <div className="page-wrapper chiller-theme toggled">
                <SellerSidebar />
                {/* sidebar-wrapper  */}
                <main className="page-content container-fluid">
                    {/* ==================header================== */}
                    <SellerHeader />
                    {/* ==================header================== */}
                    <div className="row">
                        <div className="col-md-12">
                            <div className="main-nav-Order">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <h2>Authentication</h2>
                                    </div>
                                </div>
                                <div className="card_order_main">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="main_tracker">
                                                        <h4>Hello, Dummy Name</h4>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="main_tracker_edit">
                                                        <Link to="">EDIT</Link>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="product-card-main">
                                                <h4 className="mobile_email_icon">
                                                    Mobile &amp; Email verification
                                                </h4>
                                                <div className="main_Verified">
                                                    <span>
                                                        <img src="/assets/images/seller_images/phone_icon.png" alt="phone" />
                                                        +91 1234567890
                                                    </span>
                                                    <Link to="" className="btn btn-primary next-step">
                                                        Verified
                                                    </Link>
                                                </div>
                                                <div className="main_Verified">
                                                    <span>
                                                        <img src="/assets/images/seller_images/email_icon.png" alt="phone" />
                                                        dummy@gmail.com
                                                    </span>
                                                    <Link to="" className="btn btn-primary next-step">
                                                        Verified
                                                    </Link>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="main_tracker_edit">
                                                        <Link to="">EDIT</Link>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="product-card-main">
                                                <h4 className="mobile_email_icon">
                                                    ID &amp; Documentations
                                                </h4>
                                                <div className="main_Verified">
                                                    <span>
                                                        <img src="/assets/images/seller_images/hugeicons_icon.png" alt="phone" />
                                                        Identification Card/Passport no.
                                                    </span>
                                                    <Link to="" className="btn btn-primary next-step">
                                                        Verified
                                                    </Link>
                                                </div>
                                                <div className="main_Verified">
                                                    <span>
                                                        <img src="/assets/images/seller_images/mdi_business.png" alt="phone" />
                                                        Business Name
                                                    </span>
                                                    <Link to="" className="btn btn-primary next-step">
                                                        Verified
                                                    </Link>
                                                </div>
                                            </div>
                                            <div className="product-card-main text_detail_new">
                                                <h4 className="mobile_email_icon">2 Step Verification</h4>
                                                <p>
                                                    Secure your account with 2- Step Login. you will have to
                                                    enter <br />
                                                    your password and OTP eah time you log in to your account.
                                                </p>
                                                <div className="row">
                                                    <div className="col-md-7">
                                                        <h6>
                                                            (This is for web login only. You’ll always need Otp To
                                                            login on
                                                            <br /> your mobile app )
                                                        </h6>
                                                        <h5>
                                                            <span>
                                                                <img src="/assets/images/seller_images/teenyicons_tick.png" alt="img" />
                                                            </span>
                                                            2 Step Login
                                                        </h5>
                                                    </div>
                                                    <div className="col-md-5 text-right">
                                                        <Link
                                                            to="/seller/authentication_step_1"
                                                            className="btn btn-secondary"
                                                        >
                                                            Verified
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>

            {/* ==============script linking start=============== */}
            {/* ==============script linking end=============== */}
        </div>

    )
}

export default SellerAuthentication