import React from 'react'
import BuyerYourAddressContent from './BuyerYourAddressContent'
import BuyerHeader from '../../BuyerHeader/BuyerHeader'
import BuyerFooter from '../../BuyerFooter/BuyerFooter'

const BuyerYourAddress = () => {
    return (
        <>
            <BuyerHeader />
            <BuyerYourAddressContent />
            <BuyerFooter />
        </>
    )
}

export default BuyerYourAddress