import React from 'react'
import BuyerHeader from '../BuyerHeader/BuyerHeader'
import BuyerFooter from '../BuyerFooter/BuyerFooter'
import BuyerOrderItAgainContent from './BuyerOrderItAgainContent'

const BuyerOrderItAgain = () => {
    return (
        <>
            <BuyerHeader />
            <BuyerOrderItAgainContent />
            <BuyerFooter />
        </>
    )
}

export default BuyerOrderItAgain