import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import axios from 'axios'
import toast from 'react-hot-toast';
import Slider from "react-slick";

import { useDispatch, useSelector } from 'react-redux';
import { getBuyerCartProductList, getBuyerMoreOfWhatYouLikeList } from '../../Redux/action/BuyerAction';
import {
    EmailShareButton,
    FacebookShareButton,
    InstapaperShareButton,
    LineShareButton,
    LinkedinShareButton,
    PinterestShareButton,
    RedditShareButton,
    TelegramShareButton,
    TumblrShareButton,
    TwitterShareButton,
    WhatsappShareButton,
} from "react-share";

import {
    EmailIcon,
    FacebookIcon,
    FacebookMessengerIcon,
    InstapaperIcon,
    LineIcon,
    LinkedinIcon,
    PinterestIcon,
    RedditIcon,
    TelegramIcon,
    TumblrIcon,
    TwitterIcon,
    WhatsappIcon,
} from "react-share";

let host = process.env.REACT_APP_API_SERVER;
const MoreOfWhatYouLike = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const authToken = localStorage.getItem("buyer_token")

    useEffect(() => {
        if (authToken) {
            dispatch(getBuyerMoreOfWhatYouLikeList(authToken));

        }
        else {
            // toast.error('Please Sign In To Continue from more of what you like');
            // navigate('/Buyer/sign-in');
        }

    }, [authToken]);


    const {
        loadingBuyerMoreOfWhatYouLikeListApi,
        BuyerMoreOfWhatYouLikeListApiData,
        BuyerMoreOfWhatYouLikeListApiError,

    } = useSelector(state => state?.BuyerReducer);

    let config = { headers: { "auth-token": authToken } };
    const handleClick = (id, varientId) => {

        if (authToken) {
            try {
                let values = {
                    productId: id,
                    productVarientId: varientId
                }

                axios.post(`${host}/buyer/buyer-wishlist-product`, values, config)
                    .then((response) => {
                        if (response.status === 200) {

                            toast.success(response?.data?.message);
                            dispatch(getBuyerMoreOfWhatYouLikeList(authToken));
                        } else {

                            toast.error(response?.data?.message);
                        }
                    })
            } catch (error) {

                toast.error(error?.response?.data?.message);

            }
        }
        else {
            // toast.error('please sign in to continue')
            navigate('/buyer/sign-in')
        }

    }



    const handleCartClick = async (id, varientId) => {
        try {
            const value = {
                productId: id,
                productVarientId: varientId ? varientId : undefined,

            }

            await axios.post(`${host}/buyer/add-through-more-of-what-you-like`, value, config)
                .then(function (response) {
                    if (response.status == 200) {

                        toast.success("Product added to cart");

                        dispatch(getBuyerCartProductList('auth-token', authToken));

                    } else {

                        toast.error(response.data.message);
                    }

                })
                .catch(function (error) {

                    console.log(error)
                    toast.error(error.response.data.message);
                })

        } catch (error) {
            console.error(error);
        }
    };

    const handleNavigateClick = (slug, productVariant) => {

        if (productVariant?.colourId && productVariant?.sizeId) {

            navigate(`/buyer/product-details/${slug}?colorId=${productVariant?.colourId}&sizeId=${productVariant?.sizeId}`)
        }
        else if (productVariant?.colourId == null && productVariant?.sizeId) {

            navigate(`/buyer/product-details/${slug}?sizeId=${productVariant?.sizeId}`)
        }
        else if (productVariant?.colourId && productVariant?.sizeId == null) {

            navigate(`/buyer/product-details/${slug}?colorId=${productVariant?.colourId}`)
        }
        else {

            navigate(`/buyer/product-details/${slug}`)

        }
    }
    const [url, seturl] = useState();
    const handleUrlClick = (slug, productVariant) => {
        if (productVariant?.colourId && productVariant?.sizeId) {
            seturl(`${host}/buyer/product-details/${slug}?colorId=${productVariant?.colourId}&sizeId=${productVariant?.sizeId}`)

        }
        else if (productVariant?.colourId == null && productVariant?.sizeId) {
            seturl(`${host}/buyer/product-details/${slug}?sizeId=${productVariant?.sizeId}`)

        }
        else if (productVariant?.colourId && productVariant?.sizeId == null) {
            seturl(`${host}/buyer/product-details/${slug}?colorId=${productVariant?.colourId}`)

        }
        else {
            seturl(`${host}/buyer/product-details/${slug}`)


        }
    }

    const settings2 = {
        dots: false,
        arrows: false,
        infinite: BuyerMoreOfWhatYouLikeListApiData?.length > 3,
        autoplay: true,
        autoplaySpeed: 3000,
        slidesToShow: 4,
        slidesToScroll: 1,
        centerMode: false,
        centerPadding: '60px',
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false
                }
            },
            {
                breakpoint: 780,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false,
                    arrows: false
                }
            },
            {
                breakpoint: 640,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false,
                    centerMode: false,
                    arrows: false
                }
            }
        ]
    };
    return (
        <>
            {(authToken && BuyerMoreOfWhatYouLikeListApiData?.length > 0) &&
                <div>
                    <div className="row">
                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mb-3">
                            <div className="cart_items_heading">
                                <h3 className="pb-2">More of what you like</h3>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <Slider {...settings2} className='more-of-what-you-like-slider slider'>
                            {BuyerMoreOfWhatYouLikeListApiData?.map((data) => (

                                <div className="card cart_details_inner cart_info_box">
                                    <img src={data?.image ? `${host}${data?.image}` : ''} className="img-fluid" onClick={() => { handleNavigateClick(data?.slug, data?.productVariant) }} style={{ cursor: 'pointer' }} />
                                    <div className="card-body">
                                        <h5>{data?.name}</h5>

                                        <h6>
                                            {data?.brandName}
                                            {/* <span className='px-2'>
                                            4.4 <i className="fa-solid fa-star" />
                                        </span> */}
                                        </h6>
                                        <h2>
                                            AFN. {data?.sellingPrice}{" "}
                                            <span>
                                                <del>AFN. {data?.originalPrice}</del>
                                            </span>{" "}
                                            <strong>({data?.discountPercentage}% off)</strong>
                                        </h2>
                                        <div className="cart_info_btn">
                                            <div className="cart_info_btn_left">
                                                <button data-bs-toggle="modal" onClick={() => { handleUrlClick(data?.slug, data?.productVariant) }} data-bs-target="#exampleModal">
                                                    <i className="fa-solid fa-arrow-up-from-bracket"></i>
                                                </button>
                                                <Link >
                                                    {data?.isWishlisted ?
                                                        <i className="fa fa-heart" onClick={() => { handleClick(data?.id, data?.productVariant?.id) }} />

                                                        :
                                                        <i className="fa-sharp fa-regular fa-heart" onClick={() => { handleClick(data?.id, data?.productVariant?.id) }} />
                                                    }
                                                </Link>
                                            </div>
                                            <div className="cart_info_btn_right">
                                                <a onClick={() => { handleCartClick(data?.id, data?.productVariant?.id) }}>Add</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            ))}
                        </Slider>
                    </div>
                </div>

            }

            <>
                {/* Button trigger modal */}
                <button
                    hidden={true}
                    type="button"
                    className="btn btn-primary"
                    data-bs-toggle="modal"
                    data-bs-target="#exampleModal"
                >

                </button>
                {/* Modal */}
                <div
                    className="modal fade share_popup"
                    id="exampleModal"
                    tabIndex={-1}
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="true"
                >
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">
                                    Share
                                </h5>
                                <button
                                    type="button"
                                    className="btn-close"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                />
                            </div>
                            <div className="modal-body share_btn">
                                <FacebookShareButton
                                    url={url}
                                    quote={'Afghan bazaar product'}

                                >
                                    <FacebookIcon size={40} round={true} />
                                </FacebookShareButton>

                                <WhatsappShareButton
                                    url={url}
                                    quote={'Afghan bazaar product'}

                                >
                                    <WhatsappIcon size={40} round={true} />
                                </WhatsappShareButton>
                                <EmailShareButton
                                    url={url}
                                    quote={'Afghan bazaar product'}

                                >
                                    <EmailIcon size={40} round={true} />
                                </EmailShareButton>
                                <LineShareButton
                                    url={url}
                                    quote={'Afghan bazaar product'}

                                >
                                    <LineIcon size={40} round={true} />
                                </LineShareButton>
                                <LinkedinShareButton
                                    url={url}
                                    quote={'Afghan bazaar product'}

                                >
                                    <LinkedinIcon size={40} round={true} />
                                </LinkedinShareButton>
                                <PinterestShareButton
                                    url={url}
                                    quote={'Afghan bazaar product'}

                                >
                                    <PinterestIcon size={40} round={true} />
                                </PinterestShareButton>
                                <InstapaperShareButton
                                    url={url}
                                    quote={'Afghan bazaar product'}

                                >
                                    <InstapaperIcon size={40} round={true} />
                                </InstapaperShareButton>
                                <RedditShareButton
                                    url={url}
                                    quote={'Afghan bazaar product'}

                                >
                                    <RedditIcon size={40} round={true} />
                                </RedditShareButton>
                                <TelegramShareButton
                                    url={url}
                                    quote={'Afghan bazaar product'}

                                >
                                    <TelegramIcon size={40} round={true} />
                                </TelegramShareButton>
                                <TumblrShareButton
                                    url={url}
                                    quote={'Afghan bazaar product'}

                                >
                                    <TumblrIcon size={40} round={true} />
                                </TumblrShareButton>
                                <TwitterShareButton
                                    url={url}
                                    quote={'Afghan bazaar product'}

                                >
                                    <TwitterIcon size={40} round={true} />
                                </TwitterShareButton>

                            </div>
                            {/* <div className="modal-footer">
                                <button
                                    type="button"
                                    className="btn btn-secondary"
                                    data-bs-dismiss="modal"
                                >
                                    Close
                                </button>
                                <button type="button" className="btn btn-primary share_btn1">
                                Share
                                </button>
                            </div> */}
                        </div>
                    </div>
                </div>
            </>

        </>
    )
}

export default MoreOfWhatYouLike