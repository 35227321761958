import React, { useEffect } from 'react'
import BuyerHeader from '../BuyerHeader/BuyerHeader'
import BuyerFooter from '../BuyerFooter/BuyerFooter'
import BuyerWriteReviewContent from './BuyerWriteReviewContent'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import toast from 'react-hot-toast'
import { useDispatch, useSelector } from 'react-redux'
import { getBuyerReviewPageProductDetail } from '../../Redux/action/BuyerAction'

const BuyerWriteReview = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const authToken = localStorage.getItem('buyer_token');
    const { id } = useParams();
    const queryParams = new URLSearchParams(location.search);
    const variantId = queryParams.get('variantId');
    useEffect(() => {


        if (authToken) {
            if (variantId) {
                dispatch(getBuyerReviewPageProductDetail(authToken, id, variantId));
            }
            else {
                dispatch(getBuyerReviewPageProductDetail(authToken, id, ''));
            }
        }
        else {
            // toast.error('please sign in to continue')
            navigate('/buyer/sign-in')
        }

    }, [authToken]);

    const {
        // Buyer Review Page Product Detail
        loadingBuyerReviewPageProductDetailListApi,
        BuyerReviewPageProductDetailListApiData,
        BuyerReviewPageProductDetailListApiError,

    } = useSelector(state => state?.BuyerReducer);
    return (
        <>
            <BuyerHeader />
            <BuyerWriteReviewContent BuyerReviewPageProductDetailListApiData={BuyerReviewPageProductDetailListApiData} />
            <BuyerFooter />
        </>
    )
}

export default BuyerWriteReview