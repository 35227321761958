import React, { useEffect } from 'react'
import './scss/BuyerLandingPage.css'
import TrendingNow from './TrendingNow'
import SellProduct from './SellProduct'
import NewProduct from './NewProduct'
import ShopByCategories from './ShopByCategories'
import parse from 'html-react-parser'
import Bestsellers from './Bestsellers'
import { Link, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import AOS from 'aos';
import 'aos/dist/aos.css'; // Import AOS styles
import { getHomeBanner, getHomeFourSection, getHomeLastSection, getHomeSecondSection, getHomeSecondSlider, getHomeShopByCategorySection, getHomeTrendingNow } from '../../Redux/action/BuyerAction'
import Loader from '../../Loader'
import ErrorLoader from '../../Error/ErrorLoader'

let host = process.env.REACT_APP_API_SERVER;




const BannerSlider = () => {

   

    const dispatch = useDispatch();
    const authToken = localStorage.getItem("buyer_token")

    useEffect(() => {
        dispatch(getHomeBanner());
        dispatch(getHomeSecondSection());
        dispatch(getHomeFourSection());
        dispatch(getHomeShopByCategorySection());
        dispatch(getHomeSecondSlider());
        dispatch(getHomeTrendingNow(authToken));
        dispatch(getHomeLastSection());
    }, []);
    useEffect(() => {
        // Initialize AOS animations
        AOS.init({
            duration: 1200, // Duration of animations
            once: false, // Whether animation should happen only once - while scrolling down
            mirror: false, // Whether elements should animate out while scrolling past them
        }); 

        // Refresh AOS to recalculate positions
        AOS.refresh();

        return () => {
            // Cleanup function to reset AOS if needed
            AOS.refreshHard(); // Reset AOS to avoid any memory leaks
        };
    }, [  AOS.init  ]); 
    const {
        loadingHomeBannerDataListApi,
        HomeBannerDataListApiData,
        HomeBannerDataListApiError,


        loadingHomeSecondSectionDataListApi,
        HomeSecondSectionDataListApiData,
        HomeSecondSectionDataListApiError,

        loadingHomeFourSectionDataListApi,
        HomeFourSectionDataListApiData,
        HomeFourSectionDataListApiError,

        loadingHomeShopByCategorySectionDataListApi,
        HomeShopByCategorySectionDataListApiData,
        HomeShopByCategorySectionDataListApiError,



        loadingHomeSecondSliderDataListApi,
        HomeSecondSliderDataListApiData,
        HomeSecondSliderDataListApiError,
        // Buyer Home trending now
        loadingHomeTrendingNowDataListApi,
        HomeTrendingNowDataListApiData,
        HomeTrendingNowDataListApiError,
        // Buyer Last Section
        loadingHomeLastSectionDataListApi,
        HomeLastSectionDataListApiData,
        HomeLastSectionDataListApiError,

    } = useSelector(state => state?.BuyerReducer);


    // if (loadingHomeBannerDataListApi) {
    //     return <div className="overlay-form-submission">
    //         <Loader />
    //     </div>
    // }

    // if (HomeBannerDataListApiError) {
    //     return <ErrorLoader message={HomeBannerDataListApiError} />;
    // }


    // if (loadingHomeSecondSectionDataListApi) {
    //     return <div className="overlay-form-submission">
    //         <Loader />
    //     </div>
    // }

    // if (HomeSecondSectionDataListApiError) {
    //     return <ErrorLoader message={HomeSecondSectionDataListApiError} />;
    // }


    // if (loadingHomeFourSectionDataListApi) {
    //     return <div className="overlay-form-submission">
    //         <Loader />
    //     </div>
    // }

    // if (HomeFourSectionDataListApiError) {
    //     return <ErrorLoader message={HomeFourSectionDataListApiError} />;
    // }


    // if (loadingHomeShopByCategorySectionDataListApi) {
    //     return <div className="overlay-form-submission">
    //         <Loader />
    //     </div>
    // }

    // if (HomeShopByCategorySectionDataListApiError) {
    //     return <ErrorLoader message={HomeShopByCategorySectionDataListApiError} />;
    // }
    const navigate = useNavigate();
    const handleClick = (slug, productVariant) => {

        if (productVariant?.colourId && productVariant?.sizeId) {

            navigate(`/buyer/product-details/${slug}?colorId=${productVariant?.colourId}&sizeId=${productVariant?.sizeId}`)
        }
        else if (productVariant?.colourId == null && productVariant?.sizeId) {

            navigate(`/buyer/product-details/${slug}?sizeId=${productVariant?.sizeId}`)
        }
        else if (productVariant?.colourId && productVariant?.sizeId == null) {

            navigate(`/buyer/product-details/${slug}?colorId=${productVariant?.colourId}`)
        }
        else {

            navigate(`/buyer/product-details/${slug}`)

        }
    }

    return (
        <div className='BuyerLandingPage'>
            <>
                {/* Slider */}
                <div id="demo" className="carousel home_slider slide" data-bs-ride="carousel">
                    <div className='container-fluid p-0'>
                        {/* Indicators/dots */}
                        <div className="carousel-indicators">
                            {HomeBannerDataListApiData?.map((banner, index) => (
                                <button
                                    key={banner?.id}
                                    type="button"
                                    data-bs-target="#demo"
                                    data-bs-slide-to={index}
                                    className={index === 0 ? "active" : ""}
                                />
                            ))}
                        </div>
                        {/* The slideshow/carousel */}
                        <div className="carousel-inner">
                            {HomeBannerDataListApiData?.map((banner, index) => (
                                <div key={banner.id} className={`carousel-item ${index === 0 ? "active" : ""}`}>
                                    <img
                                        src={banner?.image ? `${host}/${banner?.image}` : ''}
                                        alt={`Slide ${index + 1}`}
                                        className="d-block"
                                        style={{ width: "100%" }}
                                    />
                                </div>
                            ))}
                        </div>
                        {/* Left and right controls/icons */}
                        <button
                            className="carousel-control-prev"
                            type="button"
                            data-bs-target="#demo"
                            data-bs-slide="prev"
                        >
                            <span className="carousel-control-prev-icon" />
                        </button>
                        <button
                            className="carousel-control-next"
                            type="button"
                            data-bs-target="#demo"
                            data-bs-slide="next"
                        >
                            <span className="carousel-control-next-icon" />
                        </button>
                    </div>
                </div>
                {/* Slider */}
                <>
                    {/* Product Category */}
                    <div className="product_category">
                        <div className="container-fluid" >
                            <div className="row" data-aos="fade-up" >
                                <div className="col-xl-8 col-lg-8 col-sm-6 col-12 " >
                                    <div className="card product_section h-100" >
                                        <div className="card-body " data-aos="flip-left"  >
                                            <div
                                                id="demo1"
                                                className="carousel slide"
                                                data-bs-ride="carousel"
                                            >
                                                {/* Indicators/dots */}
                                                <div className="carousel-indicators">
                                                    <button
                                                        type="button"
                                                        data-bs-target="#demo1"
                                                        data-bs-slide-to={0}
                                                        className="active"
                                                    />
                                                    <button
                                                        type="button"
                                                        data-bs-target="#demo1"
                                                        data-bs-slide-to={1}
                                                    />
                                                    <button
                                                        type="button"
                                                        data-bs-target="#demo1"
                                                        data-bs-slide-to={2}
                                                    />
                                                </div>
                                                {/* The slideshow/carousel */}
                                                <div className="carousel-inner">
                                                    {HomeSecondSliderDataListApiData?.slice(0, 4)?.map((item, index) => (
                                                        <div key={index} className={`carousel-item ${index === 0 ? 'active' : ''}`}>
                                                            <div className="product_category_main">
                                                                <div className="product_category_main_left">
                                                                    {/* <h6>THE BEST PLACE TO PLAY</h6> */}
                                                                    <h2>{item.name.trim()}</h2>
                                                                    <p className='line-clamp'>{item.productDescription ? parse(item.productDescription) : ""}</p>
                                                                    <a onClick={() => { handleClick(item?.slug, item?.productVariant) }} style={{ cursor: 'pointer' }} className="btn_primary btn">
                                                                        Shop Now <i className="fa fa-long-arrow-right" aria-hidden="true" />
                                                                    </a>
                                                                </div>
                                                                <div className="product_category_main_right">
                                                                    <img src={item?.image ? `${host}${item?.image}` : ''} alt={item.name.trim()} />
                                                                    <div className="offer_circle">AFN{item.sellingPrice}</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-4 col-lg-4 col-sm-6 col-12">
                                    <div className="card product_section_black mb-3" data-aos="flip-left"data-aos-duration="2000">
                                        <div className="card-body product_section_black_inner">
                                            <div className="product_section_black_inner_left">
                                                <h6>{HomeSecondSectionDataListApiData?.title1}</h6>
                                                <h2 className="mb-3">{HomeSecondSectionDataListApiData?.subtitle1}</h2>
                                                <Link
                                                    to={`/buyer/product-listing?childslug=${HomeSecondSectionDataListApiData?.slug1}`}
                                                    className="btn_primary btn"
                                                >
                                                    Shop Now{" "}
                                                    <i className="fa fa-long-arrow-right" aria-hidden="true" />
                                                </Link>
                                            </div>
                                            <div className="product_section_black_inner_right text-center"  >
                                                {/* static */}
                                                {/* <div className="product_offer">29% OFF</div>  */}
                                                <img
                                                    src={HomeSecondSectionDataListApiData?.image1 ? `${host}/${HomeSecondSectionDataListApiData?.image1}` : ""}
                                                    className="img-fluid"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card product_section"  data-aos="flip-left"data-aos-duration="500">
                                        <div className="card-body product_section_black_inner gray_section_main">
                                            <div className="product_section_black_inner_right gray_section">
                                                <img
                                                    src={HomeSecondSectionDataListApiData?.image2 ? `${host}/${HomeSecondSectionDataListApiData?.image2}` : ""}
                                                    className="img-fluid"
                                                />
                                            </div>
                                            <div className="product_section_gray_inner_left">
                                                <h6>{HomeSecondSectionDataListApiData?.title2}</h6>
                                                <h2 className="mb-3">{HomeSecondSectionDataListApiData?.subtitle2}</h2>
                                                <Link
                                                    to={`/buyer/product-listing?childslug=${HomeSecondSectionDataListApiData?.slug2}`}
                                                    className="btn_primary btn"
                                                >
                                                    Shop Now{" "}
                                                    <i className="fa fa-long-arrow-right" aria-hidden="true" />
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>

                <TrendingNow HomeTrendingNowDataListApiData={HomeTrendingNowDataListApiData} />

                {/* Sell Product */}
                <SellProduct HomeFourSectionDataListApiData={HomeFourSectionDataListApiData} />
                {/* Sell Product */}
                {/* New Product */}
                <NewProduct HomeSecondSectionDataListApiData={HomeSecondSectionDataListApiData} />
                {/* New Product */}
                {/* Shop by Categories */}
                <ShopByCategories HomeShopByCategorySectionDataListApiData={HomeShopByCategorySectionDataListApiData} />
                {/* Shop by Categories */}
                {/* Bestsellers */}
                <Bestsellers title={HomeSecondSectionDataListApiData?.homeLastSectionTitle} HomeLastSectionDataListApiData={HomeLastSectionDataListApiData} />
                {/* Bestsellers */}
            </>

        </div>
    )
}

export default BannerSlider