import React from 'react'
import { Link } from 'react-router-dom'
import SellerHeader from '../../SellerEssentials/SellerHeader'
import SellerSidebar from '../../SellerEssentials/SellerSidebar'

const ProductView = () => {
    return (
        <>
            <div className="seller">
                <div className="page-wrapper chiller-theme toggled">
                    <SellerSidebar />
                    {/* sidebar-wrapper  */}
                    <main className="page-content container-fluid">
                        {/* ==================header================== */}
                        <SellerHeader />
                        {/* ==================header================== */}
                        <div className="row ">
                            <div className="col-md-12">
                                <div className="main-nav-Order">
                                    <div className="row">
                                        <div className="col-md-7">
                                            <h2>
                                                {" "}
                                                <Link to="/seller/products">
                                                    <img src={`${process.env.PUBLIC_URL}/assets/images/seller_images/left_side.png`} alt="left" />{" "}
                                                    <span>Product</span>/View
                                                </Link>{" "}
                                            </h2>
                                        </div>
                                    </div>
                                    <div className="card_order_main">
                                        <div className="row pb-3 main_border_padd">
                                            <div className="col-lg-6">
                                                <div className="tab-content bg-light" id="nav-tabContent">
                                                    <div
                                                        className="tab-pane fade active show"
                                                        id="nav-all-orders"
                                                        role="tabpanel"
                                                        aria-labelledby="nav-all-orders-tab"
                                                    >
                                                        <div className="main_view_detail">
                                                            <img src={`${process.env.PUBLIC_URL}/assets/images/seller_images/Rectangle_1.png`} alt="rect" />
                                                        </div>
                                                    </div>
                                                    <div
                                                        className="tab-pane fade"
                                                        id="nav-delivered"
                                                        role="tabpanel"
                                                        aria-labelledby="nav-delivered-tab"
                                                    >
                                                        <div className="main_view_detail">
                                                            <img src={`${process.env.PUBLIC_URL}/assets/images/seller_images/Rectangle_2.png`} alt="rect" />
                                                        </div>
                                                    </div>
                                                    <div
                                                        className="tab-pane fade"
                                                        id="nav-pending"
                                                        role="tabpanel"
                                                        aria-labelledby="nav-pending-tab"
                                                    >
                                                        <div className="main_view_detail">
                                                            <img src={`${process.env.PUBLIC_URL}/assets/images/seller_images/Rectangle_3.png`} alt="rect" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <nav>
                                                    <div
                                                        className="nav nav-tabs mb-3 all_Information_main"
                                                        id="nav-tab"
                                                        role="tablist"
                                                    >
                                                        <button
                                                            className="nav-link active"
                                                            id="nav-all-orders-tab"
                                                            data-bs-toggle="tab"
                                                            data-bs-target="#nav-all-orders"
                                                            type="button"
                                                            role="tab"
                                                            aria-controls="nav-all-orders"
                                                            aria-selected="true"
                                                        >
                                                            <img src={`${process.env.PUBLIC_URL}/assets/images/seller_images/Rectangle_1.png`} alt="" />
                                                        </button>
                                                        <button
                                                            className="nav-link"
                                                            id="nav-delivered-tab"
                                                            data-bs-toggle="tab"
                                                            data-bs-target="#nav-delivered"
                                                            type="button"
                                                            role="tab"
                                                            aria-controls="nav-delivered"
                                                            aria-selected="false"
                                                        >
                                                            <img src={`${process.env.PUBLIC_URL}/assets/images/seller_images/Rectangle_2.png`} alt="" />
                                                        </button>
                                                        <button
                                                            className="nav-link"
                                                            id="nav-pending-tab"
                                                            data-bs-toggle="tab"
                                                            data-bs-target="#nav-pending"
                                                            type="button"
                                                            role="tab"
                                                            aria-controls="nav-pending"
                                                            aria-selected="false"
                                                        >
                                                            <img src={`${process.env.PUBLIC_URL}/assets/images/seller_images/Rectangle_3.png`} alt="" />
                                                        </button>
                                                    </div>
                                                </nav>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="overall_sales height_auto">
                                                    <div className="users-table">
                                                        <table className="posts-table">
                                                            <tbody className="td_section_text_order">
                                                                <tr>
                                                                    <td>Order ID</td>
                                                                    <td className="table-text-color">Order ID</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Pick Up Date &amp; Time</td>
                                                                    <td className="table-text-color">
                                                                        Pick Up Date &amp; Time
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Package Type</td>
                                                                    <td className="table-text-color">Package Type</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Package Weight</td>
                                                                    <td className="table-text-color">180 Grams</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Easy Ship Fee</td>
                                                                    <td className="table-text-color">
                                                                        <span className="main_color_gree">AFN 3.9</span>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        Any Comments <br />
                                                                        <div className="main_img_sec">
                                                                            <img src={`${process.env.PUBLIC_URL}/assets/images/seller_images/capchar.png`} alt="code" />
                                                                        </div>
                                                                    </td>
                                                                    <td className="table-text-color">
                                                                        Lorem ipsum dolor sit amet consectetur. Suspendisse
                                                                        risus eget tincidunt morbi.
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-12 mt-3">
                                            <div className="text-right ">
                                                <Link to="/seller/products" className="btn btn-primary next-step">
                                                    Back
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>
                </div>
            </div>


        </>
    )
}

export default ProductView