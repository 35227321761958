import React from 'react'
import SellerSidebar from '../SellerEssentials/SellerSidebar'
import SellerHeader from '../SellerEssentials/SellerHeader'
import { Link } from 'react-router-dom'
import '../Scss/Seller.css'
const SellerProfile = () => {
    return (
        <div className='seller'>
            <div className="page-wrapper chiller-theme toggled">
                <SellerSidebar />
                {/* sidebar-wrapper  */}
                <main className="page-content container-fluid">
                    {/* ==================header================== */}
                    <SellerHeader />
                    {/* ==================header================== */}
                    <div className="row">
                        <div className="col-md-12">
                            <div className="main-nav-Order">
                                <h2 className="padd_das_main">Profile</h2>
                                <div className="row">
                                    <div className="col-lg-4">
                                        <Link to="/seller/my_account">
                                            <div className="pro_detail_main">
                                                <div className="col-img-main">
                                                    <img src="/assets/images/seller_images/codicon_account.png" alt="" />
                                                </div>
                                                <div className="col-text-main">
                                                    <h4>My Account</h4>
                                                    <p>
                                                        View your display information,{" "}
                                                        <span>Pickup address, login details</span>{" "}
                                                    </p>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className="col-lg-4">
                                        <Link to="/seller/business-billing-address">
                                            <div className="pro_detail_main">
                                                <div className="col-img-main">
                                                    <img src="/assets/images/seller_images/codicon_account.png" alt="" />
                                                </div>
                                                <div className="col-text-main">
                                                    <h4>Account Manager</h4>
                                                    <p>
                                                        View your account manager <span>details</span>{" "}
                                                    </p>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className="col-lg-4">
                                        <Link to="/seller/profile_bank_details">
                                            <div className="pro_detail_main">
                                                <div className="col-img-main">
                                                    <img src="/assets/images/seller_images/codicon_account.png" alt="" />
                                                </div>
                                                <div className="col-text-main">
                                                    <h4>Bank Details</h4>
                                                    <p>
                                                        View your bank details and <span>history here</span>
                                                    </p>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className="col-lg-4">
                                        <Link to="/seller/my_subscription">
                                            <div className="pro_detail_main">
                                                <div className="col-img-main">
                                                    <img src="/assets/images/seller_images/codicon_account.png" alt="" />
                                                </div>
                                                <div className="col-text-main">
                                                    <h4>My Subscription</h4>
                                                    <p>
                                                        Manage your subscriptions to view, edit, or cancel your
                                                        services.
                                                    </p>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>

            {/* ==============script linking start=============== */}
            {/* ==============script linking end=============== */}
        </div>

    )
}

export default SellerProfile