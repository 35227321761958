import React from 'react'
import { Link } from 'react-router-dom'

const SubscriptionPlan = () => {
    return (
        <>
            <main className="main_login_section container-fluid">
                <div className="row">
                    <div className="col-lg-12 bg_det_subscription">
                        <div className="container">
                            <div className="main_sub_detail">
                                <div className="logo_login sub_section_main">
                                    <img src={`${process.env.PUBLIC_URL}/assets/images/seller_images/login_logo.png`} alt="login_logo" />
                                </div>
                                <div className="Plan_form_sub">
                                    <h3>Subscription Plan</h3>
                                    <p>
                                        Please select the subscription to use the Afghan Bazar seller
                                        application
                                    </p>
                                </div>
                                <div className="main_detail_Plan">
                                    <div className="row pb-main">
                                        <div className="col-lg-4 col-md-6">
                                            <div className="code_sub_main">
                                                <h3>Monthly</h3>
                                                <h4>$ 11.99</h4>
                                                <h5>Benefits</h5>
                                                <ul>
                                                    <li>Lorem Ipsum is simply dummy Lorem Ip</li>
                                                    <li>Lorem Ipsum is simply dummy Lorem Ip</li>
                                                    <li>Lorem Ipsum is simply dummy Lorem Ip</li>
                                                </ul>
                                                <h2>Billed Monthly</h2>
                                                <Link to="/seller/checkout">Subscribe</Link>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-6">
                                            <div className="code_sub_main">
                                                <h3>Quarterly</h3>
                                                <h4>$ 11.99</h4>
                                                <h5>Benefits</h5>
                                                <ul>
                                                    <li>Lorem Ipsum is simply dummy Lorem Ip</li>
                                                    <li>Lorem Ipsum is simply dummy Lorem Ip</li>
                                                    <li>Lorem Ipsum is simply dummy Lorem Ip</li>
                                                </ul>
                                                <h2>Billed Quarterly</h2>
                                                {/* <button>Subscribe</button> */}
                                                <Link to="/seller/checkout">Subscribe</Link>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-6">
                                            <div className="code_sub_main">
                                                <h3>Yearly</h3>
                                                <h4>$ 11.99</h4>
                                                <h5>Benefits</h5>
                                                <ul>
                                                    <li>Lorem Ipsum is simply dummy Lorem Ip</li>
                                                    <li>Lorem Ipsum is simply dummy Lorem Ip</li>
                                                    <li>Lorem Ipsum is simply dummy Lorem Ip</li>
                                                </ul>
                                                <h2>Billed Yearly</h2>
                                                {/* <button>Subscribe</button> */}
                                                <Link to="/seller/checkout">Subscribe</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>

        </>
    )
}

export default SubscriptionPlan