import React from 'react'
// import './scss/BuyerFrequentlyBroughtTogether.css'
import { Link, useNavigate } from 'react-router-dom'
import axios from 'axios'
import toast from 'react-hot-toast';
import { v4 as uuidv4 } from 'uuid';
let host = process.env.REACT_APP_API_SERVER;
const BuyerFrequentlyBroughtTogether = ({ FrequentlyBroughtTogetherDataListApiData }) => {
    const navigate = useNavigate();
    const authToken = localStorage.getItem("buyer_token")
    const guestToken = localStorage.getItem("guest_token")
    const handleAddToCart = async (productId) => {
        try {
            if (authToken) {
                console.log('in auth')
                const config = {
                    headers: {

                        'content-type': 'application/json',
                        "auth-token": authToken
                    }
                };
                await axios.post(`${host}/buyer/frequently-product-add-to-cart`, { productId }, config)
                    .then(function (response) {
                        if (response.status == 200) {

                            toast.success("Cart updated successfully");
                            navigate('/buyer/cart')

                        } else {

                            toast.error(response.data.message);
                        }

                    })
                    .catch(function (error) {

                        console.log(error)
                        toast.error(error.response.data.message);
                    })
            }
            else {
                if (guestToken) {
                    const config = {
                        headers: {

                            'content-type': 'application/json',
                            "guest-token": guestToken
                        }
                    };
                    await axios.post(`${host}/buyer/frequently-product-add-to-cart`, { productId }, config)
                        .then(function (response) {
                            if (response.status == 200) {

                                toast.success("Cart updated successfully");
                                navigate('/buyer/cart')

                            } else {

                                toast.error(response.data.message);
                            }

                        })
                        .catch(function (error) {

                            console.log(error)
                            toast.error(error.response.data.message);
                        })
                }
                else {
                    const config = {
                        headers: {

                            'content-type': 'application/json',
                            "guest-token": guestToken
                        }
                    };
                    const guestId = uuidv4();
                    localStorage.setItem('guest_token', guestId);
                    await axios.post(`${host}/buyer/frequently-product-add-to-cart`, { productId }, config)
                        .then(function (response) {
                            if (response.status == 200) {

                                toast.success("Cart updated successfully");
                                navigate('/buyer/cart')

                            } else {

                                toast.error(response.data.message);
                            }

                        })
                        .catch(function (error) {

                            console.log(error)
                            toast.error(error.response.data.message);
                        })
                }
            }
        } catch (error) {
            toast.error('Error updating cart:', error.message);
        }
    }
    const renderStars = (rating) => {
        const stars = [];
        for (let i = 1; i <= 5; i++) {
            if (i <= rating) {
                stars.push(<i key={i} className="fa fa-star filled-star" />);
            } else {
                stars.push(<i key={i} className="fa fa-star empty-star" />);
            }
        }
        return stars;
    };
    return (
        <>
            {FrequentlyBroughtTogetherDataListApiData?.length > 0 &&
                <div className="frequently_bought">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mb-4">
                                <div className="related_products_heading">
                                    <h2>Frequently bought together</h2>
                                </div>
                            </div>
                        </div>
                        {/* <div className="row">
                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                            <div className="frequently_bought_main_section">
                                <div className="frequently_bought_inner">
                                    <div className="shop-page-product">
                                        <img src="https://afgan.alobhatech.com//images/productImg/image-1721732823890-838077960-product_details_2.jpg" />
                                        <ul>
                                            <li>
                                                <i className="fa fa-star" />
                                            </li>
                                            <li>
                                                <i className="fa fa-star" />
                                            </li>
                                            <li>
                                                <i className="fa fa-star" />
                                            </li>
                                            <li>
                                                <i className="fa fa-star" />
                                            </li>
                                            <li>
                                                <i className="fa fa-star" />
                                            </li>
                                            <li>(738)</li>
                                        </ul>
                                        <h4>
                                            Polaroid 57-Inch Photo/Video Tripod with Deluxe Tripod Ca...
                                        </h4>
                                        <p>
                                            <del>AFN.1600</del> <span>AFN.1,200</span>
                                        </p>
                                        <div className="hot_product">BEST DEALS</div>
                                    </div>
                                </div>
                                <div className="frequently_bought_inner">
                                    <div className="plus_icon">
                                        <Link to="/buyer/">
                                            <i className="fa-solid fa-plus" />
                                        </Link>
                                    </div>
                                </div>
                                <div className="frequently_bought_inner">
                                    <div className="shop-page-product">
                                        <img src="https://afgan.alobhatech.com//images/productImg/image-1721732823890-838077960-product_details_2.jpg" />
                                        <ul>
                                            <li>
                                                <i className="fa fa-star" />
                                            </li>
                                            <li>
                                                <i className="fa fa-star" />
                                            </li>
                                            <li>
                                                <i className="fa fa-star" />
                                            </li>
                                            <li>
                                                <i className="fa fa-star" />
                                            </li>
                                            <li>
                                                <i className="fa fa-star" />
                                            </li>
                                            <li>(738)</li>
                                        </ul>
                                        <h4>
                                            Polaroid 57-Inch Photo/Video Tripod with Deluxe Tripod Ca...
                                        </h4>
                                        <p>
                                            <del>AFN.1600</del> <span>AFN.1,200</span>
                                        </p>
                                        <div className="hot_product">HOT</div>
                                    </div>
                                </div>
                                <div className="frequently_bought_inner">
                                    <div className="plus_icon">
                                        <Link to="/buyer/">
                                            <i className="fa-solid fa-plus" />
                                        </Link>
                                    </div>
                                </div>
                                
                                <div className="frequently_bought_inner add_product_text p-3">
                                    <Link to="/buyer/cart" className="add_product text-center">
                                        Add all 3 to cart
                                    </Link>
                                    <p>These items are shipped from and sold by different sellers.</p>
                                    <Link to="/buyer/" className="see_more_btn text-start">
                                        See All
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div> */}
                        <div className="row">
                            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">

                                <div className="frequently_bought_main_section row">
                                    {FrequentlyBroughtTogetherDataListApiData && FrequentlyBroughtTogetherDataListApiData?.map((product, index) => (
                                        <React.Fragment key={product.id}>
                                            <div className='col-lg-3 col-md-3 col-sm-6 col-6'>

                                                <div className="frequently_bought_inner">
                                                    <div className='row align-items-center'>
                                                        <div className='col-lg-10 col-md-9 col-sm-9 col-10'>
                                                            <div className="shop-page-product">
                                                                <img src={product?.productImage ? `${host}/${product?.productImage}` : ''} />

                                                                <ul>
                                                                    {renderStars(product?.avgRating)}
                                                                    <li>
                                                                        <span>({product?.ratingCount ? product?.ratingCount : '0'})</span>
                                                                    </li>
                                                                </ul>
                                                                <h4>
                                                                    {product?.name}
                                                                </h4>
                                                                <p>
                                                                    <del>AFN.{product?.originalPrice}</del> <span>AFN.{product?.sellingPrice}</span>
                                                                </p>
                                                                <div className="hot_product">BEST DEALS</div>
                                                            </div>
                                                        </div>

                                                        {
                                                            index < FrequentlyBroughtTogetherDataListApiData?.length - 1 && (
                                                                <div className='col-lg-2 col-md-3 col-sm-3 col-2'>
                                                                    <div className="frequently_bought_inner">
                                                                        <div className="plus_icon">
                                                                            <Link to="">
                                                                                <i className="fa-solid fa-plus" />
                                                                            </Link>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            )
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </React.Fragment>
                                    ))}
                                    {FrequentlyBroughtTogetherDataListApiData?.length > 0 &&
                                        <div className='col-lg-3 col-md-3 col-sm-6 col-6'>
                                            <div className="frequently_bought_inner add_product_text p-3">
                                                <a onClick={() => { handleAddToCart(FrequentlyBroughtTogetherDataListApiData?.[0]?.productId) }} style={{ cursor: 'pointer' }} className="add_product text-center">
                                                    Add all {/* Add all {FrequentlyBroughtTogetherDataListApiData?.length} */}
                                                    products to cart
                                                </a>
                                                <p>These items are shipped from and sold by different sellers.</p>
                                                {/* <Link to="/buyer/" className="see_more_btn text-start">
                                        See All
                                    </Link> */}
                                            </div>
                                        </div>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div >}
        </>

    )
}

export default BuyerFrequentlyBroughtTogether