import React from 'react'
import SellerHeader from '../SellerEssentials/SellerHeader'
import SellerSidebar from '../SellerEssentials/SellerSidebar'
import { Link } from 'react-router-dom'
import '../Scss/Seller.css'
const SellerAccountManagerView = () => {
    return (
        <div className='seller'>
            <div className="page-wrapper chiller-theme toggled">
                <SellerSidebar />
                {/* sidebar-wrapper  */}
                <main className="page-content container-fluid">
                    {/* ==================header================== */}
                    <SellerHeader />
                    {/* ==================header================== */}
                    <div className="row">
                        <div className="col-md-12">
                            <div className="main-nav-Order">
                                <div className="main_detail_head">
                                    <h2>
                                        {" "}
                                        <Link to="/seller/profile">
                                            <img src="/assets/images/seller_images/left_side.png" alt="left" /> Account Manager
                                        </Link>{" "}
                                    </h2>
                                    {/* <Link className="main_account_butt" to="">Delete Account</Link> */}
                                </div>
                                <div className="card_order_main">
                                    <div className="m_d_bottom maon_detail_sec_new ">
                                        <div className=" all_order_main ">
                                            <span className="active padd_7">
                                                Business/Billing Address
                                            </span>
                                        </div>
                                        <div className="main_a_detail text-right pb-2">
                                            <Link
                                                to="/seller/business-billing-address_edit"
                                                className="btn btn-primary me-2"
                                            >
                                                Edit
                                            </Link>
                                            <Link to="" className="btn btn-secondary">
                                                Save
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-4">
                                            <div className="form-group log_in_main">
                                                <label>Address Line 1</label>
                                                <input
                                                    type="text"
                                                    defaultValue=""
                                                    placeholder="Enter Here"
                                                    className="form-control"
                                                />
                                            </div>
                                        </div>
                                        <div className="col-lg-4">
                                            <div className="form-group log_in_main">
                                                <label>Address Line 2</label>
                                                <input
                                                    type="text"
                                                    defaultValue=""
                                                    placeholder="Enter Here"
                                                    className="form-control"
                                                />
                                            </div>
                                        </div>
                                        <div className="col-lg-4">
                                            <div className="form-group log_in_main">
                                                <label>Pincode</label>
                                                <input
                                                    type="text"
                                                    defaultValue=""
                                                    placeholder="Enter Here"
                                                    className="form-control"
                                                />
                                            </div>
                                        </div>
                                        <div className="col-lg-4">
                                            <div className="form-group log_in_main">
                                                <label>Province</label>
                                                <input
                                                    type="text"
                                                    defaultValue=""
                                                    placeholder="Enter Here"
                                                    className="form-control"
                                                />
                                            </div>
                                        </div>
                                        <div className="col-lg-4">
                                            <div className="form-group log_in_main">
                                                <label>City</label>
                                                <input
                                                    type="text"
                                                    defaultValue=""
                                                    placeholder="Enter Here"
                                                    className="form-control"
                                                />
                                            </div>
                                        </div>
                                        <div className="col-lg-4">
                                            <div className="form-group log_in_main">
                                                <label>Business Address Tpye</label>
                                                <input
                                                    type="text"
                                                    defaultValue=""
                                                    placeholder="Enter Here"
                                                    className="form-control"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="m_d_bottom maon_detail_sec_new pt-4">
                                        <div className=" all_order_main ">
                                            <span className="active padd_7">Contact Details</span>
                                        </div>
                                        <div className="main_a_detail text-right pb-2">
                                            <Link
                                                to="/seller/business-billing-address_edit"
                                                className="btn btn-primary me-2"
                                            >
                                                Edit
                                            </Link>
                                            <Link to="" className="btn btn-secondary">
                                                Save
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-4">
                                            <div className="form-group log_in_main">
                                                <label>Name</label>
                                                <input
                                                    type="text"
                                                    defaultValue=""
                                                    placeholder="Enter Here"
                                                    className="form-control"
                                                />
                                            </div>
                                        </div>
                                        <div className="col-lg-4">
                                            <div className="form-group log_in_main">
                                                <label>Mobile Number</label>
                                                <input
                                                    type="text"
                                                    defaultValue=""
                                                    placeholder="Enter Here"
                                                    className="form-control"
                                                />
                                            </div>
                                        </div>
                                        <div className="col-lg-4">
                                            <div className="form-group log_in_main">
                                                <label>Email ID</label>
                                                <input
                                                    type="text"
                                                    defaultValue=""
                                                    placeholder="Enter Here"
                                                    className="form-control"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className="row">
                      <div className="col-lg-12">
                        <div className="form-footer-button">
                          
                          <Link type="button" data-bs-toggle="modal" data-bs-target="#send_approval"  className="btn Back-button">Edit Details</Link>
                          <Link to="" className="btn send-approval-butt">
                            Change Password</Link>
                      </div>
                      </div>
                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
            <div
                className="modal fade"
                id="send_approval"
                data-bs-backdrop="static"
                data-bs-keyboard="false"
                tabIndex={-1}
                aria-labelledby="staticBackdropLabel"
                style={{ display: "none" }}
                aria-hidden="true"
            >
                <div className="modal-dialog">
                    <div className="modal-content main-in-popup-space">
                        <div className="btn-close-into">
                            <button
                                type="button"
                                className="btn-close "
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            />
                        </div>
                        <div className="modal-body">
                            <div className="request_send_for_modul">
                                <h6>Edit Details</h6>
                            </div>
                            <div className="main_edit_form">
                                <form action="#">
                                    <div className="input_detail_section">
                                        <h6>Display Name</h6>
                                        <input
                                            type="text"
                                            defaultValue=""
                                            placeholder="Enter Name"
                                            className="form-control"
                                        />
                                    </div>
                                    <div className="input_detail_section">
                                        <h6>Mobile Number</h6>
                                        <input
                                            type="number"
                                            defaultValue=""
                                            placeholder="+91 0000000000"
                                            className="form-control"
                                        />
                                    </div>
                                    <div className="input_detail_section">
                                        <h6>Email Address</h6>
                                        <input
                                            type="text"
                                            defaultValue=""
                                            placeholder="Enter Email Address"
                                            className="form-control"
                                        />
                                    </div>
                                    <div className="text-right">
                                        <button type="button" className="btn send-approval-butt ">
                                            Save
                                        </button>{" "}
                                        <button
                                            type="button"
                                            className="btn btn-secondary"
                                            data-bs-dismiss="modal"
                                        >
                                            Cancel
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* ==============script linking start=============== */}
            {/* ==============script linking end=============== */}
        </div>

    )
}

export default SellerAccountManagerView