import React from 'react'
import SellerSidebar from '../SellerEssentials/SellerSidebar'
import SellerHeader from '../SellerEssentials/SellerHeader'
import '../Scss/Seller.css'
const SellerDashboardContent = () => {
    return (
        <div className='seller'>
            <div className="page-wrapper chiller-theme toggled">
                <a id="show-sidebar" className="btn btn-sm btn-dark" href="#">
                    <i className="fas fa-bars" />
                </a>
                <SellerSidebar />
                {/* sidebar-wrapper  */}
                <main className="page-content container-fluid">
                    <SellerHeader />
                    <div className="row">
                        <div className="col-md-12">
                            <div className="main-nav-Order">
                                <h2>Order Statistics</h2>
                                <div className="row">
                                    <div className="col-lg-3 col-md-6 col-sm-12 col-12 mar_xs_5">
                                        <article className="stat-cards-item">
                                            <div className="stat-cards-info">
                                                <p className="stat-cards-info__num">
                                                    Todays Total Sale{" "}
                                                    <a href="#">
                                                        <img src="img/manu_det_icon.png" alt="" />
                                                    </a>
                                                </p>
                                                <div className="daai_icon_img">
                                                    <span className="stat-cards-info-right">
                                                        <img src="/assets/images/seller_images/Clip-path.png" alt="clip" />
                                                    </span>
                                                    <p className="stat-cards-info__title">$11,000</p>
                                                </div>
                                            </div>
                                        </article>
                                    </div>
                                    <div className="col-lg-3 col-md-6 col-sm-12 col-12 mar_xs_5">
                                        <article className="stat-cards-item">
                                            <div className="stat-cards-info">
                                                <p className="stat-cards-info__num">
                                                    Todays Total Orders{" "}
                                                    <a href="#">
                                                        <img src="img/manu_det_icon.png" alt="" />
                                                    </a>
                                                </p>
                                                <div className="daai_icon_img">
                                                    <span className="stat-cards-info-right">
                                                        <img src="/assets/images/seller_images/Total_Orders.png" alt="clip" />
                                                    </span>
                                                    <p className="stat-cards-info__1">$11,000</p>
                                                </div>
                                            </div>
                                        </article>
                                    </div>
                                    <div className="col-lg-3 col-md-6 col-sm-12 col-12 mar_xs_5">
                                        <article className="stat-cards-item">
                                            <div className="stat-cards-info-right">
                                                <p className="stat-cards-info__num">
                                                    Todays Revenue{" "}
                                                    <a href="#">
                                                        <img src="img/manu_det_icon.png" alt="" />
                                                    </a>
                                                </p>
                                                <div className="daai_icon_img">
                                                    <span className="stat-cards-info-right">
                                                        <img src="/assets/images/seller_images/Revenue.png" alt="clip" />
                                                    </span>
                                                    <p className="stat-cards-info__2">$11,000</p>
                                                </div>
                                            </div>
                                        </article>
                                    </div>
                                    <div className="col-lg-3 col-md-6 col-sm-12 col-12 mar_xs_5">
                                        <article className="stat-cards-item">
                                            <div className="stat-cards-info">
                                                <p className="stat-cards-info__num">
                                                    Todays Visitors{" "}
                                                    <a href="#">
                                                        <img src="img/manu_det_icon.png" alt="" />
                                                    </a>
                                                </p>
                                                <div className="daai_icon_img">
                                                    <span className="stat-cards-info-right">
                                                        <img src="/assets/images/seller_images/Visitors.png" alt="clip" />
                                                    </span>
                                                    <p className="stat-cards-info__3">$11,000</p>
                                                </div>
                                            </div>
                                        </article>
                                    </div>
                                </div>
                                <div className="row pt-5 pb-3">
                                    <div className="col-lg-6 mar_xs_5">
                                        <div className="overall_sales">
                                            <div className="over_sales_main">
                                                <div className="main_detail_sales">
                                                    <h3>Overall Sales</h3>
                                                    <h6>$333,000</h6>
                                                </div>
                                                <div className="dropdown dropdown-filter-new">
                                                    <a
                                                        className="dropdown-toggle month_this_button"
                                                        href="#"
                                                        role="button"
                                                        id="dropdownMenuLink"
                                                        data-bs-toggle="dropdown"
                                                        aria-expanded="false"
                                                    >
                                                        Week
                                                    </a>
                                                    <div
                                                        className="dropdown-menu main_drop_new_week"
                                                        aria-labelledby="dropdownMenuLink"
                                                    >
                                                        <ul>
                                                            <li>
                                                                <a href="#">Mon</a>
                                                            </li>
                                                            <li>
                                                                <a href="#">Tue</a>
                                                            </li>
                                                            <li>
                                                                <a href="#">Web</a>
                                                            </li>
                                                            <li>
                                                                <a href="#">Thu</a>
                                                            </li>
                                                            <li>
                                                                <a href="#">Fri</a>
                                                            </li>
                                                            <li>
                                                                <a href="#">Sat</a>
                                                            </li>
                                                            <li>
                                                                <a href="#">Sun</a>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <canvas id="myChart" />
                                        </div>
                                    </div>
                                    <div className="col-lg-6 mar_xs_5">
                                        <div className="overall_sales">
                                            <div className="over_sales_main">
                                                <div className="main_detail_sales">
                                                    <h3>Top Cities</h3>
                                                </div>
                                            </div>
                                            <div className="users-table">
                                                <table className="posts-table">
                                                    <thead>
                                                        <tr className="users-table-info-new">
                                                            <th>Name</th>
                                                            <th>Order Percentage</th>
                                                            <th>Profit Percentage</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody className="td_section_text">
                                                        <tr>
                                                            <td>
                                                                <div className="pages-table-img">
                                                                    1.
                                                                    <picture>
                                                                        <source
                                                                            srcSet="/assets/images/seller_images/Kabul.png"
                                                                            type="image/webp"
                                                                        />
                                                                        <img src="/assets/images/seller_images/Kabul.png" alt="Kabul" />
                                                                    </picture>
                                                                    Kabul
                                                                </div>
                                                            </td>
                                                            <td className="users-table-text">25%</td>
                                                            <td className="users-table-text">25%</td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <div className="pages-table-img">
                                                                    2.
                                                                    <picture>
                                                                        <source
                                                                            srcSet="/assets/images/seller_images/Kabul.png"
                                                                            type="image/webp"
                                                                        />
                                                                        <img src="/assets/images/seller_images/Kabul.png" alt="Kabul" />
                                                                    </picture>
                                                                    Ghazni
                                                                </div>
                                                            </td>
                                                            <td className="users-table-text">25%</td>
                                                            <td className="users-table-text">25%</td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <div className="pages-table-img">
                                                                    3.
                                                                    <picture>
                                                                        <source
                                                                            srcSet="/assets/images/seller_images/Kabul.png"
                                                                            type="image/webp"
                                                                        />
                                                                        <img src="/assets/images/seller_images/Kabul.png" alt="Kabul" />
                                                                    </picture>
                                                                    Herat
                                                                </div>
                                                            </td>
                                                            <td className="users-table-text">25%</td>
                                                            <td className="users-table-text">25%</td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <div className="pages-table-img">
                                                                    4.
                                                                    <picture>
                                                                        <source
                                                                            srcSet="/assets/images/seller_images/Kabul.png"
                                                                            type="image/webp"
                                                                        />
                                                                        <img src="/assets/images/seller_images/Kabul.png" alt="Kabul" />
                                                                    </picture>
                                                                    Kabul
                                                                </div>
                                                            </td>
                                                            <td className="users-table-text">25%</td>
                                                            <td className="users-table-text">25%</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                                <div className="main-nav-det-button">
                                                    <a href="#">Load More</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row pb-5">
                                    <div className="col-lg-12">
                                        <div className="selling_products">
                                            <h4>Top Selling Products</h4>
                                        </div>
                                        <div className="table_detail_tariff table-responsive">
                                            <table className="posts-table geeks">
                                                <thead>
                                                    <tr className="users-table-tariff">
                                                        <th>Product Name</th>
                                                        <th>Product ID</th>
                                                        <th>Quantity</th>
                                                        <th>Order Date</th>
                                                        <th>Customer</th>
                                                        <th>Status</th>
                                                        <th>Price</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr className="main_table_row_new gfg">
                                                        <td>iPhone 14 Pro Max</td>
                                                        <td>HK4886</td>
                                                        <td>233</td>
                                                        <td>4 Jun 2024</td>
                                                        <td>Milan Jack</td>
                                                        <td>Done</td>
                                                        <td>$1399</td>
                                                        <td className="img_detail_new">
                                                            <a href="#">
                                                                <img src="/assets/images/seller_images/Edit.png" alt="Edit" />
                                                            </a>
                                                            <a href="#">
                                                                <img src="/assets/images/seller_images/delete.png" alt="delete" />
                                                            </a>
                                                        </td>
                                                    </tr>
                                                    <tr className="main_table_row_new gfg">
                                                        <td>iPhone 14 Pro Max</td>
                                                        <td>HK4886</td>
                                                        <td>233</td>
                                                        <td>4 Jun 2024</td>
                                                        <td>Milan Jack</td>
                                                        <td>Done</td>
                                                        <td>$1399</td>
                                                        <td className="img_detail_new">
                                                            <a href="#">
                                                                <img src="/assets/images/seller_images/Edit.png" alt="Edit" />
                                                            </a>
                                                            <a href="#">
                                                                <img src="/assets/images/seller_images/delete.png" alt="delete" />
                                                            </a>
                                                        </td>
                                                    </tr>
                                                    <tr className="main_table_row_new gfg">
                                                        <td>iPhone 14 Pro Max</td>
                                                        <td>HK4886</td>
                                                        <td>233</td>
                                                        <td>4 Jun 2024</td>
                                                        <td>Milan Jack</td>
                                                        <td>Done</td>
                                                        <td>$1399</td>
                                                        <td className="img_detail_new">
                                                            <a href="#">
                                                                <img src="/assets/images/seller_images/Edit.png" alt="Edit" />
                                                            </a>
                                                            <a href="#">
                                                                <img src="/assets/images/seller_images/delete.png" alt="delete" />
                                                            </a>
                                                        </td>
                                                    </tr>
                                                    <tr className="main_table_row_new gfg">
                                                        <td>iPhone 14 Pro Max</td>
                                                        <td>HK4886</td>
                                                        <td>233</td>
                                                        <td>4 Jun 2024</td>
                                                        <td>Milan Jack</td>
                                                        <td>Done</td>
                                                        <td>$1399</td>
                                                        <td className="img_detail_new">
                                                            <a href="#">
                                                                <img src="/assets/images/seller_images/Edit.png" alt="Edit" />
                                                            </a>
                                                            <a href="#">
                                                                <img src="/assets/images/seller_images/delete.png" alt="delete" />
                                                            </a>
                                                        </td>
                                                    </tr>
                                                    <tr className="main_table_row_new gfg">
                                                        <td>iPhone 14 Pro Max</td>
                                                        <td>HK4886</td>
                                                        <td>233</td>
                                                        <td>4 Jun 2024</td>
                                                        <td>Milan Jack</td>
                                                        <td>Done</td>
                                                        <td>$1399</td>
                                                        <td className="img_detail_new">
                                                            <a href="#">
                                                                <img src="/assets/images/seller_images/Edit.png" alt="Edit" />
                                                            </a>
                                                            <a href="#">
                                                                <img src="/assets/images/seller_images/delete.png" alt="delete" />
                                                            </a>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
            {/* ==============script linking start=============== */}
        </div>

    )
}

export default SellerDashboardContent