import React from 'react'
import axios from 'axios';
import toast from 'react-hot-toast';
import './scss/BuyerWishlistContent.css'
import { Link, useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux';
import { getBuyerWishList } from '../../Redux/action/BuyerAction';
let host = process.env.REACT_APP_API_SERVER;
const BuyerWishlistContent = ({ WishlistDataListApiData }) => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    let config = { headers: { "auth-token": localStorage.getItem("buyer_token") } };
    const handleClick = (id, variant) => {

        if (localStorage.getItem("buyer_token")) {
            try {
                let values = {
                    productId: id,
                    productVarientId: variant?.id ? variant?.id : undefined
                }

                axios.post(`${host}/buyer/buyer-wishlist-product`, values, config)
                    .then((response) => {
                        if (response.status === 200) {

                            toast.success(response?.data?.message);
                            dispatch(getBuyerWishList(localStorage.getItem("buyer_token")));
                        } else {

                            toast.error(response?.data?.message);
                        }
                    })
            } catch (error) {

                toast.error(error?.response?.data?.message);

            }
        }
        else {
            // toast.error('please sign in to continue')
            navigate('/buyer/sign-in')
        }

    }

    const handleCartClick = async (id, variant) => {
        try {
            const value = {
                productId: id,
                productVarientId: variant?.id ? variant?.id : undefined,
                // quantity: 1,

            }

            await axios.post(`${host}/buyer/add-through-more-of-what-you-like`, value, config)
                .then(function (response) {
                    if (response.status == 200) {

                        toast.success("Product added to cart");

                        navigate('/buyer/cart')

                    } else {

                        toast.error(response.data.message);
                    }

                })
                .catch(function (error) {

                    console.log(error)
                    toast.error(error.response.data.message);
                })

        } catch (error) {
            console.error(error);
        }
    };

    return (
        <div className="wish-list">
            <section className="wish_list_section">
                <div className="container-fluid">
                    <div className="d-flex pt-4 heading-sec px-4">
                        <div className="heart-img">
                            <img src="/assets/images/wish-icon.png" alt="heart" />
                        </div>
                        <h3>Wish List</h3>
                        <h6>({WishlistDataListApiData?.length} Items)</h6>
                    </div>
                    {WishlistDataListApiData?.length > 0 ?
                        <div>
                            {WishlistDataListApiData?.map((data) => (
                                <div className=" container-fluid px-5 py-5 main-wish-list">
                                    <div className="row first-card-sec">
                                        <div className="col-lg-3 col-md-5 py-3">
                                            <div className="first-image text-center">
                                                <img src={data?.image ? `${host}${data?.image}` : ''} className="img-fluid" />
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-5 py-3">
                                            <div className="women-black">
                                                <h4 className="women">{data?.name}</h4>
                                                <h6>{data?.brandName}</h6>
                                                <h4>AFN.{data?.sellingPrice}</h4>
                                                <div className="d-flex order-place">
                                                    <span>
                                                        <del>AFN. {data?.originalPrice}</del>
                                                    </span>{" "}
                                                    <strong>({data?.discountPercentage}% off)</strong>
                                                </div>
                                                <div className="d-flex order-place">
                                                    <div className='product-desc'>
                                                        <p className='line-clamp'>{data?.productDescription}</p>
                                                    </div>
                                                </div>
                                                <div className="add-btn">
                                                    <a className="add-cart"
                                                        onClick={() => { handleCartClick(data?.id, data?.productVariant) }}
                                                    >
                                                        <button className="">Add to cart</button>
                                                    </a>
                                                    {/* <Link to="/buyer/#"><button>Cancel</button></Link> */}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-2 py-3">
                                            <div className="delete_btn">
                                                <Link onClick={() => { handleClick(data?.id, data?.productVariant) }}>
                                                    <i className="fa-solid fa-trash" />
                                                </Link>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            ))}
                        </div>
                        :
                        <div className=" container-fluid my-5 py-5 main-wish-list">
                            <Link className='text-decoration-none' to='/buyer/home'>
                                <div className="d-flex justify-content-center pt-4 py-5 heading-sec px-4">
                                    <img src="/assets/images/purchase-protection.png" alt="" srcSet="" />


                                </div>
                            </Link>
                            <h1 className='text-center'><Link className='text-decoration-none' to='/buyer/home'>Shop Now</Link></h1>
                        </div>

                    }

                </div>
            </section>
        </div>

    )
}

export default BuyerWishlistContent