let host = process.env.REACT_APP_API_SERVER;

// Category Data
export const fetchCategoryDataRequest = () => ({
    type: "FETCH_CATEGORY_DATA_REQUEST",
});
export const fetchCategoryDataSuccess = (data) => ({
    type: "FETCH_CATEGORY_DATA_SUCCESS",
    payload: data,
});

export const fetchCategoryDataFailure = (error) => ({
    type: "FETCH_CATEGORY_DATA_FAILURE",
    payload: error,
});

export const getCategoryData = () =>

    async (dispatch) => {

        dispatch(fetchCategoryDataRequest());
        try {


            let response = await fetch(`${host}/master/get-category`, { method: "GET" });
            // console.log("response", await response.json());


            if (!response.ok) {
                throw new Error('Please enter correct URL.');
            }
            const data = await response.json();
            // console.log('datais', data);
            let desired_data = data?.data;
            // console.log('desired_data', desired_data)

            dispatch(fetchCategoryDataSuccess(desired_data));

        } catch (error) {
            dispatch(fetchCategoryDataFailure(error.message));
        }
    }


// Buyer Profile Details
export const fetchCountryRequest = () => ({
    type: "FETCH_COUNTRY_DATA_REQUEST",
});

export const fetchCountrySuccess = (data) => ({
    type: "FETCH_COUNTRY_DATA_SUCCESS",
    payload: data,
});

export const fetchCountryFailure = (error) => ({
    type: "FETCH_COUNTRY_DATA_FAILURE",
    payload: error,
});

export const getCountry = () =>

    async (dispatch) => {

        dispatch(fetchCountryRequest());
        try {


            let response = await fetch(`${host}/master/get-country`, { method: "GET" });
            // console.log("response", await response.json());


            if (!response.ok) {
                throw new Error('Please enter correct URL.');
            }
            const data = await response.json();
            // console.log('datais', data);
            let desired_data = data?.data;
            // console.log('desired_data', desired_data)

            dispatch(fetchCountrySuccess(desired_data));

        } catch (error) {
            dispatch(fetchCountryFailure(error.message));
        }
    }


// Buyer Profile Details
export const fetchStateDataRequest = () => ({
    type: "FETCH_STATE_DATA_REQUEST",
});

export const fetchStateDataSuccess = (data) => ({
    type: "FETCH_STATE_DATA_SUCCESS",
    payload: data,
});

export const fetchStateDataFailure = (error) => ({
    type: "FETCH_STATE_DATA_FAILURE",
    payload: error,
});

export const getStateData = (id) =>

    async (dispatch) => {

        dispatch(fetchStateDataRequest());
        try {


            let response = await fetch(`${host}/master/get-state?id=${id}`, { method: "GET" });
            // console.log("response", await response.json());


            if (!response.ok) {
                throw new Error('Please enter correct URL.');
            }
            const data = await response.json();
            // console.log('datais', data);
            let desired_data = data?.data;
            // console.log('desired_data', desired_data)

            dispatch(fetchStateDataSuccess(desired_data));

        } catch (error) {
            dispatch(fetchStateDataFailure(error.message));
        }
    }


// Buyer Profile Details
export const fetchCityDataRequest = () => ({
    type: "FETCH_CITY_DATA_REQUEST",
});

export const fetchCityDataSuccess = (data) => ({
    type: "FETCH_CITY_DATA_SUCCESS",
    payload: data,
});

export const fetchCityDataFailure = (error) => ({
    type: "FETCH_CITY_DATA_FAILURE",
    payload: error,
});

export const getCityData = (id) =>

    async (dispatch) => {

        dispatch(fetchCityDataRequest());
        try {


            let response = await fetch(`${host}/master/get-city?id=${id}`, { method: "GET" });
            // console.log("response", await response.json());


            if (!response.ok) {
                throw new Error('Please enter correct URL.');
            }
            const data = await response.json();
            // console.log('datais', data);
            let desired_data = data?.data;
            // console.log('desired_data', desired_data)

            dispatch(fetchCityDataSuccess(desired_data));

        } catch (error) {
            dispatch(fetchCityDataFailure(error.message));
        }
    }


// Website Setting 
export const fetchWebsiteSettingDataRequest = () => ({
    type: "FETCH_WEBSITE_SETTING_REQUEST",
});

export const fetchWebsiteSettingDataSuccess = (data) => ({
    type: "FETCH_WEBSITE_SETTING_SUCCESS",
    payload: data,
});

export const fetchWebsiteSettingDataFailure = (error) => ({
    type: "FETCH_WEBSITE_SETTING_FAILURE",
    payload: error,
});

export const getWebsiteSettingData = () =>

    async (dispatch) => {

        dispatch(fetchWebsiteSettingDataRequest());
        try {


            let response = await fetch(`${host}/master/get-website-setting`, { method: "GET" });
            // console.log("response", await response.json());


            if (!response.ok) {
                throw new Error('Please enter correct URL.');
            }
            const data = await response.json();
            // console.log('datais', data);
            let desired_data = data?.data;
            // console.log('desired_data', desired_data)

            dispatch(fetchWebsiteSettingDataSuccess(desired_data));

        } catch (error) {
            dispatch(fetchWebsiteSettingDataFailure(error.message));
        }
    }


// Website Setting 
export const fetchColourDataRequest = () => ({
    type: "FETCH_COLOUR_DATA_REQUEST",
});

export const fetchColourDataSuccess = (data) => ({
    type: "FETCH_COLOUR_DATA_SUCCESS",
    payload: data,
});

export const fetchColourDataFailure = (error) => ({
    type: "FETCH_COLOUR_DATA_FAILURE",
    payload: error,
});

export const getColourData = () =>

    async (dispatch) => {

        dispatch(fetchColourDataRequest());
        try {


            let response = await fetch(`${host}/master/get-colour`, { method: "GET" });
            // console.log("response", await response.json());


            if (!response.ok) {
                throw new Error('Please enter correct URL.');
            }
            const data = await response.json();
            // console.log('datais', data);
            let desired_data = data?.data;
            // console.log('desired_data', desired_data)

            dispatch(fetchColourDataSuccess(desired_data));

        } catch (error) {
            dispatch(fetchColourDataFailure(error.message));
        }
    }

// Brand data
export const fetchBrandDataRequest = () => ({
    type: "FETCH_BRAND_DATA_REQUEST",
});

export const fetchBrandDataSuccess = (data) => ({
    type: "FETCH_BRAND_DATA_SUCCESS",
    payload: data,
});

export const fetchBrandDataFailure = (error) => ({
    type: "FETCH_BRAND_DATA_FAILURE",
    payload: error,
});

export const getBrandData = () =>

    async (dispatch) => {

        dispatch(fetchBrandDataRequest());
        try {


            let response = await fetch(`${host}/master/get-brand`, { method: "GET" });
            // console.log("response", await response.json());


            if (!response.ok) {
                throw new Error('Please enter correct URL.');
            }
            const data = await response.json();
            // console.log('datais', data);
            let desired_data = data?.data;
            // console.log('desired_data', desired_data)

            dispatch(fetchBrandDataSuccess(desired_data));

        } catch (error) {
            dispatch(fetchBrandDataFailure(error.message));
        }
    }

// Assign size data
export const fetchAssignSizeDataRequest = () => ({
    type: "FETCH_ASSIGN_SIZE_DATA_REQUEST",
});

export const fetchAssignSizeDataSuccess = (data) => ({
    type: "FETCH_ASSIGN_SIZE_DATA_SUCCESS",
    payload: data,
});

export const fetchAssignSizeDataFailure = (error) => ({
    type: "FETCH_ASSIGN_SIZE_DATA_FAILURE",
    payload: error,
});

export const getAssignSizeData = () =>

    async (dispatch) => {

        dispatch(fetchAssignSizeDataRequest());
        try {


            let response = await fetch(`${host}/master/get-assign-size`, { method: "GET" });
            // console.log("response", await response.json());


            if (!response.ok) {
                throw new Error('Please enter correct URL.');
            }
            const data = await response.json();
            // console.log('datais', data);
            let desired_data = data?.data;
            // console.log('desired_data', desired_data)

            dispatch(fetchAssignSizeDataSuccess(desired_data));

        } catch (error) {
            dispatch(fetchAssignSizeDataFailure(error.message));
        }
    }


// Assign size data
export const fetchCmsDataRequest = () => ({
    type: "FETCH_CMS_DATA_REQUEST",
});

export const fetchCmsDataSuccess = (data) => ({
    type: "FETCH_CMS_DATA_SUCCESS",
    payload: data,
});

export const fetchCmsDataFailure = (error) => ({
    type: "FETCH_CMS_DATA_FAILURE",
    payload: error,
});

export const getCmsData = (slug) =>

    async (dispatch) => {

        dispatch(fetchCmsDataRequest());
        try {


            let response = await fetch(`${host}/master/get-all-cms?slug=${slug}`, { method: "GET" });
            // console.log("response", await response.json());


            if (!response.ok) {
                throw new Error('Please enter correct URL.');
            }
            const data = await response.json();
            // console.log('datais', data);
            let desired_data = data?.data;
            // console.log('desired_data', desired_data)

            dispatch(fetchCmsDataSuccess(desired_data));

        } catch (error) {
            dispatch(fetchCmsDataFailure(error.message));
        }
    }

// Buyer Customer Rating Details
export const fetchCustomerRatingDetailsRequest = () => ({
    type: "FETCH_CUSTOMER_RATING_DETAIL_REQUEST",
});

export const fetchCustomerRatingDetailsSuccess = (data) => ({
    type: "FETCH_CUSTOMER_RATING_DETAIL_SUCCESS",
    payload: data,
});

export const fetchCustomerRatingDetailsFailure = (error) => ({
    type: "FETCH_CUSTOMER_RATING_DETAIL_FAILURE",
    payload: error,
});


export const getCustomerRatingDetails = (productId) =>

    async (dispatch) => {

        dispatch(fetchCustomerRatingDetailsRequest());
        try {
            let response = await fetch(`${host}/master/get-all-product-rating-details?productId=${productId}`, { method: "GET" });


            // console.log("response", await response.json());


            if (!response.ok) {
                throw new Error('Please enter correct URL.');
            }
            const data = await response.json();
            // console.log('datais', data);
            let desired_data = data?.data;
            // console.log('desired_data', desired_data)

            dispatch(fetchCustomerRatingDetailsSuccess(desired_data));

        } catch (error) {
            dispatch(fetchCustomerRatingDetailsFailure(error.message));
        }
    }

// Buyer Customer Rating Details
export const fetchCustomerReviewListRequest = () => ({
    type: "FETCH_CUSTOMER_REVIEW_LIST_REQUEST",
});

export const fetchCustomerReviewListSuccess = (data) => ({
    type: "FETCH_CUSTOMER_REVIEW_LIST_SUCCESS",
    payload: data,
});

export const fetchCustomerReviewListFailure = (error) => ({
    type: "FETCH_CUSTOMER_REVIEW_LIST_FAILURE",
    payload: error,
});


export const getCustomerReviewList = (productId) =>

    async (dispatch) => {

        dispatch(fetchCustomerReviewListRequest());
        try {
            let response = await fetch(`${host}/master/get-all-customer-review-listing?productId=${productId}`, { method: "GET" });


            // console.log("response", await response.json());


            if (!response.ok) {
                throw new Error('Please enter correct URL.');
            }
            const data = await response.json();
            // console.log('datais', data);
            let desired_data = data?.data;
            // console.log('desired_data', desired_data)

            dispatch(fetchCustomerReviewListSuccess(desired_data));

        } catch (error) {
            dispatch(fetchCustomerReviewListFailure(error.message));
        }
    }


// Buyer Customer Rating Details
export const fetchReasonListRequest = () => ({
    type: "FETCH_REASON_LIST_REQUEST",
});

export const fetchReasonListSuccess = (data) => ({
    type: "FETCH_REASON_LIST_SUCCESS",
    payload: data,
});

export const fetchReasonListFailure = (error) => ({
    type: "FETCH_REASON_LIST_FAILURE",
    payload: error,
});


export const getReasonList = () =>

    async (dispatch) => {

        dispatch(fetchReasonListRequest());
        try {
            let response = await fetch(`${host}/master/get-all-reasons`, { method: "GET" });


            // console.log("response", await response.json());


            if (!response.ok) {
                throw new Error('Please enter correct URL.');
            }
            const data = await response.json();
            // console.log('datais', data);
            let desired_data = data?.data;
            // console.log('desired_data', desired_data)

            dispatch(fetchReasonListSuccess(desired_data));

        } catch (error) {
            dispatch(fetchReasonListFailure(error.message));
        }
    }


// Buyer Faq list
export const fetchBuyerFaqListRequest = () => ({
    type: "FETCH_FAQ_LIST_REQUEST",
});

export const fetchBuyerFaqListSuccess = (data) => ({
    type: "FETCH_FAQ_LIST_SUCCESS",
    payload: data,
});

export const fetchBuyerFaqListFailure = (error) => ({
    type: "FETCH_FAQ_LIST_FAILURE",
    payload: error,
});


export const getBuyerFaqList = () =>

    async (dispatch) => {

        dispatch(fetchBuyerFaqListRequest());
        try {
            let response = await fetch(`${host}/master/get-all-faq`, { method: "GET" });


            // console.log("response", await response.json());


            if (!response.ok) {
                throw new Error('Please enter correct URL.');
            }
            const data = await response.json();
            // console.log('datais', data);
            let desired_data = data?.data;
            // console.log('desired_data', desired_data)

            dispatch(fetchBuyerFaqListSuccess(desired_data));

        } catch (error) {
            dispatch(fetchBuyerFaqListFailure(error.message));
        }
    }


// Buyer Faq list
export const fetchProductOnCategoryRequest = () => ({
    type: "FETCH_PRODUCT_ON_CATEGORY_REQUEST",
});

export const fetchProductOnCategorySuccess = (data) => ({
    type: "FETCH_PRODUCT_ON_CATEGORY_SUCCESS",
    payload: data,
});

export const fetchProductOnCategoryFailure = (error) => ({
    type: "FETCH_PRODUCT_ON_CATEGORY_FAILURE",
    payload: error,
});


export const getProductOnCategory = (id) =>

    async (dispatch) => {

        dispatch(fetchProductOnCategoryRequest());
        try {
            let response = await fetch(`${host}/master/get-product-on-cat?id=${id}`, { method: "GET" });


            // console.log("response", await response.json());


            if (!response.ok) {
                throw new Error('Please enter correct URL.');
            }
            const data = await response.json();
            // console.log('datais', data);
            let desired_data = data?.data;
            // console.log('desired_data', desired_data)

            dispatch(fetchProductOnCategorySuccess(desired_data));

        } catch (error) {
            dispatch(fetchProductOnCategoryFailure(error.message));
        }
    }