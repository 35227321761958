import React, { useEffect, useState } from 'react'
import './scss/BuyerCheckoutContent.css'
import { Link, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from "formik";
import { toast } from 'react-hot-toast';
import * as Yup from "yup";
import axios from 'axios';

import { getBuyerAddressList, getBuyerCartProductList } from '../../Redux/action/BuyerAction';
import { getCityData, getCountry, getStateData } from '../../Redux/action/MasterAction';
import Loader from '../../Loader';
import ErrorLoader from '../../Error/ErrorLoader';
let host = process.env.REACT_APP_API_SERVER;
const BuyerCheckoutContent = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const authToken = localStorage.getItem("buyer_token")
    const [cartItems, setCartItems] = useState([]);
    const [subtotal, setSubtotal] = useState(0);
    const [discount, setDiscount] = useState(0);
    const [tax, setTax] = useState(0);
    const [total, setTotal] = useState(0);
    const [isEditing, setIsEditing] = useState(false);
    const [editedId, setEditedId] = useState(null);
    const [modalIsOpen, setModalIsOpen] = useState(false);



    useEffect(() => {
        const storedCartItems = localStorage.getItem('cartItems');
        if (storedCartItems) {
            const parsedCartItems = JSON.parse(storedCartItems);
            setCartItems(parsedCartItems);
            calculateSummary(parsedCartItems);
        }
    }, [navigate]);

    const calculateSummary = (items) => {
        const subtotal = items?.reduce((acc, item) => acc + item.originalPrice * item.quantity, 0);
        const discount = items?.reduce((acc, item) => acc + (item.originalPrice - item.sellingPrice) * item.quantity, 0);
        const tax = subtotal * 0.2; // Example tax rate of 20%
        const total = subtotal + tax - discount;
        setSubtotal(subtotal);
        setDiscount(discount);
        setTax(tax);
        setTotal(total);
    };



    useEffect(() => {
        if (authToken) {
            dispatch(getBuyerAddressList(authToken));
        }
        else {
            navigate('/buyer/sign-in')
        }

    }, []);
    const {
        loadingBuyerAddressListDataListApi,
        BuyerAddressListDataListApiData,
        BuyerAddressListDataListApiError,


    } = useSelector(state => state?.BuyerReducer);


    const addressData = BuyerAddressListDataListApiData?.data

    useEffect(() => {
        dispatch(getCountry());
    }, []);

    const openModal = (address = null) => {
        setModalIsOpen(true);
        if (address) {
            handleCountryChange({ target: { value: address.country } }, address.state, address.city);
        }
    };

    const closeModal = () => {
        setModalIsOpen(false);
        setIsEditing(false);
        setEditedId(null);
        formik.resetForm();
    };

    const handleCountryChange = (e, state = null, city = null) => {
        const countryId = e.target.value;
        formik.setFieldValue('country', countryId);
        dispatch(getStateData(countryId));
        if (state) {
            formik.setFieldValue('state', state);
            dispatch(getCityData(state));
        } else {
            formik.setFieldValue('state', '');
            formik.setFieldValue('city', '');
        }
        if (city) {
            formik.setFieldValue('city', city);
        }
    };

    const handleStateChange = (e, city = null) => {
        const stateId = e.target.value;
        formik.setFieldValue('state', stateId);
        dispatch(getCityData(stateId));
        if (city) {
            formik.setFieldValue('city', city);
        } else {
            formik.setFieldValue('city', '');
        }
    };
    const {
        loadingCountryDataListApi,
        CountryDataListApiData,
        CountryDataListApiError,

        loadingStateDataListApi,
        StateDataListApiData,
        StateDataListApiError,

        loadingCityDataListApi,
        CityDataListApiData,
        CityDataListApiError,


    } = useSelector(state => state?.MasterReducer);

    useEffect(() => {

        dispatch(getStateData(addressData?.country));
        dispatch(getCityData(addressData?.state))
    }, [addressData]);

    const config = {
        headers: {

            'content-type': 'application/json',
            "auth-token": localStorage.getItem("buyer_token")
        }
    };

    const formik = useFormik({
        initialValues: {
            firstName: "",
            lastName: "",
            email: "",
            phone: "",
            address: "",
            typeOfAddress: "",
            country: "",
            state: "",
            city: "",
            pincode: "",
            defaultAddress: false,
            addressId: "",

        },
        validationSchema: Yup.object().shape({
            firstName: Yup.string().required("First name is required").matches(/^[a-zA-Z .]+$/, "Name can only contain letters."),
            lastName: Yup.string().matches(/^[a-zA-Z .]+$/, "Name can only contain letters."),
            phone: Yup.string().required("Phone no. is required").matches(/^[0-9]{8,15}$/, "Please enter a valid Mobile Number."),
            email: Yup.string().required("Email is required.").matches(/^\w+[-\.\w]*@(?!(?:)\.com$)\w+[-\.\w]*?\.\w{2,4}$/, "Enter a valid E-mail"),
            address: Yup.string().required("Address is required"),
            typeOfAddress: Yup.string().required("Type of address is required"),
            country: Yup.string().required("Country is required"),
            state: Yup.string().required("Province is required"),
            city: Yup.string().required("City is required"),
            pincode: Yup.string().required("Postal/Zip is required").matches(/^[0-9]+$/, 'Postal/Zip must contain only numbers')
                .min(4, 'Enter a valid Postal/Zip')
                .required('Postal/Zip is required'),

        }),
        onSubmit: async (values, { setSubmitting, resetForm }) => {
            try {

                setSubmitting(true);

                if (isEditing) {
                    values.addressId = editedId;
                    await axios.post(`${host}/buyer/buyer-update-address`, values, config)
                        .then(function (response) {
                            if (response.status == 200) {
                                setSubmitting(false)
                                toast.success("Address updated successfully");
                                resetForm();
                                triggerModalClose();
                                dispatch(getBuyerAddressList(authToken));
                            } else {
                                setSubmitting(false)
                                toast.error(response.data.message);
                            }

                        })
                        .catch(function (error) {
                            setSubmitting(false)
                            console.log(error)
                            toast.error(error.response.data.message);
                        })
                }
                else {
                    await axios.post(`${host}/buyer/buyer-add-address`, values, config)
                        .then(function (response) {
                            if (response.status == 200) {
                                setSubmitting(false)
                                toast.success("Address Added successfully");
                                resetForm();
                                triggerModalClose();
                                dispatch(getBuyerAddressList(authToken));
                            } else {
                                setSubmitting(false)
                                toast.error(response.data.message);
                            }

                        })
                        .catch(function (error) {
                            setSubmitting(false)
                            console.log(error)
                            toast.error(error.response.data.message);
                        })
                }
            }
            catch (error) {
                setSubmitting(false)
                toast.error('Error submitting form. Please try again.');
            }
        }
    })




    const orderformik = useFormik({
        initialValues: {
            cartItems: JSON.parse(localStorage.getItem('cartItems')) || [],
            addressId: "",
            total_amount: "",
            payable_amount: "",
            shipping_cost: "",
            total_tax: "",
            shipping_applied: false,
            total_discount: "",
        },
        onSubmit: async (values, { setSubmitting, resetForm }) => {
            try {
                setSubmitting(true);

                values.payable_amount = total.toFixed(2);
                values.total_tax = tax.toFixed(2);
                values.total_amount = subtotal.toFixed(2);
                values.total_discount = discount.toFixed(2);
                values.shipping_applied = false;
                values.shipping_cost = 'free';



                const response = await axios.post(`${host}/buyer/place-order`, values, config);

                if (response.status === 200) {
                    setSubmitting(false);
                    toast.success("Order placed successfully");
                    triggerModalOpen();
                    dispatch(getBuyerCartProductList('auth-token', authToken));
                } else {
                    setSubmitting(false);
                    toast.error(response.data.message);
                }
            } catch (error) {
                setSubmitting(false);
                console.error(error);
                toast.error(error.response?.data?.message || 'Error submitting form. Please try again.');
            }
        },
    });
    useEffect(() => {
        const defaultAddress = addressData?.find(item => item.defaultAddress);
        if (defaultAddress) {
            orderformik.setFieldValue('addressId', defaultAddress.id);
        }
    }, [addressData]);
    const handleNavigate = () => {
        triggerModalClose2();
        navigate('/buyer/home')
    }


    const triggerModalClose = () => {

        const closeButton = document.getElementById('close-modal-address');
        if (closeButton) {
            closeButton.click();
        }

    };

    const triggerModalClose2 = () => {

        const closeButton = document.getElementById('close-modal2');
        if (closeButton) {
            closeButton.click();
        }

    };
    const triggerModalOpen = () => {
        const closeButton = document.getElementById('open-modal');
        if (closeButton) {
            closeButton.click();
        }

    };
    const handleAdd = () => {
        formik.setValues({
            firstName: "",
            lastName: "",
            email: "",
            phone: "",
            address: "",
            typeOfAddress: "",
            country: "",
            state: "",
            city: "",
            pincode: "",
            defaultAddress: false, // Reset to false
        });
        formik.setTouched({}); // Reset touched state
    };

    const handleEdit = (id) => {
        const selectedData = addressData?.find(item => item.id === id);
        if (selectedData) {
            formik.setValues({
                firstName: selectedData.firstName ?? "",
                lastName: selectedData.lastName ?? "",
                phone: selectedData.phone ?? "",
                email: selectedData.email ?? "",
                address: selectedData.address ?? "",
                typeOfAddress: selectedData.typeOfAddress ?? "",
                country: selectedData.country ?? "",
                state: selectedData.state ?? "",
                city: selectedData.city ?? "",
                pincode: selectedData.pincode ?? "",
                defaultAddress: !!selectedData.defaultAddress, // Ensure this is a boolean
            });
            handleCountryChange({ target: { value: selectedData.country } }, selectedData.state, selectedData.city);
        }
        setIsEditing(true);
        setEditedId(id);
        openModal(selectedData);
    };


    // if (loadingCountryDataListApi || loadingStateDataListApi || loadingCityDataListApi) {
    //     return <div className="overlay-form-submission">
    // <Loader />
    //     </div>
    // }

    if (CountryDataListApiError || StateDataListApiError || CityDataListApiError) {
        return <ErrorLoader message={CountryDataListApiError} />;
    }


    return (
        <div className='checkout'>
            {BuyerAddressListDataListApiData?.message == 'Your account is inactivated.' ?
                <div className=" container-fluid my-5 py-5 main-wish-list">

                    <div className="d-flex justify-content-center pt-4 py-5 heading-sec px-4">
                        <img src="/assets/images/inactive-account.png" width={200} alt="" srcSet="" />

                    </div>

                    <h1 className='text-center text-danger'>Your account is inacitvated</h1>
                </div>

                :
                <>
                    {/* Breadcrumb */}
                    <div className="breadcrumb_section">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                    <div className="breadcrumb_section_content">
                                        <ul>
                                            <li>
                                                <Link to="/buyer">
                                                    <i className="fa-solid fa-house" /> Home
                                                </Link>
                                            </li>
                                            <li>
                                                <i className="fa-solid fa-angle-right" />
                                            </li>
                                            <li>
                                                <Link to="/buyer/cart">Shoping Cart</Link>

                                            </li>
                                            <li>
                                                <i className="fa-solid fa-angle-right" />
                                            </li>
                                            <li>
                                                <Link to="/buyer/checkout">Checkout</Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Breadcrumb */}
                    {/* Shopping Cart */}
                    <form onSubmit={orderformik.handleSubmit}>
                        <div className="shopping_cart pb-5">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-xl-8 col-8 col-md-8 col-sm-12 col-12">
                                        <div className="shopping_cart_heading checkout_table">
                                            <h2>Product ({cartItems.length})</h2>
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th colSpan={2}>Products</th>
                                                        <th className="text-center">Price</th>
                                                        <th className="text-center">Quantity</th>
                                                        <th className="text-center">Sub-Total</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {cartItems.map((item, index) => (
                                                        <tr key={index}>
                                                            <td className="text-start">
                                                                <img src={item?.image ? `${host}${item?.image}` : ''} className="img-fluid" />
                                                            </td>
                                                            <td className="text-start">
                                                                {item.name}
                                                            </td>
                                                            <td>
                                                                <del>AFN {item.originalPrice}</del> AFN {item.sellingPrice}
                                                            </td>
                                                            <td>
                                                                <div className="quantity_number">
                                                                    <input
                                                                        className="quantity"
                                                                        type="text"
                                                                        value={item.quantity}
                                                                        readOnly
                                                                    />
                                                                </div>
                                                            </td>
                                                            <td>AFN {item.sellingPrice * item.quantity}</td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className='row'>
                                            <div className='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 order_last'>
                                                <div className="shipping_address mt-2">
                                                    <h2>
                                                        Shipping Address{" "}
                                                        {/* <span>
                                                <a href="">
                                                    <i className="fa-solid fa-trash" />
                                                </a>
                                            </span> */}
                                                    </h2>
                                                    <div className='shipping_add'>
                                                        {addressData?.map((data) => (
                                                            <div className="form-check" key={data.id}>
                                                                <input
                                                                    className="form-check-input"
                                                                    type="radio"
                                                                    name="addressId"
                                                                    id={`addressId-${data.id}`}
                                                                    value={data.id}
                                                                    onChange={orderformik.handleChange}
                                                                    onBlur={orderformik.handleBlur}
                                                                    defaultChecked={data.defaultAddress}
                                                                />
                                                                <label className="form-check-label" htmlFor={`addressId-${data.id}`}>
                                                                    <h5 className='shipping_add_title'>{data?.firstName} {data?.lastName}
                                                                        <i onClick={() => {
                                                                            handleEdit(data.id);
                                                                        }}
                                                                            data-bs-toggle="modal"
                                                                            data-bs-target="#exampleModal" className="fa fa-pencil">
                                                                        </i>
                                                                    </h5>
                                                                    <p>
                                                                        {data?.address}
                                                                    </p>
                                                                    <h6>
                                                                        Phone Number: <span>{data?.phone}</span>
                                                                    </h6>
                                                                    <h6>
                                                                        Email : <span>{data?.email}</span>
                                                                    </h6>
                                                                </label>
                                                            </div>
                                                        ))}
                                                        <h3>
                                                            <a href="" data-bs-toggle="modal" data-bs-target="#exampleModal">
                                                                <i className="fa-solid fa-plus" onClick={() => {
                                                                    setIsEditing(false);
                                                                    handleAdd();
                                                                }
                                                                } /> Add new Address
                                                            </a>
                                                        </h3>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                                        <div className="shop-page-table checkout_page">
                                            <h2>Order Summary</h2>
                                            <h4>
                                                <img src="/assets/images/cash_icon.png" alt="Cash Icon" /> On Delivery
                                            </h4>
                                            <div className="checkout_page_inner">
                                                <table>
                                                    <tbody>
                                                        <tr>
                                                            <td>Sub-total</td>
                                                            <td className="text-end">
                                                                <strong>AFN {subtotal.toFixed(2)}</strong>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>Shipping</td>
                                                            <td className="text-end">
                                                                <strong>Free</strong>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>Discount</td>
                                                            <td className="text-end">
                                                                <strong>AFN {discount.toFixed(2)}</strong>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>Tax</td>
                                                            <td className="text-end">
                                                                <strong>AFN {tax.toFixed(2)}</strong>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <th>Total</th>
                                                            <th className="text-end">
                                                                <strong>AFN {total.toFixed(2)}</strong>
                                                            </th>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                                <div className="add_to_cart mt-2">
                                                    <button type="submit" disabled={orderformik.isSubmitting}>
                                                        Place Your Order
                                                    </button>
                                                </div>
                                                <p className="text-center mt-2">
                                                    By placing your order, you agree to Afghanbazaar privacy Notice
                                                    and conditions of use.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </form>
                    {/* Shopping Cart */}
                </>}

            <>
                {/* Add Modal */}
                <button type="button" className="btn btn-primary" id='open-modal' hidden={true} data-bs-toggle="modal" data-bs-target="#exampleModal1">
                </button>
                <div
                    className="modal fade address_popup"
                    id="exampleModal"
                    tabIndex={-1}
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="true"
                >
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <button type="button" className="btn-close" hidden={true} id='close-modal-address' data-bs-dismiss="modal" aria-label="Close" />
                            <form onSubmit={formik.handleSubmit} method='post'>
                                <div className="modal-body">
                                    <div className="order-place">
                                        <h5>Contact Details</h5>
                                        <div className="row pt-3">
                                            <div className="col-md-6">
                                                <label htmlFor="name">First Name<span className='text-danger'>*</span></label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="firstName"
                                                    placeholder="Enter First Name"
                                                    name="firstName"
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.firstName} />
                                                <div className="help-block with-errors">
                                                    {formik.touched.firstName && formik.errors.firstName ? (
                                                        <div className='text-danger'>{formik.errors.firstName}</div>
                                                    ) : null}
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <label htmlFor="name">Last Name</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="lastName"
                                                    name="lastName"
                                                    placeholder="Enter Last Name"
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.lastName} />
                                                <div className="help-block with-errors">
                                                    {formik.touched.lastName && formik.errors.lastName ? (
                                                        <div className='text-danger'>{formik.errors.lastName}</div>
                                                    ) : null}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row pt-3">
                                            <div className="col-md-12 mail-sec">
                                                <label htmlFor="email">Email<span className='text-danger'>*</span></label>
                                                <input
                                                    type="email"
                                                    className="form-control"
                                                    id="email"
                                                    name="email"
                                                    placeholder="Enter your email"
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.email} />
                                            </div>
                                            <div className="help-block with-errors">
                                                {formik.touched.email && formik.errors.email ? (
                                                    <div className='text-danger'>{formik.errors.email}</div>
                                                ) : null}
                                            </div>
                                        </div>
                                        <div className="row pt-3">
                                            <div className="col-md-12 mail-sec">
                                                <label htmlFor="text">Phone Number<span className='text-danger'>*</span></label>
                                                <input
                                                    type="tel"
                                                    className="form-control"
                                                    id="phone"
                                                    name="phone"
                                                    placeholder="Enter your phone number"
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.phone} />
                                            </div>
                                            <div className="help-block with-errors">
                                                {formik.touched.phone && formik.errors.phone ? (
                                                    <div className='text-danger'>{formik.errors.phone}</div>
                                                ) : null}
                                            </div>
                                        </div>
                                        <div className="row pt-3">
                                            <div className="add_new_address_right_field">
                                                <label htmlFor="address">Type Of Address<span className='text-danger'>*</span></label>
                                                <div className="radio_buttom_section">
                                                    <div className="form-check">
                                                        <input
                                                            className="form-check-input"
                                                            type="radio"
                                                            id="general_inquiry"
                                                            value="Office Address"
                                                            checked={formik.values.typeOfAddress === 'Office Address'}
                                                            name='typeOfAddress'
                                                            onChange={formik.handleChange}
                                                            onBlur={formik.handleBlur}
                                                        />
                                                        <label
                                                            className="form-check-label"
                                                            htmlFor="general_inquiry"
                                                        >
                                                            Office Address
                                                        </label>
                                                    </div>
                                                    <div className="form-check">
                                                        <input
                                                            className="form-check-input"
                                                            type="radio"
                                                            id="general_inquiry_1"
                                                            value="Home Address"
                                                            checked={formik.values.typeOfAddress === 'Home Address'}
                                                            name='typeOfAddress'
                                                            onChange={formik.handleChange}
                                                            onBlur={formik.handleBlur}
                                                        />
                                                        <label
                                                            className="form-check-label"
                                                            htmlFor="general_inquiry_1"
                                                        >
                                                            Home Address
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="help-block with-errors">
                                                    {formik.touched.typeOfAddress && formik.errors.typeOfAddress ? (
                                                        <div className='text-danger'>{formik.errors.typeOfAddress}</div>
                                                    ) : null}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="shipping-sec pt-4">
                                        <h5>Shipping Details</h5>
                                        <div className="row pt-3">
                                            <div className="col-md-12 mail-sec">
                                                <label htmlFor="email">Address<span className='text-danger'>*</span></label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="address"
                                                    name="address"
                                                    placeholder="Enter your address"
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.address} />
                                            </div>
                                            <div className="help-block with-errors">
                                                {formik.touched.address && formik.errors.address ? (
                                                    <div className='text-danger'>{formik.errors.address}</div>
                                                ) : null}
                                            </div>
                                        </div>
                                        <div className="row pt-3">
                                            <div className="col-md-6">
                                                <label htmlFor="name">Country<span className='text-danger'>*</span></label>
                                                <select name="country" id="" className="form-control"
                                                    onChange={(e) => {
                                                        formik.handleChange(e);
                                                        handleCountryChange(e)
                                                    }}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.country}
                                                >
                                                    <option value="">Select Country</option>
                                                    {CountryDataListApiData?.map((data) => (
                                                        <option value={data?.id}>{data.name}</option>
                                                    ))}
                                                </select>
                                                <div className="help-block with-errors">
                                                    {formik.touched.country && formik.errors.country ? (
                                                        <div className='text-danger'>{formik.errors.country}</div>
                                                    ) : null}
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <label htmlFor="name">Province<span className='text-danger'>*</span></label>
                                                <select name="state" id="" className="form-control"
                                                    onChange={(e) => {
                                                        formik.handleChange(e);
                                                        handleStateChange(e)
                                                    }}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.state}
                                                >
                                                    <option value="">Select Province</option>
                                                    {StateDataListApiData?.map((data) => (
                                                        <option value={data?.id}>{data?.name}</option>
                                                    ))}
                                                </select>
                                                <div className="help-block with-errors">
                                                    {formik.touched.state && formik.errors.state ? (
                                                        <div className='text-danger'>{formik.errors.state}</div>
                                                    ) : null}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row pt-3">
                                            <div className="col-md-6">
                                                <label htmlFor="name">City<span className='text-danger'>*</span></label>
                                                <select name="city" id="" className="form-control"
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.city}
                                                >
                                                    <option value="">Select City</option>
                                                    {CityDataListApiData?.map((data) => (
                                                        <option value={data?.id}>{data?.name}</option>
                                                    ))}
                                                </select>
                                                <div className="help-block with-errors">
                                                    {formik.touched.city && formik.errors.city ? (
                                                        <div className='text-danger'>{formik.errors.city}</div>
                                                    ) : null}
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <label htmlFor="name">Postal Codes/Zip<span className='text-danger'>*</span></label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="pincode"
                                                    name="pincode"
                                                    placeholder="Enter your zip code"
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.pincode}
                                                />
                                                <div className="help-block with-errors">
                                                    {formik.touched.pincode && formik.errors.pincode ? (
                                                        <div className='text-danger'>{formik.errors.pincode}</div>
                                                    ) : null}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="checkbox pt-3">
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            name='defaultAddress'
                                            id="defaultAddress"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            checked={formik.values.defaultAddress}
                                        />
                                        <span className="ps-2">Make this my default address</span>
                                        <div className="help-block with-errors">
                                            {formik.touched.defaultAddress && formik.errors.defaultAddress ? (
                                                <div className='text-danger'>{formik.errors.defaultAddress}</div>
                                            ) : null}
                                        </div>
                                    </div>
                                    <div className="continue-btn pt-3">
                                        <button type='submit'>Continue</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

                {/* order placed modal */}
                <div
                    className="modal fade"
                    id="exampleModal1"
                    tabIndex={-1}
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="true"
                >
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <button type="button" hidden={true} className="btn-close" id='close-modal2' data-bs-dismiss="modal" aria-label="Close" />
                            <div className="modal-body">
                                <div className="order-place text-center">
                                    <h3>Order Placed Successfully</h3>
                                    <div className="order-image">
                                        <img
                                            src="/assets/images/order-place-modal.png"
                                            alt="order-place"
                                            className="img-fluid"
                                        />
                                    </div>
                                    <div className="thank-you">
                                        <h4>Thank you, your order has been placed</h4>
                                        <p>
                                            Please check you email for order confirmation and detailed
                                            delivery information or visit message center to review your
                                            notifications
                                        </p>
                                        <a onClick={handleNavigate}>
                                            <button>Continue Shopping</button>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>




        </div>

    )
}

export default BuyerCheckoutContent