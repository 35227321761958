import { React, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useFormik } from "formik";
import { toast } from 'react-hot-toast';
import * as Yup from "yup";
import axios from 'axios';
import '../scss/Auth.css';


import {
  LoginSocialGoogle, LoginSocialLinkedin,
} from 'reactjs-social-login'

import {
  GoogleLoginButton, LinkedInLoginButton,
} from 'react-social-login-buttons'
const host = process.env.REACT_APP_API_SERVER
const client_id = process.env.REACT_APP_GG_APP_CLIENT_ID;
const REDIRECT_URI = 'https://afgan.alobhadev.com/buyer/create-account'

const SignInWithOtp = () => {

  const [provider, setProvider] = useState('')
  const [profile, setProfile] = useState(null);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const guestUser = queryParams.get('guest');
  const navigate = useNavigate();
  const guestToken = localStorage.getItem('guest_token');

  if (profile?.email_verified) {


    try {
      const formdata = {
        firstName: profile?.given_name,
        lastName: profile?.family_name,
        email: profile?.email,
      }
      axios.post(`${host}/buyer/buyer-google-login-web`, formdata).
        then((response) => {
          if (response.status == 200) {

            localStorage.setItem("buyer_token", response.data.authToken);
            toast(response?.data?.message);
            if (guestToken) {
              updateBuyerIdFromGuestId();
              localStorage.removeItem("guest_token");
              if (guestUser && guestUser == 'true') {
                navigate('/buyer/cart')
              }
              else {
                navigate('/buyer')
              }
            }
            else {
              navigate('/buyer')
            }

          }
          else {

            toast(response?.data?.message);
          }
        })
    }
    catch (error) {

      toast("Error loggin In");

    }
  }

  const updateBuyerIdFromGuestId = () => {
    try {
      const config = {
        headers: {

          'content-type': 'application/json',
          "auth-token": localStorage.getItem("buyer_token")
        }
      };
      axios.post(`${host}/buyer/update-guest-token-to-buyer-token`, { guestToken }, config).
        then((response) => {
          if (response.status == 200) {
            // toast(response?.data?.message);
          }
          else {

            toast(response?.data?.message);
          }
        })
    }
    catch (error) {

      toast("Error loggin In");

    }
  }


  const handleCreateAccount = () => {
    if (guestUser && guestUser == 'true') {
      navigate('/buyer/create-account?guest=true')
    }
    else {
      navigate('/buyer/create-account')
    }
  }

  return (
    <div className="auth">
      <section className="login">
        <div className="container-fluid">
          <div className="row welcome-login-main align-items-center">
            <div className="col-md-6 px-5 form-section">
              <div className="fields-section pb-4">
                <div className="welcome-back-sec text-center pt-3 pb-3">
                  <img src="/assets/images/afgan-bazaar-logo.png" alt="logo" />
                  {/* <h3>Afghan Bazaar</h3> */}
                  <h1 className='pt-2 pb-2'>Sign in</h1>
                  <p>Welcome to Afghan Bazar! please enter your details</p>
                </div>
                <div className="pass-email-field">
                  <label htmlFor="phone">Phone No.</label>
                  <input
                    type="tel"
                    placeholder="+93-123467890"
                    className="form-control"
                  />
                </div>
                <div className="submit-btn text-center">
                  <Link to="/buyer/otp-verification">
                    <button>Continue</button>
                  </Link>
                </div>
                <div className="create_your_account">
                  <p>
                    New to Afghan Bazaar
                    <a onClick={handleCreateAccount} style={{ cursor: 'pointer' }}> Create your account</a>
                  </p>
                </div>
                <div className="single-line pt-3">
                  <div className="or-sec">
                    <hr />
                    <p>or</p>
                  </div>
                </div>
                <div className="submit-btn-google">
                  {/* <a href="#">
                    <button>
                      <div className="d-flex ">
                        <div>
                          <img
                            src="/assets/images/Google.png"
                            alt="google"
                            className="img-fluid"
                          />
                        </div>{" "}
                        <span>Sign up with Google</span>
                      </div>
                    </button>
                  </a> */}
                  <LoginSocialGoogle
                    client_id={client_id}
                    scope="openid profile email"
                    className='signin-goog-login-cls'
                    onResolve={({ provider, data }) => {
                      setProvider(provider)
                      setProfile(data)


                    }}
                    onReject={(err) => {

                    }}
                  >
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                      <GoogleLoginButton style={{ display: 'flex', justifyContent: 'center' }} />
                    </div>
                    {/* <GoogleLoginButton style={{ width: '46px', borderRadius: '50px' }} text='' /> */}
                  </LoginSocialGoogle>
                </div>
                {/* <div className="submit-btn-apple">
                  <a href="#">
                  <button>
                        <div className="d-flex apple_icon">                                                  
                                                                                
                            <span><i className="fa-brands fa-apple"></i> Sign up with Apple</span>
                            
                        </div>
                    </button>
                  </a>
                </div> */}
              </div>
            </div>
            <div className="col-md-6 p-0">
              <div className="login-banner-sec">
                <img
                  src="../assets/images/sign_in_with_otp.png"
                  alt="banner"
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default SignInWithOtp