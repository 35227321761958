import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getCmsData } from '../../../Redux/action/MasterAction'
import Loader from '../../../Loader'
import ErrorLoader from '../../../Error/ErrorLoader'
import BuyerShippingPolicyContent from './BuyerShippingPolicyContent'
import BuyerHeader from '../../BuyerHeader/BuyerHeader'
import BuyerFooter from '../../BuyerFooter/BuyerFooter'
import BuyerHeaderWithoutLogin from '../../BuyerHeader/BuyerHeaderWithoutLogin'



const BuyerShippingPolicy = () => {

    const dispatch = useDispatch();
    const authToken = localStorage.getItem("buyer_token");
    useEffect(() => {
        dispatch(getCmsData("shipping-policy"))
    }, []);
    const {

        loadingCmsDataListApi,
        CmsDataListApiData,
        CmsDataListApiError,

    } = useSelector(state => state?.MasterReducer);

    if (loadingCmsDataListApi) {
        return <div className="overlay-form-submission">
            <Loader />
        </div>
    }

    if (CmsDataListApiError) {
        return <ErrorLoader message={CmsDataListApiError} />;
    }

    return (
        <>
            {authToken ?

                <BuyerHeader />
                :
                <BuyerHeaderWithoutLogin />
            }
            <BuyerShippingPolicyContent CmsDataListApiData={CmsDataListApiData} />
            <BuyerFooter />
        </>
    )
}

export default BuyerShippingPolicy