import { React, useEffect, useState, useRef } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useFormik } from "formik";
import { toast } from 'react-hot-toast';
import * as Yup from "yup";
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { getSellerRegistrationDetails } from '../../Redux/action/SellerAction';

const host = process.env.REACT_APP_API_SERVER

const IdentificationNumber = () => {
    const triggerModalOpen = () => {
        const openButton = document.getElementById('modal-open');
        if (openButton) {
            openButton.click();
        }
    };
    const triggerModalClose = () => {
        const closeButton = document.getElementById('close-modal');
        if (closeButton) {
            closeButton.click();
        }

    };
    const [avatar, setAvatar] = useState(null);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const sellerIncompleteToken = localStorage.getItem('seller_incomplete_token')
    useEffect(() => {
        if (sellerIncompleteToken) {
            dispatch(getSellerRegistrationDetails(sellerIncompleteToken))
        }
    }, [sellerIncompleteToken])

    const {

        loadingSellerRegistrationDataListApi,
        SellerRegistrationDataListApiData,
        SellerRegistrationDataListApiError,

    } = useSelector(state => state?.SellerReducer);

    const identificationCardLength = SellerRegistrationDataListApiData ? SellerRegistrationDataListApiData?.sellerIdentificationCard ? SellerRegistrationDataListApiData?.sellerIdentificationCard?.length > 0 ? true : false : false : false



    let [initialValues, setInitialValues] = useState({

        identificationNumber: "",
        expiryOfId: "",
        identificationCard: [],


    })

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: Yup.object().shape({
            identificationNumber: Yup.string().required("Tazkira Number / Passport is required"),
            expiryOfId: Yup.string().required("Expiry date of ID / Passport is required"),
            identificationCard: identificationCardLength ? Yup.array().min(2, "Front and Back images have to be uploaded").max(2, "You can only upload up to 2 images") : Yup.array().min(2, "Front and Back images have to be uploaded").max(2, "You can only upload up to 2 images").required("Front and Back images have to be uploaded"),
        }),
        onSubmit: async (values, { setSubmitting, resetForm }) => {
            try {
                if (sellerIncompleteToken) {
                    const config = {
                        headers: {
                            'content-type': 'multipart/form-data',
                            'auth-token': sellerIncompleteToken,
                        },
                    };
                    setSubmitting(true);
                    const formData = new FormData();
                    formData.append('identificationNumber', values.identificationNumber);
                    formData.append('expiryOfId', values.expiryOfId);
                    if (values.identificationCard) {
                        values.identificationCard.forEach((file, index) => {
                            formData.append(`identificationCard`, file); // Append each file
                        });
                    }
                    formData.append('fullDetails', 1);

                    await axios.post(`${host}/seller/seller-add-tazkira-details`, formData, config)
                        .then((response) => {
                            if (response.status === 200) {
                                setSubmitting(false);
                                toast.success(response.data.message);
                                resetForm();
                                triggerModalOpen();
                                dispatch(getSellerRegistrationDetails(sellerIncompleteToken))
                            } else {
                                setSubmitting(false);
                                toast.error(response.data.message);
                                if (response.data.delAccount) {
                                    localStorage.removeItem('seller_incomplete_token');
                                }
                            }
                        })
                        .catch((error) => {
                            setSubmitting(false);
                            toast.error(error.message);
                        });
                } else {
                    navigate('/seller/create-account');
                }
            }
            catch (error) {

                console.log(error)
                setSubmitting(false)
                toast.error('Error submitting form. Please try again.');

            }

        }
    });

    const handleFileChange = (event) => {
        const files = Array.from(event.target.files);
        formik.setFieldValue('identificationCard', files); // Store multiple files in formik
    };


    useEffect(() => {

        if (SellerRegistrationDataListApiData) {
            formik.setValues({
                identificationNumber: SellerRegistrationDataListApiData?.identificationNumber ?? "",
                expiryOfId: SellerRegistrationDataListApiData?.expiryOfId ?? "",


            })
        }
    }, [SellerRegistrationDataListApiData])

    if (loadingSellerRegistrationDataListApi) {
        return <div className="overlay-form-submission">
            {/* <Loader /> */}
        </div>
    }

    if (SellerRegistrationDataListApiError) {
        // return <ErrorLoader message={SellerRegistrationDataListApiError} />;
    }


    return (
        <div className='seller'>
            <main className="main_login_section container-fluid">
                <div className="row">
                    <div className="col-lg-7 main_bg_detail">
                        <div className="main_login_detail detail_form_Shipping">
                            <form method='post' onSubmit={formik.handleSubmit}>
                                <div className="logo_login">
                                    <img src={`${process.env.PUBLIC_URL}/assets/images/seller_images/login_logo.png`} alt="login_logo" />
                                </div>
                                <div className="detail_form_log detail_form_Shipping">
                                    <h3>Tazkira Number</h3>
                                    <p>
                                        Please enter your data to complete your account registration
                                        process
                                    </p>
                                    <div className="main_detail_address mb-5">
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className="form-group log_in_main">
                                                    <label>Tazkira Number / Passport</label>
                                                    <input
                                                        type="text"
                                                        defaultValue=""
                                                        placeholder="Enter Here"
                                                        className="form-control"
                                                        name='identificationNumber'
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        value={formik.values.identificationNumber}
                                                    />
                                                    <div className="help-block with-errors">
                                                        {formik.touched.identificationNumber && formik.errors.identificationNumber ? (
                                                            <div className='text-danger'>{formik.errors.identificationNumber}</div>
                                                        ) : null}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className="form-group log_in_main new_detail_id">
                                                    <label>Expiry date of ID / Passport</label>
                                                    <input
                                                        type="date"
                                                        defaultValue=""
                                                        placeholder="Enter or Select"
                                                        className="form-control"
                                                        name='expiryOfId'
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        value={formik.values.expiryOfId}
                                                    />
                                                    {/* <span className="main_date_detail">
                                                        <img src={`${process.env.PUBLIC_URL}/assets/images/seller_images/Date_icon.png`} alt="date" />
                                                    </span> */}
                                                    <div className="help-block with-errors">
                                                        {formik.touched.expiryOfId && formik.errors.expiryOfId ? (
                                                            <div className='text-danger'>{formik.errors.expiryOfId}</div>
                                                        ) : null}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className="form-group log_in_main">
                                                    <label>Upload ID / Passport Images</label>
                                                    <div className="file-input">
                                                        <input
                                                            type="file"
                                                            accept="image/*"
                                                            multiple={true}
                                                            name="identificationCard"
                                                            onChange={handleFileChange}
                                                            onBlur={formik.handleBlur}
                                                            id="userfile"
                                                        />
                                                        <button className="folder_main_bro label_1" type="button">
                                                            <span>
                                                                <img src={`${process.env.PUBLIC_URL}/assets/images/seller_images/Upload.png`} alt="file" />
                                                            </span>{" "}
                                                            Upload File
                                                        </button>
                                                    </div>
                                                    {SellerRegistrationDataListApiData && SellerRegistrationDataListApiData?.sellerIdentificationCard && SellerRegistrationDataListApiData?.sellerIdentificationCard?.map((item) => (<img src={item?.image ? `${host}${item?.image}` : ''} width={50} srcSet="" />))}
                                                    <div className="help-block with-errors">
                                                        {formik.touched.identificationCard && formik.errors.identificationCard ? (
                                                            <div className="text-danger">{formik.errors.identificationCard}</div>
                                                        ) : null}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="detail_address_button mt-2">
                                                <button
                                                    type="submit"
                                                // data-bs-toggle="modal"
                                                // data-bs-target="#send_approval"

                                                >
                                                    Request For Approval
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className="col-lg-5 main_login_img">
                        <img src={`${process.env.PUBLIC_URL}/assets/images/seller_images/bank_img.png`} alt="login" />
                    </div>
                </div>
                {/* ===================Modal================== */}
                <button type="button" className="btn btn-primary d-none" id='modal-open' data-bs-toggle="modal" data-bs-target="#send_approval">
                    Launch demo modal
                </button>
                <div
                    className="modal fade"
                    id="send_approval"
                    tabIndex={-1}
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="true"
                >
                    <div className="modal-dialog Registra_modul_Request modal-dialog-centered">

                        <div className="modal-content ">
                            <div className="modal-body Registra_heading_detail">
                                <button type="button" className="btn-close d-none" id='close-modal' data-bs-dismiss="modal" aria-label="Close" />
                                <h4>Registration Request send for the approval</h4>
                                <p>
                                    Congratulations! We have sent the registration request to the Afghan
                                    Bazar Admin, Admin Will Connect you soon
                                </p>
                                <div className="request_send text-center">
                                    <Link
                                        to="/seller/sign-in"
                                        className="btn btn-secondary okay_color_main "
                                        data-bs-dismiss="modal" aria-label="Close"
                                    >
                                        Okay
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* ===================Modal================== */}
            </main>
        </div>
    )
}

export default IdentificationNumber