import { React, useEffect } from 'react'
import './scss/BuyerReturnOrderContent.css'
import { useFormik } from 'formik';
import { toast } from 'react-hot-toast';
import * as Yup from "yup";
import axios from 'axios';
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import { getBuyerOrderDetail } from '../../Redux/action/BuyerAction';
import { getReasonList } from '../../Redux/action/MasterAction';
let host = process.env.REACT_APP_API_SERVER;
const BuyerReturnOrderContent = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const authToken = localStorage.getItem('buyer_token')

    const dispatch = useDispatch();

    useEffect(() => {

        dispatch(getBuyerOrderDetail(authToken, id));

    }, [id, authToken]);

    useEffect(() => {

        dispatch(getReasonList())

    }, []);
    const {

        loadingBuyerOrderDetailListApi,
        BuyerOrderDetailListApiData,
        BuyerOrderDetailListApiError,

        loadingReasonListApi,
        ReasonListApiData,
        ReasonListApiError,

    } = useSelector(state => state?.BuyerReducer);
    const config = {
        headers: {

            'content-type': 'multipart/form-data',
            "auth-token": localStorage.getItem("buyer_token")
        }
    };
    const formik = useFormik({
        initialValues: {
            reason_id: '',
            message: '',
            image: null,
            quantity: '',
        },
        validationSchema: Yup.object({
            reason_id: Yup.string().required('This field is required'),
            message: Yup.string().required('This field is required'),
            image: Yup.mixed().required('This field is required'),
            quantity: Yup.string().required('This field is required'),
        }),
        onSubmit: async (values, { setSubmitting, resetForm }) => {
            try {
                if (authToken) {
                    setSubmitting(true);
                    values.orderDetailId = id;
                    console.log('value going', values)
                    var formData = new FormData();
                    formData.append('image', values.image);
                    formData.append('reason_id', values.reason_id);
                    formData.append('message', values.message);
                    formData.append('orderDetailId', values.orderDetailId);
                    formData.append('quantity', values.quantity);
                    await axios.post(`${host}/buyer/return-order`, formData, config)
                        .then(function (response) {
                            if (response.status == 200) {
                                triggerModalClose();
                                setSubmitting(false)
                                toast.success("Order return requested sucessfully");
                                resetForm();
                                navigate('/buyer/your-orders')

                            } else {
                                setSubmitting(false)
                                toast.error(response.data.message);
                            }

                        })
                        .catch(function (error) {
                            setSubmitting(false)
                            console.log(error)
                            toast.error(error.response.data.message);
                        })
                }
                else {
                    navigate('/buyer/your-orders')
                }
            }
            catch (error) {
                setSubmitting(false)
                toast.error('Error submitting form. Please try again.');
            }
        }
    });
    const triggerModalClose = () => {
        const closeButton = document.getElementById('close-modal');
        if (closeButton) {
            closeButton.click();
        }

    };
    const handleFileChange = (event) => {
        const file = event.currentTarget.files[0];
        formik.setFieldValue('image', file);
    };
    return (
        <div className='return-order'>
            <>
                {/* Breadcrumb */}
                <div className="breadcrumb_section">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                <div className="breadcrumb_section_content">
                                    <ul>
                                        <li>
                                            <Link to="/buyer/your-account"> Your Account</Link>
                                        </li>
                                        <li>
                                            <i className="fa-solid fa-angle-right" />
                                        </li>
                                        <li>
                                            <Link to="/buyer/your-orders">Your Orders</Link>
                                        </li>
                                        <li>
                                            <i className="fa-solid fa-angle-right" />
                                        </li>
                                        <li>
                                            <Link to="/buyer/return-order">Return Order</Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Breadcrumb */}
                {/* Your order */}
                <div className="order_section">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                <div className="cancel_order_heading">
                                    <div className="cancel_order_heading_left">
                                        <h2>Return Order</h2>
                                    </div>
                                    <div className="cancel_order_heading_left">
                                        {/* <div className="cancel_order_search">
                                            <input
                                                className="search-txt"
                                                type="text"
                                                name=""
                                                placeholder="Product name or item number"
                                            />
                                            <i className="fa-solid fa-magnifying-glass" />
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                <div className="order_section_inner mb-5">
                                    <div className="track_order_section_1">
                                        <div className="track_order_section_left">
                                            <Link to="/buyer/order-details">
                                                Order Number : <span>{id}</span>
                                            </Link>
                                        </div>
                                        {/* <div className="track_order_section_right">
                                            <Link to="/buyer/track-order">
                                                <i className="fa-solid fa-location-dot" /> Track Order
                                            </Link>
                                        </div> */}
                                    </div>
                                    <div className="row mt-4">
                                        <div className="col-xl-8 col-8 col-md-8 col-sm-12 col-12">
                                            <div className="shopping_cart_heading checkout_table table-responsive">
                                                <table>
                                                    <thead>
                                                        <tr>
                                                            <th colSpan={2}>Products</th>
                                                            <th className="text-center">Quantity</th>
                                                            <th className="text-center">Shipping Address</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td className="text-start">
                                                                <img
                                                                    src="/assets/images/shoping_cart_1.png"
                                                                    className="img-fluid"
                                                                />
                                                            </td>
                                                            <td className="text-start">
                                                                {BuyerOrderDetailListApiData?.name}
                                                            </td>
                                                            <td>
                                                                <div className="quantity_number order_details">
                                                                    {/* <button
                                                                        className="btn btn--plus"
                                                                        type="button"
                                                                        name="button"
                                                                    >
                                                                        {" "}
                                                                        <i className="fa-solid fa-plus" />
                                                                    </button> */}
                                                                    <input
                                                                        className="quantity"
                                                                        type="text"
                                                                        name="name"
                                                                        defaultValue={BuyerOrderDetailListApiData?.quantity}
                                                                    />
                                                                    {/* <button
                                                                        className="btn btn--plus"
                                                                        type="button"
                                                                        name="button"
                                                                    >
                                                                        <i className="fa-solid fa-trash" />
                                                                    </button> */}
                                                                </div>
                                                            </td>
                                                            <td>
                                                                {BuyerOrderDetailListApiData?.address}
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                                            <div className="shop-page-table checkout_page">
                                                <h4>Order Summary</h4>
                                                <div className="checkout_page_inner">
                                                    <table>
                                                        <tbody>
                                                            <tr>
                                                                <td>Sub-total</td>
                                                                <td className="text-end">
                                                                    <strong>AFN.{BuyerOrderDetailListApiData?.subtotal}</strong>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>Shipping</td>
                                                                <td className="text-end">
                                                                    <strong>Free</strong>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>Discount</td>
                                                                <td className="text-end">
                                                                    <strong>AFN.{BuyerOrderDetailListApiData?.discount}</strong>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>Tax</td>
                                                                <td className="text-end">
                                                                    <strong>AFN.{BuyerOrderDetailListApiData?.tax}</strong>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <th>Total</th>
                                                                <th className="text-end">
                                                                    AFN.{BuyerOrderDetailListApiData?.total_amount}
                                                                </th>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                    <div className="order_details_content text-end">
                                                        <h5 className='delivery_payment'>
                                                            cash on delivery{" "}
                                                            <img src="/assets/images/cash_icon.png" />
                                                        </h5>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <hr />
                                    <div className="row mt-4">
                                        <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12">
                                            <div className="return_order_form">
                                                <form onSubmit={formik.handleSubmit} method='post' encType='multipart/form-data'>
                                                    <div className="return_order_form_inner">
                                                        <span>Reason</span>
                                                        <div>
                                                            <select
                                                                id="product-type"
                                                                name="reason_id"
                                                                onChange={formik.handleChange}
                                                                onBlur={formik.handleBlur}
                                                                value={formik.values.reason_id}
                                                            >
                                                                <option value="">Select reason to return</option>
                                                                {ReasonListApiData?.map((data) => (
                                                                    <option key={data?.id} value={data?.id}>
                                                                        {data?.name}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                            {formik.touched.reason_id && formik.errors.reason_id ? (
                                                                <div className="text-danger">{formik.errors.reason_id}</div>

                                                            ) : null}
                                                        </div>
                                                    </div>

                                                    <div className="return_order_form_inner">
                                                        <span>Comment</span>
                                                        <div>
                                                            <textarea
                                                                name="message"
                                                                rows={4}
                                                                onChange={formik.handleChange}
                                                                onBlur={formik.handleBlur}
                                                                value={formik.values.message}
                                                            />
                                                            {formik.touched.message && formik.errors.message ? (
                                                                <div className="text-danger">{formik.errors.message}</div>
                                                            ) : null}
                                                        </div>
                                                    </div>
                                                    <div className="return_order_form_inner">
                                                        <span>Quantity</span>
                                                        <div>
                                                            <input
                                                                name="quantity"
                                                                type='number'
                                                                min={1}
                                                                onChange={formik.handleChange}
                                                                onBlur={formik.handleBlur}
                                                                value={formik.values.quantity}
                                                            />
                                                            {formik.touched.quantity && formik.errors.quantity ? (
                                                                <div className="text-danger">{formik.errors.quantity}</div>
                                                            ) : null}
                                                        </div>
                                                    </div>

                                                    <div className="return_order_form_inner">
                                                        <span>Product image</span>
                                                        <div>
                                                            <div className="custom-file mb-3">
                                                                <input
                                                                    type="file"
                                                                    className="custom-file-input"
                                                                    id="customFile"
                                                                    name='image'
                                                                    onChange={handleFileChange}
                                                                />
                                                                <label className="custom-file-label" htmlFor="customFile">
                                                                    <i className="fa-solid fa-cloud-arrow-up" />
                                                                    Click here to Upload
                                                                </label>

                                                            </div>
                                                            {formik.touched.image && formik.errors.image ? (
                                                                <div className="text-danger">{formik.errors.image}</div>
                                                            ) : null}
                                                            <div className="return_order_form_inner_btn text-center">
                                                                <button type="submit" disabled={formik.isSubmitting}>
                                                                    Submit
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>


                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Your order */}
            </>

        </div>

    )
}

export default BuyerReturnOrderContent