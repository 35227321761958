import React, { useState } from 'react'
import Slider from "react-slick";

const Testimonial = () => {
    const [currentSlide, setCurrentSlide] = useState(0);
    var settings = {
        dots: false,
        prevArrow: false,
        nextArrow: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        beforeChange: (oldIndex, newIndex) => setCurrentSlide(newIndex), 
    };
    return (
        <>
            <section className="padding-b-50 padding-t-50 seller_succes_bg">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-sm-6 col-12">
                            <div className="Seller_heading_sec">
                                <h3>Seller Success Stories</h3>
                            </div>
                        </div>
                        <div className="col-lg-6 col-sm-6 col-12">
                            <div className="main_v_slider">

                                <div className="slider-counter">
                                    {currentSlide + 1} / 5
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="slider-main_team">
                                <Slider {...settings}>
                                    <div className="item">
                                        <div className="main_Seller_new">
                                            <div className="row">
                                                <div className="col-lg-8">
                                                    <div className="main_detail_team">
                                                        <div className="book_image_icon">
                                                            <img src={`${process.env.PUBLIC_URL}/assets/images/seller_images/book_icon.png`} alt="book" />
                                                        </div>
                                                        <div className="head_slider_team">
                                                            <h2>
                                                                From five members to fifteen, a little trust can go
                                                                a long way
                                                            </h2>
                                                        </div>
                                                        <div className="img_slider_team">
                                                            <div className="img_section">
                                                                <img src={`${process.env.PUBLIC_URL}/assets/images/seller_images/Ellipse.png`} alt="" />
                                                            </div>
                                                            <div className="text_section">
                                                                <h3>Luciana James</h3>
                                                                <p>UI/UX Designer</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4">
                                                    <div className="team_images_sec">
                                                        <img src={`${process.env.PUBLIC_URL}/assets/images/seller_images/Group_team.png`} alt="Group" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="main_Seller_new">
                                            <div className="row">
                                                <div className="col-lg-8">
                                                    <div className="main_detail_team">
                                                        <div className="book_image_icon">
                                                            <img src={`${process.env.PUBLIC_URL}/assets/images/seller_images/book_icon.png`} alt="book" />
                                                        </div>
                                                        <div className="head_slider_team">
                                                            <h2>
                                                                From five members to fifteen, a little trust can go
                                                                a long way
                                                            </h2>
                                                        </div>
                                                        <div className="img_slider_team">
                                                            <div className="img_section">
                                                                <img src={`${process.env.PUBLIC_URL}/assets/images/seller_images/Ellipse.png`} alt="" />
                                                            </div>
                                                            <div className="text_section">
                                                                <h3>Luciana James</h3>
                                                                <p>UI/UX Designer</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4">
                                                    <div className="team_images_sec">
                                                        <img src={`${process.env.PUBLIC_URL}/assets/images/seller_images/Group_team.png`} alt="Group" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="main_Seller_new">
                                            <div className="row">
                                                <div className="col-lg-8">
                                                    <div className="main_detail_team">
                                                        <div className="book_image_icon">
                                                            <img src={`${process.env.PUBLIC_URL}/assets/images/seller_images/book_icon.png`} alt="book" />
                                                        </div>
                                                        <div className="head_slider_team">
                                                            <h2>
                                                                From five members to fifteen, a little trust can go
                                                                a long way
                                                            </h2>
                                                        </div>
                                                        <div className="img_slider_team">
                                                            <div className="img_section">
                                                                <img src={`${process.env.PUBLIC_URL}/assets/images/seller_images/Ellipse.png`} alt="" />
                                                            </div>
                                                            <div className="text_section">
                                                                <h3>Luciana James</h3>
                                                                <p>UI/UX Designer</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4">
                                                    <div className="team_images_sec">
                                                        <img src={`${process.env.PUBLIC_URL}/assets/images/seller_images/Group_team.png`} alt="Group" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="main_Seller_new">
                                            <div className="row">
                                                <div className="col-lg-8">
                                                    <div className="main_detail_team">
                                                        <div className="book_image_icon">
                                                            <img src={`${process.env.PUBLIC_URL}/assets/images/seller_images/book_icon.png`} alt="book" />
                                                        </div>
                                                        <div className="head_slider_team">
                                                            <h2>
                                                                From five members to fifteen, a little trust can go
                                                                a long way
                                                            </h2>
                                                        </div>
                                                        <div className="img_slider_team">
                                                            <div className="img_section">
                                                                <img src={`${process.env.PUBLIC_URL}/assets/images/seller_images/Ellipse.png`} alt="" />
                                                            </div>
                                                            <div className="text_section">
                                                                <h3>Luciana James</h3>
                                                                <p>UI/UX Designer</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4">
                                                    <div className="team_images_sec">
                                                        <img src={`${process.env.PUBLIC_URL}/assets/images/seller_images/Group_team.png`} alt="Group" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="main_Seller_new">
                                            <div className="row">
                                                <div className="col-lg-8">
                                                    <div className="main_detail_team">
                                                        <div className="book_image_icon">
                                                            <img src={`${process.env.PUBLIC_URL}/assets/images/seller_images/book_icon.png`} alt="book" />
                                                        </div>
                                                        <div className="head_slider_team">
                                                            <h2>
                                                                From five members to fifteen, a little trust can go
                                                                a long way
                                                            </h2>
                                                        </div>
                                                        <div className="img_slider_team">
                                                            <div className="img_section">
                                                                <img src={`${process.env.PUBLIC_URL}/assets/images/seller_images/Ellipse.png`} alt="" />
                                                            </div>
                                                            <div className="text_section">
                                                                <h3>Luciana James</h3>
                                                                <p>UI/UX Designer</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4">
                                                    <div className="team_images_sec">
                                                        <img src={`${process.env.PUBLIC_URL}/assets/images/seller_images/Group_team.png`} alt="Group" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Slider>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Testimonial