import React from 'react'
import './Scss/SellerCheckout.css'
import { Link } from 'react-router-dom'

const SellerCheckout = () => {
    return (
        <div className='main_login_section container-fluid'>        
        <div className="row seller-checkout">
            <div className="col-lg-12 bg_det_subscription">
                <div className="container">
                    <div className="main_sub_detail">
                        <div className="logo_login sub_section_main">
                            <img src="/assets/images/seller_images/login_logo.png" alt="login_logo" />
                        </div>
                        <div className="Plan_form_sub pb-5">
                            <h3>Checkout</h3>
                            <p>
                                Please select the subscription to use the Afghan Bazar seller
                                application
                            </p>
                        </div>
                        <div className="main_detail_Plan">
                            <div className="row">
                                <div className="col-lg-6 col-md-6">
                                    <div className="main_Checkout_Plan">
                                        <h3>Monthly Plan</h3>
                                        <p>Valid until 04 July 2024</p>
                                        <h5 className="main_map_sub">
                                            $ 11.99{" "}
                                            <span>
                                                $ 12.99 <img src="/assets/images/seller_images/drop.png" alt="Drop" />
                                            </span>
                                        </h5>
                                        <ul>
                                            <li>
                                                <span>
                                                    <img src="/assets/images/seller_images/check_icon.png" alt=" check" />
                                                </span>{" "}
                                                Lorem Ipsum is simply dummy Lorem Ip
                                            </li>
                                            <li>
                                                <span>
                                                    <img src="/assets/images/seller_images/check_icon.png" alt=" check" />
                                                </span>{" "}
                                                Lorem Ipsum is simply dummy Lorem Ip
                                            </li>
                                            <li>
                                                <span>
                                                    <img src="/assets/images/seller_images/check_icon.png" alt=" check" />
                                                </span>{" "}
                                                Lorem Ipsum is simply dummy Lorem Ip
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 bor_left_sec">
                                    <div className="main_Checkout_Plan main_de">
                                        <div className="detail_form_log detail_form_careate">
                                            <h3>
                                                Card Details{" "}
                                                <span>
                                                    <img src="/assets/images/seller_images/Vector.png" alt="detail" width={20} />
                                                </span>
                                            </h3>
                                            <div className="row">
                                                <div className="col-lg-6">
                                                    <div className="form-group log_in_main">
                                                        <label>First Name</label>
                                                        <input
                                                            type="text"
                                                            defaultValue=""
                                                            placeholder="First Name"
                                                            className="form-control"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="form-group log_in_main">
                                                        <label>Last Name</label>
                                                        <input
                                                            type="text"
                                                            defaultValue=""
                                                            placeholder="Last Name"
                                                            className="form-control"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <div className="form-group log_in_main">
                                                        <label>Card Number</label>
                                                        <input
                                                            type="text"
                                                            defaultValue=""
                                                            placeholder="Enter Card Number"
                                                            className="form-control"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-6">
                                                    <div className="form-group log_in_main">
                                                        <label>Exp. date</label>
                                                        <input
                                                            type="text"
                                                            defaultValue=""
                                                            placeholder="02/2023"
                                                            className="form-control"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="form-group log_in_main">
                                                        <label>CVC</label>
                                                        <input
                                                            type="text"
                                                            defaultValue=""
                                                            placeholder='01234'
                                                            className="form-control"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <div className="log_in_main_button">
                                                        <Link to="/seller/payment-success">Pay Now</Link>
                                                        {/* <button>Pay Now</button>              */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </div>

    )
}

export default SellerCheckout