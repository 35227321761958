import React, { useEffect, useState } from 'react'
import './scss/BuyerFooter.css'
import { Link, useNavigate } from 'react-router-dom'
import { useFormik } from "formik";
import { toast } from 'react-hot-toast';
import * as Yup from "yup";
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../Loader';
import ErrorLoader from '../../Error/ErrorLoader';
import { getWebsiteSettingData } from '../../Redux/action/MasterAction';
import HourglassLoaderColoured from '../../HourglassLoaderColoured';

const host = process.env.REACT_APP_API_SERVER
const BuyerFooter = () => {
    // const [showLoader, setShowLoader] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const authToken = localStorage.getItem('buyer_token')
    useEffect(() => {
        dispatch(getWebsiteSettingData());
    }, []);
    const {

        loadingWebsiteSettingDataListApi,
        WebsiteSettingDataListApiData,
        WebsiteSettingDataListApiError,


    } = useSelector(state => state?.MasterReducer);



    let [initialValues, setInitialValues] = useState({

        email: "",

    })

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: Yup.object().shape({

            email: Yup.string().required("Email is required.").matches(/^\w+[-\.\w]*@(?!(?:)\.com$)\w+[-\.\w]*?\.\w{2,4}$/, "Enter a valid E-mail"),

        }),
        onSubmit: async (values, { setSubmitting, resetForm }) => {

            try {
                console.log('value foign', values)
                setSubmitting(true);




                await axios.post(`${host}/buyer/newsletter`, values)
                    .then(function (response) {
                        if (response.status == 200) {
                            setSubmitting(false)

                            toast.success("Subscribed to newsletter of Afghan Bazaar");

                            resetForm()
                        } else {

                            setSubmitting(false)

                            toast.error(response.data.msg);

                            resetForm()
                        }
                    })
                    .catch(function (error) {


                        setSubmitting(false)
                        toast.error('Error submitting form. Please try again.');

                        resetForm()
                    })

            }
            catch (error) {


                setSubmitting(false)
                toast.error('Error submitting form. Please try again.');

                resetForm()
            }

        }
    })

    const handleFaqClick = () => {
        if (authToken) {
            navigate('/buyer/faq')
        }
        else {
            toast.error('Please sign in to continue');
            navigate('/buyer/sign-in')
        }
    }
    const handleYourOrderClick = () => {
        if (authToken) {
            navigate('/buyer/your-orders')
        }
        else {
            toast.error('Please sign in to continue');
            navigate('/buyer/sign-in')
        }
    }
    const handleWishlistClick = () => {
        if (authToken) {
            navigate('/buyer/wish-list')
        }
        else {
            toast.error('Please sign in to continue');
            navigate('/buyer/sign-in')
        }
    }
    const handleAccountClick = () => {
        if (authToken) {
            navigate('/buyer/your-account')
        }
        else {
            toast.error('Please sign in to continue');
            navigate('/buyer/sign-in')
        }
    }

    if (loadingWebsiteSettingDataListApi) {
        return <div className="overlay-form-submission">
            <Loader />
        </div>
    }

    if (WebsiteSettingDataListApiError) {
        return <ErrorLoader message={WebsiteSettingDataListApiError} />;
    }

    return (
        <section className="footer-sec pt-5">
            <div className="container">
                {/* <div className="container py-4">
                    <div className="row">
                        <div className="col-lg-9 col-md-12">
                            
                        </div>
                        <div className="col-lg-3 col-md-12 text-center">
                            
                        </div>
                    </div>
                </div> */}
                <div className="row footer-menu">
                    <div className="col-lg-3 col-md-6 col-sm-12 mb-4">
                        <div className='footer_logo'>
                            <div className="logo-sec">
                                <div>
                                    <img src="/assets/images/logo.png" alt="logo" />
                                    {/* <h5>Afghan Bazaar</h5> */}
                                </div>
                            </div>
                            <div className="search-sec">
                                <form method='post' onSubmit={formik.handleSubmit} >
                                    <h5>Stay In Touch</h5>
                                    <div className="mail-box-sec">
                                        <i className="fa-regular fa-envelope"></i>
                                        <input type="email" placeholder="Enter Your Email" name='email'
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.email} />
                                    </div>
                                    <div className="help-block with-errors">
                                        {formik.touched.email && formik.errors.email ? (
                                            <div className='text-danger'>{formik.errors.email}</div>
                                        ) : null}
                                    </div>
                                    <div className="subscribe-btn mt-3">
                                        {formik.isSubmitting ?
                                            <div className='text-center subs-btn-loader'>
                                                <HourglassLoaderColoured />
                                            </div>
                                            :
                                            <input type='submit' className='subs-btn-int' value="Subscribe" disabled={formik.isSubmitting} />


                                        }
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-9 col-md-12 col-sm-12 mb-4">
                        <div className='row footer-menu_1'>
                            <div className="col-lg-4 col-md-4 col-sm-6 mb-4">
                                <h6>Get To Know Us</h6>
                                <ul>
                                    <li><Link to='/buyer/about-us' className='text-decoration-none text-light'>About Us</Link></li>
                                    <li><Link to='/buyer/contact-us' className='text-decoration-none text-light'>Contact Us</Link></li>
                                    <li><Link to='/buyer/refund-policy' className='text-decoration-none text-light'>Refund Policy</Link></li>
                                    <li><a onClick={handleFaqClick} style={{ cursor: 'pointer' }} className='text-decoration-none text-light'>FAQ</a></li>
                                    <li><Link to='/buyer/terms-and-conditions' className='text-decoration-none text-light'>Terms &amp; Conditions</Link></li>
                                </ul>
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-6 mb-4">
                                <h6>Shopping</h6>
                                <ul>
                                    <li><Link to='/buyer/cart' className='text-decoration-none text-light'>Your Cart</Link></li>
                                    <li><a onClick={handleYourOrderClick} style={{ cursor: 'pointer' }} className='text-decoration-none text-light'>Your Orders</a></li>
                                    <li><a onClick={handleWishlistClick} style={{ cursor: 'pointer' }} className='text-decoration-none text-light'>Wishlist</a></li>
                                    <li><Link to='/buyer/shipping-policy' className='text-decoration-none text-light'>Shipping Policy</Link></li>
                                    <li><Link to='/buyer/privacy-policy' className='text-decoration-none text-light'>Privacy Policy</Link></li>
                                </ul>
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-6 mb-4">
                                <h6>Let Us Help You</h6>
                                <ul>
                                    <li><a onClick={handleAccountClick} style={{ cursor: 'pointer' }} className='text-decoration-none text-light'>Your Account</a></li>
                                    {/* <li><Link to='/buyer/return-policy' className='text-decoration-none text-light'>Return Centre</Link></li> */}
                                    <li><Link to='/buyer/purchase-protection' className='text-decoration-none text-light'>100% Purchase Protection</Link></li>
                                    <li><Link to='' className='text-decoration-none text-light'>Ecommerce App Download</Link></li>
                                    {/* <li><Link to='' className='text-decoration-none text-light'>Help</Link></li> */}

                                </ul>
                            </div>
                        </div>
                    </div>



                </div>
            </div>
            {/*  */}
            <hr />
            <div className='container'>
                <div className='row align-items-center pb-2'>
                    <div className='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12'>
                        <div className='copywright_text'>
                            <p>© 2024 Afghan Bazaar. All rights reserved</p>
                        </div>
                    </div>
                    <div className='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12'>
                        <div className="">
                            <div className="media-icons">
                                <a href=

                                    {WebsiteSettingDataListApiData?.facebook}
                                    target='_blank' title='Facebook'>
                                    <i className="fa-brands fa-facebook-f"></i>
                                </a>
                                <a href=
                                    {WebsiteSettingDataListApiData?.instagram}
                                    target='_blank' title='Instagram'>
                                    <i className="fa-brands fa-instagram"></i>
                                </a>
                                <a title='Whatsapp' href={`https://wa.me/${WebsiteSettingDataListApiData?.whatsapp ? WebsiteSettingDataListApiData?.whatsapp : ""}`}>
                                    <i className="fa-brands fa-whatsapp"></i>
                                </a>
                                <a href=
                                    {WebsiteSettingDataListApiData?.twitter}
                                    target='_blank' title='Twitter'>
                                    <i className="fa-brands fa-x-twitter"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    )
}

export default BuyerFooter