import React from 'react'
import { Link } from 'react-router-dom'


const BuyerPolicySidebar = () => {
    return (
        <div className="policy_content_inner">
            <h2>Legal Information</h2>
            <ul>
                <li>
                    <Link to="/buyer/about-us">
                        <img
                            src="/assets/images/about-us.png"
                            className="policy_img"
                        />{" "}
                        <img
                            src="/assets/images/about-us_1.png"
                            className="policy_img2"
                        />{" "}
                        About Us
                    </Link>
                </li>
                <li>
                    <Link to="/buyer/privacy-policy">
                        <img
                            src="/assets/images/policy_icon-1.png"
                            className="policy_img"
                        />{" "}
                        <img
                            src="/assets/images/policy_icon-1_1.png"
                            className="policy_img2"
                        />{" "}
                        Policy
                    </Link>
                </li>
                <li>
                    <Link to="/buyer/terms-and-conditions">
                        <img
                            src="/assets/images/policy_icon-2.png"
                            className="policy_img"
                        />
                        <img
                            src="/assets/images/policy_icon-2_1.png"
                            className="policy_img2"
                        />
                        Terms and Conditions
                    </Link>
                </li>
                <li>
                    <Link to="/buyer/shipping-policy">
                        <img
                            src="/assets/images/policy_icon-3.png"
                            className="policy_img"
                        />
                        <img
                            src="/assets/images/policy_icon-3_1.png"
                            className="policy_img2"
                        />
                        Shipping Policy
                    </Link>
                </li>
                <li>
                    <Link to="/buyer/refund-policy">
                        <img
                            src="/assets/images/policy_icon-4.png"
                            className="policy_img"
                        />
                        <img
                            src="/assets/images/policy_icon-4_1.png"
                            className="policy_img2"
                        />
                        Refund Policy
                    </Link>
                </li>
                <li>
                    <Link to="/buyer/purchase-protection">
                        <img
                            src="/assets/images/policy_icon-5.png"
                            className="policy_img"
                        />
                        <img
                            src="/assets/images/policy_icon-5_1.png"
                            className="policy_img2"
                        />
                        100% Purchase Protection
                    </Link>
                </li>
            </ul>
        </div>
    )
}

export default BuyerPolicySidebar