import React from 'react'
import { Link } from 'react-router-dom'

const PaymentSuccess = () => {
    return (
        <>
            <main className="main_login_section container-fluid">
                <div className="row">
                    <div className="col-lg-12 bg_det_subscription">
                        <div className="container">
                            <div className="main_sub_detail">
                                <div className="logo_login sub_section_main pb-0 pt-5">
                                    <img src={`${process.env.PUBLIC_URL}/assets/images/seller_images/login_logo.png`} alt="login_logo" />
                                </div>
                                <div className="main_detail_Plan_img">
                                    <img src={`${process.env.PUBLIC_URL}/assets/images/seller_images/Successfull.png`} alt="img" />
                                </div>
                                <div className="Plan_form_sub pb-5 pt-5">
                                    <p>
                                        Thanks a lot for using Afghan Bazar your bill was <br /> Completed
                                        successfully
                                    </p>
                                    <div className="text-center ">
                                        <Link
                                            to="/seller/dashboard"
                                            className="btn btn-primary okay_color_main"
                                        >
                                            Go to Dashborad
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>

        </>
    )
}

export default PaymentSuccess