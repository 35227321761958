import React from 'react'
import SellerHeader from '../../SellerEssentials/SellerHeader'
import SellerSidebar from '../../SellerEssentials/SellerSidebar'
import { Link } from 'react-router-dom'
import '../../Scss/Seller.css'
const SellerCancelledOrderDetails = () => {
    return (
        <div className='seller'>
            <div className="page-wrapper chiller-theme toggled">
                <SellerSidebar />
                {/* sidebar-wrapper  */}
                <main className="page-content container-fluid">
                    {/* ==================header================== */}
                    <SellerHeader />
                    {/* ==================header================== */}
                    <div className="row ">
                        <div className="col-md-12">
                            <div className="main-nav-Order">
                                <h2>
                                    {" "}
                                    <Link to="/seller/all_orders">
                                        <img src="/assets/images/seller_images/left_side.png" alt="left" />
                                    </Link>{" "}
                                    <span>Orders</span> /View Information
                                </h2>
                                <div className="card_order_main">
                                    <div className="row pb-3">
                                        <div className="col-lg-6">
                                            <div className="tab-content bg-light" id="nav-tabContent">
                                                <div
                                                    className="tab-pane fade active show"
                                                    id="nav-all-orders"
                                                    role="tabpanel"
                                                    aria-labelledby="nav-all-orders-tab"
                                                >
                                                    <div className="main_view_detail">
                                                        <img src="/assets/images/seller_images/Rectangle_1.png" alt="rect" />
                                                    </div>
                                                </div>
                                                <div
                                                    className="tab-pane fade"
                                                    id="nav-delivered"
                                                    role="tabpanel"
                                                    aria-labelledby="nav-delivered-tab"
                                                >
                                                    <div className="main_view_detail">
                                                        <img src="/assets/images/seller_images/Rectangle_2.png" alt="rect" />
                                                    </div>
                                                </div>
                                                <div
                                                    className="tab-pane fade"
                                                    id="nav-pending"
                                                    role="tabpanel"
                                                    aria-labelledby="nav-pending-tab"
                                                >
                                                    <div className="main_view_detail">
                                                        <img src="/assets/images/seller_images/Rectangle_3.png" alt="rect" />
                                                    </div>
                                                </div>
                                            </div>
                                            <nav>
                                                <div
                                                    className="nav nav-tabs mb-3 all_Information_main"
                                                    id="nav-tab"
                                                    role="tablist"
                                                >
                                                    <button
                                                        className="nav-link active"
                                                        id="nav-all-orders-tab"
                                                        data-bs-toggle="tab"
                                                        data-bs-target="#nav-all-orders"
                                                        type="button"
                                                        role="tab"
                                                        aria-controls="nav-all-orders"
                                                        aria-selected="true"
                                                    >
                                                        <img src="/assets/images/seller_images/Rectangle_1.png" alt="" />
                                                    </button>
                                                    <button
                                                        className="nav-link"
                                                        id="nav-delivered-tab"
                                                        data-bs-toggle="tab"
                                                        data-bs-target="#nav-delivered"
                                                        type="button"
                                                        role="tab"
                                                        aria-controls="nav-delivered"
                                                        aria-selected="false"
                                                    >
                                                        <img src="/assets/images/seller_images/Rectangle_2.png" alt="" />
                                                    </button>
                                                    <button
                                                        className="nav-link"
                                                        id="nav-pending-tab"
                                                        data-bs-toggle="tab"
                                                        data-bs-target="#nav-pending"
                                                        type="button"
                                                        role="tab"
                                                        aria-controls="nav-pending"
                                                        aria-selected="false"
                                                    >
                                                        <img src="/assets/images/seller_images/Rectangle_3.png" alt="" />
                                                    </button>
                                                </div>
                                            </nav>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="overall_sales height_auto">
                                                <div className="users-table">
                                                    <table className="posts-table">
                                                        <tbody className="td_section_text_order">
                                                            <tr>
                                                                <td width="50%">Order Number</td>
                                                                <td className="table-text-color" width="50%">
                                                                    Order Number
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>Order Date</td>
                                                                <td className="table-text-color">Order Date</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Quantity</td>
                                                                <td className="table-text-color">Quantity</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Vendor Name</td>
                                                                <td className="table-text-color">Vendor Name</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Order Status</td>
                                                                <td className="table-text-color">
                                                                    <span className="cancelled_color">Cancelled</span>{" "}
                                                                    <small>(Item was Damaged)</small>
                                                                </td>
                                                            </tr>
                                                            <tr style={{ verticalAlign: "baseline" }}>
                                                                <td>Reason of Cancelation</td>
                                                                <td className="table-text-color">
                                                                    Lorem ipsum dolor sit amet consectetur.
                                                                    Suspendisse risus eget tincidunt morbi.
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>

            {/* ==============script linking start=============== */}
            {/* ==============script linking end=============== */}
        </div>

    )
}

export default SellerCancelledOrderDetails