import React, { useEffect, useState } from 'react'
import './scss/BuyerWriteReviewContent.css'
import { Link, useNavigate } from 'react-router-dom'
import { useFormik } from 'formik';
import * as Yup from "yup";
import { Rating } from 'react-simple-star-rating';
import axios from 'axios';
import toast from 'react-hot-toast';
import Slider from "react-slick";
import { useDispatch, useSelector } from 'react-redux';
import { getCustomerReviewList } from '../../Redux/action/MasterAction';
import { createImageFromInitials } from '../../Utils';
let host = process.env.REACT_APP_API_SERVER;

const BuyerWriteReviewContent = ({ BuyerReviewPageProductDetailListApiData }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const features = BuyerReviewPageProductDetailListApiData?.features ? JSON.parse(BuyerReviewPageProductDetailListApiData?.features) : [];

    useEffect(() => {
        if (BuyerReviewPageProductDetailListApiData?.id != undefined) {

            dispatch(getCustomerReviewList(BuyerReviewPageProductDetailListApiData?.id));
        }
    }, [BuyerReviewPageProductDetailListApiData]);

    const {


        //CustomerReviewList
        loadingCustomerReviewListApi,
        CustomerReviewListApiData,
        CustomerReviewListApiError,

    } = useSelector(state => state?.MasterReducer);

    const totalStars = 5;
    const [srating, setsRating] = useState(0)
    const handleRating = (rate) => {

        setsRating(rate);
        // formik.setFieldValue('rating', rate);
    };

    let [initialValues, setInitialValues] = useState({
        headline: "",
        review: "",
        // rating: 0,
    })

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: Yup.object().shape({
            headline: Yup.string().required("Headline is required"),
            review: Yup.string().required("Review is required"),
            // rating: Yup.number()
            //     .min(1, 'Rating is required')
            //     .max(5, 'Rating must be at most 5')
            //     .required('Rating is required'),
        }),
        onSubmit: async (values, { setSubmitting, resetForm }) => {

            try {

                setSubmitting(true);

                const config = {
                    headers: {

                        'content-type': 'application/json',
                        "auth-token": localStorage.getItem("buyer_token")
                    }
                };

                const value = {
                    headline: values.headline,
                    review: values.review,
                    rating: srating,
                    varientId: BuyerReviewPageProductDetailListApiData?.variantId,
                    productId: BuyerReviewPageProductDetailListApiData?.id,
                }


                await axios.post(`${host}/buyer/buyer-customer-product-review`, value, config)
                    .then(function (response) {
                        if (response.status == 200) {
                            setSubmitting(false)

                            toast.success("Form Submitted Succesfully");
                            triggerModalOpen();
                            resetForm();

                            setsRating(0)
                        } else {

                            setSubmitting(false)
                            toast.error(response.data.message);
                        }
                    })
                    .catch(function (error) {
                        setSubmitting(false)
                        toast.error('Error submitting form. Please try again.');

                    })

            }
            catch (error) {


                setSubmitting(false)
                toast.error('Error submitting form. Please try again.');
                resetForm();

                setsRating(0)
            }

        }
    })

    const triggerModalOpen = () => {
        const openButton = document.getElementById('modal-open');
        if (openButton) {
            openButton.click();
        }
    };
    const triggerModalClose = () => {
        const closeButton = document.getElementById('close-modal');
        if (closeButton) {
            closeButton.click();
        }

    };

    const handleOkayClick = () => {
        triggerModalClose();
        navigate('/')
    }

    const handleClick = (slug, colourId, sizeId) => {

        if (colourId && sizeId) {

            navigate(`/buyer/product-details/${slug}?colorId=${colourId}&sizeId=${sizeId}`)
        }
        else if (colourId == null && sizeId) {

            navigate(`/buyer/product-details/${slug}?sizeId=${sizeId}`)
        }
        else if (colourId && sizeId == null) {

            navigate(`/buyer/product-details/${slug}?colorId=${colourId}`)
        }
        else {

            navigate(`/buyer/product-details/${slug}`)

        }
    }
    const renderStars = (rating) => {
        const stars = [];
        for (let i = 1; i <= 5; i++) {
            if (i <= rating) {
                stars.push(<i key={i} className="fa fa-star filled-star" />);
            } else {
                stars.push(<i key={i} className="fa fa-star empty-star" />);
            }
        }
        return stars;
    };


    const settings = {
        dots: false,
        arrows: false,
        infinite: CustomerReviewListApiData?.length > 1,
        autoplay: true,
        autoplaySpeed: 3000,
        slidesToShow: 3,
        slidesToScroll: 1,
        centerMode: false, // Change this to false to avoid centering slides
        centerPadding: '60px',
        initialSlide: 0, // Ensure the slider starts at the first slide
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false
                }
            },
            {
                breakpoint: 780,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false,
                    arrows: false
                }
            },
            {
                breakpoint: 640,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false,
                    centerMode: false, // Ensure centering is disabled on smaller screens
                    arrows: false
                }
            }
        ]
    };

    return (
        <div className='buyer-write-review'>
            <>
                {/* Write Review section */}
                <section>
                    <div className="review-heading-sec">
                        <h6>Create Review</h6>
                    </div>
                    <div className="container">
                        <div className="row py-3">
                            <div className="col-lg-6 col-md-6">
                                <div className="features_section">
                                    <div className="container-fluid">
                                        <div className="row">
                                            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                                <div className="features_section_table">
                                                    <h2>Features</h2>
                                                    <table>
                                                        <tbody>
                                                            {features?.map((feature, index) => (
                                                                <tr key={index}>
                                                                    <td>
                                                                        <strong>{feature?.title}</strong>
                                                                    </td>
                                                                    <td>{feature?.description}</td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6">
                                <div className="container star-phone-sec">
                                    <div className="row align-items-center">
                                        <div className="col-lg-5 col-md-5">
                                            <div className="iphone-star-sec">
                                                <p>{BuyerReviewPageProductDetailListApiData?.brand}</p>
                                                <h6>{BuyerReviewPageProductDetailListApiData?.name}</h6>
                                                <div className="star-sec pb-2">

                                                    {renderStars(BuyerReviewPageProductDetailListApiData?.avgRating)}

                                                    <span>{BuyerReviewPageProductDetailListApiData?.reviewCount} reviews</span>



                                                </div>
                                                <div className="d-flex">
                                                    <h4>AFN.{BuyerReviewPageProductDetailListApiData?.sellingPrice} </h4>
                                                    <span>
                                                        <del>AFN.{BuyerReviewPageProductDetailListApiData?.originalPrice}</del>
                                                    </span>
                                                </div>
                                                <h6 onClick={() => { handleClick(BuyerReviewPageProductDetailListApiData?.slug, BuyerReviewPageProductDetailListApiData?.colorId, BuyerReviewPageProductDetailListApiData?.sizeId) }} style={{ cursor: 'pointer' }}>View more →</h6>
                                            </div>
                                        </div>
                                        <div className="col-lg-7 col-md-7">
                                            <div className="phone-img">
                                                <img
                                                    src={BuyerReviewPageProductDetailListApiData?.image ? `${host}/${BuyerReviewPageProductDetailListApiData?.image}` : ''}
                                                    alt="iphone"
                                                    className="img-fluid"
                                                />
                                                <div className="offer_circle">{BuyerReviewPageProductDetailListApiData?.discountPercentage}%</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container-fluid">
                        <div className="create-review px-4">
                            <h3>Create Review</h3>
                            <p>Write a product review, user experience, and quality-related content</p>
                        </div>
                    </div>
                    <div className="container-fluid">
                        <div className="row py-4 px-3">

                            <Slider {...settings} className='review-slider slider'>
                                {CustomerReviewListApiData.length > 0 && CustomerReviewListApiData?.map((data) => (

                                    <div className="review-card p-4">
                                        <div className="icon-star d-flex justify-content-between">
                                            <div>
                                                <img
                                                    src={createImageFromInitials(500, `${data?.buyerDetails?.name}`)}
                                                    className="img-fluid"

                                                    alt='user'
                                                />
                                            </div>
                                            <div className="review_startr">
                                                <ul>
                                                    {Array.from({ length: Math.floor(data?.rating) }).map((_, index) => (
                                                        <i className="fa fa-star rated" />
                                                    ))}

                                                    {/* Render unfilled stars */}
                                                    {Array.from({ length: totalStars - Math.floor(data?.rating) }).map((_, index) => (
                                                        <i className="fa fa-star" />
                                                    ))}

                                                </ul>
                                            </div>
                                        </div>
                                        <div className="pt-3">
                                            <h5>{data?.buyerDetails?.name}</h5>
                                            <div className='review_para'>
                                                <p>
                                                    {data?.review}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                ))}

                            </Slider>
                        </div>

                    </div>
                    <hr />

                    <div className="container-fluid">
                        <form onSubmit={formik.handleSubmit} method='post'>
                            <div className="add-headline px-3 py-5">
                                <h3>Add a Headline</h3>
                                <input type="text" placeholder="What's most important to know?"
                                    name="headline"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.headline}
                                />
                                <div className="help-block with-errors">
                                    {formik.touched.headline && formik.errors.headline ? (
                                        <div className='text-danger'>{formik.errors.headline}</div>
                                    ) : null}
                                </div>
                            </div>
                            <div className="return-review px-3 py-4">
                                <h3>Add a written review</h3>
                                <textarea
                                    placeholder="What did you like or dislike? What did you use this product for?"
                                    defaultValue={""}
                                    name="review"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.review}
                                />
                                <div className="help-block with-errors">
                                    {formik.touched.review && formik.errors.review ? (
                                        <div className='text-danger'>{formik.errors.review}</div>
                                    ) : null}
                                </div>
                            </div>
                            <div className="rating-review p-3">
                                <h3>Give Rating</h3>
                                <div className="d-flex rating-img">
                                    <Rating

                                        onClick={handleRating}
                                    // value={formik.values.rating}
                                    />

                                </div>
                                {/* <div className="help-block with-errors">
                                    {formik.touched.rating && formik.errors.rating ? (
                                        <div className='text-danger'>{formik.errors.rating}</div>
                                    ) : null}
                                </div> */}
                            </div>
                            <div className="submit-btn px-3">

                                <button
                                    type='submit'

                                    disabled={formik.isSubmitting}
                                >
                                    Submit
                                </button>

                            </div>
                        </form>
                    </div>
                </section>
                {/* Modal */}
                <button
                    id='modal-open'
                    className="submit"
                    data-bs-toggle="modal"
                    data-bs-target="#exampleModal"
                    hidden={true}
                >
                    Submit
                </button>

                <div
                    className="modal fade"
                    id="exampleModal"
                    tabIndex={-1}
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="true"
                >

                    <div className="modal-dialog ">
                        <div className="modal-content">
                            <button type="button" hidden={true} id='close-modal' className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            <div className="modal-body">
                                <div className="order-place text-center">
                                    <div className="order-image">
                                        <img
                                            src="/assets/images/review-success.png"
                                            alt="order-place"
                                            className="img-fluid"
                                        />
                                    </div>
                                    <div className="thank-you pt-5">
                                        <h4>Review Submit Successful</h4>
                                        <h5>
                                            Thank you for submitting
                                            <br />
                                            the review on this
                                            <br />
                                            product{" "}
                                        </h5>
                                    </div>
                                    <div className="review_button pb-4">
                                        <a onClick={handleOkayClick} className='text-light' style={{ cursor: 'pointer' }}>Okay</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>



        </div>

    )
}

export default BuyerWriteReviewContent