import React from 'react';
import "./Scss/Homepage.css";

const HomeHowtoSell = () => {
    return (
        <>
            <section className="padding-b-50">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="main_heading_sec">
                                <h3>How to Sell on Afghan Bazaar ?</h3>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-3 col-md-4 col-sm-6 col-6">
                            <div className="Step_three-col-wrap">
                                <img src={`${process.env.PUBLIC_URL}/assets/images/seller_images/Step_1.png`} alt="Crores" />
                                <div className="book_area_Sell">
                                    <h5>Step 1</h5>
                                    <h6>Register your account</h6>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-4 col-sm-6 col-6">
                            <div className="Step_three-col-wrap">
                                <img src={`${process.env.PUBLIC_URL}/assets/images/seller_images/Step_2.png`} alt="Crores" />
                                <div className="book_area_Sell">
                                    <h5>Step 2</h5>
                                    <h6>Choose storege &amp; Shipping</h6>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-4 col-sm-6 col-6">
                            <div className="Step_three-col-wrap">
                                <img src={`${process.env.PUBLIC_URL}/assets/images/seller_images/Step_3.png`} alt="Crores" />
                                <div className="book_area_Sell">
                                    <h5>Step 3</h5>
                                    <h6>List your products</h6>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-4 col-sm-6 col-6">
                            <div className="Step_three-col-wrap">
                                <img src={`${process.env.PUBLIC_URL}/assets/images/seller_images/Step_4.png`} alt="Crores" />
                                <div className="book_area_Sell">
                                    <h5>Step 4</h5>
                                    <h6>Complete orders &amp; get paid</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default HomeHowtoSell