import React from 'react'

const Signup = () => {
    return (
        <div>
            <main className="main_login_section container-fluid">
                <div className="row">
                    <div className="col-lg-7 main_bg_detail">
                        <div className="main_login_detail">
                            <form action="#">
                                <div className="logo_login">
                                    <img src={`${process.env.PUBLIC_URL}/assets/images/seller_images/login_logo.png`} alt="login_logo" />
                                </div>
                                <div className="detail_form_log detail_form_careate">
                                    <h3>Create Account</h3>
                                    <p>Welcome to Afghan Bazar! please enter your details</p>
                                    <div className="row">
                                        <div className="col-lg-6 col-md-6 col-sm-6">
                                            <div className="form-group log_in_main">
                                                <label>First Name</label>
                                                <input
                                                    type="text"
                                                    defaultValue=""
                                                    placeholder="First Name"
                                                    className="form-control"
                                                />
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6 col-sm-6">
                                            <div className="form-group log_in_main">
                                                <label>Last Name</label>
                                                <input
                                                    type="text"
                                                    defaultValue=""
                                                    placeholder="Last Name"
                                                    className="form-control"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-12 col-md-12 col-sm-12">
                                            <div className="form-group log_in_main">
                                                <label>Email</label>
                                                <input
                                                    type="text"
                                                    defaultValue=""
                                                    placeholder="Enter Email"
                                                    className="form-control"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-6 col-md-6 col-sm-6">
                                            <div className="form-group log_in_main">
                                                <label>Phone Number</label>
                                                <input
                                                    type="text"
                                                    defaultValue=""
                                                    placeholder="+93 1234-567-890"
                                                    className="form-control"
                                                />
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6 col-sm-6">
                                            <div className="form-group log_in_main">
                                                <label>Date Of Birth</label>
                                                <input
                                                    type="text"
                                                    defaultValue=""
                                                    placeholder="DD/MM/YYYY"
                                                    className="form-control"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-12 col-md-12 col-sm-12">
                                            <div className="form-group log_in_main">
                                                <label>Enter Full Address</label>
                                                <input
                                                    type="text"
                                                    defaultValue=""
                                                    placeholder="Enter Full Address"
                                                    className="form-control"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-12 col-md-12 col-sm-12">
                                            <div className="form-group log_in_main">
                                                <label>Password</label>
                                                <input
                                                    type="text"
                                                    id="password-field-2"
                                                    placeholder="Enter Password"
                                                    className="form-control"
                                                />
                                                <a href="#" className="main_show_eyes">
                                                    <span
                                                        toggle="#password-field-2"
                                                        className="fas fa-fw fa-eye field-icon toggle-password"
                                                    />
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-12 col-md-12 col-sm-12">
                                            <div className="form-group log_in_main">
                                                <label>Confirm Password</label>
                                                <input
                                                    type="text"
                                                    id="password-field-1"
                                                    placeholder="Enter Confirm Password"
                                                    className="form-control"
                                                />
                                                <a href="#" className="main_show_eyes">
                                                    <span
                                                        toggle="#password-field-1"
                                                        className="fas fa-fw fa-eye field-icon toggle-password"
                                                    />
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-12 col-md-12 col-sm-12">
                                            <div className="log_in_main_button">
                                                {/* <button>Next</button>   */}
                                                <a href="shipping_pickup_address.html">Next</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="text_login_p">
                                    <p>
                                        By continuing you agree to E-Commerce{" "}
                                        <a href="">Conditions of Uses</a> and{" "}
                                        <a href=""> Privacy Notice.</a>{" "}
                                    </p>
                                </div>
                                <div className="main_login_newbazar">
                                    <p>
                                        <span>Already have an Account?</span>
                                    </p>
                                </div>
                                <div className="main_login_newbazar">
                                    <a href="/seller/sign-in">Sign In</a>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className="col-lg-5 main_login_img">
                        <img src={`${process.env.PUBLIC_URL}/assets/images/seller_images/Create_Account.png`} alt="login" />
                    </div>
                </div>
            </main>
        </div>
    )
}

export default Signup