import React, { useEffect, useState } from 'react';
import './scss/BuyerHeader.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import $ from 'jquery';
import Select, { components } from 'react-select';
import { getBuyerCartProductList, getBuyerMegaMenuDetails, getBuyerProfileDetails } from '../../Redux/action/BuyerAction';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../Loader';
import ErrorLoader from '../../Error/ErrorLoader';
import { getCategoryData, getProductOnCategory } from '../../Redux/action/MasterAction';
import toast from 'react-hot-toast';

const BuyerHeaderWithoutLogin = () => {
    const [showALL, setShowAll] = useState(false)
    useEffect(() => {
        const handleMenuToggle = (e) => {
            e.stopPropagation();
            $("header").toggleClass("active");
            $("body").toggleClass("noscroll");
        };

        const handleCloseSidebar = (e) => {
            if (!$(e.target).closest("button#menu-toggle, nav.slide-out-menu, .menu-link").length) {
                $("header").removeClass("active");
                $("div.menu-panel").removeClass("is-active");
                $("body").removeClass("noscroll");
            }
        };

        const handleSlideOutMenuClick = (e) => {
            e.stopPropagation();
        };

        const handleMenuLinkClick = function () {
            $("div.menu-panel").removeClass("is-active");
            const targetRef = $(this).data("ref");
            if (targetRef) {
                const $target = $(`div.menu-panel[data-menu="${targetRef}"]`);
                $target.addClass("is-active");
            }
        };

        const btn = document.querySelector('.toggle');
        if (btn) {
            btn.addEventListener('click', () => {
                document.querySelector('.toggle span').classList.toggle('toggle');
                document.getElementById('sidebar').classList.toggle('sidebarshow');
                $("body").toggleClass("noscroll");
            });
        }

        $("body").on("click tap", "button#menu-toggle", handleMenuToggle);
        $("body").on("click tap", handleCloseSidebar);
        $("body").on("click tap", "nav.slide-out-menu", handleSlideOutMenuClick);
        $("body").on("click tap", ".menu-link", handleMenuLinkClick);
        $("body").removeClass("noscroll");

        return () => {
            $("body").off("click tap", "button#menu-toggle", handleMenuToggle);
            $("body").off("click tap", handleCloseSidebar);
            $("body").off("click tap", "nav.slide-out-menu", handleSlideOutMenuClick);
            $("body").off("click tap", ".menu-link", handleMenuLinkClick);
        };
    }, []);




    const dispatch = useDispatch();
    const navigate = useNavigate();
    const authToken = localStorage.getItem("buyer_token")
    const guestToken = localStorage.getItem("guest_token")
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const guestUser = queryParams.get('guest');
    if (authToken) {

        navigate('/buyer/home')
    }
    useEffect(() => {
        dispatch(getBuyerMegaMenuDetails());
        dispatch(getCategoryData());
        if (authToken) {
            dispatch(getBuyerCartProductList('auth-token', authToken));
        }
        else if (guestToken) {
            dispatch(getBuyerCartProductList('guest-token', guestToken));
        }

    }, []);
    useEffect(() => {
        dispatch(getProductOnCategory(''))
    }, [])

    const handleCatSelect = (e) => {

        dispatch(getProductOnCategory(e.target.value))
    }
    const {
        loadingMegaMenuDataListApi,
        MegaMenuDataListApiData,
        MegaMenuDataListApiError,

        loadingBuyerCartProductListApi,
        BuyerCartProductListApiData,
        BuyerCartProductListApiError,

    } = useSelector(state => state?.BuyerReducer);
    const {
        loadingCategoryDataListApi,
        CategoryDataListApiData,
        CategoryDataListApiError,

        loadingProductOnCategoryListApi,
        ProductOnCategoryListApiData,
        ProductOnCategoryListApiError,

    } = useSelector(state => state?.MasterReducer);


    const productOptions = ProductOnCategoryListApiData?.map(data => ({
        value: data?.id,
        label: data?.name,
        slug: data?.slug,
        colourId: data?.colourId,
        sizeId: data?.sizeId
    }));


    const handleSelectChange = (field, value) => {


        if (value?.colourId && value?.sizeId) {

            navigate(`/buyer/product-details/${value?.slug}?colorId=${value?.colourId}&sizeId=${value?.sizeId}`)
        }
        else if (value?.colourId == null && value?.sizeId) {

            navigate(`/buyer/product-details/${value?.slug}?sizeId=${value?.sizeId}`)
        }
        else if (value?.colourId && value?.sizeId == null) {

            navigate(`/buyer/product-details/${value?.slug}?colorId=${value?.colourId}`)
        }
        else {

            navigate(`/buyer/product-details/${value?.slug}`)

        }
    };

    const [isExpanded, setIsExpanded] = useState(false);

    const handleExpand = () => {
        setIsExpanded(!isExpanded);
    };

    const handleSignInNavigate = () => {
        if (guestUser && guestUser == 'true') {
            navigate('/buyer/sign-in?guest=true')
        }
        else {
            navigate('/buyer/sign-in')
        }
    }

    if (loadingMegaMenuDataListApi) {
        return <div className="overlay-form-submission">
            <Loader />
        </div>
    }

    if (MegaMenuDataListApiError) {
        return <ErrorLoader message={MegaMenuDataListApiError} />;
    }
    if (loadingCategoryDataListApi) {
        return <div className="overlay-form-submission">
            <Loader />
        </div>
    }

    if (CategoryDataListApiError) {
        return <ErrorLoader message={CategoryDataListApiError} />;
    }


    return (
        <div className='buyer-header'>
            <>
                {/* Main Header */}
                <div className="main_header">
                    <div className="header">
                        <div className="container-fluid">
                            <div className="row align-items-center">
                                <div className="col-xl-1 col-lg-1 col-md-4 col-sm-4 col-4 logo_left order_4">
                                    <div className="logo-section">
                                        <div className="logo-section-left">
                                            <div className="header_in">
                                                <button
                                                    className="btn dropdown-toggle"

                                                    type="button"
                                                    id="menu-toggle" aria="menu"
                                                ></button>
                                            </div>
                                            <Link to="/buyer/home">
                                                <img src="/assets/images/logo.png" />
                                                {/* <br />
                                                Afghan Bazaar */}
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="order_4 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12">
                                    <div className="delivery-section-right">
                                        <div className="delivery-section-inner">
                                            <i className="fa-solid fa-location-dot" />
                                            <p>
                                                Deliver to  <span>Mazar-i-Sharif.</span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12 order_4">
                                    <div className="search_bar">
                                        <Select
                                            id="productOptions"
                                            name="productOptions"
                                            options={productOptions}


                                            onChange={option => handleSelectChange('productOptions', option)}
                                        // onBlur={formik.handleBlur}
                                        />
                                        <Link className="search-btn" to="">
                                            <i className="fa-solid fa-magnifying-glass" />
                                        </Link>
                                        <div className="dropdown top_select">
                                            {/* <button
                                                className="btn  dropdown-toggle"
                                                type="button"
                                                id="dropdownMenuButton1"
                                                data-bs-toggle="dropdown"
                                                aria-expanded="false"
                                            >
                                                All Category
                                            </button> */}
                                            <select onChange={handleCatSelect}>
                                                <option>All</option>
                                                {CategoryDataListApiData?.map((data) => (

                                                    <option value={data?.id}>{data?.name}</option>
                                                ))}
                                            </select>
                                            {/* <ul
                                                className="dropdown-menu"
                                                aria-labelledby="dropdownMenuButton1"
                                            >
                                                {CategoryDataListApiData?.map((data) => (

                                                    <li>{data?.name}</li>
                                                ))}

                                            </ul> */}
                                        </div>
                                    </div>
                                </div>
                                <div className="order_4 col-xl-4 col-lg-4 col-md-8 col-sm-8 col-8">
                                    <div className="header-right-side">
                                        <div className="account_section">
                                            <a onClick={handleSignInNavigate} style={{ cursor: 'pointer' }}>
                                                <p>
                                                    <img src="/assets/images/user_icon.png" /> Hello, SignIn
                                                </p>
                                            </a>
                                        </div>
                                        <div className="return_section">
                                            <a onClick={handleSignInNavigate} style={{ cursor: 'pointer' }}>
                                                <p>
                                                    <img src="/assets/images/refund_icon.png" />
                                                    Returns &amp; Orders{" "}
                                                </p>
                                            </a>
                                        </div>
                                        <div className="cart_section">
                                            <Link to="/buyer/cart">
                                                <p>
                                                    <img src="/assets/images/cart-img.png" /> Cart{" "}
                                                    <span className="cart_number">{BuyerCartProductListApiData?.data?.length ? BuyerCartProductListApiData?.data?.length : 0}</span>
                                                </p>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <header  className="secondary_header">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-xl-8 col-lg-8 col-md-8 col-sm-8 col-12 nav-cus">
                                    <div className="main_navigation sidebar" id="sidebar" >
                                        <ul>
                                            <li className="me-2">
                                                <header>
                                                    <nav className="navbar">
                                                        <button
                                                            className="btn dropdown-toggle"

                                                            type="button"
                                                            id="menu-toggle" aria="menu"
                                                        >
                                                            <i className="fa-solid fa-bars" /> All
                                                        </button>
                                                        <nav className="slide-out-menu">
                                                            <div className="menu-panels">
                                                                <div className="primary-menu-panel">
                                                                    <p>Shop By Category</p>
                                                                    <ul>
                                                                        {CategoryDataListApiData?.slice(0, isExpanded ? CategoryDataListApiData.length : 5)?.map((data) => (
                                                                            <li>
                                                                                <button
                                                                                    type="button"
                                                                                    className="menu-link"
                                                                                    data-ref={data?.slug}
                                                                                >
                                                                                    <strong> {data?.name}</strong>
                                                                                    <svg
                                                                                        className="arrow-right d-sm-ib"
                                                                                        fill="#111"
                                                                                        height="30px"
                                                                                        width="30px"
                                                                                        viewBox="0 0 185.4 300"
                                                                                    >
                                                                                        <path d="M7.3 292.7c-9.8-9.8-9.8-25.6 0-35.4L114.6 150 7.3 42.7c-9.8-9.8-9.8-25.6 0-35.4s25.6-9.8 35.4 0L185.4 150 42.7 292.7c-4.9 4.8-11.3 7.3-17.7 7.3-6.4 0-12.7-2.5-17.7-7.3z" />
                                                                                    </svg>
                                                                                </button>
                                                                            </li>
                                                                        ))}
                                                                        {
                                                                            CategoryDataListApiData.length > 5 && <li>
                                                                                <a className="dropdown-item" style={{ cursor: 'pointer' }} onClick={handleExpand}>
                                                                                    <i className="fa-solid fa-boxes-stacked" />{" "}
                                                                                    {isExpanded ? 'See less' : 'See all'}
                                                                                </a>
                                                                            </li>}
                                                                        <p>Trending</p>
                                                                        <li>
                                                                            <Link className="dropdown-item" to="/buyer/home-last-product-listing">
                                                                                <i className="fa-solid fa-tags" />
                                                                                Best Seller
                                                                            </Link>
                                                                        </li>
                                                                        <li>
                                                                            <Link to='/buyer/new-release-product-listing' className="dropdown-item" >
                                                                                <i className="fa-solid fa-circle-check" />
                                                                                New Relaease
                                                                            </Link>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                                {/* Electronic */}
                                                                {
                                                                    MegaMenuDataListApiData?.map((catData) => (
                                                                        <div key={catData.slug} className="menu-panel" data-menu={catData.slug}>
                                                                            <button type="button" className="menu-link menu-header">
                                                                                <svg
                                                                                    className="arrow-left"
                                                                                    fill="#111"
                                                                                    height="30px"
                                                                                    width="30px"
                                                                                    viewBox="0 0 185.4 300"
                                                                                >
                                                                                    <path d="M160.4 300c-6.4 0-12.7-2.5-17.7-7.3L0 150 142.7 7.3c9.8-9.8 25.6-9.8 35.4 0 9.8 9.8 9.8 25.6 0 35.4L70.7 150 178 257.3c9.8 9.8 9.8 25.6 0 35.4-4.9 4.8-11.3 7.3-17.6 7.3z" />
                                                                                </svg>
                                                                                {catData?.name}
                                                                            </button>
                                                                            {/* Subcategories */}
                                                                            {catData?.SubCategory?.map((subcatData) => (
                                                                                <ul key={subcatData.slug}>
                                                                                    <li>
                                                                                        <button type="button" className="menu-link" data-ref={subcatData?.slug}>
                                                                                            {subcatData?.name}
                                                                                            <svg
                                                                                                className="arrow-right d-sm-ib"
                                                                                                fill="#111"
                                                                                                height="30px"
                                                                                                width="30px"
                                                                                                viewBox="0 0 185.4 300"
                                                                                            >
                                                                                                <path d="M7.3 292.7c-9.8-9.8-9.8-25.6 0-35.4L114.6 150 7.3 42.7c-9.8-9.8-9.8-25.6 0-35.4s25.6-9.8 35.4 0L185.4 150 42.7 292.7c-4.9 4.8-11.3 7.3-17.7 7.3-6.4 0-12.7-2.5-17.7-7.3z" />
                                                                                            </svg>
                                                                                        </button>
                                                                                    </li>
                                                                                </ul>
                                                                            ))}
                                                                        </div>
                                                                    ))
                                                                }
                                                                {/* Child Categories */}
                                                                {
                                                                    MegaMenuDataListApiData?.flatMap((catData) =>
                                                                        catData?.SubCategory?.map((subcatData) => (
                                                                            <div key={subcatData.slug} className="menu-panel" data-menu={subcatData?.slug}>
                                                                                <button type="button" className="menu-link menu-header">
                                                                                    <svg
                                                                                        className="arrow-left"
                                                                                        fill="#111"
                                                                                        height="30px"
                                                                                        width="30px"
                                                                                        viewBox="0 0 185.4 300"
                                                                                    >
                                                                                        <path d="M160.4 300c-6.4 0-12.7-2.5-17.7-7.3L0 150 142.7 7.3c9.8-9.8 25.6-9.8 35.4 0 9.8 9.8 9.8 25.6 0 35.4L70.7 150 178 257.3c9.8 9.8 9.8 25.6 0 35.4-4.9 4.8-11.3 7.3-17.6 7.3z" />
                                                                                    </svg>
                                                                                    {subcatData?.name}
                                                                                </button>
                                                                                <ul>
                                                                                    {subcatData?.ChildCategory?.map((childcatData) => (
                                                                                        <li key={childcatData.slug}>
                                                                                            <Link to={`/buyer/product-listing?childslug=${childcatData.slug}`}>{childcatData?.name}</Link>
                                                                                        </li>
                                                                                    ))}
                                                                                </ul>
                                                                            </div>
                                                                        ))
                                                                    )
                                                                }

                                                                {/* Electronic */}

                                                            </div >
                                                        </nav >
                                                    </nav >
                                                </header >
                                            </li >
                                            {MegaMenuDataListApiData?.map((catData) => (
                                                <li className="menu-item-has-children">
                                                    <Link to="">
                                                        {catData?.name} <i className="fa fa-angle-down" />
                                                    </Link>

                                                    <div className="sub-menu mega-menu mega-menu-column-4">
                                                        {catData?.SubCategory?.map((subcatData) => (
                                                            <div className="list-item">
                                                                <Link className='header_category' to={`/buyer/product-listing?subcatslug=${subcatData.slug}`}>  <h4 className="title">{subcatData?.name}</h4></Link>
                                                                <ul>
                                                                    {subcatData?.ChildCategory?.map((childcatData) => (
                                                                        <li>
                                                                            <Link to={`/buyer/product-listing?childslug=${childcatData.slug}`}>{childcatData?.name}</Link>
                                                                        </li>
                                                                    ))}

                                                                </ul>

                                                            </div>
                                                        ))}
                                                    </div>

                                                </li>
                                            ))}

                                        </ul >
                                    </div >
                                    <header className="mobile_view">
                                        <nav className="navbar">
                                            <nav className="slide-out-menu">
                                                <div className="menu-panels">
                                                    <div className="primary-menu-panel">
                                                        <p>Shop By Category</p>
                                                        <ul>
                                                            {CategoryDataListApiData?.map((data) => (
                                                                <li>
                                                                    <button
                                                                        type="button"
                                                                        className="menu-link"
                                                                        data-ref={data?.slug}
                                                                    >
                                                                        <strong> {data?.name}</strong>
                                                                        <svg
                                                                            className="arrow-right d-sm-ib"
                                                                            fill="#111"
                                                                            height="30px"
                                                                            width="30px"
                                                                            viewBox="0 0 185.4 300"
                                                                        >
                                                                            <path d="M7.3 292.7c-9.8-9.8-9.8-25.6 0-35.4L114.6 150 7.3 42.7c-9.8-9.8-9.8-25.6 0-35.4s25.6-9.8 35.4 0L185.4 150 42.7 292.7c-4.9 4.8-11.3 7.3-17.7 7.3-6.4 0-12.7-2.5-17.7-7.3z" />
                                                                        </svg>
                                                                    </button>
                                                                </li>
                                                            ))}

                                                            {
                                                                CategoryDataListApiData.length > 5 &&
                                                                <li>
                                                                    <a className="dropdown-item" style={{ cursor: 'pointer' }} onClick={handleExpand}>
                                                                        <i className="fa-solid fa-boxes-stacked" />{" "}
                                                                        {isExpanded ? 'See less' : 'See all'}
                                                                    </a>
                                                                </li>}
                                                            <p>Trending</p>
                                                            <li>
                                                                <Link className="dropdown-item" to="/buyer/home-last-product-listing">
                                                                    <i className="fa-solid fa-tags" />
                                                                    Best Seller
                                                                </Link>
                                                            </li>
                                                            <li>
                                                                <Link className="dropdown-item" to='/buyer/new-release-product-listing'>
                                                                    <i className="fa-solid fa-circle-check" />
                                                                    New Relaease
                                                                </Link>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    {/* Electronic */}
                                                    {MegaMenuDataListApiData?.map((catData) => (
                                                        <div key={catData.slug} className="menu-panel" data-menu={catData.slug}>
                                                            <button type="button" className="menu-link menu-header">
                                                                <svg
                                                                    className="arrow-left"
                                                                    fill="#111"
                                                                    height="30px"
                                                                    width="30px"
                                                                    viewBox="0 0 185.4 300"
                                                                >
                                                                    <path d="M160.4 300c-6.4 0-12.7-2.5-17.7-7.3L0 150 142.7 7.3c9.8-9.8 25.6-9.8 35.4 0 9.8 9.8 9.8 25.6 0 35.4L70.7 150 178 257.3c9.8 9.8 9.8 25.6 0 35.4-4.9 4.8-11.3 7.3-17.6 7.3z" />
                                                                </svg>
                                                                {catData?.name}
                                                            </button>
                                                            {/* Subcategories */}
                                                            {catData?.SubCategory?.map((subcatData) => (
                                                                <ul key={subcatData.slug}>
                                                                    <li>
                                                                        <button type="button" className="menu-link" data-ref={subcatData?.slug}>
                                                                            {subcatData?.name}
                                                                            <svg
                                                                                className="arrow-right d-sm-ib"
                                                                                fill="#111"
                                                                                height="30px"
                                                                                width="30px"
                                                                                viewBox="0 0 185.4 300"
                                                                            >
                                                                                <path d="M7.3 292.7c-9.8-9.8-9.8-25.6 0-35.4L114.6 150 7.3 42.7c-9.8-9.8-9.8-25.6 0-35.4s25.6-9.8 35.4 0L185.4 150 42.7 292.7c-4.9 4.8-11.3 7.3-17.7 7.3-6.4 0-12.7-2.5-17.7-7.3z" />
                                                                            </svg>
                                                                        </button>
                                                                    </li>
                                                                </ul>
                                                            ))}
                                                        </div>
                                                    ))}
                                                    {/* Child Categories */}
                                                    {MegaMenuDataListApiData?.flatMap((catData) =>
                                                        catData?.SubCategory?.map((subcatData) => (
                                                            <div key={subcatData.slug} className="menu-panel" data-menu={subcatData?.slug}>
                                                                <button type="button" className="menu-link menu-header">
                                                                    <svg
                                                                        className="arrow-left"
                                                                        fill="#111"
                                                                        height="30px"
                                                                        width="30px"
                                                                        viewBox="0 0 185.4 300"
                                                                    >
                                                                        <path d="M160.4 300c-6.4 0-12.7-2.5-17.7-7.3L0 150 142.7 7.3c9.8-9.8 25.6-9.8 35.4 0 9.8 9.8 9.8 25.6 0 35.4L70.7 150 178 257.3c9.8 9.8 9.8 25.6 0 35.4-4.9 4.8-11.3 7.3-17.6 7.3z" />
                                                                    </svg>
                                                                    {subcatData?.name}
                                                                </button>
                                                                <ul>
                                                                    {subcatData?.ChildCategory?.map((childcatData) => (
                                                                        <li key={childcatData.slug}>
                                                                            <Link to="">{childcatData?.name}</Link>
                                                                        </li>
                                                                    ))}
                                                                </ul>
                                                            </div>
                                                        ))
                                                    )}

                                                    {/* Electronic */}


                                                </div>
                                            </nav>
                                        </nav>
                                    </header>
                                </div >
                                <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                                    <div className="secondary_header_right">
                                        <div className="seller_btn">
                                            <Link to="/seller/home">Become a Seller</Link>
                                        </div>
                                        <div className="wish_list">
                                            <Link to="/buyer/wish-list">
                                                WishList <i className="fa-solid fa-heart" />
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div >
                        </div >
                    </header  >
                </div>
                {/* Main Header */}
            </>


        </div>
    )
}

export default BuyerHeaderWithoutLogin