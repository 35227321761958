import React, { useEffect } from 'react'
import BuyerProfileContent from './BuyerProfileContent'
import BuyerHeader from '../../BuyerHeader/BuyerHeader'
import BuyerFooter from '../../BuyerFooter/BuyerFooter'
import { getBuyerProfileDetails } from '../../../Redux/action/BuyerAction'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import toast from 'react-hot-toast'
import Loader from '../../../Loader'
import ErrorLoader from '../../../Error/ErrorLoader'

const BuyerProfile = () => {

    const authToken = localStorage.getItem('buyer_token');
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {

        if (authToken) {
            dispatch(getBuyerProfileDetails(authToken))
        }
        else {
            // toast.error('please sign in to continue')
            navigate('/buyer/sign-in')
        }

    }, [authToken]);
    const {

        loadingBuyerProfileDataListApi,
        BuyerProfileDataListApiData,
        BuyerProfileDataListApiError,

    } = useSelector(state => state?.BuyerReducer);

    // if (loadingBuyerProfileDataListApi) {
    //     return <div className="overlay-form-submission">
    //         <Loader />
    //     </div>
    // }

    // if (BuyerProfileDataListApiError) {
    //     return <ErrorLoader message={BuyerProfileDataListApiError} />;
    // }

    return (
        <>
            <BuyerHeader />
            {BuyerProfileDataListApiData?.message == 'Your account is inactivated.' ?
                <div className=" container-fluid my-5 py-5 main-wish-list">

                    <div className="d-flex justify-content-center pt-4 py-5 heading-sec px-4">
                        <img src="/assets/images/inactive-account.png" width={200} alt="" srcSet="" />

                    </div>

                    <h1 className='text-center text-danger'>Your account is inacitvated</h1>
                </div>

                :
                <BuyerProfileContent BuyerProfileDataListApiData={BuyerProfileDataListApiData?.data} />}
            <BuyerFooter />
        </>
    )
}

export default BuyerProfile