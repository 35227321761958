import React from 'react'
import { Hourglass } from 'react-loader-spinner';
const HourglassLoader = () => {
    return (
        <>
            <Hourglass
                visible={true}
                height="20"
                width="50"
                ariaLabel="hourglass-loading"
                wrapperStyle={{}}
                wrapperClass=""
                colors={['#FFFFFF', '#FFFFFF']}
            />
        </>
    )
}

export default HourglassLoader
