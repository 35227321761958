import React from 'react'
import '../Scss/Seller.css'
import SellerHeader from '../SellerEssentials/SellerHeader'
import SellerSidebar from '../SellerEssentials/SellerSidebar'
import { Link } from 'react-router-dom'
import SellerDeliveredOrders from './SellerDeliveredOrders/SellerDeliveredOrders'
import SellerPendingOrders from './SellerPendingOrders/SellerPendingOrders'
import SellerAllOrdersContent from './SellerAllOrdersContent'
import SellerNewOrders from './SellerNewOrders/SellerNewOrders'
import SellerCancelledOrders from './SellerCancelledOrders/SellerCancelledOrders'
const SellerAllOrders = () => {
    return (
        <div className='seller'>
            <div className="page-wrapper chiller-theme toggled">
                <SellerSidebar />
                {/* sidebar-wrapper  */}
                <main className="page-content container-fluid">
                    {/* ==================header================== */}
                    <SellerHeader />
                    {/* ==================header================== */}
                    <div className="row">
                        <div className="col-md-12">
                            <div className="main-nav-Order">
                                <h2>Orders</h2>
                                <div className="card_order_main">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <nav>
                                                <div
                                                    className="nav nav-tabs mb-3 all_order_main"
                                                    id="nav-tab"
                                                    role="tablist"
                                                >
                                                    <button
                                                        className="nav-link active"
                                                        id="nav-all-orders-tab"
                                                        data-bs-toggle="tab"
                                                        data-bs-target="#nav-all-orders"
                                                        type="button"
                                                        role="tab"
                                                        aria-controls="nav-all-orders"
                                                        aria-selected="true"
                                                    >
                                                        All Orders (50)
                                                    </button>
                                                    <button
                                                        className="nav-link"
                                                        id="nav-New-Order"
                                                        data-bs-toggle="tab"
                                                        data-bs-target="#nav-New-Orders"
                                                        type="button"
                                                        role="tab"
                                                        aria-controls="nav-New-Order"
                                                        aria-selected="false"
                                                    >
                                                        New Orders (10)
                                                    </button>
                                                    <button
                                                        className="nav-link"
                                                        id="nav-delivered-tab"
                                                        data-bs-toggle="tab"
                                                        data-bs-target="#nav-delivered"
                                                        type="button"
                                                        role="tab"
                                                        aria-controls="nav-delivered"
                                                        aria-selected="false"
                                                    >
                                                        Delivered (10)
                                                    </button>
                                                    <button
                                                        className="nav-link"
                                                        id="nav-pending-tab"
                                                        data-bs-toggle="tab"
                                                        data-bs-target="#nav-pending"
                                                        type="button"
                                                        role="tab"
                                                        aria-controls="nav-pending"
                                                        aria-selected="false"
                                                    >
                                                        Pending (10)
                                                    </button>
                                                    <button
                                                        className="nav-link"
                                                        id="nav-canceled-tab"
                                                        data-bs-toggle="tab"
                                                        data-bs-target="#nav-canceled"
                                                        type="button"
                                                        role="tab"
                                                        aria-controls="nav-canceled"
                                                        aria-selected="false"
                                                    >
                                                        Cancelled (10)
                                                    </button>
                                                </div>
                                            </nav>
                                        </div>
                                        {/* <div className="col-lg-3">
                  <div className="filter_button_section">
                    <Link to="#"><span><img src="/assets/images/seller_images/Filter.png" alt="filter"></span>Filter</a>
                  </div>
                </div> */}
                                    </div>
                                    <div className="tab-content" id="nav-tabContent">
                                        <div
                                            className="tab-pane fade active show"
                                            id="nav-all-orders"
                                            role="tabpanel"
                                            aria-labelledby="nav-all-orders-tab"
                                        >
                                            <SellerAllOrdersContent />
                                        </div>
                                        <div
                                            className="tab-pane fade"
                                            id="nav-New-Orders"
                                            role="tabpanel"
                                            aria-labelledby="nav-Order-tab"
                                        >
                                            <SellerNewOrders />
                                        </div>
                                        <div
                                            className="tab-pane fade"
                                            id="nav-delivered"
                                            role="tabpanel"
                                            aria-labelledby="nav-delivered-tab"
                                        >
                                            <SellerDeliveredOrders />
                                        </div>
                                        <div
                                            className="tab-pane fade"
                                            id="nav-pending"
                                            role="tabpanel"
                                            aria-labelledby="nav-pending-tab"
                                        >
                                            <SellerPendingOrders />
                                        </div>
                                        <div
                                            className="tab-pane fade"
                                            id="nav-canceled"
                                            role="tabpanel"
                                            aria-labelledby="nav-canceled-tab"
                                        >
                                            <SellerCancelledOrders />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>

            {/* ==============script linking start=============== */}
        </div>

    )
}

export default SellerAllOrders