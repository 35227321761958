import React from 'react'
import { useNavigate } from 'react-router-dom';

const SellerHeader = () => {
    const navigate = useNavigate();
    const triggerModalClose = () => {
        const closeButton = document.getElementById('close-modal');
        if (closeButton) {
            closeButton.click();
        }

    };
    const handleLogout = () => {
        localStorage.removeItem('seller_token');
        triggerModalClose();
        navigate('/seller/sign-in')

    }

    return (
        <div className="page-content-main row">
            <nav className="main-nav--bg">
                <div className="container-floud main-nav">
                    <div className="main-nav-start">
                        <div className="row">
                            <div className="col-lg-4 col-md-5 col-sm-5 col-5 col_xs_logo">
                                <h2 className="head-title-main">4 Jul 2024</h2>
                                <div className="xs_logo_show_detail">
                                    <img src="/assets/images/seller_images/login_logo.png" alt="loho" />
                                </div>
                            </div>
                            <div className="col-lg-8 col-md-7 col-sm-7 col-7">
                                <div className="header_detail_nav">
                                    <ul>
                                        <li className="pro_nav_icon">
                                            <a className="posi_section">
                                                <img src="/assets/images/seller_images/email.png" alt="bell" />
                                                <span className="pro_nav_icon-span email_color">
                                                    1
                                                </span>
                                            </a>
                                        </li>
                                        <li className="pro_nav_icon">
                                            <a className="posi_section">
                                                <img src="/assets/images/seller_images/bell.png" alt="bell" />
                                                <span className="pro_nav_icon-span bell_color">
                                                    6
                                                </span>
                                            </a>
                                        </li>
                                        <li className="profile_detail">
                                            <div className="dropdown dropdown-filter-new">
                                                <a
                                                    className="dropdown-toggle"
                                                    href="#"
                                                    role="button"
                                                    id="dropdownMenuLink"
                                                    data-bs-toggle="dropdown"
                                                    aria-expanded="false"
                                                >
                                                    <span>
                                                        <img src="/assets/images/seller_images/profile.png" alt="Profile" />
                                                    </span>
                                                    ASIN
                                                </a>
                                                <div
                                                    className="dropdown-menu main_drop_new_week"
                                                    aria-labelledby="dropdownMenuLink"
                                                    style={{}}
                                                >
                                                    <ul className="users-item-dropdown nav-user-dropdown">
                                                        {/* <li>
              <a href="contact_us.html" className="main_icon_hover">
                <i>
                  <img src="/assets/images/seller_images/support-outline.png" alt="">
                </i>
                <span>Contact Us</span>
              </a>
            </li> */}
                                                        <li>
                                                            <a
                                                                href="profile.html"
                                                                className="main_icon_hover"
                                                            >
                                                                <i>
                                                                    <img
                                                                        src="/assets/images/seller_images/side_icon_4.png"
                                                                        alt="Dashboard"
                                                                    />
                                                                </i>
                                                                <span>Profile</span>
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a
                                                                type="button"
                                                                data-bs-toggle="modal"
                                                                data-bs-target="#logout_main"
                                                                className="main_icon_hover"
                                                            >
                                                                <i>
                                                                    <img
                                                                        src="/assets/images/seller_images/material-logout.png"
                                                                        alt="Dashboard"
                                                                    />
                                                                </i>
                                                                <span>Logout</span>
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </nav>

            <div
                className="modal fade"
                id="logout_main"
                data-bs-backdrop="static"
                data-bs-keyboard="false"
                tabIndex={-1}
                aria-labelledby="staticBackdropLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog classmain_log_modul pt-100">
                    <div className="modal-content mt-100">
                        <div className="modal-body">
                            <div className="request_send_for_modul padd_none classmain_log">
                                <h6>Log out?</h6>
                                <p>Are you sure you want to log out?</p>
                                <div className="main_acsept_butt">
                                    <button
                                        type="button"
                                        className="btn btn-secondary me-2"
                                        data-bs-dismiss="modal"
                                        id='close-modal'
                                    >
                                        Cancel
                                    </button>
                                    <button onClick={handleLogout} type="button" className="classmain_log_button btn">
                                        Log out
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default SellerHeader