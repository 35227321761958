import React from 'react'
import HomeHeader from '../HomeHeader'
import HomeFooter from '../HomeFooter'
import Testimonial from '../Testimonial'
import { Link } from 'react-router-dom'

const SellerFAQ = () => {
    return (
        <>
            <>
                <HomeHeader />
                <main>
                    <div className="breadcrumb_section">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                    <div className="breadcrumb_section_content">
                                        <ul>
                                            <li>
                                                <Link to="/seller/home">Home</Link>
                                            </li>
                                            <li>
                                                <i className="fa-solid fa-angle-right" />
                                            </li>
                                            <li>
                                                <Link to="/seller/faq">Faq</Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="profile_section">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                    <div className="profile_heading border-bottom pb-2 mb-4">
                                        <h2>Frequently Asked Questions </h2>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12">
                                    <div className="accordion faq_section" id="accordionExample">
                                        <div className="accordion-item">
                                            <h2 className="accordion-header" id="headingOne">
                                                <button
                                                    className="accordion-button"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#collapseOne"
                                                    aria-expanded="true"
                                                    aria-controls="collapseOne"
                                                >
                                                    Lorem Ipsum is simply dummy text of the printing
                                                </button>
                                            </h2>
                                            <div
                                                id="collapseOne"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="headingOne"
                                                data-bs-parent="#accordionExample"
                                            >
                                                <div className="accordion-body">
                                                    <p>
                                                        Lorem Ipsum is simply dummy text of the printing and
                                                        typesetting industry. Lorem Ipsum has been the industry's
                                                        standard dummy text ever since the 1500s, when an unknown
                                                        printer took a galley of type and scrambled it to make a
                                                        type specimen book.
                                                    </p>
                                                    <ul>
                                                        <li>Lorem Ipsum is simply dummy text of the printing </li>
                                                        <li>Lorem Ipsum is simply dummy text of the printing </li>
                                                        <li>Lorem Ipsum is simply dummy text of the printing </li>
                                                        <li>Lorem Ipsum is simply dummy text of the printing </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2 className="accordion-header" id="headingThree">
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#collapseThree"
                                                    aria-expanded="false"
                                                    aria-controls="collapseThree"
                                                >
                                                    Lorem Ipsum is simply dummy text of the printing
                                                </button>
                                            </h2>
                                            <div
                                                id="collapseThree"
                                                className="accordion-collapse collapse"
                                                aria-labelledby="headingThree"
                                                data-bs-parent="#accordionExample"
                                            >
                                                <div className="accordion-body">
                                                    <p>
                                                        Lorem Ipsum is simply dummy text of the printing and
                                                        typesetting industry. Lorem Ipsum has been the industry's
                                                        standard dummy text ever since the 1500s, when an unknown
                                                        printer took a galley of type and scrambled it to make a
                                                        type specimen book.
                                                    </p>
                                                    <ul>
                                                        <li>Lorem Ipsum is simply dummy text of the printing </li>
                                                        <li>Lorem Ipsum is simply dummy text of the printing </li>
                                                        <li>Lorem Ipsum is simply dummy text of the printing </li>
                                                        <li>Lorem Ipsum is simply dummy text of the printing </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2 className="accordion-header" id="heading4">
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#collapse4"
                                                    aria-expanded="false"
                                                    aria-controls="collapse4"
                                                >
                                                    Lorem Ipsum is simply dummy text of the printing
                                                </button>
                                            </h2>
                                            <div
                                                id="collapse4"
                                                className="accordion-collapse collapse"
                                                aria-labelledby="heading4"
                                                data-bs-parent="#accordionExample"
                                            >
                                                <div className="accordion-body">
                                                    <p>
                                                        Lorem Ipsum is simply dummy text of the printing and
                                                        typesetting industry. Lorem Ipsum has been the industry's
                                                        standard dummy text ever since the 1500s, when an unknown
                                                        printer took a galley of type and scrambled it to make a
                                                        type specimen book.
                                                    </p>
                                                    <ul>
                                                        <li>Lorem Ipsum is simply dummy text of the printing </li>
                                                        <li>Lorem Ipsum is simply dummy text of the printing </li>
                                                        <li>Lorem Ipsum is simply dummy text of the printing </li>
                                                        <li>Lorem Ipsum is simply dummy text of the printing </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2 className="accordion-header" id="heading5">
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#collapse5"
                                                    aria-expanded="false"
                                                    aria-controls="collapse5"
                                                >
                                                    Lorem Ipsum is simply dummy text of the printing
                                                </button>
                                            </h2>
                                            <div
                                                id="collapse5"
                                                className="accordion-collapse collapse"
                                                aria-labelledby="heading5"
                                                data-bs-parent="#accordionExample"
                                            >
                                                <div className="accordion-body">
                                                    <p>
                                                        Lorem Ipsum is simply dummy text of the printing and
                                                        typesetting industry. Lorem Ipsum has been the industry's
                                                        standard dummy text ever since the 1500s, when an unknown
                                                        printer took a galley of type and scrambled it to make a
                                                        type specimen book.
                                                    </p>
                                                    <ul>
                                                        <li>Lorem Ipsum is simply dummy text of the printing </li>
                                                        <li>Lorem Ipsum is simply dummy text of the printing </li>
                                                        <li>Lorem Ipsum is simply dummy text of the printing </li>
                                                        <li>Lorem Ipsum is simply dummy text of the printing </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2 className="accordion-header" id="heading6">
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#collapse6"
                                                    aria-expanded="false"
                                                    aria-controls="collapse6"
                                                >
                                                    Lorem Ipsum is simply dummy text of the printing
                                                </button>
                                            </h2>
                                            <div
                                                id="collapse6"
                                                className="accordion-collapse collapse"
                                                aria-labelledby="heading6"
                                                data-bs-parent="#accordionExample"
                                            >
                                                <div className="accordion-body">
                                                    <p>
                                                        Lorem Ipsum is simply dummy text of the printing and
                                                        typesetting industry. Lorem Ipsum has been the industry's
                                                        standard dummy text ever since the 1500s, when an unknown
                                                        printer took a galley of type and scrambled it to make a
                                                        type specimen book.
                                                    </p>
                                                    <ul>
                                                        <li>Lorem Ipsum is simply dummy text of the printing </li>
                                                        <li>Lorem Ipsum is simply dummy text of the printing </li>
                                                        <li>Lorem Ipsum is simply dummy text of the printing </li>
                                                        <li>Lorem Ipsum is simply dummy text of the printing </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                                    <div className="faq_right">
                                        <h2>Don’t find your answer, Ask for support.</h2>
                                        <p>
                                            Need more help? Explore our support options.We're here to assist
                                            you, In every way you need.
                                        </p>
                                        <form>
                                            <input
                                                type="email"
                                                className="form-control"
                                                id="email"
                                                name="email"
                                                placeholder="Email address"
                                            />
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="email"
                                                name="email"
                                                placeholder="Subject"
                                            />
                                            <textarea placeholder="Message" defaultValue={""} />
                                            <button type="submit">
                                                Send Message <i className="fa-solid fa-arrow-right" />
                                            </button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Testimonial />
                </main>
                {/* ==========main end=========== */}
                {/* ============footer========== */}
                <HomeFooter />
            </>

        </>
    )
}

export default SellerFAQ