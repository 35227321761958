import React from 'react'
import './scss/BuyerAccountContent.css'
import { Link, useNavigate } from 'react-router-dom'

const BuyerAccountContent = () => {
    const navigate = useNavigate()
    
    return (
        <div className='buyer-account'>
            <>
                {/* Breadcrumb */}
                <div className="breadcrumb_section">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                <div className="breadcrumb_section_content">
                                    <ul>
                                        <li>
                                            <Link to="/buyer/your-account">Your Account</Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Breadcrumb */}
                {/* Your Account */}
                <div className="your_account">
                    <div className="container-fluid">
                        <div className="row">
                            {/* <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12">
                                <div className="your_account_left h-100">
                                    <ul>
                                        <li>
                                            <Link to="/buyer/home">
                                                <i className="fa-solid fa-house" /> Home
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/buyer/your-account" className="">
                                                <i className="fa-regular fa-user" /> Your Account
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/buyer/your-address">
                                                <i className="fa-solid fa-location-dot" /> Your Address
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="">
                                                <i className="fa fa-shopping-cart" />
                                                Shopping Cart
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/buyer/wish-list">
                                                <i className="fa-regular fa-heart" />
                                                Wishlist
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/buyer/contact-us">
                                                <i className="fa-solid fa-book" /> Contact Us
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="">
                                                <i className="fa-solid fa-mobile-screen" /> Seller Account
                                            </Link>
                                        </li>
                                        <li>
                                            <Link
                                                to=""
                                                data-bs-toggle="modal"
                                                data-bs-target="#exampleModalCenter"
                                            >
                                                <i className="fa-solid fa-right-from-bracket" />
                                                Logout
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            </div> */}
                            <div className="col-xl-1 col-lg-1 col-md-1 col-sm-1 col-12"></div>
                            <div className="col-xl-10 col-lg-10 col-md-10 col-sm-10 col-12">
                                <div className="your_account_right h-100">
                                    <div className="row">
                                        <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 mb-3">
                                            <div className="card your_account_inner text-center">
                                                <Link to="/buyer/your-orders">
                                                    <i className="fa-solid fa-box-open" />
                                                    <h4>Your Order</h4>
                                                    <p>Track, Return and Save for later</p>
                                                </Link>
                                            </div>
                                        </div>
                                        <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 mb-3">
                                            <div className="card your_account_inner text-center">
                                                <Link to="/buyer/profile">
                                                    <i className="fa-solid fa-address-card" />
                                                    <h4>Profile</h4>
                                                    <p>Edit profile, name, mobile number</p>
                                                </Link>
                                            </div>
                                        </div>
                                        <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 mb-3">
                                            <div className="card your_account_inner text-center">
                                                <Link to="/buyer/your-address">
                                                    <i className="fa-solid fa-location-dot" />
                                                    <h4>Your Address</h4>
                                                    <p>Edit address for orders</p>
                                                </Link>
                                            </div>
                                        </div>
                                        <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 mb-3">
                                            <div className="card your_account_inner text-center">
                                                <Link to="/buyer/contact-us">
                                                    <i className="fa-solid fa-envelope-open-text" />
                                                    <h4>Contact Us</h4>
                                                    <p>Contact us if any query</p>
                                                </Link>
                                            </div>
                                        </div>
                                        <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 mb-3">
                                            <div className="card your_account_inner text-center">
                                                <Link to="">
                                                    <i className="fa-regular fa-circle-user" />
                                                    <h4>Seller Account</h4>
                                                    <p>Sign up for free to save up to 28% with GST invoice</p>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-1 col-lg-1 col-md-1 col-sm-1 col-12"></div>
                        </div>
                    </div>
                </div>
                {/* Your Account */}


                

            </>


        </div>

    )
}

export default BuyerAccountContent