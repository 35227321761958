import React from 'react'
import SellerSidebar from '../../SellerEssentials/SellerSidebar';
import SellerHeader from '../../SellerEssentials/SellerHeader';
import { Link } from 'react-router-dom';

const ProductIdentity = () => {
    return (
        <>
            <div className="seller">
                <div className="page-wrapper chiller-theme toggled">
                    <SellerSidebar />
                    {/* sidebar-wrapper  */}
                    <main className="page-content container-fluid">
                        {/* ==================header================== */}
                        <SellerHeader />
                        {/* ==================header================== */}
                        <div className="row ">
                            <div className="col-md-12">
                                <div className="main-nav-Order">
                                    <h2>
                                        {" "}
                                        <Link to="/seller/products">
                                            <img src={`${process.env.PUBLIC_URL}/assets/images/seller_images/left_side.png`} alt="left" />
                                        </Link>{" "}
                                        <span>Products</span> / Add Products
                                    </h2>
                                    <form id="signUpForm" action="#!">
                                        {/* start step indicators */}
                                        <div className="form-header d-flex">
                                            <div className="stepIndicator">PRODUCT IDENTITY</div>
                                            <div className="stepIndicator">PRODUCT DESCRIPTION</div>
                                            <div className="stepIndicator">VARIATIONS</div>
                                            <div className="stepIndicator">PRODUCT DETAILS</div>
                                        </div>
                                        {/* end step indicators */}
                                        {/* step one */}
                                        <div className="step1">
                                            <div className="main_detail_identity pt-0">
                                                <div className="please_fill_main">
                                                    <h6>
                                                        Please Fill the below details to complete Product
                                                        Identity:
                                                    </h6>
                                                </div>
                                                <div className="row mt-3">
                                                    <div className="col-lg-4">
                                                        <div className="form-group log_in_main">
                                                            <label>Item Name</label>
                                                            <input
                                                                type="text"
                                                                defaultValue=""
                                                                oninput="this.className = ''"
                                                                className="form-control"
                                                                placeholder="Enter Item Name"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-4">
                                                        <div className="form-group log_in_main">
                                                            <label>Category</label>
                                                            <div className="main_select">
                                                                <select name="#" id="#">
                                                                    <option value="#">Select Category</option>
                                                                    <option value="#">Man</option>
                                                                    <option value="#">Women</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-4">
                                                        <div className="form-group log_in_main">
                                                            <label>Sub Category</label>
                                                            <div className="main_select">
                                                                <select name="#" id="#">
                                                                    <option value="#">Select Sub Category</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-4">
                                                        <div className="form-group log_in_main">
                                                            <label>Child Category</label>
                                                            <div className="main_select">
                                                                <select name="#" id="#">
                                                                    <option value="#">Select Child Category</option>
                                                                    <option value="#">Empower</option>
                                                                    <option value="#">Pursue</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-4">
                                                        <div className="form-group log_in_main">
                                                            <label>Model Number</label>
                                                            <input
                                                                type="text"
                                                                defaultValue=""
                                                                oninput="this.className = ''"
                                                                className="form-control"
                                                                placeholder="Enter Model Number"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-4">
                                                        <div className="form-group log_in_main">
                                                            <label>Brand Name</label>
                                                            <div className="main_select">
                                                                <select name="#" id="#">
                                                                    <option value="#">Select Brand Name</option>
                                                                    <option value="#">Innovate</option>
                                                                    <option value="#">Empower</option>
                                                                    <option value="#">Pursue</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-footer d-flex main_detail_save">
                                                <Link
                                                    type="button"
                                                    id="nextBtn"
                                                    className="btn btn-primary next-step"
                                                    // onClick="nextPrev(1)"
                                                    to="/seller/products/add/description"
                                                >
                                                    Next
                                                </Link>
                                            </div>
                                        </div>
                                        {/* step two */}
                                        {/* <div className="step">
                                            <div className="main_detail_identity pt-0">
                                                <div className="please_fill_main">
                                                    <h6>
                                                        Please Fill the below details to complete Product
                                                        Description:
                                                    </h6>
                                                </div>
                                                <div className="row mt-3">
                                                    <div className="col-lg-12">
                                                        <div className="form-group log_in_main input_box_section">
                                                            <label>Description</label>
                                                            <div id="summernote" />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12">
                                                        <div className="form-group log_in_main input_box_section">
                                                            <label>Addifianal Informetion</label>
                                                            <div id="summernote_next" />
                                                        </div>
                                                    </div>
                                                    <div className="add_detail_listing">
                                                        <div className="row">
                                                            <div className="col-lg-12 col-md-12">
                                                                <div className="form-group log_in_main input_box_section">
                                                                    <label>Feature</label>
                                                                </div>
                                                                <div className="card-add-product">
                                                                    <div className="card-body">
                                                                        <div className="table-responsive">
                                                                            <table
                                                                                id="faqs"
                                                                                className="table table-hover main_detail_section"
                                                                            >
                                                                                <thead>
                                                                                    <tr>
                                                                                        <th>Key</th>
                                                                                        <th>Value</th>
                                                                                        <th>Status</th>
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                    <tr>
                                                                                        <td>
                                                                                            <input
                                                                                                type="text"
                                                                                                className="form-control"
                                                                                                placeholder="User name"
                                                                                            />
                                                                                        </td>
                                                                                        <td>
                                                                                            <input
                                                                                                type="text"
                                                                                                placeholder="Product name"
                                                                                                className="form-control"
                                                                                            />
                                                                                        </td>
                                                                                        <td className="mt-10">
                                                                                            <button
                                                                                                onClick="addfaqs();"
                                                                                                className="badge_add badge-success"
                                                                                            >
                                                                                                <i className="fa fa-plus" /> ADD NEW
                                                                                            </button>
                                                                                        </td>
                                                                                    </tr>
                                                                                </tbody>
                                                                            </table>
                                                                        </div>
                                                                        <div className="text-center" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-footer d-flex main_detail_save">
                                                <button
                                                    type="button"
                                                    id="prevBtn"
                                                    className="btn btn-primary next-step"
                                                    onClick="nextPrev(-1)"
                                                >
                                                    Previous
                                                </button>
                                                <button
                                                    type="button"
                                                    id="nextBtn"
                                                    className="btn btn-primary next-step"
                                                    onClick="nextPrev(1)"
                                                >
                                                    Next
                                                </button>
                                            </div>
                                        </div> */}
                                        {/* step three */}
                                        {/* <div className="step">
                                            <div className="main_detail_identity pt-0">
                                                <div className="please_fill_main">
                                                    <h6>
                                                        Please Fill the below details to complete Product Details
                                                    </h6>
                                                </div>
                                                <div className="row mt-3">
                                                    <div className="col-lg-3">
                                                        <div className="form-group log_in_main">
                                                            <label>Model Name</label>
                                                            <input
                                                                type="text"
                                                                defaultValue=""
                                                                className="form-control"
                                                                placeholder="Enter Model Name"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-3">
                                                        <div className="form-group log_in_main">
                                                            <label>Date of Exchange/Return</label>
                                                            <input
                                                                type="text"
                                                                defaultValue=""
                                                                className="form-control"
                                                                placeholder="Exchange Days"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-3">
                                                        <div className="form-group log_in_main">
                                                            <label>Delivery Charge</label>
                                                            <input
                                                                type="text"
                                                                defaultValue=""
                                                                className="form-control"
                                                                placeholder="Delivery Charge"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12">
                                                        <div className="form-group log_in_main">
                                                            <label>Manufacturing Informetion</label>
                                                            <div id="manufacturing_next" />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6">
                                                        <div className="form-group log_in_main">
                                                            <label>Size Chart Image</label>
                                                            <div className="file-input">
                                                                <input type="file" name="userfile" id="userfile" />
                                                                <button className="folder_main_img label_1">
                                                                    <span>
                                                                        <img src="images/Upload.png" alt="file" />
                                                                    </span>{" "}
                                                                    Upload Image
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-footer d-flex main_detail_save">
                                                <button
                                                    type="button"
                                                    id="prevBtn"
                                                    className="btn btn-primary next-step"
                                                    onClick="nextPrev(-1)"
                                                >
                                                    Previous
                                                </button>
                                                <button
                                                    type="button"
                                                    id="nextBtn"
                                                    className="btn btn-primary next-step"
                                                    onClick="nextPrev(1)"
                                                >
                                                    Next
                                                </button>
                                            </div>
                                        </div> */}
                                        {/* step four */}
                                        {/* <div className="step">
                                            <div className="variations">
                                                <div className="tab-container pt-3">
                                                    <div className="row">
                                                        <div className="col-lg-3 col-md-4 col-sm-6">
                                                            <div className="main_select">
                                                                <label>Variations</label>
                                                                <select id="select-box">
                                                                    <option value={1}>Yes</option>
                                                                    <option value={2}>No</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row pt-2 pb-2">
                                                        <div className="col-lg-12">
                                                            <div id="tab-1" className="tab-content_Products">
                                                                <div className="row mt-3">
                                                                    <div className="col-lg-6">
                                                                        <div className="form-group main_select_color main_select">
                                                                            <label>Color</label>
                                                                            <select
                                                                                id="choices-multiple-remove-button"
                                                                                placeholder="Select upto 5 tags"
                                                                                multiple=""
                                                                            >
                                                                                <option value="Red">Red</option>
                                                                                <option value="green">Green</option>
                                                                                <option value="white">White</option>
                                                                                <option value="black">Black</option>
                                                                                <option value="blue">Blue</option>
                                                                            </select>
                                                                            <span>
                                                                                <img src="images/drop.png" alt="deop" />
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-6">
                                                                        <div className="form-group main_select main_select_color">
                                                                            <label>Size</label>
                                                                            <select
                                                                                id="choices-multiple-remove-button"
                                                                                placeholder="Select upto 6 tags"
                                                                                multiple=""
                                                                            >
                                                                                <option value="XS">XS</option>
                                                                                <option value="S">S</option>
                                                                                <option value="M">M</option>
                                                                                <option value="L">L</option>
                                                                                <option value="XL">XL</option>
                                                                                <option value="XXL">XXL</option>
                                                                            </select>
                                                                            <span>
                                                                                <img src="images/drop.png" alt="deop" />
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-md-12">
                                                                        <div className="table_detail_tariff table-responsive  border bg-light">
                                                                            <table className="posts-table geeks-new_detail">
                                                                                <thead>
                                                                                    <tr className="users-table-tariff text-cen">
                                                                                        <th>
                                                                                            <div className="round_Products">
                                                                                                <input
                                                                                                    type="checkbox"
                                                                                                    id="checkbox_main"
                                                                                                />
                                                                                                <label htmlFor="checkbox_main" />
                                                                                            </div>
                                                                                        </th>
                                                                                        <th>Color</th>
                                                                                        <th>Size</th>
                                                                                        <th>Product Image</th>
                                                                                        <th>Quantity</th>
                                                                                        <th>Orignal Price</th>
                                                                                        <th>Discount %</th>
                                                                                        <th>Selling Price</th>
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody className="text-cen">
                                                                                    <tr className="main_table_row_new gfg">
                                                                                        <td>
                                                                                            <div className="round_Products">
                                                                                                <input
                                                                                                    type="checkbox"
                                                                                                    id="checkbox-1"
                                                                                                />
                                                                                                <label htmlFor="checkbox-1" />
                                                                                            </div>
                                                                                        </td>
                                                                                        <td>Red</td>
                                                                                        <td>Xs</td>
                                                                                        <td>
                                                                                            <a
                                                                                                type="button"
                                                                                                className="product_cemra"
                                                                                                data-bs-toggle="modal"
                                                                                                data-bs-target="#staticBackdrop"
                                                                                            >
                                                                                                <img
                                                                                                    src="images/cemra.png"
                                                                                                    alt="product"
                                                                                                />
                                                                                                Add Images
                                                                                            </a>
                                                                                        </td>
                                                                                        <td>
                                                                                            <a
                                                                                                href="javascript:void(0)"
                                                                                                className="input_price_main "
                                                                                            >
                                                                                                <input type="text" defaultValue="" />
                                                                                            </a>
                                                                                        </td>
                                                                                        <td width="10%">
                                                                                            <a
                                                                                                href="javascript:void(0)"
                                                                                                className="main_input_price"
                                                                                            >
                                                                                                <span>AFN$</span>
                                                                                                <input type="text" defaultValue="" />
                                                                                            </a>
                                                                                        </td>
                                                                                        <td>
                                                                                            <a
                                                                                                href="javascript:void(0)"
                                                                                                className="input_price_main"
                                                                                            >
                                                                                                <input
                                                                                                    type="text"
                                                                                                    defaultValue=""
                                                                                                    className="w_100"
                                                                                                />
                                                                                            </a>
                                                                                        </td>
                                                                                        <td>
                                                                                            <a
                                                                                                href="javascript:void(0)"
                                                                                                className="main_input_price"
                                                                                            >
                                                                                                <span>AFN$</span>
                                                                                                <input
                                                                                                    type="text"
                                                                                                    defaultValue=""
                                                                                                    className="w_100"
                                                                                                />
                                                                                            </a>
                                                                                        </td>
                                                                                    </tr>
                                                                                </tbody>
                                                                            </table>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div id="tab-2" className="tab-content_Products">
                                                                <div className="row mt-3">
                                                                    <div className="col-lg-4">
                                                                        <div className="form-group log_in_main">
                                                                            <label>Quantity</label>
                                                                            <input
                                                                                type="text"
                                                                                defaultValue=""
                                                                                className="form-control"
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-4">
                                                                        <div className="form-group log_in_main">
                                                                            <label>Price</label>
                                                                            <input
                                                                                type="text"
                                                                                defaultValue=""
                                                                                className="form-control"
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-4">
                                                                        <div className="form-group log_in_main">
                                                                            <label>Discount Price</label>
                                                                            <input
                                                                                type="text"
                                                                                defaultValue=""
                                                                                className="form-control"
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-4">
                                                                        <div className="form-group log_in_main">
                                                                            <label>Selling Price</label>
                                                                            <input
                                                                                type="text"
                                                                                defaultValue=""
                                                                                className="form-control"
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-4">
                                                                        <div className="form-group log_in_main">
                                                                            <label>Select Add Images</label>
                                                                            <a
                                                                                type="button"
                                                                                className="product_cemra mxs-top-main"
                                                                                data-bs-toggle="modal"
                                                                                data-bs-target="#staticBackdrop"
                                                                            >
                                                                                <img src="images/cemra.png" alt="product" />
                                                                                Add Images
                                                                            </a>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-footer d-flex main_detail_save">
                                                <button
                                                    type="button"
                                                    id="prevBtn"
                                                    className="btn btn-primary next-step"
                                                    onClick="nextPrev(-1)"
                                                >
                                                    Previous
                                                </button>
                                                <a
                                                    href="products_review_auto_filled.html"
                                                    className="btn btn-primary next-step"
                                                >
                                                    Review
                                                </a>
                                            </div>
                                        </div> */}
                                    </form>
                                </div>
                            </div>
                        </div>
                        {/* Modal */}
                        <div
                            className="modal fade"
                            id="staticBackdrop"
                            data-bs-backdrop="static"
                            data-bs-keyboard="false"
                            tabIndex={-1}
                            aria-labelledby="staticBackdropLabel"
                            aria-hidden="true"
                        >
                            <div className="modal-dialog dasbord-main_model">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title" id="staticBackdropLabel">
                                            Edit images
                                        </h5>
                                        <button
                                            type="button"
                                            className="btn-close"
                                            data-bs-dismiss="modal"
                                            aria-label="Close"
                                        />
                                    </div>
                                    <div className="modal-body">
                                        <div className="main_detail_head">
                                            <h6>Product images style guideline</h6>
                                            <p>
                                                Uploaded:0 of 9 images. Maximum 9 images are allowed. you can
                                                arrange the order after uploading.
                                            </p>
                                        </div>
                                        <div className="upload-box-main">
                                            <div className="upload-box-inner">
                                                <span>
                                                    <span className="file-name" />
                                                    <input type="file" className="file-element" />
                                                    <img
                                                        className="main-img-icon"
                                                        src="images/cemra.png"
                                                        alt=""
                                                    />
                                                    <h3>Update</h3>
                                                </span>
                                                <div className="main_hading_popup">
                                                    <h3>MAIN</h3>
                                                </div>
                                            </div>
                                            <div className="upload-box-inner">
                                                <span>
                                                    <span className="file-name" />
                                                    <input type="file" className="file-element" />
                                                    <img
                                                        className="main-img-icon"
                                                        src="images/cemra.png"
                                                        alt=""
                                                    />
                                                    <h3>Update</h3>
                                                </span>
                                            </div>
                                            <div className="upload-box-inner">
                                                <span>
                                                    <span className="file-name" />
                                                    <input type="file" className="file-element" />
                                                    <img
                                                        className="main-img-icon"
                                                        src="images/cemra.png"
                                                        alt=""
                                                    />
                                                    <h3>Update</h3>
                                                </span>
                                            </div>
                                            <div className="upload-box-inner">
                                                <span>
                                                    <span className="file-name" />
                                                    <input type="file" className="file-element" />
                                                    <img
                                                        className="main-img-icon"
                                                        src="images/cemra.png"
                                                        alt=""
                                                    />
                                                    <h3>Update</h3>
                                                </span>
                                            </div>
                                            <div className="upload-box-inner">
                                                <span>
                                                    <span className="file-name" />
                                                    <input type="file" className="file-element" />
                                                    <img
                                                        className="main-img-icon"
                                                        src="images/cemra.png"
                                                        alt=""
                                                    />
                                                    <h3>Update</h3>
                                                </span>
                                            </div>
                                            <div className="upload-box-inner">
                                                <span>
                                                    <span className="file-name" />
                                                    <input type="file" className="file-element" />
                                                    <img
                                                        className="main-img-icon"
                                                        src="images/cemra.png"
                                                        alt=""
                                                    />
                                                    <h3>Update</h3>
                                                </span>
                                            </div>
                                            <div className="upload-box-inner">
                                                <span>
                                                    <span className="file-name" />
                                                    <input type="file" className="file-element" />
                                                    <img
                                                        className="main-img-icon"
                                                        src="images/cemra.png"
                                                        alt=""
                                                    />
                                                    <h3>Update</h3>
                                                </span>
                                            </div>
                                            <div className="upload-box-inner">
                                                <span>
                                                    <span className="file-name" />
                                                    <input type="file" className="file-element" />
                                                    <img
                                                        className="main-img-icon"
                                                        src="images/cemra.png"
                                                        alt=""
                                                    />
                                                    <h3>Update</h3>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="modal-footer">
                                        <button
                                            type="button"
                                            className="btn btn-secondary"
                                            data-bs-dismiss="modal"
                                        >
                                            Close
                                        </button>
                                        <button type="button" className="btn btn-primary">
                                            Add to Listing
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>
                </div>
                <div
                    className="modal fade"
                    id="logout_main"
                    data-bs-backdrop="static"
                    data-bs-keyboard="false"
                    tabIndex={-1}
                    aria-labelledby="staticBackdropLabel"
                    aria-hidden="true"
                >
                    <div className="modal-dialog classmain_log_modul pt-100">
                        <div className="modal-content mt-100 ">
                            <div className="modal-body">
                                <div className="request_send_for_modul padd_none classmain_log">
                                    <h6>Log out ?</h6>
                                    <p>Are you sure want to log out ? </p>
                                    <div className="main_acsept_butt">
                                        <button
                                            type="button"
                                            className="btn btn-secondary"
                                            data-bs-dismiss="modal"
                                        >
                                            Cancel
                                        </button>
                                        <button type="button" className="classmain_log_button btn ">
                                            Log out
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>

    )
}

export default ProductIdentity