import React from 'react'
import SellerHeader from '../SellerEssentials/SellerHeader'
import SellerSidebar from '../SellerEssentials/SellerSidebar'
import { Link } from 'react-router-dom'
import '../Scss/Seller.css'
const SellerMyAccount = () => {
    return (
        <div className='seller'>
            <div className="page-wrapper chiller-theme toggled">
                <SellerSidebar />
                {/* sidebar-wrapper  */}
                <main className="page-content container-fluid">
                    {/* ==================header================== */}
                    <SellerHeader />
                    {/* ==================header================== */}
                    <div className="row">
                        <div className="col-md-12 mb-5">
                            <div className="main-nav-Order">
                                <h2>
                                    {" "}
                                    <Link to="/seller/profile">
                                        <img src="/assets/images/seller_images/left_side.png" alt="left" /> My Account
                                    </Link>{" "}
                                </h2>
                                <div className="row mt-3">
                                    <div className="col-lg-12 pro_bg_main">
                                        <img src="/assets/images/seller_images/profile_bg.png" alt="profile" />
                                        <span>
                                            {" "}
                                            <img src="/assets/images/seller_images/pro_file.png" alt="profile" />
                                        </span>
                                    </div>
                                    <div className="name_profile_main">
                                        <h4>John Murphy</h4>
                                    </div>
                                    <div className="col-lg-12 mt-5 mb-5">
                                        <div className="main_form_detail_profile">
                                            <div className="row">
                                                <div className="col-lg-4">
                                                    <div className="form-group log_in_main">
                                                        <label>First Name</label>
                                                        <input
                                                            type="text"
                                                            defaultValue=""
                                                            placeholder="Full Name Here"
                                                            className="form-control"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-lg-4">
                                                    <div className="form-group log_in_main">
                                                        <label>Phone Number</label>
                                                        <input
                                                            type="text"
                                                            defaultValue=""
                                                            placeholder="Phone Number"
                                                            className="form-control"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-lg-4">
                                                    <div className="form-group log_in_main">
                                                        <label>Email Address</label>
                                                        <input
                                                            type="text"
                                                            defaultValue=""
                                                            placeholder="Email Address"
                                                            className="form-control"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-lg-4">
                                                    <div className="form-group log_in_main">
                                                        <label>Date of Birth</label>
                                                        <input
                                                            type="text"
                                                            defaultValue=""
                                                            placeholder="Date of Birth"
                                                            className="form-control"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-lg-4">
                                                    <div className="form-group log_in_main">
                                                        <label>Current Address</label>
                                                        <input
                                                            type="text"
                                                            defaultValue=""
                                                            placeholder="Current Address"
                                                            className="form-control"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
            {/* ==============script linking start=============== */}
            {/* ==============script linking end=============== */}
        </div>

    )
}

export default SellerMyAccount