import { React, useEffect, useState } from 'react'

import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../../Loader';
import ErrorLoader from '../../../Error/ErrorLoader';
import BuyerHomeFourSectionProductListing from './BuyerHomeFourSectionProductListing'
import BuyerHeader from '../../BuyerHeader/BuyerHeader'
import BuyerFooter from '../../BuyerFooter/BuyerFooter'
import { getBrandData, getColourData } from '../../../Redux/action/MasterAction';
import BuyerHeaderWithoutLogin from '../../BuyerHeader/BuyerHeaderWithoutLogin';
import AOS from 'aos';
import 'aos/dist/aos.css'; // Import AOS styles


const BuyerHomeFourSectionProductListingHome = () => {


    const dispatch = useDispatch();


    useEffect(() => {
        dispatch(getBrandData());
        dispatch(getColourData());

    }, []);
    useEffect(() => {
        // Initialize AOS animations
        AOS.init({
            duration: 1200, // Duration of animations
            once: false, // Whether animation should happen only once - while scrolling down
            mirror: false, // Whether elements should animate out while scrolling past them
        }); 

        // Refresh AOS to recalculate positions
        AOS.refresh();

        return () => {
            // Cleanup function to reset AOS if needed
            AOS.refreshHard(); // Reset AOS to avoid any memory leaks
        };
    }, [  AOS.init  ]);
    const {

        loadingColourDataListApi,
        ColourDataListApiData,
        ColourDataListApiError,

        loadingBrandDataListApi,
        BrandDataListApiData,
        BrandDataListApiError,


    } = useSelector(state => state?.MasterReducer);


    const authToken = localStorage.getItem("buyer_token")

    return (
        <>
            {authToken ?

                <BuyerHeader />
                :
                <BuyerHeaderWithoutLogin />
            }
            <BuyerHomeFourSectionProductListing ColourDataListApiData={ColourDataListApiData} BrandDataListApiData={BrandDataListApiData} />
            <BuyerFooter />
        </>
    )
}

export default BuyerHomeFourSectionProductListingHome