import { React, useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useFormik } from "formik";
import { toast } from 'react-hot-toast';
import * as Yup from "yup";
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { getSellerAllBillingAddress, getSellerRegistrationDetails } from '../../Redux/action/SellerAction';

const host = process.env.REACT_APP_API_SERVER
const BillingAddressList = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const sellerIncompleteToken = localStorage.getItem('seller_incomplete_token')
    useEffect(() => {
        if (sellerIncompleteToken) {
            dispatch(getSellerAllBillingAddress(sellerIncompleteToken))
            dispatch(getSellerRegistrationDetails(sellerIncompleteToken))
        }
        else {
            toast.error('Please register to continue')
            navigate('/seller/create-account')
        }
    }, [sellerIncompleteToken])

    const {

        loadingSellerAllBillingAddressListApi,
        SellerAllBillingAddressListApiData,
        SellerAllBillingAddressListApiError,

        loadingSellerRegistrationDataListApi,
        SellerRegistrationDataListApiData,
        SellerRegistrationDataListApiError,

    } = useSelector(state => state?.SellerReducer);

    const config = {
        headers: {

            'content-type': 'application/json',
            "auth-token": sellerIncompleteToken
        }
    };

    const handleDelete = async (id) => {
        try {

            const response = await axios.get(`${host}/seller/seller-delete-billing-address?addressId=${id}`, config);
            if (response.status === 200) {
                toast.success('Seller billing address deleted successfully.!');

                dispatch(getSellerAllBillingAddress(sellerIncompleteToken))
            } else {

                toast.error(response.data.message)
            }
        } catch (error) {
            toast.error(error.message)

        }
    };


    let [initialValues, setInitialValues] = useState({

        billingAddressId: "",


    })

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: Yup.object().shape({



            billingAddressId: Yup.string().required("Please select a billing address"),

        }),
        onSubmit: async (values, { setSubmitting, resetForm }) => {

            try {
                if (sellerIncompleteToken) {

                    const config = {
                        headers: {

                            'content-type': 'application/json',
                            "auth-token": sellerIncompleteToken
                        }
                    };
                    setSubmitting(true);
                    values.fullDetails = 0;
                    await axios.post(`${host}/seller/seller-add-billing-address-in-registration`, values, config)
                        .then(function (response) {
                            if (response.status == 200) {
                                setSubmitting(false)

                                toast.success(response.data.message);
                                resetForm();
                                navigate('/seller/store-details')

                            } else {

                                setSubmitting(false)

                                toast.error(response.data.message);
                                if (response.data.delAccount) {
                                    localStorage.removeItem('seller_incomplete_token')
                                }

                            }

                        })
                        .catch(function (error) {
                            setSubmitting(false)
                            toast.error(error.message);

                        })


                }
                else {
                    navigate('/seller/create-account')
                }

            }
            catch (error) {


                setSubmitting(false)
                toast.error('Error submitting form. Please try again.');

            }

        }
    });


    useEffect(() => {

        if (SellerRegistrationDataListApiData) {
            formik.setValues({
                billingAddressId: SellerRegistrationDataListApiData?.billingAddressId ?? "",

            })
        }
    }, [SellerRegistrationDataListApiData])

    if (loadingSellerAllBillingAddressListApi) {
        return <div className="overlay-form-submission">
            {/* <Loader /> */}
        </div>
    }

    if (SellerAllBillingAddressListApiError) {
        // return <ErrorLoader message={SellerRegistrationDataListApiError} />;
    }


    return (
        <div className='seller'>
            <main className="main_login_section container-fluid">
                <div className="row">
                    <div className="col-lg-7 main_bg_detail">
                        <div className="main_login_detail">
                            <form method='post' onSubmit={formik.handleSubmit}>
                                <div className="logo_login">
                                    <img src={`${process.env.PUBLIC_URL}/assets/images/seller_images/login_logo.png`} alt="login_logo" />
                                </div>
                                <div className="detail_form_log detail_form_careate">
                                    <h3>Billing/Business Address</h3>
                                    <p>
                                        Please enter your data to complete your account registration
                                        process
                                    </p>
                                    <div className="main_detail_address">
                                        {SellerAllBillingAddressListApiData?.map((data) => (
                                            <div className="address_here_detail">
                                                <div className="flex_detail_blog">
                                                    <label>
                                                        <input
                                                            type="radio"
                                                            className="input-radio off"
                                                            checked={formik.values.billingAddressId == data?.id}
                                                            name="billingAddressId"
                                                            onChange={formik.handleChange}
                                                            onBlur={formik.handleBlur}
                                                            value={data?.id}
                                                        />{" "}
                                                        <span className="main_add_section">
                                                            {data?.firstName} {data?.lastName} <br />{" "}
                                                            <a>
                                                                {data?.address}

                                                            </a>
                                                            <a>{data?.state},{data?.city},{data?.pincode}</a>
                                                        </span>
                                                    </label>
                                                    <div className="button_home_add">
                                                        <a>{data?.typeOfAddress}</a>
                                                    </div>
                                                </div>

                                                <div className="edit_butt_detail">
                                                    <Link to={`/seller/edit-billing-address/${data?.slug}`} className='me-2'>Edit</Link>
                                                    <a onClick={() => { handleDelete(data?.id) }}>Delete</a>
                                                </div>
                                            </div>
                                        ))}
                                        <div className="help-block with-errors">
                                            {formik.touched.billingAddressId && formik.errors.billingAddressId ? (
                                                <div className='text-danger'>{formik.errors.billingAddressId}</div>
                                            ) : null}
                                        </div>
                                        <div className="main-nav-det pb-1 pt-1">
                                            <Link to="/seller/add-billing-address">
                                                <span>
                                                    <img src={`${process.env.PUBLIC_URL}/assets/images/seller_images/pla.png`} alt="img" />
                                                </span>{" "}
                                                Add New Address
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="detail_address_button mt-2">
                                                <button type='submit' disabled={formik.isSubmitting}>Save and Continue</button>
                                                {/* <Link to="/seller/store-details">Save and Continue</Link> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className="col-lg-5 main_login_img">
                        <img src={`${process.env.PUBLIC_URL}/assets/images/seller_images/billing_business.png`} alt="login" />
                    </div>
                </div>
            </main>
        </div>
    )
}

export default BillingAddressList