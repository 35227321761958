const host = process.env.REACT_APP_API_SERVER

// Buyer cart product list
export const fetchSellerRegistrationDetailsRequest = () => ({
    type: "FETCH_SELLER_REGISTRATION_DETAILS_REQUEST",
});

export const fetchSellerRegistrationDetailsSuccess = (data) => ({
    type: "FETCH_SELLER_REGISTRATION_DETAILS_SUCCESS",
    payload: data,
});

export const fetchSellerRegistrationDetailsFailure = (error) => ({
    type: "FETCH_SELLER_REGISTRATION_DETAILS_FAILURE",
    payload: error,
});


export const getSellerRegistrationDetails = (token) =>

    async (dispatch) => {

        dispatch(fetchSellerRegistrationDetailsRequest());
        try {


            let response = await fetch(`${host}/seller/get-seller-details`, { method: "GET", headers: { "auth-token": token } });
            // console.log("response", await response.json());


            if (!response.ok) {
                throw new Error('Please enter correct URL.');
            }
            const data = await response.json();
            // console.log('datais', data);
            let desired_data = data?.data;
            // console.log('desired_data', desired_data)

            dispatch(fetchSellerRegistrationDetailsSuccess(desired_data));

        } catch (error) {
            dispatch(fetchSellerRegistrationDetailsFailure(error.message));
        }
    }


// Buyer cart product list
export const fetchSellerAllBillingAddressRequest = () => ({
    type: "FETCH_SELLER_ALL_BILLING_ADDRESS_REQUEST",
});

export const fetchSellerAllBillingAddressSuccess = (data) => ({
    type: "FETCH_SELLER_ALL_BILLING_ADDRESS_SUCCESS",
    payload: data,
});

export const fetchSellerAllBillingAddressFailure = (error) => ({
    type: "FETCH_SELLER_ALL_BILLING_ADDRESS_FAILURE",
    payload: error,
});


export const getSellerAllBillingAddress = (token) =>

    async (dispatch) => {

        dispatch(fetchSellerAllBillingAddressRequest());
        try {


            let response = await fetch(`${host}/seller/get-seller-all-billing-address`, { method: "GET", headers: { "auth-token": token } });
            // console.log("response", await response.json());


            if (!response.ok) {
                throw new Error('Please enter correct URL.');
            }
            const data = await response.json();
            // console.log('datais', data);
            let desired_data = data?.data;
            // console.log('desired_data', desired_data)

            dispatch(fetchSellerAllBillingAddressSuccess(desired_data));

        } catch (error) {
            dispatch(fetchSellerAllBillingAddressFailure(error.message));
        }
    }


// Buyer cart product list
export const fetchSellerBillingAddressRequest = () => ({
    type: "FETCH_SELLER_BILLING_ADDRESS_REQUEST",
});

export const fetchSellerBillingAddressSuccess = (data) => ({
    type: "FETCH_SELLER_BILLING_ADDRESS_SUCCESS",
    payload: data,
});

export const fetchSellerBillingAddressFailure = (error) => ({
    type: "FETCH_SELLER_BILLING_ADDRESS_FAILURE",
    payload: error,
});


export const getSellerBillingAddressDetails = (token, slug) =>

    async (dispatch) => {

        dispatch(fetchSellerBillingAddressRequest());
        try {


            let response = await fetch(`${host}/seller/get-seller-billing-address?slug=${slug}`, { method: "GET", headers: { "auth-token": token } });
            // console.log("response", await response.json());


            if (!response.ok) {
                throw new Error('Please enter correct URL.');
            }
            const data = await response.json();
            // console.log('datais', data);
            let desired_data = data?.data;
            // console.log('desired_data', desired_data)

            dispatch(fetchSellerBillingAddressSuccess(desired_data));

        } catch (error) {
            dispatch(fetchSellerBillingAddressFailure(error.message));
        }
    }

