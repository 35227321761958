import React from 'react'
import SellerSidebar from '../../SellerEssentials/SellerSidebar';
import SellerHeader from '../../SellerEssentials/SellerHeader';
import { Link } from 'react-router-dom';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

const ProductDetails = () => {
    const CKEDITORConfig = {
        // plugins: [Paragraph, Bold, Italic, Essentials],
        fontSize: {
            options: [
                9,
                11,
                13,
                'default',
                17,
                19,
                21
            ]
        },
        toolbar: [
            // "heading",
            "undo",
            "redo",
            'bold',
            'italic',
            "fontSize",
            "bulletedList",
            "numberedList"
        ],
    }
    return (
        <>
            <div className="seller">
                <div className="page-wrapper chiller-theme toggled">
                    <SellerSidebar />
                    {/* sidebar-wrapper  */}
                    <main className="page-content container-fluid">
                        {/* ==================header================== */}
                        <SellerHeader />
                        {/* ==================header================== */}
                        <div className="row ">
                            <div className="col-md-12">
                                <div className="main-nav-Order">
                                    <h2>
                                        {" "}
                                        <Link to="/seller/products">
                                            <img src={`${process.env.PUBLIC_URL}/assets/images/seller_images/left_side.png`} alt="left" />
                                        </Link>{" "}
                                        <span>Products</span> / Add Products
                                    </h2>
                                    <form id="signUpForm" action="#!">
                                        {/* start step indicators */}
                                        <div className="form-header d-flex">
                                            <div className="stepIndicator">PRODUCT IDENTITY</div>
                                            <div className="stepIndicator">PRODUCT DESCRIPTION</div>
                                            <div className="stepIndicator">VARIATIONS</div>
                                            <div className="stepIndicator">PRODUCT DETAILS</div>
                                        </div>
                                        {/* end step indicators */}
                                        {/* step three */}
                                        <div className="step4">
                                            <div className="main_detail_identity pt-0">
                                                <div className="please_fill_main">
                                                    <h6>
                                                        Please Fill the below details to complete Product Details
                                                    </h6>
                                                </div>
                                                <div className="row mt-3">
                                                    <div className="col-lg-3">
                                                        <div className="form-group log_in_main">
                                                            <label>Model Name</label>
                                                            <input
                                                                type="text"
                                                                defaultValue=""
                                                                className="form-control"
                                                                placeholder="Enter Model Name"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-3">
                                                        <div className="form-group log_in_main">
                                                            <label>Date of Exchange/Return</label>
                                                            <input
                                                                type="text"
                                                                defaultValue=""
                                                                className="form-control"
                                                                placeholder="Exchange Days"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-3">
                                                        <div className="form-group log_in_main">
                                                            <label>Delivery Charge</label>
                                                            <input
                                                                type="text"
                                                                defaultValue=""
                                                                className="form-control"
                                                                placeholder="Delivery Charge"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12">
                                                        <div className="form-group log_in_main">
                                                            <label>Manufacturing Information</label>
                                                            <CKEditor
                                                                name="description"
                                                                editor={ClassicEditor}
                                                                config={CKEDITORConfig}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-footer d-flex main_detail_save">
                                                <Link
                                                    type="button"
                                                    id="prevBtn"
                                                    className="btn btn-primary next-step"
                                                    to="/seller/products/add/variation"
                                                >
                                                    Previous
                                                </Link>
                                                <Link
                                                    type="button"
                                                    id="nextBtn"
                                                    className="btn btn-primary next-step"
                                                    to="/seller/products/review"
                                                // onClick="nextPrev(1)"
                                                >
                                                    Review
                                                </Link>
                                            </div>
                                        </div>
                                        {/* step four */}
                                    </form>
                                </div>
                            </div>
                        </div>
                        {/* Modal */}
                        <div
                            className="modal fade"
                            id="staticBackdrop"
                            data-bs-backdrop="static"
                            data-bs-keyboard="false"
                            tabIndex={-1}
                            aria-labelledby="staticBackdropLabel"
                            aria-hidden="true"
                        >
                            <div className="modal-dialog dasbord-main_model">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title" id="staticBackdropLabel">
                                            Edit images
                                        </h5>
                                        <button
                                            type="button"
                                            className="btn-close"
                                            data-bs-dismiss="modal"
                                            aria-label="Close"
                                        />
                                    </div>
                                    <div className="modal-body">
                                        <div className="main_detail_head">
                                            <h6>Product images style guideline</h6>
                                            <p>
                                                Uploaded:0 of 9 images. Maximum 9 images are allowed. you can
                                                arrange the order after uploading.
                                            </p>
                                        </div>
                                        <div className="upload-box-main">
                                            <div className="upload-box-inner">
                                                <span>
                                                    <span className="file-name" />
                                                    <input type="file" className="file-element" />
                                                    <img
                                                        className="main-img-icon"
                                                        src="images/cemra.png"
                                                        alt=""
                                                    />
                                                    <h3>Update</h3>
                                                </span>
                                                <div className="main_hading_popup">
                                                    <h3>MAIN</h3>
                                                </div>
                                            </div>
                                            <div className="upload-box-inner">
                                                <span>
                                                    <span className="file-name" />
                                                    <input type="file" className="file-element" />
                                                    <img
                                                        className="main-img-icon"
                                                        src="images/cemra.png"
                                                        alt=""
                                                    />
                                                    <h3>Update</h3>
                                                </span>
                                            </div>
                                            <div className="upload-box-inner">
                                                <span>
                                                    <span className="file-name" />
                                                    <input type="file" className="file-element" />
                                                    <img
                                                        className="main-img-icon"
                                                        src="images/cemra.png"
                                                        alt=""
                                                    />
                                                    <h3>Update</h3>
                                                </span>
                                            </div>
                                            <div className="upload-box-inner">
                                                <span>
                                                    <span className="file-name" />
                                                    <input type="file" className="file-element" />
                                                    <img
                                                        className="main-img-icon"
                                                        src="images/cemra.png"
                                                        alt=""
                                                    />
                                                    <h3>Update</h3>
                                                </span>
                                            </div>
                                            <div className="upload-box-inner">
                                                <span>
                                                    <span className="file-name" />
                                                    <input type="file" className="file-element" />
                                                    <img
                                                        className="main-img-icon"
                                                        src="images/cemra.png"
                                                        alt=""
                                                    />
                                                    <h3>Update</h3>
                                                </span>
                                            </div>
                                            <div className="upload-box-inner">
                                                <span>
                                                    <span className="file-name" />
                                                    <input type="file" className="file-element" />
                                                    <img
                                                        className="main-img-icon"
                                                        src="images/cemra.png"
                                                        alt=""
                                                    />
                                                    <h3>Update</h3>
                                                </span>
                                            </div>
                                            <div className="upload-box-inner">
                                                <span>
                                                    <span className="file-name" />
                                                    <input type="file" className="file-element" />
                                                    <img
                                                        className="main-img-icon"
                                                        src="images/cemra.png"
                                                        alt=""
                                                    />
                                                    <h3>Update</h3>
                                                </span>
                                            </div>
                                            <div className="upload-box-inner">
                                                <span>
                                                    <span className="file-name" />
                                                    <input type="file" className="file-element" />
                                                    <img
                                                        className="main-img-icon"
                                                        src="images/cemra.png"
                                                        alt=""
                                                    />
                                                    <h3>Update</h3>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="modal-footer">
                                        <button
                                            type="button"
                                            className="btn btn-secondary"
                                            data-bs-dismiss="modal"
                                        >
                                            Close
                                        </button>
                                        <button type="button" className="btn btn-primary">
                                            Add to Listing
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>
                </div>
            </div>

        </>
    )
}

export default ProductDetails