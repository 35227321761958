let host = process.env.REACT_APP_API_SERVER;

// Home Banner
export const fetchHomeBannerRequest = () => ({
    type: "FETCH_HOME_BANNER_REQUEST",
});

export const fetchHomeBannerSuccess = (data) => ({
    type: "FETCH_HOME_BANNER_SUCCESS",
    payload: data,
});

export const fetchHomeBannerFailure = (error) => ({
    type: "FETCH_HOME_BANNER_FAILURE",
    payload: error,
});

export const getHomeBanner = () =>

    async (dispatch) => {

        dispatch(fetchHomeBannerRequest());
        try {


            let response = await fetch(`${host}/master/get-home-banners`, { method: "GET" });
            // console.log("response", await response.json());


            if (!response.ok) {
                throw new Error('Please enter correct URL.');
            }
            const data = await response.json();
            // console.log('datais', data);
            let desired_data = data?.data;
            // console.log('desired_data', desired_data)

            dispatch(fetchHomeBannerSuccess(desired_data));

        } catch (error) {
            dispatch(fetchHomeBannerFailure(error.message));
        }
    }



// Home Second Section
export const fetchHomeSecondSectionRequest = () => ({
    type: "FETCH_HOME_SECOND_SECTION_REQUEST",
});

export const fetchHomeSecondSectionSuccess = (data) => ({
    type: "FETCH_HOME_SECOND_SECTION_SUCCESS",
    payload: data,
});

export const fetchHomeSecondSectionFailure = (error) => ({
    type: "FETCH_HOME_SECOND_SECTION_FAILURE",
    payload: error,
});

export const getHomeSecondSection = () =>

    async (dispatch) => {

        dispatch(fetchHomeSecondSectionRequest());
        try {


            let response = await fetch(`${host}/master/get-home-second-section`, { method: "GET" });
            // console.log("response", await response.json());


            if (!response.ok) {
                throw new Error('Please enter correct URL.');
            }
            const data = await response.json();
            // console.log('datais', data);
            let desired_data = data?.data;
            // console.log('desired_data', desired_data)

            dispatch(fetchHomeSecondSectionSuccess(desired_data));

        } catch (error) {
            dispatch(fetchHomeSecondSectionFailure(error.message));
        }
    }


// Home Four Section
export const fetchHomeFourSectionRequest = () => ({
    type: "FETCH_HOME_FOUR_SECTION_REQUEST",
});

export const fetchHomeFourSectionSuccess = (data) => ({
    type: "FETCH_HOME_FOUR_SECTION_SUCCESS",
    payload: data,
});

export const fetchHomeFourSectionFailure = (error) => ({
    type: "FETCH_HOME_FOUR_SECTION_FAILURE",
    payload: error,
});

export const getHomeFourSection = () =>

    async (dispatch) => {

        dispatch(fetchHomeFourSectionRequest());
        try {


            let response = await fetch(`${host}/master/get-home-four-section`, { method: "GET" });
            // console.log("response", await response.json());


            if (!response.ok) {
                throw new Error('Please enter correct URL.');
            }
            const data = await response.json();
            // console.log('datais', data);
            let desired_data = data?.data;
            // console.log('desired_data', desired_data)

            dispatch(fetchHomeFourSectionSuccess(desired_data));

        } catch (error) {
            dispatch(fetchHomeFourSectionFailure(error.message));
        }
    }


// Home Shop By Category
export const fetchHomeShopByCategorySectionRequest = () => ({
    type: "FETCH_HOME_SHOP_BY_CATEGORY_SECTION_REQUEST",
});

export const fetchHomeShopByCategorySectionSuccess = (data) => ({
    type: "FETCH_HOME_SHOP_BY_CATEGORY_SECTION_SUCCESS",
    payload: data,
});

export const fetchHomeShopByCategorySectionFailure = (error) => ({
    type: "FETCH_HOME_SHOP_BY_CATEGORY_SECTION_FAILURE",
    payload: error,
});

export const getHomeShopByCategorySection = () =>

    async (dispatch) => {

        dispatch(fetchHomeShopByCategorySectionRequest());
        try {


            let response = await fetch(`${host}/master/get-sub-category`, { method: "GET" });
            // console.log("response", await response.json());


            if (!response.ok) {
                throw new Error('Please enter correct URL.');
            }
            const data = await response.json();
            // console.log('datais', data);
            let desired_data = data?.data;
            // console.log('desired_data', desired_data)

            dispatch(fetchHomeShopByCategorySectionSuccess(desired_data));

        } catch (error) {
            dispatch(fetchHomeShopByCategorySectionFailure(error.message));
        }
    }

// Home Second Slider
export const fetchHomeSecondSliderRequest = () => ({
    type: "FETCH_HOME_SECOND_SLIDER_REQUEST",
});

export const fetchHomeSecondSliderSuccess = (data) => ({
    type: "FETCH_HOME_SECOND_SLIDER_SUCCESS",
    payload: data,
});

export const fetchHomeSecondSliderFailure = (error) => ({
    type: "FETCH_HOME_SECOND_SLIDER_FAILURE",
    payload: error,
});

export const getHomeSecondSlider = () =>

    async (dispatch) => {

        dispatch(fetchHomeSecondSliderRequest());
        try {


            let response = await fetch(`${host}/master/get-home-second-slider`, { method: "GET" });
            // console.log("response", await response.json());


            if (!response.ok) {
                throw new Error('Please enter correct URL.');
            }
            const data = await response.json();
            // console.log('datais', data);
            let desired_data = data?.data;
            // console.log('desired_data', desired_data)

            dispatch(fetchHomeSecondSliderSuccess(desired_data));

        } catch (error) {
            dispatch(fetchHomeSecondSliderFailure(error.message));
        }
    }

// Home Trending now
export const fetchHomeTrendingNowRequest = () => ({
    type: "FETCH_HOME_TRENDING_NOW_REQUEST",
});

export const fetchHomeTrendingNowSuccess = (data) => ({
    type: "FETCH_HOME_TRENDING_NOW_SUCCESS",
    payload: data,
});

export const fetchHomeTrendingNowFailure = (error) => ({
    type: "FETCH_HOME_TRENDING_NOW_FAILURE",
    payload: error,
});

export const getHomeTrendingNow = (token) =>

    async (dispatch) => {

        dispatch(fetchHomeTrendingNowRequest());
        try {

            let response
            if (token) {
                response = await fetch(`${host}/master/get-home-trending-now`, { method: "GET", headers: { "auth-token": token } });
            }
            else {
                response = await fetch(`${host}/master/get-home-trending-now`, { method: "GET" });
                // console.log("response", await response.json());
            }


            // console.log("response", await response.json());


            if (!response.ok) {
                throw new Error('Please enter correct URL.');
            }
            const data = await response.json();
            // console.log('datais', data);
            let desired_data = data?.data;
            // console.log('desired_data', desired_data)

            dispatch(fetchHomeTrendingNowSuccess(desired_data));

        } catch (error) {
            dispatch(fetchHomeTrendingNowFailure(error.message));
        }
    }

// Home Last Section
export const fetchHomeLastSectionRequest = () => ({
    type: "FETCH_HOME_LAST_SECTION_REQUEST",
});

export const fetchHomeLastSectionSuccess = (data) => ({
    type: "FETCH_HOME_LAST_SECTION_SUCCESS",
    payload: data,
});

export const fetchHomeLastSectionFailure = (error) => ({
    type: "FETCH_HOME_LAST_SECTION_FAILURE",
    payload: error,
});

export const getHomeLastSection = () =>

    async (dispatch) => {

        dispatch(fetchHomeLastSectionRequest());
        try {


            let response = await fetch(`${host}/master/get-home-last-section`, { method: "GET" });
            // console.log("response", await response.json());


            if (!response.ok) {
                throw new Error('Please enter correct URL.');
            }
            const data = await response.json();
            // console.log('datais', data);
            let desired_data = data?.data;
            // console.log('desired_data', desired_data)

            dispatch(fetchHomeLastSectionSuccess(desired_data));

        } catch (error) {
            dispatch(fetchHomeLastSectionFailure(error.message));
        }
    }


// Buyer Profile Details
export const fetchBuyerProfileDetailsRequest = () => ({
    type: "FETCH_BUYER_PROFILE_DETAILS_REQUEST",
});

export const fetchBuyerProfileDetailsSuccess = (data) => ({
    type: "FETCH_BUYER_PROFILE_DETAILS_SUCCESS",
    payload: data,
});

export const fetchBuyerProfileDetailsFailure = (error) => ({
    type: "FETCH_BUYER_PROFILE_DETAILS_FAILURE",
    payload: error,
});

export const getBuyerProfileDetails = (token) =>

    async (dispatch) => {

        dispatch(fetchBuyerProfileDetailsRequest());
        try {


            let response = await fetch(`${host}/buyer/buyer-profile-details`, { method: "GET", headers: { "auth-token": token } });
            // console.log("response", await response.json());


            if (!response.ok) {
                throw new Error('Please enter correct URL.');
            }
            const data = await response.json();
            // console.log('datais', data);
            // let desired_data = data?.data;
            // console.log('desired_data', desired_data)

            dispatch(fetchBuyerProfileDetailsSuccess(data));

        } catch (error) {
            dispatch(fetchBuyerProfileDetailsFailure(error.message));
        }
    }


// Buyer Profile Details
export const fetchBuyerNavProfileDetailsRequest = () => ({
    type: "FETCH_BUYER_NAV_PROFILE_DETAILS_REQUEST",
});

export const fetchBuyerNavProfileDetailsSuccess = (data) => ({
    type: "FETCH_BUYER_NAV_PROFILE_DETAILS_SUCCESS",
    payload: data,
});

export const fetchBuyerNavProfileDetailsFailure = (error) => ({
    type: "FETCH_BUYER_NAV_PROFILE_DETAILS_FAILURE",
    payload: error,
});

export const getBuyerNavProfileDetails = (token) =>

    async (dispatch) => {

        dispatch(fetchBuyerNavProfileDetailsRequest());
        try {


            let response = await fetch(`${host}/buyer/buyer-nav-profile-details`, { method: "GET", headers: { "auth-token": token } });
            // console.log("response", await response.json());


            if (!response.ok) {
                throw new Error('Please enter correct URL.');
            }
            const data = await response.json();
            // console.log('datais', data);
            let desired_data = data?.data;
            // console.log('desired_data', desired_data)

            dispatch(fetchBuyerNavProfileDetailsSuccess(desired_data));

        } catch (error) {
            dispatch(fetchBuyerNavProfileDetailsFailure(error.message));
        }
    }


// Buyer Profile Details
export const fetchBuyerMegaMenuDetailsRequest = () => ({
    type: "FETCH_BUYER_MEGA_MENU_REQUEST",
});

export const fetchBuyerMegaMenuDetailsSuccess = (data) => ({
    type: "FETCH_BUYER_MEGA_MENU_SUCCESS",
    payload: data,
});

export const fetchBuyerMegaMenuDetailsFailure = (error) => ({
    type: "FETCH_BUYER_MEGA_MENU_FAILURE",
    payload: error,
});

export const getBuyerMegaMenuDetails = () =>

    async (dispatch) => {

        dispatch(fetchBuyerMegaMenuDetailsRequest());
        try {


            let response = await fetch(`${host}/master/get-mega-menu-data`, { method: "GET" });
            // console.log("response", await response.json());


            if (!response.ok) {
                throw new Error('Please enter correct URL.');
            }
            const data = await response.json();
            // console.log('datais', data);
            let desired_data = data?.data;
            // console.log('desired_data', desired_data)

            dispatch(fetchBuyerMegaMenuDetailsSuccess(desired_data));

        } catch (error) {
            dispatch(fetchBuyerMegaMenuDetailsFailure(error.message));
        }
    }

// Buyer Address List
export const fetchBuyerAddressListRequest = () => ({
    type: "FETCH_BUYER_ADDRESS_LIST_REQUEST",
});

export const fetchBuyerAddressListSuccess = (data) => ({
    type: "FETCH_BUYER_ADDRESS_LIST_SUCCESS",
    payload: data,
});

export const fetchBuyerAddressListFailure = (error) => ({
    type: "FETCH_BUYER_ADDRESS_LIST_FAILURE",
    payload: error,
});

export const getBuyerAddressList = (token) =>

    async (dispatch) => {

        dispatch(fetchBuyerAddressListRequest());
        try {


            let response = await fetch(`${host}/buyer/buyer-address-list`, { method: "GET", headers: { "auth-token": token } });
            // console.log("response", await response.json());


            if (!response.ok) {
                throw new Error('Please enter correct URL.');
            }
            const data = await response.json();

            // let desired_data = data?.data;
            // console.log('desired_data', desired_data)

            dispatch(fetchBuyerAddressListSuccess(data));

        } catch (error) {
            dispatch(fetchBuyerAddressListFailure(error.message));
        }
    }

// Buyer Address List
export const fetchBuyerAddressEditRequest = () => ({
    type: "FETCH_BUYER_ADDRESS_EDIT_REQUEST",
});

export const fetchBuyerAddressEditSuccess = (data) => ({
    type: "FETCH_BUYER_ADDRESS_EDIT_SUCCESS",
    payload: data,
});

export const fetchBuyerAddressEditFailure = (error) => ({
    type: "FETCH_BUYER_ADDRESS_EDIT_FAILURE",
    payload: error,
});

export const getBuyerAddressEdit = (token, slug) =>

    async (dispatch) => {

        dispatch(fetchBuyerAddressEditRequest());
        try {


            let response = await fetch(`${host}/buyer/buyer-address-edit?slug=${slug}`, { method: "GET", headers: { "auth-token": token } });
            // console.log("response", await response.json());


            if (!response.ok) {
                throw new Error('Please enter correct URL.');
            }
            const data = await response.json();
            console.log('datais', data);
            let desired_data = data?.data;
            // console.log('desired_data', desired_data)

            dispatch(fetchBuyerAddressEditSuccess(desired_data));

        } catch (error) {
            dispatch(fetchBuyerAddressEditFailure(error.message));
        }
    }


// Product Data
export const fetchProductDataRequest = () => ({
    type: "FETCH_PRODUCT_DATA_REQUEST",
});

export const fetchProductDataSuccess = (data) => ({
    type: "FETCH_PRODUCT_DATA_SUCCESS",
    payload: data,
});

export const fetchProductDataFailure = (error) => ({
    type: "FETCH_PRODUCT_DATA_FAILURE",
    payload: error,
});

export const getProductData = (token, type, slug, query) =>

    async (dispatch) => {

        dispatch(fetchProductDataRequest());
        try {
            let response
            if (token) {
                response = await fetch(`${host}/master/get-product-listing?${type}=${slug}&${query}`, { method: "GET", headers: { "auth-token": token } });
            }
            else {
                response = await fetch(`${host}/master/get-product-listing?${type}=${slug}&${query}`, { method: "GET" });
                // console.log("response", await response.json());
            }

            // console.log("response", await response.json());


            if (!response.ok) {
                throw new Error('Please enter correct URL.');
            }
            const data = await response.json();
            // console.log('datais', data);
            let desired_data = data;
            // console.log('desired_data', desired_data)

            dispatch(fetchProductDataSuccess(desired_data));

        } catch (error) {
            dispatch(fetchProductDataFailure(error.message));
        }
    }


//Home Four Section Product Data
export const fetchHomeFourSectionProductDataRequest = () => ({
    type: "FETCH_HOME_FOUR_SECTION_PRODUCT_DATA_REQUEST",
});

export const fetchHomeFourSectionProductDataSuccess = (data) => ({
    type: "FETCH_HOME_FOUR_SECTION_PRODUCT_DATA_SUCCESS",
    payload: data,
});

export const fetchHomeFourSectionProductDataFailure = (error) => ({
    type: "FETCH_HOME_FOUR_SECTION_PRODUCT_DATA_FAILURE",
    payload: error,
});

export const getHomeFourSectionProductData = (token, slug, query) =>

    async (dispatch) => {

        dispatch(fetchHomeFourSectionProductDataRequest());
        try {
            let response
            if (token) {
                response = await fetch(`${host}/master/get-home-four-section-product-listing?slug=${slug}&${query}`, { method: "GET", headers: { "auth-token": token } });
            }
            else {
                response = await fetch(`${host}/master/get-home-four-section-product-listing?slug=${slug}&${query}`, { method: "GET" });
            }

            // console.log("response", await response.json());


            if (!response.ok) {
                throw new Error('Please enter correct URL.');
            }
            const data = await response.json();
            // console.log('datais', data);
            let desired_data = data;
            // console.log('desired_data', desired_data)

            dispatch(fetchHomeFourSectionProductDataSuccess(desired_data));

        } catch (error) {
            dispatch(fetchHomeFourSectionProductDataFailure(error.message));
        }
    }

//Home Last Section Product Data
export const fetchHomeLastSectionProductDataRequest = () => ({
    type: "FETCH_HOME_LAST_SECTION_PRODUCT_DATA_REQUEST",
});

export const fetchHomeLastSectionProductDataSuccess = (data) => ({
    type: "FETCH_HOME_LAST_SECTION_PRODUCT_DATA_SUCCESS",
    payload: data,
});

export const fetchHomeLastSectionProductDataFailure = (error) => ({
    type: "FETCH_HOME_LAST_SECTION_PRODUCT_DATA_FAILURE",
    payload: error,
});

export const getHomeLastSectionProductData = (token, query) =>

    async (dispatch) => {

        dispatch(fetchHomeLastSectionProductDataRequest());
        try {
            let response
            if (token) {
                response = await fetch(`${host}/master/get-home-last-section-product-listing?${query}`, { method: "GET", headers: { "auth-token": token } });
            }
            else {
                response = await fetch(`${host}/master/get-home-last-section-product-listing?&${query}`, { method: "GET" });
            }


            if (!response.ok) {
                throw new Error('Please enter correct URL.');
            }
            const data = await response.json();
            // console.log('datais', data);
            let desired_data = data;
            // console.log('desired_data', desired_data)

            dispatch(fetchHomeLastSectionProductDataSuccess(desired_data));

        } catch (error) {
            dispatch(fetchHomeLastSectionProductDataFailure(error.message));
        }
    }


// home new release
export const fetchHomeNewReleaseProductDataRequest = () => ({
    type: "FETCH_HOME_NEW_RELEASE_PRODUCT_DATA_REQUEST",
});

export const fetchHomeNewReleaseProductDataSuccess = (data) => ({
    type: "FETCH_HOME_NEW_RELEASE_PRODUCT_DATA_SUCCESS",
    payload: data,
});

export const fetchHomeNewReleaseProductDataFailure = (error) => ({
    type: "FETCH_HOME_NEW_RELEASE_PRODUCT_DATA_FAILURE",
    payload: error,
});

export const getHomeNewReleaseProductData = (token, query) =>

    async (dispatch) => {

        dispatch(fetchHomeNewReleaseProductDataRequest());
        try {
            let response
            if (token) {
                response = await fetch(`${host}/master/get-product-listing-new-release?${query}`, { method: "GET", headers: { "auth-token": token } });
            }
            else {
                response = await fetch(`${host}/master/get-product-listing-new-release?${query}`, { method: "GET" });
                // console.log("response", await response.json());
            }



            if (!response.ok) {
                throw new Error('Please enter correct URL.');
            }
            const data = await response.json();
            // console.log('datais', data);
            let desired_data = data;
            // console.log('desired_data', desired_data)

            dispatch(fetchHomeNewReleaseProductDataSuccess(desired_data));

        } catch (error) {
            dispatch(fetchHomeNewReleaseProductDataFailure(error.message));
        }
    }

// Product Detail Data
export const fetchProductDetailDataRequest = () => ({
    type: "FETCH_PRODUCT_DETAIL_DATA_REQUEST",
});

export const fetchProductDetailDataSuccess = (data) => ({
    type: "FETCH_PRODUCT_DETAIL_DATA_SUCCESS",
    payload: data,
});

export const fetchProductDetailDataFailure = (error) => ({
    type: "FETCH_PRODUCT_DETAIL_DATA_FAILURE",
    payload: error,
});

export const getProductDetailData = (slug, colorId, sizeId, type, token) =>

    async (dispatch) => {

        dispatch(fetchProductDetailDataRequest());
        try {
            let config
            if (type == 'auth-token') {
                config = { method: "GET", headers: { 'auth-token': token } };
            }
            else if (type == 'guest-token') {
                config = { method: "GET", headers: { 'guest-token': token } };
            }
            else {
                console.log('type not provided')
            }
            let response = await fetch(`${host}/master/get-product-details?slug=${slug}&colorId=${colorId}&sizeId=${sizeId}`, config);
            // console.log("response", await response.json());


            if (!response.ok) {
                throw new Error('Please enter correct URL.');
            }
            const data = await response.json();
            // console.log('datais', data);
            let desired_data = data?.data;
            // console.log('desired_data', desired_data)

            dispatch(fetchProductDetailDataSuccess(desired_data));

        } catch (error) {
            dispatch(fetchProductDetailDataFailure(error.message));
        }
    }


// Product Detail Data
export const fetchProductVariationDetailDataRequest = () => ({
    type: "FETCH_PRODUCT_VARIENT_DETAIL_DATA_REQUEST",
});

export const fetchProductVariationDetailDataSuccess = (data) => ({
    type: "FETCH_PRODUCT_VARIENT_DETAIL_DATA_SUCCESS",
    payload: data,
});

export const fetchProductVariationDetailDataFailure = (error) => ({
    type: "FETCH_PRODUCT_VARIENT_DETAIL_DATA_FAILURE",
    payload: error,
});

export const getProductVariationDetailData = (slug, colourId, sizeId) => async (dispatch) => {
    dispatch(fetchProductVariationDetailDataRequest());

    try {
        // Construct the query parameters based on colorId and sizeId
        const queryParams = new URLSearchParams({ slug, colourId, sizeId }).toString();

        // Fetch data from the server
        const response = await fetch(`${host}/master/get-product-variant-details?slug=${slug}&${queryParams}`, { method: "GET" });

        if (!response.ok) {
            throw new Error('Please enter the correct URL.');
        }

        const data = await response.json();

        dispatch(fetchProductVariationDetailDataSuccess(data.data));
    } catch (error) {
        dispatch(fetchProductVariationDetailDataFailure(error.message));
    }
};

// Product Detail Data
export const fetchProductImageDataRequest = () => ({
    type: "FETCH_PRODUCT_IMAGE_DATA_REQUEST",
});

export const fetchProductImageDataSuccess = (data) => ({
    type: "FETCH_PRODUCT_IMAGE_DATA_SUCCESS",
    payload: data,
});

export const fetchProductImageDataFailure = (error) => ({
    type: "FETCH_PRODUCT_IMAGE_DATA_FAILURE",
    payload: error,
});

export const getProductImageData = (slug, colourId, sizeId) => async (dispatch) => {
    dispatch(fetchProductImageDataRequest());

    try {
        // Construct the query parameters based on colorId and sizeId
        const queryParams = new URLSearchParams({ slug, colourId, sizeId }).toString();

        // Fetch data from the server
        const response = await fetch(`${host}/master/get-product-image?${queryParams}`, { method: "GET" });

        if (!response.ok) {
            throw new Error('Please enter the correct URL.');
        }

        const data = await response.json();

        dispatch(fetchProductImageDataSuccess(data.data));
    } catch (error) {
        dispatch(fetchProductImageDataFailure(error.message));
    }
};



// Product Size and Colour Data
export const fetchProductVariantColourSizeDataRequest = () => ({
    type: "FETCH_PRODUCT_VARIENT_SIZE_COLOUR_DATA_REQUEST",
});

export const fetchProductVariantColourSizeDataSuccess = (data) => ({
    type: "FETCH_PRODUCT_VARIENT_SIZE_COLOUR_DATA_SUCCESS",
    payload: data,
});

export const fetchProductVariantColourSizeDataFailure = (error) => ({
    type: "FETCH_PRODUCT_VARIENT_SIZE_COLOUR_DATA_FAILURE",
    payload: error,
});

export const getProductVariantColourSizeData = (slug) => async (dispatch) => {
    dispatch(fetchProductVariantColourSizeDataRequest());

    try {

        // Fetch data from the server
        const response = await fetch(`${host}/master/get-product-varient-size-colour-data?slug=${slug}`, { method: "GET" });

        if (!response.ok) {
            throw new Error('Please enter the correct URL.');
        }

        const data = await response.json();

        dispatch(fetchProductVariantColourSizeDataSuccess(data.data));
    } catch (error) {
        dispatch(fetchProductVariantColourSizeDataFailure(error.message));
    }
};



// Frequently brought together
export const fetchFrequentlyBroughtTogetherDataRequest = () => ({
    type: "FETCH_FREQUENTLY_BROUGHT_TOGETHER_DATA_REQUEST",
});

export const fetchFrequentlyBroughtTogetherDataSuccess = (data) => ({
    type: "FETCH_FREQUENTLY_BROUGHT_TOGETHER_DATA_SUCCESS",
    payload: data,
});

export const fetchFrequentlyBroughtTogetherDataFailure = (error) => ({
    type: "FETCH_FREQUENTLY_BROUGHT_TOGETHER_DATA_FAILURE",
    payload: error,
});

export const getFrequentlyBroughtTogetherData = (slug) =>

    async (dispatch) => {

        dispatch(fetchFrequentlyBroughtTogetherDataRequest());
        try {
            let response = await fetch(`${host}/master/get-frequently-brought-together-list?slug=${slug}`, { method: "GET" });
            // console.log("response", await response.json());


            if (!response.ok) {
                throw new Error('Please enter correct URL.');
            }
            const data = await response.json();
            // console.log('datais', data);
            let desired_data = data?.data;
            // console.log('desired_data', desired_data)

            dispatch(fetchFrequentlyBroughtTogetherDataSuccess(desired_data));

        } catch (error) {
            dispatch(fetchFrequentlyBroughtTogetherDataFailure(error.message));
        }
    }


// Related products
export const fetchRelatedProductsDataRequest = () => ({
    type: "FETCH_RELATED_PRODUCTS_DATA_REQUEST",
});

export const fetchRelatedProductsDataSuccess = (data) => ({
    type: "FETCH_RELATED_PRODUCTS_DATA_SUCCESS",
    payload: data,
});

export const fetchRelatedProductsDataFailure = (error) => ({
    type: "FETCH_RELATED_PRODUCTS_DATA_FAILURE",
    payload: error,
});

export const getRelatedProductsData = (slug, token) =>

    async (dispatch) => {

        dispatch(fetchRelatedProductsDataRequest());
        try {
            let response
            if (token) {
                response = await fetch(`${host}/master/get-related-products-list?slug=${slug}`, { method: "GET", headers: { "auth-token": token } });
            }
            else {
                response = await fetch(`${host}/master/get-related-products-list?slug=${slug}`, { method: "GET" });
                // console.log("response", await response.json());
            }

            if (!response.ok) {
                throw new Error('Please enter correct URL.');
            }
            const data = await response.json();
            // console.log('datais', data);
            let desired_data = data?.data;
            // console.log('desired_data', desired_data)

            dispatch(fetchRelatedProductsDataSuccess(desired_data));

        } catch (error) {
            dispatch(fetchRelatedProductsDataFailure(error.message));
        }
    }


// Buyer Wish List
export const fetchBuyerWishListRequest = () => ({
    type: "FETCH_BUYER_WISH_LIST_REQUEST",
});

export const fetchBuyerWishListSuccess = (data) => ({
    type: "FETCH_BUYER_WISH_LIST_SUCCESS",
    payload: data,
});

export const fetchBuyerWishListFailure = (error) => ({
    type: "FETCH_BUYER_WISH_LIST_FAILURE",
    payload: error,
});


export const getBuyerWishList = (token) =>

    async (dispatch) => {

        dispatch(fetchBuyerWishListRequest());
        try {


            let response = await fetch(`${host}/buyer/buyer-get-wishlist-data`, { method: "GET", headers: { "auth-token": token } });
            // console.log("response", await response.json());


            if (!response.ok) {
                throw new Error('Please enter correct URL.');
            }
            const data = await response.json();
            console.log('datais', data);
            // let desired_data = data?.data;
            // console.log('desired_data', desired_data)

            dispatch(fetchBuyerWishListSuccess(data));

        } catch (error) {
            dispatch(fetchBuyerWishListFailure(error.message));
        }
    }


// Buyer Wish List
export const fetchBuyerReviewPageProductDetailRequest = () => ({
    type: "FETCH_BUYER_REVIEW_PAGE_PRODUCT_DETAIL_REQUEST",
});

export const fetchBuyerReviewPageProductDetailSuccess = (data) => ({
    type: "FETCH_BUYER_REVIEW_PAGE_PRODUCT_DETAIL_SUCCESS",
    payload: data,
});

export const fetchBuyerReviewPageProductDetailFailure = (error) => ({
    type: "FETCH_BUYER_REVIEW_PAGE_PRODUCT_DETAIL_FAILURE",
    payload: error,
});


export const getBuyerReviewPageProductDetail = (token, productId, variantId) =>

    async (dispatch) => {

        dispatch(fetchBuyerReviewPageProductDetailRequest());
        try {
            let response

            if (variantId) {
                response = await fetch(`${host}/buyer/buyer-get-product-review-data?productId=${productId}&variantId=${variantId}`, { method: "GET", headers: { "auth-token": token } });
            }
            else {
                response = await fetch(`${host}/buyer/buyer-get-product-review-data?productId=${productId}`, { method: "GET", headers: { "auth-token": token } });
            }

            // console.log("response", await response.json());


            if (!response.ok) {
                throw new Error('Please enter correct URL.');
            }
            const data = await response.json();
            // console.log('datais', data);
            let desired_data = data?.data;
            // console.log('desired_data', desired_data)

            dispatch(fetchBuyerReviewPageProductDetailSuccess(desired_data));

        } catch (error) {
            dispatch(fetchBuyerReviewPageProductDetailFailure(error.message));
        }
    }

// Buyer cart product list
export const fetchBuyerCartProductListRequest = () => ({
    type: "FETCH_BUYER_CART_PRODUCT_LIST_REQUEST",
});

export const fetchBuyerCartProductListSuccess = (data) => ({
    type: "FETCH_BUYER_CART_PRODUCT_LIST_SUCCESS",
    payload: data,
});

export const fetchBuyerCartProductListFailure = (error) => ({
    type: "FETCH_BUYER_CART_PRODUCT_LIST_FAILURE",
    payload: error,
});


export const getBuyerCartProductList = (type, token) =>

    async (dispatch) => {

        dispatch(fetchBuyerCartProductListRequest());
        try {


            let config
            if (type == 'auth-token') {
                config = { method: "GET", headers: { 'auth-token': token } };
            }
            else if (type == 'guest-token') {
                config = { method: "GET", headers: { 'guest-token': token } };
            }
            else {
                console.log('type not provided')
            }
            let response = await fetch(`${host}/buyer/buyer-cart-product-list`, config);
            // console.log("response", await response.json());


            if (!response.ok) {
                throw new Error('Please enter correct URL.');
            }
            const data = await response.json();
            // console.log('datais', data);
            // let desired_data = data?.data;
            // console.log('desired_data', desired_data)

            dispatch(fetchBuyerCartProductListSuccess(data));

        } catch (error) {
            dispatch(fetchBuyerCartProductListFailure(error.message));
        }
    }

// Buyer cart product list
export const fetchBuyerMoreOfWhatYouLikeListRequest = () => ({
    type: "FETCH_BUYER_MORE_OF_WHAT_YOU_LIKE_LIST_REQUEST",
});

export const fetchBuyerMoreOfWhatYouLikeListSuccess = (data) => ({
    type: "FETCH_BUYER_MORE_OF_WHAT_YOU_LIKE_LIST_SUCCESS",
    payload: data,
});

export const fetchBuyerMoreOfWhatYouLikeListFailure = (error) => ({
    type: "FETCH_BUYER_MORE_OF_WHAT_YOU_LIKE_LIST_FAILURE",
    payload: error,
});


export const getBuyerMoreOfWhatYouLikeList = (token) =>

    async (dispatch) => {

        dispatch(fetchBuyerMoreOfWhatYouLikeListRequest());
        try {


            let response = await fetch(`${host}/buyer/get-more-of-what-you-like`, { method: "GET", headers: { "auth-token": token } });
            // console.log("response", await response.json());


            if (!response.ok) {
                throw new Error('Please enter correct URL.');
            }
            const data = await response.json();
            // console.log('datais', data);
            let desired_data = data?.data;
            // console.log('desired_data', desired_data)

            dispatch(fetchBuyerMoreOfWhatYouLikeListSuccess(desired_data));

        } catch (error) {
            dispatch(fetchBuyerMoreOfWhatYouLikeListFailure(error.message));
        }
    }


// Buyer cart product list
export const fetchBuyerOrderListRequest = () => ({
    type: "FETCH_BUYER_ORDER_LIST_REQUEST",
});

export const fetchBuyerOrderListSuccess = (data) => ({
    type: "FETCH_BUYER_ORDER_LIST_SUCCESS",
    payload: data,
});

export const fetchBuyerOrderListFailure = (error) => ({
    type: "FETCH_BUYER_ORDER_LIST_FAILURE",
    payload: error,
});


export const getBuyerOrderList = (token, searchString) =>

    async (dispatch) => {

        dispatch(fetchBuyerOrderListRequest());
        try {


            let response = await fetch(`${host}/buyer/get-all-orders?searchString=${searchString}`, { method: "GET", headers: { "auth-token": token } });
            // console.log("response", await response.json());


            if (!response.ok) {
                throw new Error('Please enter correct URL.');
            }
            const data = await response.json();
            // console.log('datais', data);
            // let desired_data = data?.data;
            // console.log('desired_data', desired_data)

            dispatch(fetchBuyerOrderListSuccess(data));

        } catch (error) {
            dispatch(fetchBuyerOrderListFailure(error.message));
        }
    }


// Buyer cart product list
export const fetchBuyerOrderDetailRequest = () => ({
    type: "FETCH_BUYER_ORDER_DETAIL_REQUEST",
});

export const fetchBuyerOrderDetailSuccess = (data) => ({
    type: "FETCH_BUYER_ORDER_DETAIL_SUCCESS",
    payload: data,
});

export const fetchBuyerOrderDetailFailure = (error) => ({
    type: "FETCH_BUYER_ORDER_DETAIL_FAILURE",
    payload: error,
});


export const getBuyerOrderDetail = (token, orderItemId) =>

    async (dispatch) => {

        dispatch(fetchBuyerOrderDetailRequest());
        try {


            let response = await fetch(`${host}/buyer/get-order-detail-data?orderItemId=${orderItemId}`, { method: "GET", headers: { "auth-token": token } });
            // console.log("response", await response.json());


            if (!response.ok) {
                throw new Error('Please enter correct URL.');
            }
            const data = await response.json();
            // console.log('datais', data);
            let desired_data = data?.data;
            // console.log('desired_data', desired_data)

            dispatch(fetchBuyerOrderDetailSuccess(desired_data));

        } catch (error) {
            dispatch(fetchBuyerOrderDetailFailure(error.message));
        }
    }


// Buyer cart product list
export const fetchBuyerBuyItAgainListRequest = () => ({
    type: "FETCH_BUYER_BUY_IT_AGAIN_LIST_REQUEST",
});

export const fetchBuyerBuyItAgainListSuccess = (data) => ({
    type: "FETCH_BUYER_BUY_IT_AGAIN_LIST_SUCCESS",
    payload: data,
});

export const fetchBuyerBuyItAgainListFailure = (error) => ({
    type: "FETCH_BUYER_BUY_IT_AGAIN_LIST_FAILURE",
    payload: error,
});


export const getBuyerBuyItAgainList = (token, searchString) =>

    async (dispatch) => {

        dispatch(fetchBuyerBuyItAgainListRequest());
        try {


            let response = await fetch(`${host}/buyer/get-buy-again-list?searchString=${searchString}`, { method: "GET", headers: { "auth-token": token } });
            // console.log("response", await response.json());


            if (!response.ok) {
                throw new Error('Please enter correct URL.');
            }
            const data = await response.json();
            // console.log('datais', data);
            let desired_data = data?.data;
            // console.log('desired_data', desired_data)

            dispatch(fetchBuyerBuyItAgainListSuccess(desired_data));

        } catch (error) {
            dispatch(fetchBuyerBuyItAgainListFailure(error.message));
        }
    }
