import React from 'react'
import BuyerHeader from '../BuyerHeader/BuyerHeader'
import BuyerFooter from '../BuyerFooter/BuyerFooter'
import BuyerOrdersContent from './BuyerOrdersContent'

const BuyerOrders = () => {
    return (
        <>
            <BuyerHeader />
            <BuyerOrdersContent />
            <BuyerFooter />
        </>
    )
}

export default BuyerOrders