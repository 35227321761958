import { React, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useFormik } from "formik";
import { toast } from 'react-hot-toast';
import * as Yup from "yup";
import axios from 'axios';
import HourglassLoader from '../../HourglassLoader';

const host = process.env.REACT_APP_API_SERVER
const Forgetpassword = () => {

    const [disablebtn, setdisablebtn] = useState(false);

    const triggerModalOpen = () => {
        const openButton = document.getElementById('modal-open');
        if (openButton) {
            openButton.click();
        }
    };
    const triggerModalClose = () => {
        const closeButton = document.getElementById('close-modal');
        if (closeButton) {
            closeButton.click();
        }

    };

    const [thisEmail, setThisEmail] = useState();
    const navigate = useNavigate();
    const forgePassFormik = useFormik({
        initialValues: {
            email: "",
        },
        validationSchema: Yup.object().shape({
            email: Yup.string().required("Email is required.").matches(/^\w+[-\.\w]*@(?!(?:)\.com$)\w+[-\.\w]*?\.\w{2,4}$/, "Enter a valid E-mail"),
        }),
        onSubmit: async (values, { setSubmitting, resetForm }) => {
            try {
                setSubmitting(true);

                setThisEmail(values.email);
                await axios.post(`${host}/seller/seller-forget-password`, values)
                    .then(function (response) {
                        if (response.status == 200) {
                            setSubmitting(false)
                            toast.success("Otp sent to your mail.Please Verify");
                            resetForm();

                            triggerModalOpen()

                        } else {
                            setSubmitting(false)
                            toast.error(response.data.message);
                        }

                    })
                    .catch(function (error) {
                        setSubmitting(false)
                        toast.error('Error submitting form. Please try again.');
                    })
            }
            catch (error) {
                setSubmitting(false)
                toast.error('Error submitting form. Please try again.');
            }
        }
    })

    const otpVerifyFormik = useFormik({
        initialValues: {
            otp: "",
        },
        validationSchema: Yup.object().shape({
            otp: Yup.string().required("Otp is required.")
        }),
        onSubmit: async (values, { setSubmitting, resetForm }) => {
            try {
                setSubmitting(true);
                values.email = thisEmail;

                await axios.post(`${host}/seller/seller-otp-verify`, values)
                    .then(function (response) {
                        if (response.status == 200) {
                            setSubmitting(false)
                            triggerModalClose();
                            toast.success("Otp Verified.Please Reset Your Password");
                            resetForm();


                            navigate('/seller/change-password', { state: { email: thisEmail } });


                        } else {
                            setSubmitting(false)
                            toast.error(response.data.message);
                        }

                    })
                    .catch(function (error) {
                        setSubmitting(false)
                        console.log(error)
                        toast.error('Error submitting form. Please try again.');
                    })
            }
            catch (error) {
                setSubmitting(false)
                toast.error('Error submitting form. Please try again.');
            }
        }
    })

    const handleResendPassword = async () => {
        setdisablebtn(true)
        try {
            const value = {
                'email': thisEmail
            }

            await axios.post(`${host}/seller/seller-forget-password`, value)
                .then(function (response) {
                    if (response.status == 200) {

                        toast.success("Otp sent to your mail.Please check");
                        setdisablebtn(false)
                    } else {

                        toast.error(response.data.message);
                        setdisablebtn(false)
                    }

                })
                .catch(function (error) {

                    toast.error('Error submitting form. Please try again.');
                    setdisablebtn(false)
                })
        }
        catch (error) {

            toast.error('Error submitting form. Please try again.');
            setdisablebtn(false)
        }
    }

    return (
        <div className='seller'>
            <main className="main_login_section container-fluid">
                <div className="row">
                    <div className="col-lg-7 main_bg_detail">
                        <div className="main_login_detail">
                            <form method='post' onSubmit={forgePassFormik.handleSubmit}>
                                <div className="logo_login">
                                    <img src={`${process.env.PUBLIC_URL}/assets/images/seller_images/login_logo.png`} alt="login_logo" />
                                </div>
                                <div className="detail_form_log detail_form_careate">
                                    <h3>Forgot Your Password?</h3>
                                    <p>Welcome to Afghan Bazar! please enter your details</p>
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="form-group log_in_main">
                                                <label>
                                                    We'll send you a One Time Password on this email.
                                                </label>
                                                <input
                                                    type="text"
                                                    placeholder="Enter your email"
                                                    className="form-control"
                                                    name='email'
                                                    maxLength={50}
                                                    onChange={forgePassFormik.handleChange}
                                                    onBlur={forgePassFormik.handleBlur}
                                                    value={forgePassFormik.values.email}
                                                />
                                                <div className="help-block with-errors">
                                                    {forgePassFormik.touched.email && forgePassFormik.errors.email ? (
                                                        <div className='text-danger'>{forgePassFormik.errors.email}</div>
                                                    ) : null}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="log_in_main_button">
                                    <div className="detail_address_button mt-3">
                                        <button
                                            type='submit' disabled={forgePassFormik.isSubmitting}

                                        >
                                            {forgePassFormik.isSubmitting ?
                                                <HourglassLoader />
                                                :
                                                'Send Otp'
                                            }
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className="col-lg-5 main_login_img">
                        <img src={`${process.env.PUBLIC_URL}/assets/images/seller_images/Create_Account.png`} alt="login" />
                    </div>
                </div>
            </main>
            {/* Button trigger modal */}
            <button type="button" className="btn btn-primary d-none" id='modal-open' data-bs-toggle="modal" data-bs-target="#send_approval">
                Launch demo modal
            </button>
            <div
                className="modal fade"
                id="send_approval"
                data-bs-backdrop="static"
                data-bs-keyboard="false"
                tabIndex={-1}
                aria-labelledby="staticBackdropLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content mt-100">
                        <div className="modal-body">
                            <form method='post' onSubmit={otpVerifyFormik.handleSubmit}>
                                <button type="button" className="btn-close d-none" id='close-modal' data-bs-dismiss="modal" aria-label="Close" />
                                <div className="request_send_for_modul padd_none">
                                    <h6>Verify its you.</h6>
                                    <p>
                                        We have sent verification code to {thisEmail} <br />
                                        Please check your inbox and enter the code below.
                                    </p>
                                    <div className="form-group log_in_main">
                                        <input
                                            type="password"
                                            placeholder="Please enter otp"
                                            className="form-control text-center"
                                            maxLength={4}
                                            name='otp'
                                            onChange={otpVerifyFormik.handleChange}
                                            onBlur={otpVerifyFormik.handleBlur}
                                            value={otpVerifyFormik.values.otp}
                                        />


                                        <div className="help-block with-errors">
                                            {otpVerifyFormik.touched.otp && otpVerifyFormik.errors.otp ? (
                                                <div className='text-danger'>{otpVerifyFormik.errors.otp}</div>
                                            ) : null}
                                        </div>
                                        <span className=' resend_otp_heading my-3 text-start d-block'>Didn't receive OTP?
                                            {disablebtn ?
                                                ''
                                                :
                                                <span className='resend_otp fw-bold ms-1' style={{ cursor: 'pointer' }} onClick={handleResendPassword} disabled={disablebtn}>
                                                    Resend Code</span>
                                            }
                                        </span>
                                    </div>
                                    <div className="main_acsept_butt">
                                        <button

                                            className="btn  btn-warning-main-button"
                                            type='submit' disabled={disablebtn}
                                        >
                                            Verify Otp{" "}
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Forgetpassword