import React from 'react'
import { Link } from 'react-router-dom'
import SellerSidebar from '../../SellerEssentials/SellerSidebar'
import SellerHeader from '../../SellerEssentials/SellerHeader'
import '../../Scss/Seller.css'
const SellerReturnExchangeAccept = () => {
    return (
        <div className='seller'>
            <div className="page-wrapper chiller-theme toggled">
                <SellerSidebar />
                {/* sidebar-wrapper  */}
                <main className="page-content container-fluid">
                    {/* ==================header================== */}
                    <SellerHeader />
                    {/* ==================header================== */}
                    <div className="row ">
                        <div className="col-md-12">
                            <div className="main-nav-Order">
                                <div className="row">
                                    <div className="col-md-7">
                                        <h2>
                                            {" "}
                                            <Link to="/seller/return_exchange">
                                                <img src="/assets/images/seller_images/left_side.png" alt="left" />
                                            </Link>{" "}
                                            Return/Exchange /Return Summary
                                            <span />
                                        </h2>
                                    </div>
                                    <div className="col-md-5">
                                        <div className="search-container">
                                            <input type="text" placeholder="Search..." />
                                            <span>
                                                <i className="fas fa-search" />
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="card_order_main">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="main_tracker pad_detail_text pb-3">
                                                <h4 className="mobile_email_icon">
                                                    Ecommerce Return Summary
                                                </h4>
                                                <h4>Hello, Akash Rathor </h4>
                                                <p>
                                                    We’ve accepted your return request for the item listed
                                                    below. once we receive the item, we’ll issue your refund.
                                                </p>
                                                <p>
                                                    Pick Up Date:{" "}
                                                    <span>Mon, oct 14 between 1:00PM-7:00PM</span>
                                                </p>
                                                <p>
                                                    Pick Up Address:
                                                    <span>
                                                        Lorem ipsum dolor sit amet consectetur. Suspendisse
                                                        risus eget tincidunt morbi.
                                                    </span>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row main_border_padd pb-100">
                                        <div className="col-lg-6">
                                            <div className="main_view_detail">
                                                <img src="/assets/images/seller_images/Rectangle_1.png" alt="rect" />
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="overall_sales height_auto">
                                                <div className="users-table">
                                                    <table className="posts-table">
                                                        <tbody className="td_section_text_order">
                                                            <tr>
                                                                <td width="50%">Order Number:</td>
                                                                <td className="table-text-color" width="50%">
                                                                    000000000000000000
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>Name:</td>
                                                                <td className="table-text-color">Akash Rathor</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Email Id</td>
                                                                <td className="table-text-color">
                                                                    dummy@gmail.com
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>Phone Number</td>
                                                                <td className="table-text-color">+91 1234567891</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Estimated Refund:</td>
                                                                <td className="table-text-color">
                                                                    <span className="main_color_gree">AFN 123</span>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>Reason For Refund</td>
                                                                <td className="table-text-color">
                                                                    Lorem ipsum dolor sit amet consectetur.
                                                                    Suspendisse risus eget tincidunt morbi.
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-12 mt-3">
                                        <div className="text-right main_left_space">
                                            <Link
                                                to="/seller/return_exchange"
                                                className="btn btn-primary next-step"
                                            >
                                                Accept
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>

            {/* ==============script linking start=============== */}
            {/* ==============script linking end=============== */}
        </div>

    )
}

export default SellerReturnExchangeAccept