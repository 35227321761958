import React from 'react'
import { Link } from 'react-router-dom'
import '../scss/Auth.css'
const PasswordChange = () => {
    return (
        <div className="otp">
            <div className="otp_section">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-4 col-lg-4 col-md-2 col-sm-2 col-12" />
                        <div className="col-xl-4 col-lg-4 col-md-8 col-sm-8 col-12">
                            <div className="otp_section_inner">
                                <h2>Password changed</h2>
                                <p>Your Password has been successfully changed.</p>
                                <div className="verify-btn mt-5">
                                    <Link to="/buyer/sign-in">
                                        <button>Log in</button>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-2 col-sm-2 col-12" />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PasswordChange