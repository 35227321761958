import React from 'react'
import { Link } from 'react-router-dom';
import './scss/OtpVerificationEmail.css'

const OtpVerificationEmail = () => {
    return (
        <div className="otp-verification-email">
            {/* Button trigger modal */}
            <button type="button" className="btn btn-primary" id='modal-open' data-bs-toggle="modal" data-bs-target="#exampleModal">
                Launch demo modal
            </button>
            <div>

                {/* Modal */}
                <div className="modal fade otp_section" id="exampleModal" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content otp_section_inner">
                            <div className="modal-header">
                                <h1 className="modal-title fs-5" id="exampleModalLabel">Modal title</h1>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                            </div>
                            <div className="modal-body">
                                <div className="">
                                    <p>We have sent the verification code to your email address</p>
                                    <input
                                        id="otp-first"
                                        type="number"
                                        min={0}
                                        max={9}
                                        step={1}
                                        aria-label="first digit"
                                    />
                                    <input
                                        id="otp-second"
                                        type="number"
                                        min={0}
                                        max={9}
                                        step={1}
                                        aria-label="second digit"
                                    />
                                    <input
                                        id="otp-third"
                                        type="number"
                                        min={0}
                                        max={9}
                                        step={1}
                                        aria-label="third digit"
                                    />
                                    <input
                                        id="otp-fourth"
                                        type="number"
                                        min={0}
                                        max={9}
                                        step={1}
                                        aria-label="fourth digit"
                                    />
                                    <h5>Don't receive OTP code ?</h5>
                                    <Link to="/buyer/reset-password">Resend Code</Link>
                                    {/* <div className="verify-btn">
                                <Link to="/buyer/reset-password">
                                    <button>Verify &amp; Proceed</button>
                                </Link>
                            </div> */}
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                <button type="button" className="btn btn-primary">Save changes</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default OtpVerificationEmail