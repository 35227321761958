import React from 'react'
import SellerSidebar from '../SellerEssentials/SellerSidebar'
import SellerHeader from '../SellerEssentials/SellerHeader'

const SellerAuthenticationVerifyMobile = () => {
    return (
        <div className='seller'>
            <div className="page-wrapper chiller-theme toggled">
                <SellerSidebar />
                {/* sidebar-wrapper  */}
                <main className="page-content container-fluid">
                    {/* ==================header================== */}
                    <SellerHeader />
                    {/* ==================header================== */}
                    <div className="row">
                        <div className="col-md-12">
                            <div className="main-nav-Order">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <h2>
                                            {" "}
                                            <span>Authentication &gt;</span>2 Step Verification
                                        </h2>
                                    </div>
                                </div>
                                <div className="card_order_main">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="main_tracker">
                                                        <h4>
                                                            We Have Sent You a sms no year Registered Mobule
                                                            Numbar{" "}
                                                        </h4>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="product-card-main">
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className="main_detail_form mb-3">
                                                            <div className="form-group">
                                                                <label>Registered Mobile No </label>
                                                                <input
                                                                    type="text"
                                                                    defaultValue="+00 1234567890"
                                                                    placeholder=""
                                                                    className="form-control"
                                                                />
                                                            </div>
                                                            <div className="main_a_detail">
                                                                <a href="#" className="btn btn-primary me-2">
                                                                    Send OTP
                                                                </a>
                                                                <a href="#" className="btn btn-secondary">
                                                                    Resend OTP
                                                                </a>
                                                            </div>
                                                        </div>
                                                        <div className="main_detail_form mb-5">
                                                            <div className="form-group">
                                                                <label>Enter the Six Digit OTP </label>
                                                                <input
                                                                    type="text"
                                                                    defaultValue=""
                                                                    placeholder=""
                                                                    className="form-control"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="main_detail_form mt-2">
                                                            <a href="#" className="btn btn-primary">
                                                                {" "}
                                                                Submit
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>

            {/* ==============script linking start=============== */}
            {/* ==============script linking end=============== */}
        </div>

    )
}

export default SellerAuthenticationVerifyMobile