import { React, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useFormik } from "formik";
import { toast } from 'react-hot-toast';
import * as Yup from "yup";
import axios from 'axios';
import './scss/CreateAccount.css';

import {
    LoginSocialGoogle, LoginSocialLinkedin,
} from 'reactjs-social-login'

import {
    GoogleLoginButton, LinkedInLoginButton,
} from 'react-social-login-buttons'
const host = process.env.REACT_APP_API_SERVER
const client_id = process.env.REACT_APP_GG_APP_CLIENT_ID;
const REDIRECT_URI = 'https://afgan.alobhadev.com/buyer/create-account'

const CreateAccount = () => {

    const [faIcon, setfaIcon] = useState('fa-eye-slash');
    const togglePasswordType = () => {
        let input_pass = document.getElementById('password-field');
        const toggle_pass = document.getElementById("toggle_pass");
        let input_type = input_pass.getAttribute('type');
        if (input_type == "password") {
            setfaIcon('fa-eye');
            input_pass.type = "text";
        } else {
            setfaIcon('fa-eye-slash')
            input_pass.type = "password"
        }
    }

    const [faIcon1, setfaIcon1] = useState('fa-eye-slash');
    const togglePasswordType1 = () => {
        let input_pass = document.getElementById('password-field_1');
        const toggle_pass = document.getElementById("toggle_pass_1");
        let input_type = input_pass.getAttribute('type');
        if (input_type == "password") {
            setfaIcon1('fa-eye');
            input_pass.type = "text";
        } else {
            setfaIcon1('fa-eye-slash')
            input_pass.type = "password"
        }
    }
    const [provider, setProvider] = useState('')
    const [profile, setProfile] = useState(null)
    const navigate = useNavigate();
    const guestToken = localStorage.getItem('guest_token');
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const guestUser = queryParams.get('guest');

    let [initialValues, setInitialValues] = useState({
        name: "",
        phone: "",
        email: "",
        currentAdd: "",
        password: "",
        cpassword: "",
        agreeOnTermsAndConditions: ""
    })

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: Yup.object().shape({
            name: Yup.string().required("Name is required").matches(/^[a-zA-Z .]+$/, "Name can only contain letters."),
            email: Yup.string().required("Email is required.").matches(/^\w+[-\.\w]*@(?!(?:)\.com$)\w+[-\.\w]*?\.\w{2,4}$/, "Enter a valid E-mail"),
            phone: Yup.string().required("Phone is required").matches(/^[0-9]{8,15}$/, "Please enter a valid Mobile Number."),
            currentAdd: Yup.string().required("Current address is required"),
            password: Yup.string()
                .required("Password is required")
                .min(6, "Password must be at least 6 characters long"),
            cpassword: Yup.string()
                .oneOf([Yup.ref('password'), null], 'Passwords must match')
                .required("Confirm password is required"),
            agreeOnTermsAndConditions: Yup.bool().oneOf([true], 'You must agree on terms and conditions').required('You must agree on terms and conditions'),
        }),
        onSubmit: async (values, { setSubmitting, resetForm }) => {

            try {

                setSubmitting(true);





                await axios.post(`${host}/buyer/buyer-registration-direct`, values)
                    .then(function (response) {
                        if (response.status == 200) {
                            setSubmitting(false)

                            toast.success("Buyer Registered Succesfully");
                            resetForm();
                            if (guestUser && guestUser == 'true') {
                                navigate('/buyer/sign-in?guest=true')
                            }
                            else {
                                navigate('/buyer/sign-in')
                            }




                        } else {

                            setSubmitting(false)

                            toast.error(response.data.message);

                        }

                    })
                    .catch(function (error) {


                        setSubmitting(false)
                        toast.error('Error submitting form. Please try again.');

                    })

            }
            catch (error) {


                setSubmitting(false)
                toast.error('Error submitting form. Please try again.');

            }

        }
    });

    if (profile?.email_verified) {


        try {
            const formdata = {
                firstName: profile?.given_name,
                lastName: profile?.family_name,
                email: profile?.email,
            }
            axios.post(`${host}/buyer/buyer-google-login-web`, formdata).
                then((response) => {
                    if (response.status == 200) {

                        localStorage.setItem("buyer_token", response.data.authToken);
                        toast(response?.data?.message);
                        if (guestToken) {
                            updateBuyerIdFromGuestId();
                            localStorage.removeItem("guest_token");
                            if (guestUser && guestUser == 'true') {
                                navigate('/buyer/cart')
                            }
                            else {
                                navigate('/buyer')
                            }
                        }
                        else {
                            navigate('/buyer')
                        }

                    }
                    else {

                        toast(response?.data?.message);
                    }
                })
        }
        catch (error) {

            toast("Error loggin In");

        }
    }

    const updateBuyerIdFromGuestId = () => {
        try {
            const config = {
                headers: {

                    'content-type': 'application/json',
                    "auth-token": localStorage.getItem("buyer_token")
                }
            };
            axios.post(`${host}/buyer/update-guest-token-to-buyer-token`, { guestToken }, config).
                then((response) => {
                    if (response.status == 200) {
                        // toast(response?.data?.message);
                    }
                    else {

                        toast(response?.data?.message);
                    }
                })
        }
        catch (error) {

            toast("Error loggin In");

        }
    }

    const handleSignInNavigate = () => {
        if (guestUser && guestUser == 'true') {
            navigate('/buyer/sign-in?guest=true')
        }
        else {
            navigate('/buyer/sign-in')
        }
    }

    const handleTermsCNavigate = () => {
        if (guestUser && guestUser == 'true') {
            navigate('/buyer/terms-and-conditions?guest=true')
        }
        else {
            navigate('/buyer/terms-and-conditions')
        }
    }

    const handlePrivacyNavigate = () => {
        if (guestUser && guestUser == 'true') {
            navigate('/buyer/privacy-policy?guest=true')
        }
        else {
            navigate('/buyer/privacy-policy')
        }
    }


    return (
        <div className="createaccount">
            <section className="login">
                <div className="container-fluid">
                    <div className="row welcome-login-main">
                        <div className="col-md-6 px-5 form-section py-5">
                            <div className="fields-section">
                                <div className="welcome-back-sec text-center pt-3">
                                    <img src="/assets/images/afgan-bazaar-logo.png" alt="logo" />
                                    {/* <h3>Afghan Bazaar</h3> */}
                                    <h1 className="pt-0">Create Account</h1>
                                    <p>Sign Up for Afghan Bazaar- Start your shopping adventure today</p>
                                </div>
                                <div className="create-account-sec">
                                    <form method='post' onSubmit={formik.handleSubmit}>
                                        <div className="pass-email-field">
                                            <label htmlFor="name">Name<span className='text-danger'>*</span></label>
                                            <input
                                                type="text"
                                                placeholder="Enter your full name"
                                                className="form-control"
                                                name='name'
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.name}
                                            />
                                            <div className="help-block with-errors">
                                                {formik.touched.name && formik.errors.name ? (
                                                    <div className='text-danger'>{formik.errors.name}</div>
                                                ) : null}
                                            </div>
                                            <label htmlFor="email">Email Address<span className='text-danger'>*</span></label>
                                            <input
                                                type="text"
                                                placeholder="Enter your email address"
                                                className="form-control"
                                                name='email'
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.email}
                                            />
                                            <div className="help-block with-errors">
                                                {formik.touched.email && formik.errors.email ? (
                                                    <div className='text-danger'>{formik.errors.email}</div>
                                                ) : null}
                                            </div>
                                            <label htmlFor="name">Phone No.<span className='text-danger'>*</span></label>
                                            <input
                                                type="tel"
                                                placeholder="Enter your phone number"
                                                className="form-control"
                                                name='phone'
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.phone}
                                            />
                                            <div className="help-block with-errors">
                                                {formik.touched.phone && formik.errors.phone ? (
                                                    <div className='text-danger'>{formik.errors.phone}</div>
                                                ) : null}
                                            </div>
                                            <label htmlFor="name">Current Address<span className='text-danger'>*</span></label>
                                            <input
                                                type="text"
                                                placeholder="Enter your current address"
                                                className="form-control"
                                                name='currentAdd'
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.currentAdd}
                                            />
                                            <div className="help-block with-errors">
                                                {formik.touched.currentAdd && formik.errors.currentAdd ? (
                                                    <div className='text-danger'>{formik.errors.currentAdd}</div>
                                                ) : null}
                                            </div>
                                            <label htmlFor="password">Password<span className='text-danger'>*</span></label>
                                            <div className='password_input'>
                                                <input
                                                    id="password-field"
                                                    type="password"
                                                    className="form-control"
                                                    name="password"

                                                    placeholder="Enter your password"
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.password}
                                                />
                                                <i
                                                    toggle="#password-field" className={`fa-regular ${faIcon} field-icon toggle-password `} id="toggle_pass" onClick={togglePasswordType}
                                                />

                                            </div>
                                            <div className="help-block with-errors">
                                                {formik.touched.password && formik.errors.password ? (
                                                    <div className='text-danger'>{formik.errors.password}</div>
                                                ) : null}
                                            </div>
                                            <label htmlFor="password">Confirm Password<span className='text-danger'>*</span></label>
                                            <div className='password_input'>
                                                <input
                                                    id="password-field_1"
                                                    type="password"
                                                    className="form-control"
                                                    name="cpassword"
                                                    placeholder="Enter password again"
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.cpassword}
                                                />
                                                <i
                                                    toggle="#password-field_1"
                                                    className={`fa-regular ${faIcon1} field-icon toggle-password `} id="toggle_pass_1" onClick={togglePasswordType1}
                                                />
                                            </div>
                                            <div className="help-block with-errors">
                                                {formik.touched.cpassword && formik.errors.cpassword ? (
                                                    <div className='text-danger'>{formik.errors.cpassword}</div>
                                                ) : null}
                                            </div>
                                        </div>
                                        <div className="forget-pass ">
                                            <div className="are-you-agree d-flex">
                                                <span>
                                                    <input type="checkbox"
                                                        name="agreeOnTermsAndConditions"
                                                        placeholder="Enter password again"
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        value={formik.values.agreeOnTermsAndConditions} />
                                                </span>
                                                <p>
                                                    Are you agree to Afghan Bazaar <span><a onClick={handleTermsCNavigate} style={{ cursor: 'pointer' }}>Terms & Condition</a></span>{" "}
                                                    and <span><a onClick={handlePrivacyNavigate} style={{ cursor: 'pointer' }}>Privacy Policy</a>.</span>
                                                </p>
                                            </div>
                                            <div className="help-block with-errors">
                                                {formik.touched.agreeOnTermsAndConditions && formik.errors.agreeOnTermsAndConditions ? (
                                                    <div className='text-danger'>{formik.errors.agreeOnTermsAndConditions}</div>
                                                ) : null}
                                            </div>
                                        </div>
                                        <div className="submit-btn text-center">

                                            <button type='submit' disabled={formik.isSubmitting}>Sign up →</button>

                                        </div>
                                    </form>
                                    <div className="create_your_account mt-3">
                                        <p>
                                            Sign In To
                                            <a onClick={handleSignInNavigate} style={{ cursor: 'pointer' }} > Afghan Bazaar account</a>
                                        </p>
                                    </div>
                                    <div className="single-line pt-3">
                                        <div className="or-sec">
                                            <hr />
                                            <p>or</p>
                                        </div>
                                    </div>
                                    <div className="submit-btn-google">
                                        {/* <Link to="home">
                                            <button>
                                                <div className="d-flex ">
                                                    <div>
                                                        <img
                                                            src="/assets/images/Google.png"
                                                            alt="google"
                                                            className="img-fluid"
                                                        />
                                                    </div>
                                                    <span>Sign up with Google</span>
                                                </div>
                                            </button>
                                        </Link> */}
                                        <LoginSocialGoogle
                                            client_id={client_id}
                                            scope="openid profile email"
                                            className='signin-goog-login-cls'
                                            onResolve={({ provider, data }) => {
                                                setProvider(provider)
                                                setProfile(data)


                                            }}
                                            onReject={(err) => {

                                            }}
                                        >
                                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                <GoogleLoginButton style={{ display: 'flex', justifyContent: 'center' }} text='Sign Up with Google' />
                                            </div>
                                            {/* <GoogleLoginButton style={{ width: '46px', borderRadius: '50px' }} text='' /> */}
                                        </LoginSocialGoogle>
                                    </div>

                                    {/* <div className="submit-btn-apple">
                                        <a href="#">
                                            <button>
                                                <div className="d-flex apple_icon">                                                  
                                                                                                        
                                                    <span><i className="fa-brands fa-apple"></i> Sign up with Apple</span>
                                                   
                                                </div>
                                            </button>
                                        </a>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 p-0">
                            <div className="login-banner-sec">
                                <img
                                    src="/assets/images/create-account-img.png"
                                    alt="banner"
                                    className="img-fluid"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>

    )
}

export default CreateAccount