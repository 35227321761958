import { React, useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { useFormik } from "formik";
import { toast } from 'react-hot-toast';
import * as Yup from "yup";
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import './scss/BuyerFaqContent.css'
import { getBuyerFaqList } from '../../Redux/action/MasterAction';
import Loader from '../../Loader';
import ErrorLoader from '../../Error/ErrorLoader';

const host = process.env.REACT_APP_API_SERVER
const BuyerFaqContent = () => {
    const dispatch = useDispatch();
    useEffect(() => {


        dispatch(getBuyerFaqList())

    }, []);

    const {
        loadingFaqListApi,
        FaqListApiData,
        FaqListApiError,

    } = useSelector(state => state?.MasterReducer);

    const formik = useFormik({
        initialValues: {

            email: "",

            subject: "",
            question: "",

        },
        validationSchema: Yup.object().shape({

            email: Yup.string().required("Email is required.").matches(/^\w+[-\.\w]*@(?!(?:)\.com$)\w+[-\.\w]*?\.\w{2,4}$/, "Enter a valid E-mail"),

            subject: Yup.string().required("Subject is required"),
            question: Yup.string().required("Question is required"),
        }),
        onSubmit: async (values, { setSubmitting, resetForm }) => {
            try {
                setSubmitting(true);


                await axios.post(`${host}/buyer/post-faq-question`, values)
                    .then(function (response) {
                        if (response.status == 200) {
                            setSubmitting(false)
                            toast.success("Your Question subitted successfully");
                            resetForm();

                        } else {
                            setSubmitting(false)
                            toast.error(response.data.message);
                        }

                    })
                    .catch(function (error) {
                        setSubmitting(false)
                        console.log(error)
                        toast.error(error.response.data.message);
                    })
            }
            catch (error) {
                setSubmitting(false)
                toast.error('Error submitting form. Please try again.');
            }
        }
    })


    if (loadingFaqListApi) {
        return <div className="overlay-form-submission">
            <Loader />
        </div>
    }

    if (FaqListApiError) {
        return <ErrorLoader message={FaqListApiError} />;
    }


    return (
        <div className='buyer-faq'>
            <>
                {/* Breadcrumb */}
                <div className="breadcrumb_section">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                <div className="breadcrumb_section_content">
                                    <ul>
                                        <li>
                                            <Link to="/buyer/home">Home</Link>
                                        </li>
                                        <li>
                                            <i className="fa-solid fa-angle-right" />
                                        </li>
                                        <li>
                                            <Link to="/buyer/faq">Faq</Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Breadcrumb */}
                {/* Profile */}
                <div className="profile_section">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                <div className="profile_heading border-bottom pb-2 mb-4">
                                    <h2>Frequently Asked Questions </h2>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12">
                                <div className="accordion faq_section" id="accordionExample">
                                    {FaqListApiData?.map((faq, index) => (
                                        <div className="accordion-item" key={faq?.id}>
                                            <h2 className="accordion-header" id={`heading${faq?.id}`}>
                                                <button
                                                    className={`accordion-button ${index !== 0 ? 'collapsed' : ''}`}
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target={`#collapse${faq?.id}`}
                                                    aria-expanded={index === 0 ? "true" : "false"}
                                                    aria-controls={`collapse${faq?.id}`}
                                                >
                                                    {faq?.question}
                                                </button>
                                            </h2>
                                            <div
                                                id={`collapse${faq?.id}`}
                                                className={`accordion-collapse collapse ${index === 0 ? 'show' : ''}`}
                                                aria-labelledby={`heading${faq?.id}`}
                                                data-bs-parent="#accordionExample"
                                            >
                                                <div className="accordion-body">
                                                    <p>{faq?.answer}</p>

                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                                <div className="faq_right">
                                    <h2>Don’t find your answer, Ask for support.</h2>
                                    <p>
                                        Need more help? Explore our support options.
                                        We're here to assist you, In every way you need.
                                    </p>
                                    <form onSubmit={formik.handleSubmit}>
                                        <input type="email" id="email" name="email"
                                            placeholder="Enter your email"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.email} />
                                        <div className="help-block with-errors">
                                            {formik.touched.email && formik.errors.email ? (
                                                <div className='text-danger'>{formik.errors.email}</div>
                                            ) : null}
                                        </div>
                                        <input
                                            className="form-check-input"
                                            type="text"
                                            value={formik.values.subject}
                                            placeholder="Enter subject"
                                            name='subject'
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                        />
                                        <div className="help-block with-errors">
                                            {formik.touched.subject && formik.errors.subject ? (
                                                <div className='text-danger'>{formik.errors.subject}</div>
                                            ) : null}
                                        </div>
                                        <textarea
                                            className="w-100 remove-border"
                                            name="question"
                                            id="question"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.question}
                                        />
                                        <div className="help-block with-errors">
                                            {formik.touched.question && formik.errors.question ? (
                                                <div className='text-danger'>{formik.errors.question}</div>
                                            ) : null}
                                        </div>
                                        <button type="submit" disabled={formik.isSubmitting}>
                                            Send Message <i className="fa-solid fa-arrow-right" />
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Profile */}
            </>

        </div>

    )
}

export default BuyerFaqContent