import React from 'react'
import BuyerUpdateAddressContent from './BuyerUpdateAddressContent'
import BuyerHeader from '../../BuyerHeader/BuyerHeader'
import BuyerFooter from '../../BuyerFooter/BuyerFooter'

const BuyerUpdateAddress = () => {
    return (
        <>
            <BuyerHeader />
            <BuyerUpdateAddressContent />
            <BuyerFooter />
        </>
    )
}

export default BuyerUpdateAddress