import React from 'react'
import BuyerProfileChangeNameContent from './BuyerProfileChangeNameContent'
import BuyerHeader from '../../BuyerHeader/BuyerHeader'
import BuyerFooter from '../../BuyerFooter/BuyerFooter'



const BuyerProfileChangeName = () => {
    return (
        <>
            <BuyerHeader />
            <BuyerProfileChangeNameContent />
            <BuyerFooter />
        </>
    )
}

export default BuyerProfileChangeName