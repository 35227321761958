import { React, useEffect } from 'react'
import { useFormik } from 'formik';
import { toast } from 'react-hot-toast';
import * as Yup from "yup";
import axios from 'axios';
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import './scss/BuyerOrderDetailsContent.css'
import { getBuyerOrderDetail } from '../../Redux/action/BuyerAction';
import { getReasonList } from '../../Redux/action/MasterAction';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
let host = process.env.REACT_APP_API_SERVER;
const BuyerOrderDetailsContent = () => {
    const { id } = useParams();
    const authToken = localStorage.getItem('buyer_token')

    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {

        dispatch(getBuyerOrderDetail(authToken, id));

    }, [id, authToken]);
    useEffect(() => {

        dispatch(getReasonList())

    }, []);
    const {

        loadingBuyerOrderDetailListApi,
        BuyerOrderDetailListApiData,
        BuyerOrderDetailListApiError,

        loadingReasonListApi,
        ReasonListApiData,
        ReasonListApiError,

    } = useSelector(state => state?.BuyerReducer);

    const handleNavigateClick = (slug, colourId, sizeId) => {


        if (colourId && sizeId) {

            navigate(`/buyer/product-details/${slug}?colorId=${colourId}&sizeId=${sizeId}`)
        }
        else if (colourId == null && sizeId) {

            navigate(`/buyer/product-details/${slug}?sizeId=${sizeId}`)
        }
        else if (colourId && sizeId == null) {

            navigate(`/buyer/product-details/${slug}?colorId=${colourId}`)
        }
        else {

            navigate(`/buyer/product-details/${slug}`)

        }
    }
    const OrderStatus = {
        1: { label: 'Order Placed' },
        2: { label: 'Seller Confirmed' },
        3: { label: 'Dispatched' },
        4: { label: 'Picked Up by Courier Parner' },
        5: { label: 'Out For Delivery' },
        6: { label: 'Delivered' },
        7: { label: 'Returned' },
        8: { label: 'Exchanged' },
        9: { label: 'Return Parcel Received' },
        10: { label: 'Exchange Parcel Received' },
        11: { label: 'Cancelled' },
        12: { label: 'Return Requested' },
        13: { label: 'Cancel Requested' },
    };
    const getStatusLabel = (status) => {
        return OrderStatus[status]?.label || 'Unknown Status';
    };

    const config = {
        headers: {

            'content-type': 'application/json',
            "auth-token": localStorage.getItem("buyer_token")
        }
    };


    const formik = useFormik({
        initialValues: {
            reason_id: "",
            message: "",
            orderDetailId: "",
        },
        validationSchema: Yup.object().shape({
            reason_id: Yup.string().required("This field is required."),
            message: Yup.string().required("This field is required."),
        }),
        onSubmit: async (values, { setSubmitting, resetForm }) => {
            try {
                setSubmitting(true);
                values.orderDetailId = id;


                await axios.post(`${host}/buyer/cancel-order`, values, config)
                    .then(function (response) {
                        if (response.status == 200) {
                            triggerModalClose();
                            setSubmitting(false)
                            toast.success("Order cancelled sucessfully");
                            resetForm();

                        } else {
                            setSubmitting(false)
                            toast.error(response.data.message);
                        }

                    })
                    .catch(function (error) {
                        setSubmitting(false)
                        console.log(error)
                        toast.error(error.response.data.message);
                    })
            }
            catch (error) {
                setSubmitting(false)
                toast.error('Error submitting form. Please try again.');
            }
        }
    });

    const triggerModalClose = () => {
        const closeButton = document.getElementById('close-modal');
        if (closeButton) {
            closeButton.click();
        }
    }

    const logoBase64 = '/assets/images/afgan-bazaar-logo.png';
    const generateInvoice = (invoice_Data) => {
        const doc = new jsPDF();
        const invoiceData = JSON.parse(invoice_Data);

        // Add Logo
        const logoWidth = 50; // Adjust logo width
        const logoHeight = 20; // Adjust logo height
        doc.addImage(logoBase64, 'PNG', 10, 10, logoWidth, logoHeight);

        // Add Title
        doc.setFontSize(18);
        doc.text('Invoice', 105, 40, null, null, 'center');

        // Add Customer Details
        doc.setFontSize(12);
        doc.text(`Customer Name: ${invoiceData.CustomerName}`, 10, 50);
        doc.text(`Order ID: ${invoiceData.order_item_id}`, 10, 60);
        doc.text(`Order Date: ${invoiceData.orderDate}`, 10, 70);
        doc.text(`Order Time: ${invoiceData.orderTime}`, 10, 80);
        doc.text(`Address: ${invoiceData.address}`, 10, 90);

        // Table for Invoice Details
        doc.autoTable({
            startY: 100,
            head: [['Description', 'Amount (AFN)']],
            body: [
                ['Total Discount', invoiceData.total_discount],
                ['Total Amount', invoiceData.total_amount],
                ['Payable Amount', invoiceData.payable_amount],
                ['Shipping Cost', invoiceData.shipping_cost],
                ['Total Tax', invoiceData.total_tax],
            ],
            theme: 'striped',
            styles: {
                fontSize: 10,
                cellPadding: 3,
            },
            headStyles: {
                fillColor: [0, 0, 0],  // Black header
                textColor: [255, 255, 255]  // White text in header
            },
            bodyStyles: {
                fillColor: [255, 255, 255], // White background for rows
                textColor: [0, 0, 0],  // Black text for rows
            },
        });

        // Save the PDF
        doc.save('invoice.pdf');
    };

    return (
        <div className='buyer-order-details'>
            <>
                {/* Breadcrumb */}
                <div className="breadcrumb_section">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                <div className="breadcrumb_section_content">
                                    <ul>
                                        <li>
                                            <Link to="/buyer/your-account"> Your Account</Link>
                                        </li>
                                        <li>
                                            <i className="fa-solid fa-angle-right" />
                                        </li>
                                        <li>
                                            <Link to="/buyer/your-orders">Your Orders</Link>
                                        </li>
                                        <li>
                                            <i className="fa-solid fa-angle-right" />
                                        </li>
                                        <li>
                                            <Link to="/buyer/order-details">In Progress</Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Breadcrumb */}
                {/* Your order */}
                <div className="order_section">
                    <div className='container-fluid'>
                        <div className="row">
                            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                <div className="cancel_order_heading">
                                    <div className="cancel_order_heading_left">
                                        <h2>Order Details </h2>
                                        <p>Ordered on {BuyerOrderDetailListApiData?.orderPlacedDate ? new Date(BuyerOrderDetailListApiData.orderPlacedDate).toDateString() : 'No date available'}</p>
                                    </div>
                                    <div className="cancel_order_heading_left">
                                        {/* <div className="cancel_order_search">
                                            <input className="search-txt" type="text" name="" placeholder="Search all orders" />
                                            <i className="fa-solid fa-magnifying-glass" />
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                <div className="order_section_inner mb-5">

                                    <div className="track_order_section_1">
                                        <div className="track_order_section_right">
                                            <h4>Order Details</h4>
                                            <p>Ordered on {BuyerOrderDetailListApiData?.orderPlacedDate ? new Date(BuyerOrderDetailListApiData.orderPlacedDate).toDateString() : 'No date available'}</p>
                                        </div>
                                        <div className="track_order_section_left me-3">
                                            <Link to="/buyer/order-details">
                                                Order Number : <span>{id}</span>
                                            </Link>
                                            <p><span><a onClick={() => generateInvoice(BuyerOrderDetailListApiData?.invoice_data)} style={{ cursor: 'pointer' }}>Invoice</a></span></p>

                                        </div>
                                    </div>
                                    <div className='order_details_table'>
                                        <div className='row'>
                                            <div className='col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12'>
                                                <div className='order_details_content'>
                                                    <h2>Shipping Address</h2>
                                                    <p>  {BuyerOrderDetailListApiData?.address}</p>
                                                </div>
                                            </div>
                                            <div className='col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12'>
                                                <div className='order_details_content'>
                                                    <h2>Payment Methods</h2>
                                                    <p>cash on delivery</p>
                                                </div>
                                            </div>
                                            <div className='col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12'>
                                                <div className='order_details_content'>
                                                    <h2>Order Summary</h2>
                                                    <table>
                                                        <tbody>
                                                            <tr>
                                                                <td>Sub-total</td>
                                                                <td className="text-end">   <strong>AFN.{BuyerOrderDetailListApiData?.subtotal}</strong></td>
                                                            </tr>
                                                            <tr>
                                                                <td>Shipping</td>
                                                                <td className="text-end"><strong>Free</strong></td>
                                                            </tr>
                                                            <tr>
                                                                <td>Discount</td>
                                                                <td className="text-end"><strong>AFN.{BuyerOrderDetailListApiData?.discount}</strong></td>
                                                            </tr>
                                                            <tr>
                                                                <td>Tax</td>
                                                                <td className="text-end"><strong>AFN.{BuyerOrderDetailListApiData?.tax}</strong></td>
                                                            </tr>
                                                            <tr>
                                                                <th>Total</th>
                                                                <th className="text-end"><strong>AFN.{BuyerOrderDetailListApiData?.total_amount} </strong></th>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                <div className="order_section_inner pt-2 pb-4">

                                    <div className='inner_sec_heading'>
                                        <h5><strong>Ordered On {BuyerOrderDetailListApiData?.orderPlacedDate ? new Date(BuyerOrderDetailListApiData.orderPlacedDate).toDateString() : 'No date available'}</strong></h5>
                                        {/* <h6>
                                            Lorem Ipsum is simply dummy text of the printing
                                        </h6> */}
                                    </div>

                                    <div className="track_order_details">

                                        <div className="track_order_details_main">

                                            <img src={BuyerOrderDetailListApiData?.image ? `${host}${BuyerOrderDetailListApiData?.image}` : ''} className="img-fluid" />
                                        </div>
                                        <div className="track_order_details_main">
                                            <p>
                                                {BuyerOrderDetailListApiData?.name}
                                            </p>
                                            <p className='main_date'>Return window closed on {BuyerOrderDetailListApiData?.returnDate ? new Date(BuyerOrderDetailListApiData.returnDate).toDateString() : 'No date available'}</p>
                                            <p className='main_date'>Exchange window closed on {BuyerOrderDetailListApiData?.exchangeDate ? new Date(BuyerOrderDetailListApiData.exchangeDate).toDateString() : 'No date available'}</p>

                                            {BuyerOrderDetailListApiData?.orderStatus === '1' &&
                                                <div className='main_buttons'>
                                                    <a onClick={() => { handleNavigateClick(BuyerOrderDetailListApiData?.slug, BuyerOrderDetailListApiData?.colourId, BuyerOrderDetailListApiData?.sizeId) }}> <button type="button" className='button-white'>View Details</button></a>
                                                    <button type="button" BuyerOrderDetailListApiData-bs-toggle="modal"
                                                        BuyerOrderDetailListApiData-bs-target="#exampleModal1" className='button-white'>Cancel Order</button>
                                                </div>}
                                            {BuyerOrderDetailListApiData?.orderStatus === '2' || BuyerOrderDetailListApiData?.orderStatus === '3' || BuyerOrderDetailListApiData?.orderStatus === '4' || BuyerOrderDetailListApiData?.orderStatus === '5' &&


                                                <div className='main_buttons'>
                                                    <button type="button" BuyerOrderDetailListApiData-bs-toggle="modal"
                                                        BuyerOrderDetailListApiData-bs-target="#exampleModal2" className='button-white'>cancel order</button>
                                                    <Link to="/buyer/track-order"><button type="button" className='button-white'>Track order</button></Link>


                                                </div>
                                            }
                                            {BuyerOrderDetailListApiData?.orderStatus === '6' &&

                                                <div className='main_buttons'>
                                                    <Link to="/buyer/return-order"> <button type="button" className='button-white'>Return Order</button></Link>
                                                    <Link to="/buyer/buy-it-again"><button type="button" className='button-white'>Buy Again</button></Link>
                                                </div>
                                            }
                                            {BuyerOrderDetailListApiData?.orderStatus === '11' || BuyerOrderDetailListApiData?.orderStatus === '7' &&

                                                <div className='main_buttons'>
                                                    <a onClick={() => { handleNavigateClick(BuyerOrderDetailListApiData?.slug, BuyerOrderDetailListApiData?.colourId, BuyerOrderDetailListApiData?.sizeId) }}> <button type="button" className='button-white'>View Details</button></a>
                                                    <Link to="/buyer/buy-it-again"><button type="button" className='button-white'>Buy Again</button></Link>
                                                </div>
                                            }
                                        </div>
                                        <div className="track_order_details_main text-center">
                                            <p>
                                                Details
                                            </p>
                                            <h6>{BuyerOrderDetailListApiData?.childCategory}</h6>
                                        </div>
                                        <div className="track_order_details_main text-center">
                                            <p>
                                                Status
                                            </p>
                                            <h6>{getStatusLabel(BuyerOrderDetailListApiData?.orderStatus)}</h6>
                                        </div>
                                        <div className="track_order_details_main text-center">
                                            <p>Quantity</p>
                                            <h6>{BuyerOrderDetailListApiData?.quantity}</h6>
                                        </div>

                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                {/* Your order */}
            </>
            <div
                className="modal fade"
                id="exampleModal1"
                tabIndex={-1}
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog cancel_popup">
                    <div className="modal-content">
                        <form method='post' onSubmit={formik.handleSubmit}>
                            <div className="modal-body">
                                <button type="button" className="btn-close" hidden={true} id='close-modal' data-bs-dismiss="modal" aria-label="Close" />
                                <img src="/assets/images/cancel_order.png" className="img-fluid" />
                                <h2>Are you sure you want to cancel your product</h2>
                                <select type="text" name='reason_id' className='form-control mb-2'
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.reason_id}

                                >
                                    <option>Select reason to cancel</option>
                                    {ReasonListApiData?.map((data) => (<option key={data?.id} value={data?.id}>{data?.name}</option>))}
                                </select>
                                <div className="help-block with-errors">
                                    {formik.touched.reason_id && formik.errors.reason_id ? (
                                        <div className='text-danger'>{formik.errors.reason_id}</div>
                                    ) : null}
                                </div>
                                <textarea name='message' onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.message}></textarea>
                                <div className="help-block with-errors">
                                    {formik.touched.message && formik.errors.message ? (
                                        <div className='text-danger'>{formik.errors.message}</div>
                                    ) : null}
                                </div>
                                <div className='cancel_order_popup text-end'>
                                    {/* <Link to="/buyer/product-details"><button type='button'>View Details</button></Link> */}
                                    <button className='me-0' type='submit' disabled={formik.isSubmitting}>Cancel Order</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default BuyerOrderDetailsContent