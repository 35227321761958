import React from 'react'
import { Hourglass } from 'react-loader-spinner';
const HourglassLoaderColoured = () => {
    return (
        <>
            <Hourglass
                visible={true}
                height="20"
                width="50"
                ariaLabel="hourglass-loading"
                wrapperStyle={{}}
                wrapperClass=""
                colors={['#183823', '#183823']}
            />
        </>
    )
}

export default HourglassLoaderColoured
