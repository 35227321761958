import React, { useEffect } from 'react'
import BuyerHeader from '../../BuyerHeader/BuyerHeader'
import BuyerFooter from '../../BuyerFooter/BuyerFooter'
import BuyerProductDetailsContent from './BuyerProductDetailsContent'
import { useDispatch, useSelector } from 'react-redux'
import { getAssignSizeData } from '../../../Redux/action/MasterAction'
import BuyerHeaderWithoutLogin from '../../BuyerHeader/BuyerHeaderWithoutLogin'


const BuyerProductDetails = () => {

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getAssignSizeData());


    }, []);


    const {

        loadingColourDataListApi,
        ColourDataListApiData,
        ColourDataListApiError,

        loadingAssignSizeDataListApi,
        AssignSizeDataListApiData,
        AssignSizeDataListApiError,

    } = useSelector(state => state?.MasterReducer);


    const authToken = localStorage.getItem("buyer_token")

    return (
        <>
            {authToken ?

                <BuyerHeader />
                :
                <BuyerHeaderWithoutLogin />
            }
            <BuyerProductDetailsContent ColourDataListApiData={ColourDataListApiData} AssignSizeDataListApiData={AssignSizeDataListApiData} />
            <BuyerFooter />
        </>
    )
}

export default BuyerProductDetails