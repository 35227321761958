import React from 'react';
import "./Scss/Homepage.css";
import { Link } from 'react-router-dom';

const HomeHeader = () => {
    return (
        <>
            <header className="bg-primary-black text-white header_padd_20 fix_header_main">
                <nav className="navbar navbar-expand-lg navbar-dark">
                    <div className="container">
                        <Link className="navbar-brand logo_main" to="/seller/home">
                            <img src={`${process.env.PUBLIC_URL}/assets/images/seller_images/logoipsum.png`} alt="logo" />
                        </Link>
                        <div className="ms-auto">
                            <Link className="but_start_header" to="/seller/sign-in">
                                Start Selling
                            </Link>
                        </div>
                    </div>
                </nav>
            </header>
        </>
    )
}

export default HomeHeader