import React from 'react'
import { Link } from 'react-router-dom'

const SellerAccountManagerSaveChanges = () => {
    return (
        <div className='seller'>
            <main className="main_login_section container-fluid">
                <div className="row">
                    <div className="col-lg-12 bg_det_subscription">
                        <div className="container">
                            <div className="main_sub_detail">
                                <div className="logo_login sub_section_main pb-0 pt-5">
                                    <img src="/assets/images/seller_images/login_logo.png" alt="login_logo" />
                                </div>
                                <div className="main_detail_Plan_img">
                                    <img src="/assets/images/seller_images/save_successfull_1.png" alt="img" />
                                </div>
                                <div className="Plan_form_sub pb-5 pt-5">
                                    <h4 className="text-center">Save Change Successfully !!</h4>
                                    <p>You will be receiving an email of recent Updates</p>
                                    <div className="text-center ">
                                        <Link to="/seller/profile" className="btn btn-primary">
                                            Go back to Profile
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
            {/* ==============script linking start=============== */}
            {/* ==============script linking end=============== */}
        </div>

    )
}

export default SellerAccountManagerSaveChanges