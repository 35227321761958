import React, { useState } from 'react'
import SellerSidebar from '../../SellerEssentials/SellerSidebar';
import SellerHeader from '../../SellerEssentials/SellerHeader';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import { useFormik } from 'formik';
const ProductVariation = () => {
    const [isVariation, setIsVariation] = useState(true);
    const [variationType, setVariationType] = useState(0);
    const [selectedColors, setSelectedColors] = useState([]);
    const [selectedSizes, setSelectedSizes] = useState([]);
    const [tableRows, setTableRows] = useState([]);

    let initialValues = {
        isVariation: true,
        variation_type: 0,
        color: [],
        size: [],

    }
    const formik = useFormik(({
        initialValues: initialValues,
        onSubmit: values => {
            console.log(values);
        }
    }));

    let colorOptions = [
        { value: 1, label: 'Red' },
        { value: 2, label: 'Black' },
        { value: 3, label: 'Blue' },
        { value: 4, label: 'Green' },
        { value: 5, label: 'White' },
    ]
    let sizeOptions = [
        { value: 1, label: 'XS' },
        { value: 2, label: 'S' },
        { value: 3, label: 'M' },
        { value: 4, label: 'L' },
        { value: 5, label: 'XL' },
    ]

    const handleVariationChange = (e) => {
        const value = Number(e.target.value);
        setVariationType(value);
        setSelectedColors([]);
        setSelectedSizes([]);
        setTableRows([]);
    };

    // Update table rows based on selected colors and sizes
    const updateTableRows = (colors, sizes) => {
        let rows = [];
        if (variationType === 1) {
            // Only color variations
            rows = colors.map((color) => ({ color: color.label, size: '', active: true }));
        } else if (variationType === 2) {
            // Only size variations
            rows = sizes.map((size) => ({ color: '', size: size.label, active: true }));
        } else if (variationType === 3) {
            // Both color and size variations
            colors.forEach((color) => {
                sizes.forEach((size) => {
                    rows.push({ color: color.label, size: size.label, active: true });
                });
            });
        }
        setTableRows(rows);
    };

    const handleColorChange = (selectedOptions) => {
        const colors = selectedOptions || [];
        setSelectedColors(colors);
        updateTableRows(colors, selectedSizes);
    };

    const handleSizeChange = (selectedOptions) => {
        const sizes = selectedOptions || [];
        setSelectedSizes(sizes);
        updateTableRows(selectedColors, sizes);
    };

    const handleDeleteRow = (index) => {
        const updatedRows = tableRows.filter((_, i) => i !== index);
        setTableRows(updatedRows);
    };

    const handleToggleStatus = (index) => {
        const updatedRows = [...tableRows];
        updatedRows[index].active = !updatedRows[index].active;
        setTableRows(updatedRows);
    };

    return (
        <>
            <div className="seller">
                <div className="page-wrapper chiller-theme toggled">
                    <SellerSidebar />
                    {/* sidebar-wrapper  */}
                    <main className="page-content container-fluid">
                        {/* ==================header================== */}
                        <SellerHeader />
                        {/* ==================header================== */}
                        <div className="row ">
                            <div className="col-md-12">
                                <div className="main-nav-Order">
                                    <h2>
                                        {" "}
                                        <Link to="/seller/products">
                                            <img src={`${process.env.PUBLIC_URL}/assets/images/seller_images/left_side.png`} alt="left" />
                                        </Link>{" "}
                                        <span>Products</span> / Add Products
                                    </h2>
                                    <form id="signUpForm" action="#!">
                                        {/* start step indicators */}
                                        <div className="form-header d-flex">
                                            <div className="stepIndicator">PRODUCT IDENTITY</div>
                                            <div className="stepIndicator">PRODUCT DESCRIPTION</div>
                                            <div className="stepIndicator">VARIATIONS</div>
                                            <div className="stepIndicator">PRODUCT DETAILS</div>
                                        </div>
                                        {/* end step indicators */}
                                        {/* step four */}
                                        <div className="step">
                                            <div className="variations">
                                                <div className="tab-container pt-3">
                                                    <div className="row">
                                                        <div className="col-lg-6 col-md-6 col-sm-6">
                                                            <div className="main_select">
                                                                <label>Variations</label>
                                                                <select
                                                                    id="select-box"
                                                                    name="isVariation"
                                                                    onChange={(e) => {
                                                                        if (Number(e.target.value) == 1) {
                                                                            setIsVariation(true);
                                                                        } else if (Number(e.target.value == 2)) {
                                                                            setIsVariation(false);
                                                                        }
                                                                    }}
                                                                >
                                                                    <option value={1}>Yes</option>
                                                                    <option value={2}>No</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        {isVariation && <div className="col-lg-6 col-md-6 col-sm-6">
                                                            <div className="main_select">
                                                                <label>Variation Type</label>
                                                                <select
                                                                    id="select-box"
                                                                    name="isVariation"
                                                                    onChange={handleVariationChange}
                                                                >
                                                                    <option value={0}>-Select a variation type-</option>
                                                                    <option value={1}>Color</option>
                                                                    <option value={2}>Size</option>
                                                                    <option value={3}>Color & Size</option>
                                                                </select>
                                                            </div>
                                                        </div>}

                                                    </div>
                                                    <div className="row pt-2 pb-2">
                                                        <div className="col-lg-12">
                                                            {isVariation && <div id="tab-1" className="tab-content_Products">
                                                                <div className="row my-3">
                                                                    {(variationType === 1 || variationType === 3) && <div className="col-lg-6">
                                                                        <div className="form-group main_select_color main_select">
                                                                            <label>Color</label>
                                                                            <Select
                                                                                id="color"
                                                                                name="color"
                                                                                placeholder="Select Color Variations"
                                                                                options={colorOptions}
                                                                                onChange={handleColorChange}
                                                                                isMulti
                                                                            />
                                                                        </div>
                                                                    </div>}
                                                                    {
                                                                        (variationType === 2 || variationType === 3) && <> <div className="col-lg-6">
                                                                            <div className="form-group main_select main_select_color">
                                                                                <label>Size</label>
                                                                                <Select
                                                                                    id="size"
                                                                                    name="size"
                                                                                    placeholder="Select Size Variations"
                                                                                    options={sizeOptions}
                                                                                    onChange={handleSizeChange}
                                                                                    isMulti
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                            <div className="row py-2">
                                                                                <div className="col-lg-6">
                                                                                    <div className="form-group log_in_main">
                                                                                        <label>Size Chart Image</label>
                                                                                        <div className="file-input">
                                                                                            <input type="file" name="userfile" id="userfile" />
                                                                                            <button className="folder_main_img label_1">
                                                                                                <span>
                                                                                                    <img src={`${process.env.PUBLIC_URL}/assets/images/seller_images/Upload.png`} alt="file" />
                                                                                                </span>{" "}
                                                                                                Upload Image
                                                                                            </button>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                        </>
                                                                    }
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-md-12">
                                                                        <div className="table_detail_tariff table-responsive  border bg-light">
                                                                            <table className="posts-table geeks-new_detail">
                                                                                <thead>
                                                                                    <tr className="users-table-tariff text-cen">
                                                                                        {/* <th>
                                                                                            <div className="round_Products">
                                                                                                <input
                                                                                                    type="checkbox"
                                                                                                    id="checkbox_main"
                                                                                                />
                                                                                                <label htmlFor="checkbox_main" />
                                                                                            </div>
                                                                                        </th> */}
                                                                                        <th>#</th>
                                                                                        <th>Color</th>
                                                                                        <th>Size</th>
                                                                                        <th>Product Image</th>
                                                                                        <th>Quantity</th>
                                                                                        <th>Orignal Price</th>
                                                                                        <th>Discount %</th>
                                                                                        <th>Selling Price</th>
                                                                                        <th>Action</th>
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody className="text-cen">
                                                                                    {tableRows.length > 0 ?
                                                                                        tableRows.map((row, index) => (
                                                                                            <tr key={index} className="main_table_row_new gfg">
                                                                                                {/* <td>
                                                                                                <div className="round_Products">
                                                                                                    <input type="checkbox" id={`checkbox-${index}`} />
                                                                                                    <label htmlFor={`checkbox-${index}`} />
                                                                                                </div>
                                                                                            </td> */}
                                                                                                <td>{index + 1}</td>
                                                                                                <td>{row.color || '-'}</td>
                                                                                                <td>{row.size || '-'}</td>
                                                                                                <td>
                                                                                                    <a
                                                                                                        type="button"
                                                                                                        className="product_cemra"
                                                                                                        data-bs-toggle="modal"
                                                                                                        data-bs-target="#staticBackdrop"
                                                                                                    >
                                                                                                        <img
                                                                                                            src={`${process.env.PUBLIC_URL}/assets/images/seller_images/cemra.png`}
                                                                                                            alt="product"
                                                                                                        />
                                                                                                        Add Images
                                                                                                    </a>
                                                                                                </td>
                                                                                                <td>
                                                                                                    <a href="javascript:void(0)" className="input_price_main">
                                                                                                        <input type="text" defaultValue="" />
                                                                                                    </a>
                                                                                                </td>
                                                                                                <td width="10%">
                                                                                                    <a href="javascript:void(0)" className="main_input_price">
                                                                                                        <span>AFN$</span>
                                                                                                        <input type="text" defaultValue="" />
                                                                                                    </a>
                                                                                                </td>
                                                                                                <td>
                                                                                                    <a href="javascript:void(0)" className="input_price_main">
                                                                                                        <input type="text" defaultValue="" className="w_100" />
                                                                                                    </a>
                                                                                                </td>
                                                                                                <td>
                                                                                                    <a href="javascript:void(0)" className="main_input_price">
                                                                                                        <span>AFN$</span>
                                                                                                        <input type="text" defaultValue="" className="w_100" />
                                                                                                    </a>
                                                                                                </td>
                                                                                                <td>
                                                                                                    <div className="d-flex">
                                                                                                        <button
                                                                                                            type='button'
                                                                                                            className={`btn ${row.active ? 'btn-outline-success' : 'btn-outline-danger'} btn-sm me-1`}
                                                                                                            title={row.active ? 'Deactivate Variation' : 'Activate Variation'}
                                                                                                            onClick={() => handleToggleStatus(index)}
                                                                                                        >
                                                                                                            <i className={`fas ${row.active ? 'fa-toggle-on' : 'fa-toggle-off'}`}></i>
                                                                                                        </button>
                                                                                                        <button className="btn btn-outline-danger btn-sm ms-1" title='Delete Variation' type='button' onClick={() => handleDeleteRow(index)}><i className="fa-solid fa-trash"></i></button>
                                                                                                        {/* <button className="btn btn-outline-success btn-sm ms-1" title='Change Variation Status' type='button'><i className="fas fa-toggle-on"></i></button> */}
                                                                                                    </div>
                                                                                                </td>
                                                                                            </tr>
                                                                                        ))
                                                                                        :
                                                                                        <i className='w-100 text-center'>No data available.</i>
                                                                                    }
                                                                                </tbody>
                                                                            </table>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>}
                                                            {!isVariation && <div id="tab-2" className="tab-content_Products">
                                                                <div className="row mt-3">
                                                                    <div className="col-lg-4">
                                                                        <div className="form-group log_in_main">
                                                                            <label>Quantity</label>
                                                                            <input
                                                                                type="text"
                                                                                defaultValue=""
                                                                                className="form-control"
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-4">
                                                                        <div className="form-group log_in_main">
                                                                            <label>Price</label>
                                                                            <input
                                                                                type="text"
                                                                                defaultValue=""
                                                                                className="form-control"
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-4">
                                                                        <div className="form-group log_in_main">
                                                                            <label>Discount Price</label>
                                                                            <input
                                                                                type="text"
                                                                                defaultValue=""
                                                                                className="form-control"
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-4">
                                                                        <div className="form-group log_in_main">
                                                                            <label>Selling Price</label>
                                                                            <input
                                                                                type="text"
                                                                                defaultValue=""
                                                                                className="form-control"
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-4">
                                                                        <div className="form-group log_in_main">
                                                                            <label>Select Add Images</label>
                                                                            <a
                                                                                type="button"
                                                                                className="product_cemra mxs-top-main"
                                                                                data-bs-toggle="modal"
                                                                                data-bs-target="#staticBackdrop"
                                                                            >
                                                                                <img src={`${process.env.PUBLIC_URL}/assets/images/seller_images/cemra.png`} alt="product" />
                                                                                Add Images
                                                                            </a>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-footer d-flex main_detail_save">
                                                <Link
                                                    type="button"
                                                    id="prevBtn"
                                                    className="btn btn-primary next-step"
                                                    to="/seller/products/add/description"
                                                >
                                                    Previous
                                                </Link>
                                                <Link
                                                    to="/seller/products/add/details"
                                                    className="btn btn-primary next-step"
                                                >
                                                    Next
                                                </Link>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        {/* Modal */}
                        <div
                            className="modal fade"
                            id="staticBackdrop"
                            data-bs-backdrop="static"
                            data-bs-keyboard="false"
                            tabIndex={-1}
                            aria-labelledby="staticBackdropLabel"
                            aria-hidden="true"
                        >
                            <div className="modal-dialog dasbord-main_model">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title" id="staticBackdropLabel">
                                            Edit images
                                        </h5>
                                        <button
                                            type="button"
                                            className="btn-close"
                                            data-bs-dismiss="modal"
                                            aria-label="Close"
                                        />
                                    </div>
                                    <div className="modal-body">
                                        <div className="main_detail_head">
                                            <h6>Product images style guideline</h6>
                                            <p>
                                                Uploaded:0 of 9 images. Maximum 9 images are allowed. you can
                                                arrange the order after uploading.
                                            </p>
                                        </div>
                                        <div className="upload-box-main">
                                            <div className="upload-box-inner">
                                                <span>
                                                    <span className="file-name" />
                                                    <input type="file" className="file-element" />
                                                    <img
                                                        className="main-img-icon"
                                                        src="images/cemra.png"
                                                        alt=""
                                                    />
                                                    <h3>Update</h3>
                                                </span>
                                                <div className="main_hading_popup">
                                                    <h3>MAIN</h3>
                                                </div>
                                            </div>
                                            <div className="upload-box-inner">
                                                <span>
                                                    <span className="file-name" />
                                                    <input type="file" className="file-element" />
                                                    <img
                                                        className="main-img-icon"
                                                        src="images/cemra.png"
                                                        alt=""
                                                    />
                                                    <h3>Update</h3>
                                                </span>
                                            </div>
                                            <div className="upload-box-inner">
                                                <span>
                                                    <span className="file-name" />
                                                    <input type="file" className="file-element" />
                                                    <img
                                                        className="main-img-icon"
                                                        src="images/cemra.png"
                                                        alt=""
                                                    />
                                                    <h3>Update</h3>
                                                </span>
                                            </div>
                                            <div className="upload-box-inner">
                                                <span>
                                                    <span className="file-name" />
                                                    <input type="file" className="file-element" />
                                                    <img
                                                        className="main-img-icon"
                                                        src="images/cemra.png"
                                                        alt=""
                                                    />
                                                    <h3>Update</h3>
                                                </span>
                                            </div>
                                            <div className="upload-box-inner">
                                                <span>
                                                    <span className="file-name" />
                                                    <input type="file" className="file-element" />
                                                    <img
                                                        className="main-img-icon"
                                                        src="images/cemra.png"
                                                        alt=""
                                                    />
                                                    <h3>Update</h3>
                                                </span>
                                            </div>
                                            <div className="upload-box-inner">
                                                <span>
                                                    <span className="file-name" />
                                                    <input type="file" className="file-element" />
                                                    <img
                                                        className="main-img-icon"
                                                        src="images/cemra.png"
                                                        alt=""
                                                    />
                                                    <h3>Update</h3>
                                                </span>
                                            </div>
                                            <div className="upload-box-inner">
                                                <span>
                                                    <span className="file-name" />
                                                    <input type="file" className="file-element" />
                                                    <img
                                                        className="main-img-icon"
                                                        src="images/cemra.png"
                                                        alt=""
                                                    />
                                                    <h3>Update</h3>
                                                </span>
                                            </div>
                                            <div className="upload-box-inner">
                                                <span>
                                                    <span className="file-name" />
                                                    <input type="file" className="file-element" />
                                                    <img
                                                        className="main-img-icon"
                                                        src="images/cemra.png"
                                                        alt=""
                                                    />
                                                    <h3>Update</h3>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="modal-footer">
                                        <button
                                            type="button"
                                            className="btn btn-secondary"
                                            data-bs-dismiss="modal"
                                        >
                                            Close
                                        </button>
                                        <button type="button" className="btn btn-primary">
                                            Add to Listing
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>
                </div>
            </div>

        </>
    )
}

export default ProductVariation