import React from 'react'
import SellerSidebar from '../SellerEssentials/SellerSidebar'
import SellerHeader from '../SellerEssentials/SellerHeader'
import { Link } from 'react-router-dom'
const SellerInventory = () => {
    return (
        <div className='seller'>
            <div className="page-wrapper chiller-theme toggled">
                <SellerSidebar />
                {/* sidebar-wrapper  */}
                <main className="page-content container-fluid">
                    {/* ==================header================== */}
                    <SellerHeader />
                    {/* ==================header================== */}
                    <div className="row">
                        <div className="col-md-12">
                            <div className="main-nav-Order">
                                <div className="row">
                                    <div className="col-lg-6">
                                        <div className="main-nav-Order p-0">
                                            <h2> Inventory Alert </h2>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="main_turn_inventory">
                                            <label>
                                                <span>Turn on Inventory Alert</span>{" "}
                                                <input
                                                    defaultChecked=""
                                                    className="cb cb2"
                                                    type="checkbox"
                                                    name="work"
                                                    onclick="cbChange(this)"
                                                />
                                                <i />
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="card_order_main">
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <div className="product-card">
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <Link to="/seller/inventory_alert_2">
                                                            <div className="product_card_image">
                                                                <img src="/assets/images/seller_images/product.png" alt="product" />
                                                            </div>
                                                        </Link>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <Link to="/seller/inventory_alert_2">
                                                            <div className="product_card_detail">
                                                                <div className="rating-header">
                                                                    <span className="stars">★★★★★</span>
                                                                    <span className="total-ratings">5 out of 5</span>
                                                                </div>
                                                                <p>Entire Bromo mountain view Cabin in Surabaya</p>
                                                                <h6>Malang, Probolinggo</h6>
                                                            </div>
                                                        </Link>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className="main_list_product">
                                                            <div className="main_hide_section">
                                                                <Link to="/seller/inventory_alert_2">
                                                                    <img
                                                                        src="/assets/images/seller_images/solar_box-linear.png"
                                                                        alt="Total"
                                                                    />{" "}
                                                                    Total stock: <span>100</span>
                                                                </Link>
                                                                <Link to="/seller/inventory_alert_2">
                                                                    <img
                                                                        src="/assets/images/seller_images/solar_box-linear.png"
                                                                        alt="Total"
                                                                    />{" "}
                                                                    Stock Left:{" "}
                                                                    <span className="red_icon_main">10</span>
                                                                </Link>
                                                            </div>
                                                            <div className="hide_butt">
                                                                <Link to="/seller/#" className="main_active_detail active">
                                                                    {" "}
                                                                    <span /> Active
                                                                </Link>
                                                            </div>
                                                        </div>
                                                        <div className="main_button_low">
                                                            <Link to="/seller/inventory_alert_2">Low inventory</Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="product-card">
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <Link to="/seller/inventory_alert_2">
                                                            <div className="product_card_image">
                                                                <img src="/assets/images/seller_images/product.png" alt="product" />
                                                            </div>
                                                        </Link>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <Link to="/seller/inventory_alert_2">
                                                            <div className="product_card_detail">
                                                                <div className="rating-header">
                                                                    <span className="stars">★★★★★</span>
                                                                    <span className="total-ratings">5 out of 5</span>
                                                                </div>
                                                                <p>Entire Bromo mountain view Cabin in Surabaya</p>
                                                                <h6>Malang, Probolinggo</h6>
                                                            </div>
                                                        </Link>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className="main_list_product">
                                                            <div className="main_hide_section">
                                                                <Link to="/seller/inventory_alert_2">
                                                                    <img
                                                                        src="/assets/images/seller_images/solar_box-linear.png"
                                                                        alt="Total"
                                                                    />{" "}
                                                                    Total stock: <span>100</span>
                                                                </Link>
                                                                <Link to="/seller/inventory_alert_2">
                                                                    <img
                                                                        src="/assets/images/seller_images/solar_box-linear.png"
                                                                        alt="Total"
                                                                    />{" "}
                                                                    Stock Left:{" "}
                                                                    <span className="red_icon_main">10</span>
                                                                </Link>
                                                            </div>
                                                            <div className="hide_butt">
                                                                <Link to="/seller/#" className="main_active_detail">
                                                                    {" "}
                                                                    <span /> Inactive
                                                                </Link>
                                                            </div>
                                                        </div>
                                                        <div className="main_button_low">
                                                            <Link to="/seller/inventory_alert_2">Low inventory</Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="product-card">
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <Link to="/seller/inventory_alert_2">
                                                            <div className="product_card_image">
                                                                <img src="/assets/images/seller_images/product.png" alt="product" />
                                                            </div>
                                                        </Link>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <Link to="/seller/inventory_alert_2">
                                                            <div className="product_card_detail">
                                                                <div className="rating-header">
                                                                    <span className="stars">★★★★★</span>
                                                                    <span className="total-ratings">5 out of 5</span>
                                                                </div>
                                                                <p>Entire Bromo mountain view Cabin in Surabaya</p>
                                                                <h6>Malang, Probolinggo</h6>
                                                            </div>
                                                        </Link>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className="main_list_product">
                                                            <div className="main_hide_section">
                                                                <Link to="/seller/inventory_alert_2">
                                                                    <img
                                                                        src="/assets/images/seller_images/solar_box-linear.png"
                                                                        alt="Total"
                                                                    />{" "}
                                                                    Total stock: <span>100</span>
                                                                </Link>
                                                                <Link to="/seller/inventory_alert_2">
                                                                    <img
                                                                        src="/assets/images/seller_images/solar_box-linear.png"
                                                                        alt="Total"
                                                                    />{" "}
                                                                    Stock Left:{" "}
                                                                    <span className="red_icon_main">10</span>
                                                                </Link>
                                                            </div>
                                                            <div className="hide_butt">
                                                                <Link to="/seller/#" className="main_active_detail active">
                                                                    {" "}
                                                                    <span /> Active
                                                                </Link>
                                                            </div>
                                                        </div>
                                                        <div className="main_button_low">
                                                            <Link to="/seller/inventory_alert_2">Low inventory</Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="product-card">
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <Link to="/seller/inventory_alert_2">
                                                            <div className="product_card_image">
                                                                <img src="/assets/images/seller_images/product.png" alt="product" />
                                                            </div>
                                                        </Link>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <Link to="/seller/inventory_alert_2">
                                                            <div className="product_card_detail">
                                                                <div className="rating-header">
                                                                    <span className="stars">★★★★★</span>
                                                                    <span className="total-ratings">5 out of 5</span>
                                                                </div>
                                                                <p>Entire Bromo mountain view Cabin in Surabaya</p>
                                                                <h6>Malang, Probolinggo</h6>
                                                            </div>
                                                        </Link>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className="main_list_product">
                                                            <div className="main_hide_section">
                                                                <Link to="/seller/inventory_alert_2">
                                                                    <img
                                                                        src="/assets/images/seller_images/solar_box-linear.png"
                                                                        alt="Total"
                                                                    />{" "}
                                                                    Total stock: <span>100</span>
                                                                </Link>
                                                                <Link to="/seller/inventory_alert_2">
                                                                    <img
                                                                        src="/assets/images/seller_images/solar_box-linear.png"
                                                                        alt="Total"
                                                                    />{" "}
                                                                    Stock Left:{" "}
                                                                    <span className="red_icon_main">10</span>
                                                                </Link>
                                                            </div>
                                                            <div className="hide_butt">
                                                                <Link to="/seller/#" className="main_active_detail">
                                                                    {" "}
                                                                    <span /> Inactive
                                                                </Link>
                                                            </div>
                                                        </div>
                                                        <div className="main_button_low">
                                                            <Link to="/seller/inventory_alert_2">Low inventory</Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>

            {/* ==============script linking start=============== */}
            {/* ==============script linking end=============== */}
        </div>

    )
}

export default SellerInventory