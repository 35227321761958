import React, { useState, useEffect } from 'react'
import './scss/BuyerInProgessOrdersContent.css'
import { Link, useNavigate } from 'react-router-dom'
import { useFormik } from "formik";
import { toast } from 'react-hot-toast';
import * as Yup from "yup";
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { getReasonList } from '../../../Redux/action/MasterAction';
import { jsPDF } from 'jspdf';
let host = process.env.REACT_APP_API_SERVER;
const BuyerInProgessOrdersContent = ({ InProgressOrdersData }) => {
    const dispatch = useDispatch();
    useEffect(() => {

        dispatch(getReasonList())

    }, []);
    const {

        loadingReasonListApi,
        ReasonListApiData,
        ReasonListApiError,

    } = useSelector(state => state?.BuyerReducer);
    const [cancelId, setcancelId] = useState();
    const OrderStatus = {
        1: { label: 'Order Placed' },
        2: { label: 'Seller Confirmed' },
        3: { label: 'Dispatched' },
        4: { label: 'Picked Up by Courier Parner' },
        5: { label: 'Out For Delivery' },
        6: { label: 'Delivered' },
        7: { label: 'Returned' },
        8: { label: 'Exchanged' },
        9: { label: 'Return Parcel Received' },
        10: { label: 'Exchange Parcel Received' },
        11: { label: 'Cancelled' },
        12: { label: 'Return Requested' },
        13: { label: 'Cancel Requested' },

    };
    const getStatusLabel = (status) => {
        return OrderStatus[status]?.label || 'Unknown Status';
    };

    const config = {
        headers: {

            'content-type': 'application/json',
            "auth-token": localStorage.getItem("buyer_token")
        }
    };

    const formik = useFormik({
        initialValues: {
            reason_id: "",
            message: "",
            orderDetailId: "",
        },
        validationSchema: Yup.object().shape({
            reason_id: Yup.string().required("This field is required."),
            message: Yup.string().required("This field is required."),
        }),
        onSubmit: async (values, { setSubmitting, resetForm }) => {
            try {
                setSubmitting(true);
                values.orderDetailId = cancelId;
                console.log('value going', values)

                await axios.post(`${host}/buyer/cancel-order`, values, config)
                    .then(function (response) {
                        if (response.status == 200) {
                            triggerModalClose();
                            setSubmitting(false)
                            toast.success("Order cancelled sucessfully");
                            resetForm();

                        } else {
                            setSubmitting(false)
                            toast.error(response.data.message);
                        }

                    })
                    .catch(function (error) {
                        setSubmitting(false)
                        console.log(error)
                        toast.error(error.response.data.message);
                    })
            }
            catch (error) {
                setSubmitting(false)
                toast.error('Error submitting form. Please try again.');
            }
        }
    });

    const triggerModalClose = () => {
        const closeButton = document.getElementById('close-modal');
        if (closeButton) {
            closeButton.click();
        }

    };

    const generateInvoice = (invoice_Data) => {
        const doc = new jsPDF();
        const invoiceData = JSON.parse(invoice_Data)
        doc.text(`Customer Name: ${invoiceData.CustomerName}`, 10, 10);
        doc.text(`Order ID: ${invoiceData.order_item_id}`, 10, 20);
        doc.text(`Order Date: ${invoiceData.orderDate}`, 10, 30);
        doc.text(`Order Time: ${invoiceData.orderTime}`, 10, 40);
        doc.text(`Address: ${invoiceData.address}`, 10, 50);
        doc.text(`Total Discount: AFN ${invoiceData.total_discount}`, 10, 60);
        doc.text(`Total Amount: AFN ${invoiceData.total_amount}`, 10, 70);
        doc.text(`Payable Amount: AFN ${invoiceData.payable_amount}`, 10, 80);
        doc.text(`Shipping Cost: ${invoiceData.shipping_cost}`, 10, 90);
        doc.text(`Total Tax: AFN ${invoiceData.total_tax}`, 10, 100);

        doc.save('invoice.pdf');
    };
    return (
        <div className='buyer-completed-orders'>
            <>

                {/* Your order */}

                <div className="order_section">
                    <div className='container-fluid'>
                        {InProgressOrdersData?.map((data) => (
                            <div className="row">
                                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                    <div className="order_section_inner mb-5">

                                        <div className="track_order_section_1">
                                            <div className="track_order_section_right">
                                                <Link to="buyer/order-details" className=' text-center'>
                                                    Order Placed <br /> <span className='heading-sm'>{data?.orderPlacedDate ? new Date(data.orderPlacedDate).toDateString() : 'No date available'}</span>
                                                </Link>
                                                <Link to={`/buyer/order-details/${data?.orderItemNumber}`} className='text-center'>
                                                    Total <br /> <span className='heading-sm'>AFN.{data?.orderItemTotalPrice}</span>
                                                </Link>
                                                <Link to={`/buyer/order-details/${data?.orderItemNumber}`} className='text-center'>
                                                    Shipping To <br /> <span className='heading-sm'>{data?.buyerName}</span>
                                                </Link>
                                            </div>
                                            <div className="track_order_section_left">
                                                <Link to={`/buyer/order-details/${data?.orderItemNumber}`}>
                                                    Order Number : <span>{data?.orderItemNumber}</span>
                                                </Link>
                                                <p><span><Link to={`/buyer/order-details/${data?.orderItemNumber}`}>View order details</Link></span>|<span><a onClick={() => generateInvoice(data?.invoice_data)} style={{ cursor: 'pointer' }}>Invoice</a></span></p>
                                            </div>
                                        </div>
                                        <div className='inner_sec_heading'>
                                            {/* <h5><strong>Order Item Number : {data?.orderItemNumber}</strong></h5> */}
                                            {/* <h6>
                                            Lorem Ipsum is simply dummy text of the printing
                                        </h6> */}
                                        </div>

                                        <div className="track_order_details">

                                            <div className="track_order_details_main">

                                                <img src={data?.image ? `${host}${data?.image}` : ''} className="img-fluid" />
                                            </div>
                                            <div className="track_order_details_main">
                                                <p>
                                                    {data?.name}
                                                </p>
                                                <p className='main_date'>Return window closed on 01-Jun-2024</p>
                                                <div className='main_buttons'>
                                                    <button type="button" data-bs-toggle="modal"
                                                        data-bs-target="#exampleModal1" className='button-white'>cancel order</button>
                                                    <Link to={`/buyer/track-order/${data?.orderItemNumber}`}><button type="button" className='button-white'>Track order</button></Link>
                                                </div>
                                            </div>
                                            <div className="track_order_details_main text-center">
                                                <p>
                                                    Details
                                                </p>
                                                <h6>{data?.childCategory}</h6>
                                            </div>
                                            <div className="track_order_details_main text-center">
                                                <p>
                                                    Status
                                                </p>
                                                <h6>{getStatusLabel(data?.orderStatus)}</h6>
                                            </div>
                                            <div className="track_order_details_main text-center">
                                                <p>Quantity</p>
                                                <h6>{data?.quantity}</h6>
                                            </div>

                                        </div>

                                    </div>

                                </div>
                            </div>
                        ))}
                    </div>
                </div>

                {/* Your order */}
            </>
            <div
                className="modal fade"
                id="exampleModal1"
                tabIndex={-1}
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog cancel_popup">
                    <div className="modal-content">
                        <form method='post' onSubmit={formik.handleSubmit}>
                            <div className="modal-body">
                                <button type="button" className="btn-close" hidden={true} id='close-modal' data-bs-dismiss="modal" aria-label="Close" />
                                <img src="/assets/images/cancel_order.png" className="img-fluid" />
                                <h2>Are you sure you want to cancel your product</h2>
                                <select type="text" name='reason_id' className='form-control mb-2'
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.reason_id}

                                >
                                    <option>Select reason to cancel</option>
                                    {ReasonListApiData?.map((data) => (<option key={data?.id} value={data?.id}>{data?.name}</option>))}
                                </select>
                                <div className="help-block with-errors">
                                    {formik.touched.reason_id && formik.errors.reason_id ? (
                                        <div className='text-danger'>{formik.errors.reason_id}</div>
                                    ) : null}
                                </div>
                                <textarea name='message' onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.message}></textarea>
                                <div className="help-block with-errors">
                                    {formik.touched.message && formik.errors.message ? (
                                        <div className='text-danger'>{formik.errors.message}</div>
                                    ) : null}
                                </div>
                                <div className='cancel_order_popup text-end'>
                                    {/* <Link to="/buyer/product-details"><button type='button'>View Details</button></Link> */}
                                    <button className='me-0' type='submit' disabled={formik.isSubmitting}>Cancel Order</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>


        </div>

    )
}

export default BuyerInProgessOrdersContent