const buyerInitialState = {
    //home banner
    loadingHomeBannerDataListApi: false,
    HomeBannerDataListApiData: [],
    HomeBannerDataListApiError: '',

    // home second section
    loadingHomeSecondSectionDataListApi: false,
    HomeSecondSectionDataListApiData: [],
    HomeSecondSectionDataListApiError: '',

    // home four section
    loadingHomeFourSectionDataListApi: false,
    HomeFourSectionDataListApiData: [],
    HomeFourSectionDataListApiError: '',

    // home shop by category section
    loadingHomeShopByCategorySectionDataListApi: false,
    HomeShopByCategorySectionDataListApiData: [],
    HomeShopByCategorySectionDataListApiError: '',

    // Mega menu
    loadingMegaMenuDataListApi: false,
    MegaMenuDataListApiData: [],
    MegaMenuDataListApiError: '',

    // Buyer Address List
    loadingBuyerAddressListDataListApi: false,
    BuyerAddressListDataListApiData: [],
    BuyerAddressListDataListApiError: '',

    // Buyer Address Edit
    loadingBuyerAddressEditDataListApi: false,
    BuyerAddressEditDataListApiData: [],
    BuyerAddressEditDataListApiError: '',

    // Buyer Profile
    loadingBuyerProfileDataListApi: false,
    BuyerProfileDataListApiData: [],
    BuyerProfileDataListApiError: '',


    // Buyer Nav Profile
    loadingBuyerNavProfileDataListApi: false,
    BuyerNavProfileDataListApiData: [],
    BuyerNavProfileDataListApiError: '',

    // Home Second slider
    loadingHomeSecondSliderDataListApi: false,
    HomeSecondSliderDataListApiData: [],
    HomeSecondSliderDataListApiError: '',
    // Buyer Home trending now
    loadingHomeTrendingNowDataListApi: false,
    HomeTrendingNowDataListApiData: [],
    HomeTrendingNowDataListApiError: '',
    // Buyer Last Section
    loadingHomeLastSectionDataListApi: false,
    HomeLastSectionDataListApiData: [],
    HomeLastSectionDataListApiError: '',

    // Product
    loadingProductDataListApi: false,
    ProductDataListApiData: [],
    ProductDataListApiError: '',

    // Home Four Section Product
    loadingHomeFourSectionProductDataListApi: false,
    HomeFourSectionProductDataListApiData: [],
    HomeFourSectionProductDataListApiError: '',

    // Home last Section Product
    loadingHomeLastSectionProductDataListApi: false,
    HomeLastSectionProductDataListApiData: [],
    HomeLastSectionProductDataListApiError: '',

    // Product Detail
    loadingProductDetailDataListApi: false,
    ProductDetailDataListApiData: [],
    ProductDetailDataListApiError: '',

    // Product Variation Detail
    loadingProductVariationDetailDataListApi: false,
    ProductVariationDetailDataListApiData: [],
    ProductVariationDetailDataListApiError: '',


    // Product Variant colour and size list
    loadingProductVariantSizeColourListApi: false,
    ProductVariantSizeColourListApiData: [],
    ProductVariantSizeColourListApiError: '',

    // Product Image
    loadingProductImageDataListApi: false,
    ProductImageDataListApiData: [],
    ProductImageDataListApiError: '',

    // Related products
    loadingRelatedProductDataListApi: false,
    RelatedProductDataListApiData: [],
    RelatedProductDataListApiError: '',

    // Frequently Brought Together
    loadingFrequentlyBroughtTogetherDataListApi: false,
    FrequentlyBroughtTogetherDataListApiData: [],
    FrequentlyBroughtTogetherDataListApiError: '',

    // Wishlist
    loadingWishlistDataListApi: false,
    WishlistDataListApiData: [],
    WishlistDataListApiError: '',

    // Buyer Review Page Product Detail
    loadingBuyerReviewPageProductDetailListApi: false,
    BuyerReviewPageProductDetailListApiData: [],
    BuyerReviewPageProductDetailListApiError: '',

    //Buyer cart product list
    loadingBuyerCartProductListApi: false,
    BuyerCartProductListApiData: [],
    BuyerCartProductListApiError: '',

    //Buyer cart product list
    loadingBuyerMoreOfWhatYouLikeListApi: false,
    BuyerMoreOfWhatYouLikeListApiData: [],
    BuyerMoreOfWhatYouLikeListApiError: '',

    //Buyer order list
    loadingBuyerOrderListApi: false,
    BuyerOrderListApiData: [],
    BuyerOrderListApiError: '',

    //Buyer order list
    loadingReasonListApi: false,
    ReasonListApiData: [],
    ReasonListApiError: '',

    //Buyer order detail
    loadingBuyerOrderDetailListApi: false,
    BuyerOrderDetailListApiData: [],
    BuyerOrderDetailListApiError: '',

    //Buyer buy it again list
    loadingBuyerBuyItAgainListApi: false,
    BuyerBuyItAgainListApiData: [],
    BuyerBuyItAgainListApiError: '',


    // Home New Release Product
    loadingHomeNewReleaseProductDataListApi: false,
    HomeNewReleaseProductDataListApiData: [],
    HomeNewReleaseProductDataListApiError: '',
}

const BuyerReducer = (state = buyerInitialState, action) => {
    switch (action.type) {

        case 'FETCH_HOME_BANNER_REQUEST':
            return {
                ...state,
                loadingHomeBannerDataListApi: true,
                HomeBannerDataListApiError: '',
            };
        case 'FETCH_HOME_BANNER_SUCCESS':
            return {
                ...state,
                loadingHomeBannerDataListApi: false,
                HomeBannerDataListApiData: action.payload,
            };
        case 'FETCH_HOME_BANNER_FAILURE':

            return {
                ...state,
                loadingHomeBannerDataListApi: false,
                HomeBannerDataListApiError: action.payload,
            };


        case 'FETCH_HOME_SECOND_SECTION_REQUEST':
            return {
                ...state,
                loadingHomeSecondSectionDataListApi: true,
                HomeSecondSectionDataListApiError: '',
            };
        case 'FETCH_HOME_SECOND_SECTION_SUCCESS':
            return {
                ...state,
                loadingHomeSecondSectionDataListApi: false,
                HomeSecondSectionDataListApiData: action.payload,
            };
        case 'FETCH_HOME_SECOND_SECTION_FAILURE':

            return {
                ...state,
                loadingHomeSecondSectionDataListApi: false,
                HomeSecondSectionDataListApiError: action.payload,
            };

        case 'FETCH_HOME_FOUR_SECTION_REQUEST':
            return {
                ...state,
                loadingHomeFourSectionDataListApi: true,
                HomeFourSectionDataListApiError: '',
            };
        case 'FETCH_HOME_FOUR_SECTION_SUCCESS':
            return {
                ...state,
                loadingHomeFourSectionDataListApi: false,
                HomeFourSectionDataListApiData: action.payload,
            };
        case 'FETCH_HOME_FOUR_SECTION_FAILURE':

            return {
                ...state,
                loadingHomeFourSectionDataListApi: false,
                HomeFourSectionDataListApiError: action.payload,
            };

        case 'FETCH_HOME_SHOP_BY_CATEGORY_SECTION_REQUEST':
            return {
                ...state,
                loadingHomeShopByCategorySectionDataListApi: true,
                HomeShopByCategorySectionDataListApiError: '',
            };
        case 'FETCH_HOME_SHOP_BY_CATEGORY_SECTION_SUCCESS':
            return {
                ...state,
                loadingHomeShopByCategorySectionDataListApi: false,
                HomeShopByCategorySectionDataListApiData: action.payload,
            };
        case 'FETCH_HOME_SHOP_BY_CATEGORY_SECTION_FAILURE':

            return {
                ...state,
                loadingHomeShopByCategorySectionDataListApi: false,
                HomeShopByCategorySectionDataListApiError: action.payload,
            };
        case 'FETCH_BUYER_MEGA_MENU_REQUEST':
            return {
                ...state,
                loadingMegaMenuDataListApi: true,
                MegaMenuDataListApiError: '',
            };
        case 'FETCH_BUYER_MEGA_MENU_SUCCESS':
            return {
                ...state,
                loadingMegaMenuDataListApi: false,
                MegaMenuDataListApiData: action.payload,
            };
        case 'FETCH_BUYER_MEGA_MENU_FAILURE':

            return {
                ...state,
                loadingMegaMenuDataListApi: false,
                MegaMenuDataListApiError: action.payload,
            };
        case 'FETCH_BUYER_ADDRESS_LIST_REQUEST':
            return {
                ...state,
                loadingBuyerAddressListDataListApi: true,
                BuyerAddressListDataListApiError: '',
            };
        case 'FETCH_BUYER_ADDRESS_LIST_SUCCESS':
            return {
                ...state,
                loadingBuyerAddressListDataListApi: false,
                BuyerAddressListDataListApiData: action.payload,
            };
        case 'FETCH_BUYER_ADDRESS_LIST_FAILURE':

            return {
                ...state,
                loadingBuyerAddressListDataListApi: false,
                BuyerAddressListDataListApiError: action.payload,
            };

        case 'FETCH_BUYER_ADDRESS_EDIT_REQUEST':
            return {
                ...state,
                loadingBuyerAddressEditDataListApi: true,
                BuyerAddressEditDataListApiError: '',
            };
        case 'FETCH_BUYER_ADDRESS_EDIT_SUCCESS':
            return {
                ...state,
                loadingBuyerAddressEditDataListApi: false,
                BuyerAddressEditDataListApiData: action.payload,
            };
        case 'FETCH_BUYER_ADDRESS_EDIT_FAILURE':

            return {
                ...state,
                loadingBuyerAddressEditDataListApi: false,
                BuyerAddressEditDataListApiError: action.payload,
            };


        case 'FETCH_BUYER_PROFILE_DETAILS_REQUEST':
            return {
                ...state,
                loadingBuyerProfileDataListApi: true,
                BuyerProfileDataListApiError: '',
            };
        case 'FETCH_BUYER_PROFILE_DETAILS_SUCCESS':
            return {
                ...state,
                loadingBuyerProfileDataListApi: false,
                BuyerProfileDataListApiData: action.payload,
            };
        case 'FETCH_BUYER_PROFILE_DETAILS_FAILURE':

            return {
                ...state,
                loadingBuyerProfileDataListApi: false,
                BuyerProfileDataListApiError: action.payload,
            };

        case 'FETCH_HOME_LAST_SECTION_REQUEST':
            return {
                ...state,
                loadingHomeLastSectionDataListApi: true,
                HomeLastSectionDataListApiError: '',
            };
        case 'FETCH_HOME_LAST_SECTION_SUCCESS':
            return {
                ...state,
                loadingHomeLastSectionDataListApi: false,
                HomeLastSectionDataListApiData: action.payload,
            };
        case 'FETCH_HOME_LAST_SECTION_FAILURE':

            return {
                ...state,
                loadingHomeLastSectionDataListApi: false,
                HomeLastSectionDataListApiError: action.payload,
            };

        case 'FETCH_HOME_TRENDING_NOW_REQUEST':
            return {
                ...state,
                loadingHomeTrendingNowDataListApi: true,
                HomeTrendingNowDataListApiError: '',
            };
        case 'FETCH_HOME_TRENDING_NOW_SUCCESS':
            return {
                ...state,
                loadingHomeTrendingNowDataListApi: false,
                HomeTrendingNowDataListApiData: action.payload,
            };
        case 'FETCH_HOME_TRENDING_NOW_FAILURE':

            return {
                ...state,
                loadingHomeTrendingNowDataListApi: false,
                HomeTrendingNowDataListApiError: action.payload,
            };

        case 'FETCH_HOME_SECOND_SLIDER_REQUEST':
            return {
                ...state,
                loadingHomeSecondSliderDataListApi: true,
                HomeSecondSliderDataListApiError: '',
            };
        case 'FETCH_HOME_SECOND_SLIDER_SUCCESS':
            return {
                ...state,
                loadingHomeSecondSliderDataListApi: false,
                HomeSecondSliderDataListApiData: action.payload,
            };
        case 'FETCH_HOME_SECOND_SLIDER_FAILURE':

            return {
                ...state,
                loadingHomeSecondSliderDataListApi: false,
                HomeSecondSliderDataListApiError: action.payload,
            };

        case 'FETCH_PRODUCT_DATA_REQUEST':
            return {
                ...state,
                loadingProductDataListApi: true,
                ProductDataListApiError: '',
            };
        case 'FETCH_PRODUCT_DATA_SUCCESS':
            return {
                ...state,
                loadingProductDataListApi: false,
                ProductDataListApiData: action.payload,
            };
        case 'FETCH_PRODUCT_DATA_FAILURE':

            return {
                ...state,
                loadingProductDataListApi: false,
                ProductDataListApiError: action.payload,
            };

        case 'FETCH_HOME_FOUR_SECTION_PRODUCT_DATA_REQUEST':
            return {
                ...state,
                loadingHomeFourSectionProductDataListApi: true,
                HomeFourSectionProductDataListApiError: '',
            };
        case 'FETCH_HOME_FOUR_SECTION_PRODUCT_DATA_SUCCESS':
            return {
                ...state,
                loadingHomeFourSectionProductDataListApi: false,
                HomeFourSectionProductDataListApiData: action.payload,
            };
        case 'FETCH_HOME_FOUR_SECTION_PRODUCT_DATA_FAILURE':

            return {
                ...state,
                loadingHomeFourSectionProductDataListApi: false,
                HomeFourSectionProductDataListApiError: action.payload,
            };

        case 'FETCH_HOME_LAST_SECTION_PRODUCT_DATA_REQUEST':
            return {
                ...state,
                loadingHomeLastSectionProductDataListApi: true,
                HomeLastSectionProductDataListApiError: '',
            };
        case 'FETCH_HOME_LAST_SECTION_PRODUCT_DATA_SUCCESS':
            return {
                ...state,
                loadingHomeLastSectionProductDataListApi: false,
                HomeLastSectionProductDataListApiData: action.payload,
            };
        case 'FETCH_HOME_LAST_SECTION_PRODUCT_DATA_FAILURE':

            return {
                ...state,
                loadingHomeLastSectionProductDataListApi: false,
                HomeLastSectionProductDataListApiError: action.payload,
            };

        case 'FETCH_PRODUCT_DETAIL_DATA_REQUEST':
            return {
                ...state,
                loadingProductDetailDataListApi: true,
                ProductDetailDataListApiError: '',
            };
        case 'FETCH_PRODUCT_DETAIL_DATA_SUCCESS':
            return {
                ...state,
                loadingProductDetailDataListApi: false,
                ProductDetailDataListApiData: action.payload,
            };
        case 'FETCH_PRODUCT_DETAIL_DATA_FAILURE':

            return {
                ...state,
                loadingProductDetailDataListApi: false,
                ProductDetailDataListApiError: action.payload,
            };


        case 'FETCH_PRODUCT_VARIENT_DETAIL_DATA_REQUEST':
            return {
                ...state,
                loadingProductVariationDetailDataListApi: true,
                ProductVariationDetailDataListApiError: '',
            };
        case 'FETCH_PRODUCT_VARIENT_DETAIL_DATA_SUCCESS':
            return {
                ...state,
                loadingProductVariationDetailDataListApi: false,
                ProductVariationDetailDataListApiData: action.payload,
            };
        case 'FETCH_PRODUCT_VARIENT_DETAIL_DATA_FAILURE':

            return {
                ...state,
                loadingProductVariationDetailDataListApi: false,
                ProductVariationDetailDataListApiError: action.payload,
            };

        case 'FETCH_PRODUCT_VARIENT_SIZE_COLOUR_DATA_REQUEST':
            return {
                ...state,
                loadingProductVariantSizeColourListApi: true,
                ProductVariantSizeColourListApiError: '',
            };
        case 'FETCH_PRODUCT_VARIENT_SIZE_COLOUR_DATA_SUCCESS':
            return {
                ...state,
                loadingProductVariantSizeColourListApi: false,
                ProductVariantSizeColourListApiData: action.payload,
            };
        case 'FETCH_PRODUCT_VARIENT_SIZE_COLOUR_DATA_FAILURE':

            return {
                ...state,
                loadingProductVariantSizeColourListApi: false,
                ProductVariantSizeColourListApiError: action.payload,
            };

        case 'FETCH_PRODUCT_IMAGE_DATA_REQUEST':
            return {
                ...state,
                loadingProductImageDataListApi: true,
                ProductImageDataListApiError: '',
            };
        case 'FETCH_PRODUCT_IMAGE_DATA_SUCCESS':
            return {
                ...state,
                loadingProductImageDataListApi: false,
                ProductImageDataListApiData: action.payload,
            };
        case 'FETCH_PRODUCT_IMAGE_DATA_FAILURE':

            return {
                ...state,
                loadingProductImageDataListApi: false,
                ProductImageDataListApiError: action.payload,
            };


        case 'FETCH_RELATED_PRODUCTS_DATA_REQUEST':
            return {
                ...state,
                loadingRelatedProductDataListApi: true,
                RelatedProductDataListApiError: '',
            };
        case 'FETCH_RELATED_PRODUCTS_DATA_SUCCESS':
            return {
                ...state,
                loadingRelatedProductDataListApi: false,
                RelatedProductDataListApiData: action.payload,
            };
        case 'FETCH_RELATED_PRODUCTS_DATA_FAILURE':

            return {
                ...state,
                loadingRelatedProductDataListApi: false,
                RelatedProductDataListApiError: action.payload,
            };


        case 'FETCH_FREQUENTLY_BROUGHT_TOGETHER_DATA_REQUEST':
            return {
                ...state,
                loadingFrequentlyBroughtTogetherDataListApi: true,
                FrequentlyBroughtTogetherDataListApiError: '',
            };
        case 'FETCH_FREQUENTLY_BROUGHT_TOGETHER_DATA_SUCCESS':

            return {
                ...state,
                loadingFrequentlyBroughtTogetherDataListApi: false,
                FrequentlyBroughtTogetherDataListApiData: action.payload,
            };
        case 'FETCH_FREQUENTLY_BROUGHT_TOGETHER_DATA_FAILURE':

            return {
                ...state,
                loadingFrequentlyBroughtTogetherDataListApi: false,
                FrequentlyBroughtTogetherDataListApiError: action.payload,
            };

        case 'FETCH_BUYER_WISH_LIST_REQUEST':
            return {
                ...state,
                loadingWishlistDataListApi: true,
                WishlistDataListApiError: '',
            };
        case 'FETCH_BUYER_WISH_LIST_SUCCESS':

            return {
                ...state,
                loadingWishlistDataListApi: false,
                WishlistDataListApiData: action.payload,
            };
        case 'FETCH_BUYER_WISH_LIST_FAILURE':

            return {
                ...state,
                loadingWishlistDataListApi: false,
                WishlistDataListApiError: action.payload,
            };

        case 'FETCH_BUYER_REVIEW_PAGE_PRODUCT_DETAIL_REQUEST':
            return {
                ...state,
                loadingBuyerReviewPageProductDetailListApi: true,
                BuyerReviewPageProductDetailListApiError: '',
            };
        case 'FETCH_BUYER_REVIEW_PAGE_PRODUCT_DETAIL_SUCCESS':

            return {
                ...state,
                loadingBuyerReviewPageProductDetailListApi: false,
                BuyerReviewPageProductDetailListApiData: action.payload,
            };
        case 'FETCH_BUYER_REVIEW_PAGE_PRODUCT_DETAIL_FAILURE':

            return {
                ...state,
                loadingBuyerReviewPageProductDetailListApi: false,
                BuyerReviewPageProductDetailListApiError: action.payload,
            };

        case 'FETCH_BUYER_CART_PRODUCT_LIST_REQUEST':
            return {
                ...state,
                loadingBuyerCartProductListApi: true,
                BuyerCartProductListApiError: '',
            };
        case 'FETCH_BUYER_CART_PRODUCT_LIST_SUCCESS':

            return {
                ...state,
                loadingBuyerCartProductListApi: false,
                BuyerCartProductListApiData: action.payload,
            };
        case 'FETCH_BUYER_CART_PRODUCT_LIST_FAILURE':

            return {
                ...state,
                loadingBuyerCartProductListApi: false,
                BuyerCartProductListApiError: action.payload,
            };

        case 'FETCH_BUYER_MORE_OF_WHAT_YOU_LIKE_LIST_REQUEST':
            return {
                ...state,
                loadingBuyerMoreOfWhatYouLikeListApi: true,
                BuyerMoreOfWhatYouLikeListApiError: '',
            };
        case 'FETCH_BUYER_MORE_OF_WHAT_YOU_LIKE_LIST_SUCCESS':

            return {
                ...state,
                loadingBuyerMoreOfWhatYouLikeListApi: false,
                BuyerMoreOfWhatYouLikeListApiData: action.payload,
            };
        case 'FETCH_BUYER_MORE_OF_WHAT_YOU_LIKE_LIST_FAILURE':

            return {
                ...state,
                loadingBuyerMoreOfWhatYouLikeListApi: false,
                BuyerMoreOfWhatYouLikeListApiError: action.payload,
            };

        case 'FETCH_BUYER_ORDER_LIST_REQUEST':
            return {
                ...state,
                loadingBuyerOrderListApi: true,
                BuyerOrderListApiError: '',
            };
        case 'FETCH_BUYER_ORDER_LIST_SUCCESS':

            return {
                ...state,
                loadingBuyerOrderListApi: false,
                BuyerOrderListApiData: action.payload,
            };
        case 'FETCH_BUYER_ORDER_LIST_FAILURE':

            return {
                ...state,
                loadingBuyerOrderListApi: false,
                BuyerOrderListApiError: action.payload,
            };


        case 'FETCH_REASON_LIST_REQUEST':
            return {
                ...state,
                loadingReasonListApi: true,
                ReasonListApiError: '',
            };
        case 'FETCH_REASON_LIST_SUCCESS':

            return {
                ...state,
                loadingReasonListApi: false,
                ReasonListApiData: action.payload,
            };
        case 'FETCH_REASON_LIST_FAILURE':

            return {
                ...state,
                loadingReasonListApi: false,
                ReasonListApiError: action.payload,
            };

        case 'FETCH_BUYER_ORDER_DETAIL_REQUEST':
            return {
                ...state,
                loadingBuyerOrderDetailListApi: true,
                BuyerOrderDetailListApiError: '',
            };
        case 'FETCH_BUYER_ORDER_DETAIL_SUCCESS':

            return {
                ...state,
                loadingBuyerOrderDetailListApi: false,
                BuyerOrderDetailListApiData: action.payload,
            };
        case 'FETCH_BUYER_ORDER_DETAIL_FAILURE':

            return {
                ...state,
                loadingBuyerOrderDetailListApi: false,
                BuyerOrderDetailListApiError: action.payload,
            };

        case 'FETCH_BUYER_BUY_IT_AGAIN_LIST_REQUEST':
            return {
                ...state,
                loadingBuyerBuyItAgainListApi: true,
                BuyerBuyItAgainListApiError: '',
            };
        case 'FETCH_BUYER_BUY_IT_AGAIN_LIST_SUCCESS':

            return {
                ...state,
                loadingBuyerBuyItAgainListApi: false,
                BuyerBuyItAgainListApiData: action.payload,
            };
        case 'FETCH_BUYER_BUY_IT_AGAIN_LIST_FAILURE':

            return {
                ...state,
                loadingBuyerBuyItAgainListApi: false,
                BuyerBuyItAgainListApiError: action.payload,
            };


        case 'FETCH_HOME_NEW_RELEASE_PRODUCT_DATA_REQUEST':
            return {
                ...state,
                loadingHomeNewReleaseProductDataListApi: true,
                HomeNewReleaseProductDataListApiError: '',
            };
        case 'FETCH_HOME_NEW_RELEASE_PRODUCT_DATA_SUCCESS':

            return {
                ...state,
                loadingHomeNewReleaseProductDataListApi: false,
                HomeNewReleaseProductDataListApiData: action.payload,
            };
        case 'FETCH_HOME_NEW_RELEASE_PRODUCT_DATA_FAILURE':

            return {
                ...state,
                loadingHomeNewReleaseProductDataListApi: false,
                HomeNewReleaseProductDataListApiError: action.payload,
            };


        case 'FETCH_BUYER_NAV_PROFILE_DETAILS_REQUEST':
            return {
                ...state,
                loadingBuyerNavProfileDataListApi: true,
                BuyerNavProfileDataListApiError: '',
            };
        case 'FETCH_BUYER_NAV_PROFILE_DETAILS_SUCCESS':

            return {
                ...state,
                loadingBuyerNavProfileDataListApi: false,
                BuyerNavProfileDataListApiData: action.payload,
            };
        case 'FETCH_BUYER_NAV_PROFILE_DETAILS_FAILURE':

            return {
                ...state,
                loadingBuyerNavProfileDataListApi: false,
                BuyerNavProfileDataListApiError: action.payload,
            };
        default:
            return state;
    }
}
export default BuyerReducer;