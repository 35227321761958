import React from 'react'
import SellerHeader from '../../SellerEssentials/SellerHeader'
import SellerSidebar from '../../SellerEssentials/SellerSidebar'
import { Link } from 'react-router-dom'

const SellerNewOrderShipment = () => {
    return (
        <div className='seller'>
            <div className="page-wrapper chiller-theme toggled">
                <SellerSidebar />
                {/* sidebar-wrapper  */}
                <main className="page-content container-fluid">
                    {/* ==================header================== */}
                    <SellerHeader />
                    {/* ==================header================== */}
                    <div className="row ">
                        <div className="col-md-12">
                            <div className="main-nav-Order">
                                <div className="row">
                                    <div className="col-md-7">
                                        <h2>
                                            {" "}
                                            <Link to="/seller/all_orders">
                                                <img src="/assets/images/seller_images/left_side.png" alt="left" />
                                                <span>Orders</span>
                                                /Shippment
                                            </Link>{" "}
                                        </h2>
                                    </div>
                                    <div className="col-md-5">
                                        <div className="text-right ">
                                            <Link
                                                type="button"
                                                className="btn btn-primary next-step"
                                                data-bs-toggle="modal"
                                                data-bs-target="#buyer_details"
                                            >
                                                Contact buyer
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="card_order_main">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <h6>Current Shipment</h6>
                                            <div className="product-card-main">
                                                <div className="row">
                                                    <div className="col-md-3 border-right">
                                                        <div className="main_img_ship">
                                                            <div className="images_icon_ship">
                                                                <img src="/assets/images/seller_images/upload-solid.png" alt="upload" />
                                                            </div>
                                                            <div className="main_tracker">
                                                                <h4>Shipment Number Here</h4>
                                                                <p>
                                                                    On the way <span>24 June</span>
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-9">
                                                        <div className="progress-container-1">
                                                            <div className="progress-bar-1">
                                                                <div className="progress" />
                                                                <div className="circle middle" />
                                                                <div className="circle middle" />
                                                                <div className="circle middle" />
                                                                <div className="circle middle" />
                                                            </div>
                                                            <div className="main_flex_detail">
                                                                <div className="start_new color_form_to">From</div>
                                                                <div className="end_new color_form_to">To</div>
                                                            </div>
                                                            <div className="main_flex_detail">
                                                                <div className="start_new">Kabul, Afghanistan</div>
                                                                <div className="end_new">
                                                                    Jalalabad, Afghanistan
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row pb-3 main_border_padd">
                                        <div className="col-lg-6">
                                            <div className="tab-content bg-light" id="nav-tabContent">
                                                <div
                                                    className="tab-pane fade active show"
                                                    id="nav-all-orders"
                                                    role="tabpanel"
                                                    aria-labelledby="nav-all-orders-tab"
                                                >
                                                    <div className="main_view_detail">
                                                        <img src="/assets/images/seller_images/Rectangle_1.png" alt="rect" />
                                                    </div>
                                                </div>
                                                <div
                                                    className="tab-pane fade"
                                                    id="nav-delivered"
                                                    role="tabpanel"
                                                    aria-labelledby="nav-delivered-tab"
                                                >
                                                    <div className="main_view_detail">
                                                        <img src="/assets/images/seller_images/Rectangle_2.png" alt="rect" />
                                                    </div>
                                                </div>
                                                <div
                                                    className="tab-pane fade"
                                                    id="nav-pending"
                                                    role="tabpanel"
                                                    aria-labelledby="nav-pending-tab"
                                                >
                                                    <div className="main_view_detail">
                                                        <img src="/assets/images/seller_images/Rectangle_3.png" alt="rect" />
                                                    </div>
                                                </div>
                                            </div>
                                            <nav>
                                                <div
                                                    className="nav nav-tabs mb-3 all_Information_main"
                                                    id="nav-tab"
                                                    role="tablist"
                                                >
                                                    <button
                                                        className="nav-link active all_Info_height"
                                                        id="nav-all-orders-tab"
                                                        data-bs-toggle="tab"
                                                        data-bs-target="#nav-all-orders"
                                                        type="button"
                                                        role="tab"
                                                        aria-controls="nav-all-orders"
                                                        aria-selected="true"
                                                    >
                                                        <img src="/assets/images/seller_images/Rectangle_1.png" alt="" />
                                                    </button>
                                                    <button
                                                        className="nav-link all_Info_height"
                                                        id="nav-delivered-tab"
                                                        data-bs-toggle="tab"
                                                        data-bs-target="#nav-delivered"
                                                        type="button"
                                                        role="tab"
                                                        aria-controls="nav-delivered"
                                                        aria-selected="false"
                                                    >
                                                        <img src="/assets/images/seller_images/Rectangle_2.png" alt="" />
                                                    </button>
                                                    <button
                                                        className="nav-link all_Info_height"
                                                        id="nav-pending-tab"
                                                        data-bs-toggle="tab"
                                                        data-bs-target="#nav-pending"
                                                        type="button"
                                                        role="tab"
                                                        aria-controls="nav-pending"
                                                        aria-selected="false"
                                                    >
                                                        <img src="/assets/images/seller_images/Rectangle_3.png" alt="" />
                                                    </button>
                                                </div>
                                            </nav>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="overall_sales height_auto">
                                                <div className="users-table">
                                                    <table className="posts-table">
                                                        <tbody className="td_section_text_order">
                                                            <tr>
                                                                <td width="50%">Order ID</td>
                                                                <td className="table-text-color" width="50%">
                                                                    Order ID
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>Pick Up Date &amp; Time</td>
                                                                <td className="table-text-color">
                                                                    Pick Up Date &amp; Time
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>Package Type</td>
                                                                <td className="table-text-color">Package Type</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Package Weight</td>
                                                                <td className="table-text-color">180 Grams</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Easy Ship Fee</td>
                                                                <td className="table-text-color">
                                                                    <span className="main_color_gree">AFN 3.9</span>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    Any Comments <br />
                                                                    <div className="main_img_sec">
                                                                        <img src="/assets/images/seller_images/capchar.png" alt="code" />
                                                                    </div>
                                                                </td>
                                                                <td className="table-text-color">
                                                                    Lorem ipsum dolor sit amet consectetur.
                                                                    Suspendisse risus eget tincidunt morbi.
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-12 mt-3">
                                        <div className="text-right ">
                                            <Link
                                                to="/seller/new-order-details-after-deliver"
                                                className="btn btn-primary next-step"
                                            >
                                                Back
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
            <div
                className="modal fade"
                id="buyer_details"
                data-bs-backdrop="static"
                data-bs-keyboard="false"
                tabIndex={-1}
                aria-labelledby="staticBackdropLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog dasbord-main_new">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="request_send_for_modul padd_none">
                                <h6>Buyer Details </h6>
                                <div className="row">
                                    <div className="col-md-6 border-right">
                                        <div className="users-table">
                                            <h4 className="request_send_text">Shipping Details</h4>
                                            <table className="posts-table">
                                                <tbody className="td_section_text_order">
                                                    <tr>
                                                        <td>Shipping:</td>
                                                        <td className="table-text-color">Standard</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Sales Channel:</td>
                                                        <td className="table-text-color">Afghan Bazar</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Order Date:</td>
                                                        <td className="table-text-color">06-07-2024</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Order ID:</td>
                                                        <td className="table-text-color">Order ID</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Unit Price</td>
                                                        <td className="table-text-color">Price Here</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="users-table">
                                            <h4 className="request_send_text">Customer Details</h4>
                                            <table className="posts-table">
                                                <tbody className="td_section_text_order">
                                                    <tr>
                                                        <td>Name:</td>
                                                        <td className="table-text-color">Name Here</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Phone Number:</td>
                                                        <td className="table-text-color">Phone Number</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Shipped To:</td>
                                                        <td className="table-text-color">Shipped To</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Email Address:</td>
                                                        <td className="table-text-color">Email Address</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Product:</td>
                                                        <td className="table-text-color">Product Name</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <div className="col-md-6">
                                        <div className="main_detail_unshiped">
                                            <Link to="/seller/#" className="status">
                                                Status
                                            </Link>
                                            <Link to="/seller/#" className="unshipped">
                                                Unshipped
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="text-right">
                                            <button
                                                type="button"
                                                className="btn btn-primary"
                                                data-bs-dismiss="modal"
                                            >
                                                Close
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* ==============script linking start=============== */}
            {/* ==============script linking end=============== */}
        </div>

    )
}

export default SellerNewOrderShipment