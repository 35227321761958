import React from 'react';
import { Link, useNavigate } from 'react-router-dom';

let host = process.env.REACT_APP_API_SERVER;

const SellProduct = ({ HomeFourSectionDataListApiData }) => {
  const navigate = useNavigate();

  const handleClick = (slug, productVariant) => {

    if (productVariant?.colourId && productVariant?.sizeId) {

      navigate(`/buyer/product-details/${slug}?colorId=${productVariant?.colourId}&sizeId=${productVariant?.sizeId}`)
    }
    else if (productVariant?.colourId == null && productVariant?.sizeId) {

      navigate(`/buyer/product-details/${slug}?sizeId=${productVariant?.sizeId}`)
    }
    else if (productVariant?.colourId && productVariant?.sizeId == null) {

      navigate(`/buyer/product-details/${slug}?colorId=${productVariant?.colourId}`)
    }
    else {

      navigate(`/buyer/product-details/${slug}`)

    }
  }

  const handleNavigateClick = (slug, productVariant) => {

    if (productVariant?.colourId && productVariant?.sizeId) {

      navigate(`/buyer/product-details/${slug}?colorId=${productVariant?.colourId}&sizeId=${productVariant?.sizeId}`)
    }
    else if (productVariant?.colourId == null && productVariant?.sizeId) {

      navigate(`/buyer/product-details/${slug}?sizeId=${productVariant?.sizeId}`)
    }
    else if (productVariant?.colourId && productVariant?.sizeId == null) {

      navigate(`/buyer/product-details/${slug}?colorId=${productVariant?.colourId}`)
    }
    else {

      navigate(`/buyer/product-details/${slug}`)

    }
  }

  return (
    <div className="sell_product">
      <div className="container-fluid">
        <div className="row">
          {HomeFourSectionDataListApiData?.map((cdata, cindex) => (
            <div
              className="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12 mb-3" key={cindex}
              data-aos="fade-up"  // AOS animation type
              data-aos-delay={`${cindex * 200}`}

            >
              <div className="sell_product_inner h-100"
              >
                <h2>{cdata?.title}</h2>
                <div className="sell_product_inner_main">
                  {cdata?.products?.slice(0, 4)?.map((data, index) => (
                    <div
                      className="sell_product_inner_main_section"
                      key={index}
                      data-aos="fade-in"  // AOS animation for inner elements
                      data-aos-delay={`${index * 500}`}  // Adjust delay for inner elements
                    >
                      <img onClick={() => { handleNavigateClick(data?.slug, data?.productVariant) }}
                        src={
                          data?.image
                            ? `${host}${data?.image}`
                            : ""
                        }
                        className="img-fluid"
                      />
                      <p>{data?.name}</p>
                    </div>
                  ))}
                </div>
                <div>
                  <Link to={`/buyer/home-four-product-listing/${cdata?.slug}`} className="see_more_btn">
                    See more
                  </Link>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SellProduct;
