import { React, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useFormik } from "formik";
import { toast } from 'react-hot-toast';
import * as Yup from "yup";
import axios from 'axios';

const host = process.env.REACT_APP_API_SERVER
const Changepassword = () => {

    const [faIcon, setfaIcon] = useState('fa-eye-slash');
    const togglePasswordType = () => {
        let input_pass = document.getElementById('password-field');
        const toggle_pass = document.getElementById("toggle_pass");
        let input_type = input_pass.getAttribute('type');
        if (input_type == "password") {
            setfaIcon('fa-eye');
            input_pass.type = "text";
        } else {
            setfaIcon('fa-eye-slash')
            input_pass.type = "password"
        }
    }

    const [faIcon1, setfaIcon1] = useState('fa-eye-slash');
    const togglePasswordType1 = () => {
        let input_pass = document.getElementById('password-field_1');
        const toggle_pass = document.getElementById("toggle_pass_1");
        let input_type = input_pass.getAttribute('type');
        if (input_type == "password") {
            setfaIcon1('fa-eye');
            input_pass.type = "text";
        } else {
            setfaIcon1('fa-eye-slash')
            input_pass.type = "password"
        }
    }


    const navigate = useNavigate();
    const triggerModalOpen = () => {
        const openButton = document.getElementById('modal-open');
        if (openButton) {
            openButton.click();
        }
    };
    const triggerModalClose = () => {
        const closeButton = document.getElementById('close-modal');
        if (closeButton) {
            closeButton.click();
        }

    };



    const location = useLocation();
    const email = location.state?.email;
    const queryParams = new URLSearchParams(location.search);
    const guestUser = queryParams.get('guest');
    let [initialValues, setInitialValues] = useState({

        email: "",

        password: "",
        cpassword: "",

    })

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: Yup.object().shape({



            password: Yup.string()
                .required("Password is required")
                .min(6, "Password must be at least 6 characters long"),
            cpassword: Yup.string()
                .oneOf([Yup.ref('password'), null], 'Passwords must match')
                .required("Confirm Password is required"),

        }),
        onSubmit: async (values, { setSubmitting, resetForm }) => {

            try {

                setSubmitting(true);
                values.email = email




                await axios.post(`${host}/seller/seller-reset-password`, values)
                    .then(function (response) {
                        if (response.status == 200) {
                            setSubmitting(false)

                            toast.success("Password changed successfully");
                            resetForm();

                            triggerModalOpen();
                            // navigate('/seller/sign-in')





                        } else {

                            setSubmitting(false)

                            toast.error(response.data.message);

                        }

                    })
                    .catch(function (error) {


                        setSubmitting(false)
                        toast.error('Error submitting form. Please try again.');

                    })

            }
            catch (error) {


                setSubmitting(false)
                toast.error('Error submitting form. Please try again.');

            }

        }
    });



    return (
        <div className='seller'>
            <main className="main_login_section container-fluid">
                <div className="row">
                    <div className="col-lg-7 main_bg_detail">
                        <div className="main_login_detail">
                            <form method='post' onSubmit={formik.handleSubmit}>
                                <div className="logo_login">
                                    <img src={`${process.env.PUBLIC_URL}/assets/images/seller_images/login_logo.png`} alt="login_logo" />
                                </div>
                                <div className="detail_form_log detail_form_careate">
                                    <h3>Change Password </h3>
                                    <p>Please enter your Password</p>
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="form-group log_in_main">
                                                <label>New Password </label>
                                                <input
                                                    type="text"
                                                    id="password-field"
                                                    name='password'
                                                    placeholder="Enter New Password"
                                                    className="form-control"
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.password}
                                                />
                                                <a className="main_show_eyes">
                                                    <span
                                                        id="toggle_pass" onClick={togglePasswordType}
                                                        toggle="#password-field" className={`fa-regular ${faIcon} field-icon toggle-password `}
                                                    />
                                                </a>
                                            </div>
                                            <div className="help-block with-errors">
                                                {formik.touched.password && formik.errors.password ? (
                                                    <div className='text-danger'>{formik.errors.password}</div>
                                                ) : null}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="form-group log_in_main">
                                                <label>Confirm Password</label>
                                                <input
                                                    className="form-control"
                                                    id="password-field_1"
                                                    name="cpassword"
                                                    placeholder="Enter password again"
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.cpassword}
                                                />
                                                <a className="main_show_eyes">
                                                    <span
                                                        id="toggle_pass_1" onClick={togglePasswordType1}
                                                        toggle="#password-field_1" className={`fa-regular ${faIcon1} field-icon toggle-password `}


                                                    />
                                                </a>
                                            </div>
                                            <div className="help-block with-errors">
                                                {formik.touched.cpassword && formik.errors.cpassword ? (
                                                    <div className='text-danger'>{formik.errors.cpassword}</div>
                                                ) : null}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="log_in_main_button">
                                    <div className="detail_address_button mt-3">
                                        <button
                                            type="submit"
                                        // data-bs-toggle="modal"
                                        // data-bs-target="#send_approval"

                                        >
                                            Save
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className="col-lg-5 main_login_img">
                        <img src={`${process.env.PUBLIC_URL}/assets/images/seller_images/Create_Account.png`} alt="login" />
                    </div>
                </div>
            </main>
            {/* Button trigger modal */}
            <button type="button" className="btn btn-primary d-none" id='modal-open' data-bs-toggle="modal" data-bs-target="#send_approval">
                Launch demo modal
            </button>
            <div
                className="modal fade"
                id="send_approval"
                data-bs-backdrop="static"
                data-bs-keyboard="false"
                tabIndex={-1}
                aria-labelledby="staticBackdropLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content mt-100">
                        <div className="modal-body">
                            <button type="button" className="btn-close d-none" id='close-modal' data-bs-dismiss="modal" aria-label="Close" />
                            <div className="request_send_for_modul padd_none">
                                <h6>Congratulations your password Changed Succesfully!!</h6>
                                {/* <p>
                                    Lorem Ipsum&nbsp;is simply dummy text of the printing and
                                    typesetting industry. Lorem Ipsum has been the industry's standard
                                    dummy text ever since the 1500s.
                                </p> */}
                                <div className="main_acsept_butt">
                                    <Link to='/seller/sign-in'>
                                        <button
                                            type="button"
                                            className="btn btn-warning"
                                            data-bs-dismiss="modal"
                                        >
                                            Back to Login{" "}
                                        </button>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Changepassword