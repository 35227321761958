import React from 'react'
import SellerHeader from '../SellerEssentials/SellerHeader'
import SellerSidebar from '../SellerEssentials/SellerSidebar'
import { Link } from 'react-router-dom'
const SellerInventoryProductDetails = () => {
    return (
        <div className='seller'>
            <div className="page-wrapper chiller-theme toggled">
                <SellerSidebar />
                {/* sidebar-wrapper  */}
                <main className="page-content container-fluid">
                    {/* ==================header================== */}
                    <SellerHeader />
                    {/* ==================header================== */}
                    <div className="row">
                        <div className="col-md-12">
                            <div className="main-nav-Order">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <h2>
                                            {" "}
                                            <Link to="/seller/inventory_alert_1">
                                                <img src="/assets/images/seller_images/left_side.png" alt="left" /> Inventory
                                                Alert{" "}
                                            </Link>
                                        </h2>
                                    </div>
                                </div>
                                <div className="card_order_main">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="product-card-main">
                                                <div className="row">
                                                    <div className="col-md-3 border-right">
                                                        <div className="main_tracker">
                                                            <h4>Inventory Tracker</h4>
                                                            <p>
                                                                On Hand Quantity: <span>10</span>
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-9">
                                                        <Link to="/seller/#">
                                                            <div className="rangeslider-wrap">
                                                                <input
                                                                    type="range"
                                                                    min={1}
                                                                    max={100}
                                                                    step="0.1"
                                                                    labels="Low, Maximum"
                                                                />
                                                            </div>
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="product-card-main">
                                                <div className="row">
                                                    <div className="col-md-4">
                                                        <div className="product_card_image">
                                                            <img src="/assets/images/seller_images/product.png" alt="product" />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-8">
                                                        <div className="product_card_detail">
                                                            <div className="row">
                                                                <div className="col-md-8">
                                                                    <div className="rating-header">
                                                                        <span className="stars">★★★★★</span>
                                                                        <span className="total-ratings">
                                                                            5 out of 5
                                                                        </span>
                                                                    </div>
                                                                    <p>
                                                                        Entire Bromo mountain view Cabin in Surabaya
                                                                    </p>
                                                                </div>
                                                                <div className="col-md-4">
                                                                    <div className="main_turn_detail">
                                                                        <label>
                                                                            <input
                                                                                defaultChecked=""
                                                                                className="cb cb2"
                                                                                type="checkbox"
                                                                                name="work"
                                                                                onclick="cbChange(this)"
                                                                            />
                                                                            <i /> <span>Active</span>
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <h6>Malang, Probolinggo</h6>
                                                            <p>On Hand Quantity: 10</p>
                                                            <div className="main_button_low text_main_left">
                                                                <Link to="/seller/#" className="low_info_inven">
                                                                    <span /> Low inventory
                                                                </Link>
                                                            </div>
                                                        </div>
                                                        <div className="row mt-2">
                                                            <div className="col-md-6">
                                                                <div className="form-group maindetail_input ">
                                                                    <label>Add QTY.</label>
                                                                    <input
                                                                        type="text"
                                                                        defaultValue=""
                                                                        className="form-control"
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <div className="restock_butt_now">
                                                                    <Link
                                                                        to="/seller/inventory_alert_1"
                                                                        className="btn btn-primary next-step"
                                                                    >
                                                                        Restock Now
                                                                    </Link>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>

            {/* ==============script linking start=============== */}
            {/* ==============script linking end=============== */}
        </div>

    )
}

export default SellerInventoryProductDetails