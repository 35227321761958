import React, { useEffect } from 'react'
import BuyerHeader from '../BuyerHeader/BuyerHeader'
import BuyerFooter from '../BuyerFooter/BuyerFooter'
import BuyerAccountContent from './BuyerAccountContent'
import { useNavigate } from 'react-router-dom'
import toast from 'react-hot-toast'
import { getBuyerProfileDetails } from '../../Redux/action/BuyerAction'
import Loader from '../../Loader'
import ErrorLoader from '../../Error/ErrorLoader'
import { useDispatch, useSelector } from 'react-redux'

const BuyerAccount = () => {
    const authToken = localStorage.getItem('buyer_token');
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {

        if (!authToken) {

            // toast.error('please sign in to continue')
            navigate('/buyer/sign-in')
        }

    }, [authToken]);



    return (
        <>

            <BuyerHeader />
            <BuyerAccountContent />
            <BuyerFooter />
        </>
    )
}

export default BuyerAccount