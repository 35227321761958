import React from 'react';
import { RotatingLines } from 'react-loader-spinner';

const Loader = () => {
    const loaderStyle = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh'
    };

    return (
        <div style={loaderStyle}>
            <RotatingLines
                width="80"
                visible={true}
                color="grey"
                strokeWidth="5"
                animationDuration="0.75"
                ariaLabel="rotating-lines-loading"
            />
        </div>
    );
};
export default Loader;