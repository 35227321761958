
import React from 'react'
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import toast from 'react-hot-toast';
import Slider from "react-slick";
import parse from 'html-react-parser'
import { useDispatch } from 'react-redux';
import { getHomeTrendingNow } from '../../Redux/action/BuyerAction';
let host = process.env.REACT_APP_API_SERVER;
const TrendingNow = ({ HomeTrendingNowDataListApiData }) => {
    const settings = {
        dots: true, // Enable dots
        arrows: false,
        infinite: HomeTrendingNowDataListApiData?.length > 3,
        autoplay: true,
        autoplaySpeed: 1500,
        slidesToShow: 3,
        slidesToScroll: 1,
        centerMode: true,
        centerPadding: '150px',
        swipeToSlide: true,
        touchMove: true,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true,
                    swipeToSlide: true,
                    touchMove: true,
                }
            },
            {
                breakpoint: 780,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true,
                    arrows: false,
                    swipeToSlide: true,
                    touchMove: true,
                }
            },
            {
                breakpoint: 640,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true,
                    centerMode: false,
                    arrows: false,
                    swipeToSlide: true,
                    touchMove: true,
                }
            }
        ]
    };


    const navigate = useNavigate();
    const dispatch = useDispatch();
    const authToken = localStorage.getItem("buyer_token");

    const handleClick = (slug, productVariant) => {
        if (productVariant?.colourId && productVariant?.sizeId) {
            navigate(`/buyer/product-details/${slug}?colorId=${productVariant?.colourId}&sizeId=${productVariant?.sizeId}`);
        } else if (productVariant?.colourId == null && productVariant?.sizeId) {
            navigate(`/buyer/product-details/${slug}?sizeId=${productVariant?.sizeId}`);
        } else if (productVariant?.colourId && productVariant?.sizeId == null) {
            navigate(`/buyer/product-details/${slug}?colorId=${productVariant?.colourId}`);
        } else {
            navigate(`/buyer/product-details/${slug}`);
        }
    }

    const handleWishlistClick = (id, varientId) => {
        if (authToken) {
            try {
                let values = {
                    productId: id,
                    productVarientId: varientId
                };
                let config = { headers: { "auth-token": authToken } };
                axios.post(`${host}/buyer/buyer-wishlist-product`, values, config)
                    .then((response) => {
                        if (response.status === 200) {
                            toast.success(response?.data?.message);
                            dispatch(getHomeTrendingNow(authToken));
                        } else {
                            toast.error(response?.data?.message);
                        }
                    })
            } catch (error) {
                toast.error(error?.response?.data?.message);
            }
        } else {
            toast.error('Please sign in to continue');
            navigate('/buyer/sign-in');
        }
    }

    return (
        <div className="trending_now">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                        <div className="headeing">
                            <h1>Trending Now</h1>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                        <Slider {...settings} className='slider mb-5'>
                            {HomeTrendingNowDataListApiData?.map((data) => (
                                <div className="slide slider__item" key={data?.id}>
                                    <div className="card trending_product pos-rel" style={{ cursor: 'pointer' }}>

                                        <div className='image pos-rel'>
                                            <div className="d-flex justify-content-end pos-abso left">
                                                <Link>
                                                    {data?.isWishlisted ? (
                                                        <i className="fa fa-heart heart filled-heart" aria-hidden="true" onClick={() => { handleWishlistClick(data?.id, data?.productVariant?.id) }} />
                                                    ) : (
                                                        <i className="fa-sharp fa-regular fa-heart heart" onClick={() => { handleWishlistClick(data?.id, data?.productVariant?.id) }} />
                                                    )}
                                                </Link>
                                            </div>
                                            <img
                                                src={data?.image ? `${host}${data?.image}` : ''}
                                                className="img-fluid"
                                                onClick={() => { handleClick(data?.slug, data?.productVariant) }}
                                                style={{ cursor: 'pointer' }}
                                                alt={data?.name}
                                            />
                                        </div>
                                        <div className="card-body ht" onClick={() => { handleClick(data?.slug, data?.productVariant) }} style={{ cursor: 'pointer' }}>
                                            <h5>{data?.name}</h5>
                                            <h6>
                                                {data?.brandName}{" "}
                                                {data?.avgRating && (
                                                    <span>
                                                        {data?.avgRating} <i className="fa-solid fa-star" />
                                                    </span>
                                                )}
                                            </h6>
                                            <h2>
                                                AFN. {data?.sellingPrice}{" "}
                                                <span>
                                                    <del>AFN. {data?.originalPrice}</del>
                                                </span>{" "}
                                                <strong>({data?.discountPercentage}% off)</strong>
                                            </h2>
                                            <div className='product-desc'>
                                                <p>{data?.productDescription ? parse(data?.productDescription) : ''}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </Slider>
                    </div>
                </div>
            </div>
        </div>
    );
};


export default TrendingNow