import { React, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useFormik } from "formik";
import { toast } from 'react-hot-toast';
import * as Yup from "yup";
import axios from 'axios';


const host = process.env.REACT_APP_API_SERVER
const Login = () => {
    const [faIcon, setfaIcon] = useState('fa-eye-slash');
    const togglePasswordType = () => {
        let input_pass = document.getElementById('password-field');
        const toggle_pass = document.getElementById("toggle_pass");
        let input_type = input_pass.getAttribute('type');
        if (input_type == "password") {
            setfaIcon('fa-eye');
            input_pass.type = "text";
        } else {
            setfaIcon('fa-eye-slash')
            input_pass.type = "password"
        }
    }
    const navigate = useNavigate();
    let [initialValues, setInitialValues] = useState({

        email: "",
        password: "",

    })

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: Yup.object().shape({

            email: Yup.string().required("Email is required.").matches(/^\w+[-\.\w]*@(?!(?:)\.com$)\w+[-\.\w]*?\.\w{2,4}$/, "Enter a valid E-mail"),

            password: Yup.string().required("Password is required"),

        }),
        onSubmit: async (values, { setSubmitting, resetForm }) => {

            try {

                setSubmitting(true);
                console.log('value coming', values)
                await axios.post(`${host}/seller/seller-sign-in-direct`, values)
                    .then(function (response) {
                        if (response.status == 200) {
                            setSubmitting(false)

                            toast.success("Seller Signed In Succesfully");
                            resetForm();


                            localStorage.setItem("seller_token", response.data.authToken);


                            navigate('/seller/dashboard')


                        } else {

                            setSubmitting(false)

                            toast.error(response.data.message);

                        }

                    })
                    .catch(function (error) {


                        setSubmitting(false)
                        toast.error('Error submitting form. Please try again.');

                    })

            }
            catch (error) {


                setSubmitting(false)
                toast.error('Error submitting form. Please try again.');

            }

        }
    })


    return (
        <div className='seller'>
            <main className="main_login_section container-fluid">
                <div className="row">
                    <div className="col-lg-7 main_bg_detail">
                        <div className="main_login_detail">

                            <div className="logo_login">
                                <img src={`${process.env.PUBLIC_URL}/assets/images/seller_images/login_logo.png`} alt="login_logo" />
                            </div>
                            <form method='post' onSubmit={formik.handleSubmit}>
                                <div className="detail_form_log">
                                    <h3>Sign In</h3>
                                    <p>Welcome back! please enter your details</p>
                                    <div className="form-group log_in_main">
                                        <label>Email</label>
                                        <input
                                            type="text"
                                            defaultValue=""
                                            placeholder="Your email"
                                            className="form-control"
                                            name='email'
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.email}
                                            maxLength={50}
                                        />
                                        <div className="help-block with-errors">
                                            {formik.touched.email && formik.errors.email ? (
                                                <div className='text-danger'>{formik.errors.email}</div>
                                            ) : null}
                                        </div>
                                    </div>
                                    <div className="form-group log_in_main">
                                        <label>Password</label>
                                        <input
                                            type="text"
                                            id="password-field"
                                            placeholder="Enter password"
                                            className="form-control"
                                            name="password"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.password}
                                            maxLength={50}
                                        />

                                        <Link className="main_show_eyes">
                                            <span
                                                id="toggle_pass" onClick={togglePasswordType}
                                                toggle="#password-field" className={`fa-regular ${faIcon} field-icon toggle-password `}
                                            />
                                        </Link>
                                        <div className="help-block with-errors">
                                            {formik.touched.password && formik.errors.password ? (
                                                <div className='text-danger'>{formik.errors.password}</div>
                                            ) : null}
                                        </div>
                                    </div>
                                    <div className="forget_new_page">
                                        <Link to="/seller/forget-password">Forgot Password</Link>
                                    </div>
                                    <div className="log_in_main_button">
                                        <button type='submit' disabled={formik.isSubmitting}>Continue</button>
                                    </div>
                                </div>
                            </form>
                            <div className="text_login_p">
                                <p>
                                    By continuing you agree to E-Commerce{" "}
                                    {/* // eslint-disable-next-line */}

                                    <Link to="/seller/confidentiality-policy">Confidentiality Policy</Link> and{" "}
                                    {/* // eslint-disable-next-line */}

                                    <Link to="/seller/terms-of-use">Terms Of Use</Link> .
                                </p>
                            </div>
                            <div className="main_login_newbazar">
                                <p>
                                    <span>New to Afghan Bazaar?</span>
                                </p>
                            </div>
                            <div className="main_login_newbazar">
                                <Link to="/seller/create-account">Create Your Seller Account Now</Link>
                            </div>

                        </div>
                    </div>
                    <div className="col-lg-5 main_login_img">
                        <img src={`${process.env.PUBLIC_URL}/assets/images/seller_images/login.png`} alt="login" />
                    </div>
                </div>
            </main>
            <footer>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="main_footer_icon">
                                {/* // eslint-disable-next-line */}
                                <Link to="#">2024 Afghan Bazaar. All reserved rights</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    )
}

export default Login