import React from 'react'
import BuyerHeader from '../BuyerHeader/BuyerHeader'
import BuyerFooter from '../BuyerFooter/BuyerFooter'
import BuyerReturnOrderContent from './BuyerReturnOrderContent'

const BuyerReturnOrder = () => {
    return (
        <>
            <BuyerHeader />
            <BuyerReturnOrderContent />
            <BuyerFooter />
        </>
    )
}

export default BuyerReturnOrder