import React from 'react'
import SellerHeader from '../../SellerEssentials/SellerHeader'
import SellerSidebar from '../../SellerEssentials/SellerSidebar'
import { Link } from 'react-router-dom'
const SellerNewOrdersAfterDelivered = () => {
    return (
        <div className='seller'>
            <div className="page-wrapper chiller-theme toggled">
                <SellerSidebar />
                {/* sidebar-wrapper  */}
                <main className="page-content container-fluid">
                    {/* ==================header================== */}
                    <SellerHeader />
                    {/* ==================header================== */}
                    <div className="row ">
                        <div className="col-md-12">
                            <div className="main-nav-Order">
                                <div className="row">
                                    <div className="col-md-7">
                                        {" "}
                                        <h2>
                                            {" "}
                                            <Link to="/seller/new-order-approval-details">
                                                <img src="/assets/images/seller_images/left_side.png" alt="left" />
                                            </Link>{" "}
                                            <span>Orders</span> / View Information
                                        </h2>{" "}
                                    </div>
                                    <div className="col-md-5">
                                        <div className="text-right ">
                                            <a
                                                type="button"
                                                className="btn btn-primary next-step"
                                                data-bs-toggle="modal"
                                                data-bs-target="#buyer_details"
                                            >
                                                Contact buyer
                                            </a>
                                        </div>
                                    </div>
                                </div>

                                <div className="card_order_main">
                                    <div className="row pb-3">
                                        <div className="col-lg-6">
                                            <div className="tab-content bg-light" id="nav-tabContent">
                                                <div
                                                    className="tab-pane fade active show"
                                                    id="nav-all-orders"
                                                    role="tabpanel"
                                                    aria-labelledby="nav-all-orders-tab"
                                                >
                                                    <div className="main_view_detail">
                                                        <img src="/assets/images/seller_images/Rectangle_1.png" alt="rect" />
                                                    </div>
                                                </div>
                                                <div
                                                    className="tab-pane fade"
                                                    id="nav-delivered"
                                                    role="tabpanel"
                                                    aria-labelledby="nav-delivered-tab"
                                                >
                                                    <div className="main_view_detail">
                                                        <img src="/assets/images/seller_images/Rectangle_2.png" alt="rect" />
                                                    </div>
                                                </div>
                                                <div
                                                    className="tab-pane fade"
                                                    id="nav-pending"
                                                    role="tabpanel"
                                                    aria-labelledby="nav-pending-tab"
                                                >
                                                    <div className="main_view_detail">
                                                        <img src="/assets/images/seller_images/Rectangle_3.png" alt="rect" />
                                                    </div>
                                                </div>
                                            </div>
                                            <nav>
                                                <div
                                                    className="nav nav-tabs mb-3 all_Information_main"
                                                    id="nav-tab"
                                                    role="tablist"
                                                >
                                                    <button
                                                        className="nav-link active"
                                                        id="nav-all-orders-tab"
                                                        data-bs-toggle="tab"
                                                        data-bs-target="#nav-all-orders"
                                                        type="button"
                                                        role="tab"
                                                        aria-controls="nav-all-orders"
                                                        aria-selected="true"
                                                    >
                                                        <img src="/assets/images/seller_images/Rectangle_1.png" alt="" />
                                                    </button>
                                                    <button
                                                        className="nav-link"
                                                        id="nav-delivered-tab"
                                                        data-bs-toggle="tab"
                                                        data-bs-target="#nav-delivered"
                                                        type="button"
                                                        role="tab"
                                                        aria-controls="nav-delivered"
                                                        aria-selected="false"
                                                    >
                                                        <img src="/assets/images/seller_images/Rectangle_2.png" alt="" />
                                                    </button>
                                                    <button
                                                        className="nav-link"
                                                        id="nav-pending-tab"
                                                        data-bs-toggle="tab"
                                                        data-bs-target="#nav-pending"
                                                        type="button"
                                                        role="tab"
                                                        aria-controls="nav-pending"
                                                        aria-selected="false"
                                                    >
                                                        <img src="/assets/images/seller_images/Rectangle_3.png" alt="" />
                                                    </button>
                                                </div>
                                            </nav>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="overall_sales height_auto">
                                                <div className="users-table">
                                                    <table className="posts-table">
                                                        <tbody className="td_section_text_order">
                                                            <tr>
                                                                <td>Order Number</td>
                                                                <td className="table-text-color">Order Number</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Order Date</td>
                                                                <td className="table-text-color">Order Date</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Quantity</td>
                                                                <td className="table-text-color">Quantity</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Vendor Name</td>
                                                                <td className="table-text-color">Vendor Name</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Order Status</td>
                                                                <td className="table-text-color">
                                                                    <span className="main_color_red">Approved</span>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>Shipping Address</td>
                                                                <td className="table-text-color">Shipping Address</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Shipping Method</td>
                                                                <td className="table-text-color">Shipping Method</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Payment Method</td>
                                                                <td className="table-text-color">Payment Method</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Tracking Information</td>
                                                                <td className="table-text-color">Tracking Information</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Billing Address</td>
                                                                <td className="table-text-color">Billing Address</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Promotions &amp; Discounts</td>
                                                                <td className="table-text-color">Promotions &amp; Discounts</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <div className="main_acsept_butt">
                                                    <Link to="/seller/new_order_shipment" className="mx-1 main_acsept_butt_2 btn">
                                                        Shipment
                                                    </Link>
                                                    <Link to="/seller/new_order_status" style={{ backgroundColor: '#FF8901' }} className="mx-1 main_acsept_butt_2 btn">
                                                        Status
                                                    </Link>

                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="overall_sales height_auto">
                                                <div className="row">
                                                    <div className="col-lg-5 bor_right_main">
                                                        <div className="rating-container">
                                                            <h3>User Overall Reviews</h3>
                                                            <div className="rating-header">
                                                                <span className="stars">★★★★★</span>
                                                                <span className="total-ratings">
                                                                    2500 total ratings
                                                                </span>
                                                            </div>
                                                            <p>2500 total ratings</p>
                                                            <div className="rating-bars">
                                                                <div className="rating-bar active">
                                                                    <span className="rating-label">5 Star</span>
                                                                    <div className="bar">
                                                                        <div
                                                                            className="fill"
                                                                            style={{ width: "100%" }}
                                                                        />
                                                                    </div>
                                                                    <span className="percentage">100%</span>
                                                                </div>
                                                                <div className="rating-bar">
                                                                    <span className="rating-label">4 Star</span>
                                                                    <div className="bar">
                                                                        <div className="fill" style={{ width: "0%" }} />
                                                                    </div>
                                                                    <span className="percentage">0%</span>
                                                                </div>
                                                                <div className="rating-bar">
                                                                    <span className="rating-label">3 Star</span>
                                                                    <div className="bar">
                                                                        <div className="fill" style={{ width: "0%" }} />
                                                                    </div>
                                                                    <span className="percentage">0%</span>
                                                                </div>
                                                                <div className="rating-bar">
                                                                    <span className="rating-label">2 Star</span>
                                                                    <div className="bar">
                                                                        <div className="fill" style={{ width: "0%" }} />
                                                                    </div>
                                                                    <span className="percentage">0%</span>
                                                                </div>
                                                                <div className="rating-bar">
                                                                    <span className="rating-label">1 Star</span>
                                                                    <div className="bar">
                                                                        <div className="fill" style={{ width: "0%" }} />
                                                                    </div>
                                                                    <span className="percentage">0%</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-7">
                                                        <div className="feedbacks_detail_section">
                                                            <div className="row">
                                                                <div className="col-lg-12">
                                                                    <div className="over_sales_main">
                                                                        <div className="main_detail_sales">
                                                                            <h3>User Feedbacks</h3>
                                                                        </div>
                                                                        <div className="dropdown dropdown-filter-new">
                                                                            <a
                                                                                className="dropdown-toggle month_this_button"
                                                                                href="#"
                                                                                role="button"
                                                                                id="dropdownMenuLink"
                                                                                data-bs-toggle="dropdown"
                                                                                aria-expanded="false"
                                                                            >
                                                                                Most Recent
                                                                            </a>
                                                                            <div
                                                                                className="dropdown-menu main_drop_new_week"
                                                                                aria-labelledby="dropdownMenuLink"
                                                                            >
                                                                                <ul>
                                                                                    <li>
                                                                                        <a href="#">Most Recent</a>
                                                                                    </li>
                                                                                    <li>
                                                                                        <a href="#">Most Recent</a>
                                                                                    </li>
                                                                                    <li>
                                                                                        <a href="#">Most Recent</a>
                                                                                    </li>
                                                                                </ul>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-md-6 mb-4">
                                                                    <div className="review-card d-flex align-items-start">
                                                                        <img
                                                                            src="/assets/images/seller_images/user-image.png"
                                                                            alt="User Image"
                                                                            className="user-image rounded-circle me-3"
                                                                        />
                                                                        <div>
                                                                            <span className="user-name fw-bold">
                                                                                User Name
                                                                            </span>
                                                                            <div className="main_flex_div">
                                                                                <div className="stars text-warning">
                                                                                    ★★★★★{" "}
                                                                                </div>
                                                                                <span className="text_detail_best">
                                                                                    Best Quality
                                                                                </span>
                                                                            </div>
                                                                            <p className="review-text mb-1">
                                                                                Review in Afghanistan on 21 June 2024
                                                                            </p>
                                                                            <a
                                                                                href="#"
                                                                                className="verified-seller text-primary"
                                                                            >
                                                                                Verified seller
                                                                            </a>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-6 mb-4">
                                                                    <div className="review-card d-flex align-items-start">
                                                                        <img
                                                                            src="/assets/images/seller_images/user-image.png"
                                                                            alt="User Image"
                                                                            className="user-image rounded-circle me-3"
                                                                        />
                                                                        <div>
                                                                            <span className="user-name fw-bold">
                                                                                User Name
                                                                            </span>
                                                                            <div className="main_flex_div">
                                                                                <div className="stars text-warning">
                                                                                    ★★★★★{" "}
                                                                                </div>
                                                                                <span className="text_detail_best">
                                                                                    Best Quality
                                                                                </span>
                                                                            </div>
                                                                            <p className="review-text mb-1">
                                                                                Review in Afghanistan on 21 June 2024
                                                                            </p>
                                                                            <a
                                                                                href="#"
                                                                                className="verified-seller text-primary"
                                                                            >
                                                                                Verified seller
                                                                            </a>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-6 mb-4">
                                                                    <div className="review-card d-flex align-items-start">
                                                                        <img
                                                                            src="/assets/images/seller_images/user-image.png"
                                                                            alt="User Image"
                                                                            className="user-image rounded-circle me-3"
                                                                        />
                                                                        <div>
                                                                            <span className="user-name fw-bold">
                                                                                User Name
                                                                            </span>
                                                                            <div className="main_flex_div">
                                                                                <div className="stars text-warning">
                                                                                    ★★★★★{" "}
                                                                                </div>
                                                                                <span className="text_detail_best">
                                                                                    Best Quality
                                                                                </span>
                                                                            </div>
                                                                            <p className="review-text mb-1">
                                                                                Review in Afghanistan on 21 June 2024
                                                                            </p>
                                                                            <a
                                                                                href="#"
                                                                                className="verified-seller text-primary"
                                                                            >
                                                                                Verified seller
                                                                            </a>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-6 mb-4">
                                                                    <div className="review-card d-flex align-items-start">
                                                                        <img
                                                                            src="/assets/images/seller_images/user-image.png"
                                                                            alt="User Image"
                                                                            className="user-image rounded-circle me-3"
                                                                        />
                                                                        <div>
                                                                            <span className="user-name fw-bold">
                                                                                User Name
                                                                            </span>
                                                                            <div className="main_flex_div">
                                                                                <div className="stars text-warning">
                                                                                    ★★★★★{" "}
                                                                                </div>
                                                                                <span className="text_detail_best">
                                                                                    Best Quality
                                                                                </span>
                                                                            </div>
                                                                            <p className="review-text mb-1">
                                                                                Review in Afghanistan on 21 June 2024
                                                                            </p>
                                                                            <a
                                                                                href="#"
                                                                                className="verified-seller text-primary"
                                                                            >
                                                                                Verified seller
                                                                            </a>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
            <div
                className="modal fade"
                id="buyer_details"
                data-bs-backdrop="static"
                data-bs-keyboard="false"
                tabIndex={-1}
                aria-labelledby="staticBackdropLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog dasbord-main_new">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="request_send_for_modul padd_none">
                                <h6>Buyer Details </h6>
                                <div className="row">
                                    <div className="col-md-6 border-right">
                                        <div className="users-table">
                                            <h4 className="request_send_text">Shipping Details</h4>
                                            <table className="posts-table">
                                                <tbody className="td_section_text_order">
                                                    <tr>
                                                        <td>Shipping:</td>
                                                        <td className="table-text-color">Standard</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Sales Channel:</td>
                                                        <td className="table-text-color">Afghan Bazar</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Order Date:</td>
                                                        <td className="table-text-color">06-07-2024</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Order ID:</td>
                                                        <td className="table-text-color">Order ID</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Unit Price</td>
                                                        <td className="table-text-color">Price Here</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="users-table">
                                            <h4 className="request_send_text">Customer Details</h4>
                                            <table className="posts-table">
                                                <tbody className="td_section_text_order">
                                                    <tr>
                                                        <td>Name:</td>
                                                        <td className="table-text-color">Name Here</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Phone Number:</td>
                                                        <td className="table-text-color">Phone Number</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Shipped To:</td>
                                                        <td className="table-text-color">Shipped To</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Email Address:</td>
                                                        <td className="table-text-color">Email Address</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Product:</td>
                                                        <td className="table-text-color">Product Name</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <div className="col-md-6">
                                        <div className="main_detail_unshiped">
                                            <a href="#" className="status">
                                                Status
                                            </a>
                                            <a href="#" className="unshipped">
                                                Unshipped
                                            </a>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="text-right">
                                            <button
                                                type="button"
                                                className="btn btn-primary"
                                                data-bs-dismiss="modal"
                                            >
                                                Close
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* ==============script linking start=============== */}
            {/* ==============script linking end=============== */}
        </div>

    )
}

export default SellerNewOrdersAfterDelivered