import React, { useEffect, useState } from 'react'
import './scss/BuyerCartContent.css'
import { Link, useNavigate } from 'react-router-dom'
import axios from 'axios'
import toast from 'react-hot-toast';
import Slider from "react-slick";
import { getBuyerCartProductList } from '../../Redux/action/BuyerAction';
import { useDispatch } from 'react-redux';
import MoreOfWhatYouLike from './MoreOfWhatYouLike';
let host = process.env.REACT_APP_API_SERVER;

const BuyerCartContent = ({ BuyerCartProductListApiData, WishlistDataListApiData }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const authToken = localStorage.getItem("buyer_token")
    const guestToken = localStorage.getItem("guest_token")
    const [cartItems, setCartItems] = useState([]);

    useEffect(() => {
        if (Array.isArray(BuyerCartProductListApiData)) {
            setCartItems(BuyerCartProductListApiData.map(item => ({ ...item })));
        }
    }, [BuyerCartProductListApiData]);

    const handleIncreaseQuantity = (index) => {
        setCartItems(prevItems =>
            prevItems.map((item, i) =>
                i === index ? { ...item, quantity: Number(item.quantity) + 1 } : item
            )
        );
    };

    const handleDecreaseQuantity = (index) => {
        setCartItems(prevItems =>
            prevItems.map((item, i) =>
                i === index && item.quantity > 1 ? { ...item, quantity: Number(item.quantity) - 1 } : item
            )
        );
    };


    const handleUpdateCart = async () => {
        let config
        if (authToken) {
            config = {
                headers: {

                    'content-type': 'application/json',
                    "auth-token": localStorage.getItem("buyer_token")
                }
            };
        }
        else {
            if (guestToken) {
                config = {
                    headers: {

                        'content-type': 'application/json',
                        "guest-token": localStorage.getItem("guest_token")
                    }
                };
            }
            else {
                toast.error('No tokens created')
            }
        }
        try {

            await axios.post(`${host}/buyer/update-product-buyer-cart`, { cartItems }, config)
                .then(function (response) {
                    if (response.status == 200) {

                        toast.success("Cart updated successfully");
                        if (authToken) {
                            dispatch(getBuyerCartProductList('auth-token', authToken));
                        }
                        else if (guestToken) {
                            dispatch(getBuyerCartProductList('guest-token', guestToken));
                        }
                    } else {

                        toast.error(response.data.message);
                    }

                })
                .catch(function (error) {

                    console.log(error)
                    toast.error(error.response.data.message);
                })
        } catch (error) {
            toast.error('Error updating cart:', error.message);
        }
    };


    const handleDeleteCart = async (productId, productVarientId) => {
        let config
        if (authToken) {
            config = {
                headers: {

                    'content-type': 'application/json',
                    "auth-token": localStorage.getItem("buyer_token")
                }
            };
        }
        else {
            if (guestToken) {
                config = {
                    headers: {

                        'content-type': 'application/json',
                        "guest-token": localStorage.getItem("guest_token")
                    }
                };
            }
            else {
                toast.error('No tokens created')
            }
        }
        try {
            await axios.post(`${host}/buyer/buyer-delete-cart-product`, { productId, productVarientId }, config)
                .then(function (response) {
                    if (response.status == 200) {

                        toast.success("Product deleted successfully");
                        if (authToken) {
                            dispatch(getBuyerCartProductList('auth-token', authToken));
                        }
                        else if (guestToken) {
                            dispatch(getBuyerCartProductList('guest-token', guestToken));
                        }

                    } else {

                        toast.error(response.data.message);
                    }

                })
                .catch(function (error) {

                    console.log(error)
                    toast.error(error.response.data.message);
                })
        } catch (error) {
            toast.error('Error updating cart:', error.message);
        }
    };

    const calculateSubtotal = () => {
        return cartItems.reduce((acc, item) => acc + item.originalPrice * item.quantity, 0);
    };

    const calculateDiscount = () => {
        return cartItems.reduce((acc, item) => acc + (item.originalPrice - item.sellingPrice) * item.quantity, 0);
    };

    const calculateTax = (subtotal) => {
        const taxRate = 0.2; // Example tax rate of 20%
        return subtotal * taxRate;
    };

    const subtotal = calculateSubtotal();
    const discount = calculateDiscount();
    const tax = calculateTax(subtotal);
    const total = subtotal + tax - discount;

    const handleProceedToCheckout = () => {
        localStorage.setItem('cartItems', JSON.stringify(cartItems));
        if (authToken) {
            navigate('/buyer/checkout')
        }
        else if (guestToken) {
            toast.error('please sign in to continue')
            navigate('/buyer/sign-in?guest=true');
        }
    };

    const settings = {
        dots: false,
        arrows: false,
        infinite: WishlistDataListApiData?.length > 3,
        autoplay: true,
        autoplaySpeed: 3000,
        slidesToShow: 3,
        slidesToScroll: 1,
        centerMode: false,
        centerPadding: '60px',
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false
                }
            },
            {
                breakpoint: 780,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false,
                    arrows: false
                }
            },
            {
                breakpoint: 640,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false,
                    centerMode: false,
                    arrows: false
                }
            }
        ]
    };


    const handleNavigateClick = (slug, productVariant) => {

        if (productVariant?.colourId && productVariant?.sizeId) {

            navigate(`/buyer/product-details/${slug}?colorId=${productVariant?.colourId}&sizeId=${productVariant?.sizeId}`)
        }
        else if (productVariant?.colourId == null && productVariant?.sizeId) {

            navigate(`/buyer/product-details/${slug}?sizeId=${productVariant?.sizeId}`)
        }
        else if (productVariant?.colourId && productVariant?.sizeId == null) {

            navigate(`/buyer/product-details/${slug}?colorId=${productVariant?.colourId}`)
        }
        else {

            navigate(`/buyer/product-details/${slug}`)

        }
    }

    const handleReturnToShop = () => {
        navigate(-1);
    }

    return (
        <div className='cart'>
            <>
                {/* Breadcrumb */}
                <div className="breadcrumb_section">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                <div className="breadcrumb_section_content">
                                    <ul>
                                        <li>
                                            <Link to="/buyer">
                                                <i className="fa-solid fa-house" /> Home
                                            </Link>
                                        </li>
                                        <li>
                                            <i className="fa-solid fa-angle-right" />
                                        </li>
                                        <li>
                                            <Link to="/buyer/cart">Shoping Cart</Link>

                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Breadcrumb */}
                {/* Shopping Cart */}
                <div className="shopping_cart">
                    <div className="container-fluid">
                        {BuyerCartProductListApiData?.length > 0 ?
                            <div className="row">
                                <div className="col-xl-8 col-8 col-md-8 col-sm-12 col-12">
                                    <div className="shopping_cart_heading">
                                        <h2>Shopping Cart</h2>
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th colSpan={3}>Products</th>
                                                    <th className="text-center">Price</th>
                                                    <th className="text-center">Quantity</th>
                                                    <th className="text-center">Sub-Total</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {cartItems.map((data, index) => (
                                                    <tr key={index}>
                                                        <td>
                                                            <i style={{ cursor: 'pointer' }} onClick={() => { handleDeleteCart(data?.productId, data?.productVarientId) }} className="fa-regular fa-circle-xmark" />
                                                        </td>
                                                        <td className="text-start">
                                                            <img src={data?.image ? `${host}${data?.image}` : ''} className="img-fluid" />
                                                        </td>
                                                        <td className="text-start">
                                                            {data.name}
                                                        </td>
                                                        <td>
                                                            <del>AFN {data.originalPrice}</del> AFN {data.sellingPrice}
                                                        </td>
                                                        <td>
                                                            <div className="quantity_number">
                                                                <button
                                                                    className="btn btn--minus"
                                                                    type="button"
                                                                    onClick={() => handleDecreaseQuantity(index)}
                                                                >
                                                                    <span>-</span>
                                                                </button>
                                                                <input
                                                                    className="quantity"
                                                                    type="text"
                                                                    value={data.quantity}
                                                                    readOnly
                                                                />
                                                                <button
                                                                    className="btn btn--plus"
                                                                    type="button"
                                                                    onClick={() => handleIncreaseQuantity(index)}
                                                                >
                                                                    <span>+</span>
                                                                </button>
                                                            </div>
                                                        </td>
                                                        <td>AFN {data.sellingPrice * data.quantity}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>

                                    </div>
                                    <div className="return_shop_section">
                                        <div>
                                            <a onClick={handleReturnToShop} style={{ cursor: 'pointer' }}>
                                                <i className="fa-solid fa-arrow-left" /> Return to Shop
                                            </a>
                                        </div>
                                        {/* <div>
                                            <a style={{ cursor: 'pointer' }} onClick={handleUpdateCart}>Update cart</a>
                                        </div> */}
                                    </div>
                                </div>
                                <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                                    <div className="shop-page-table">
                                        <h2>Cart</h2>
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <td>Sub-total</td>
                                                    <td className="text-end">
                                                        <strong>AFN {subtotal}</strong>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Shipping</td>
                                                    <td className="text-end">
                                                        <strong>Free</strong>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Discount</td>
                                                    <td className="text-end">
                                                        <strong>AFN {discount}</strong>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Tax</td>
                                                    <td className="text-end">
                                                        <strong>AFN {tax.toFixed(2)}</strong>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>Total</th>
                                                    <th className="text-end">
                                                        <strong>AFN {total.toFixed(2)}</strong>
                                                    </th>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <div className="add_to_cart mt-2">
                                            <a onClick={handleProceedToCheckout} style={{ cursor: 'pointer' }}>
                                                Proceed to Checkout <i className="fa-solid fa-arrow-right" />
                                            </a>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            :
                            <div className=" container-fluid my-2 py-2 main-wish-list">
                                <Link className='text-decoration-none' to='/buyer/home'>
                                    <div className="d-flex justify-content-center pt-4 py-5 heading-sec px-4">
                                        <img src="/assets/images/purchase-protection.png" alt="" srcSet="" className='img-fluid' />


                                    </div>
                                </Link>
                                <h1 className='text-center '><Link className='text-decoration-none empty_data' to='/buyer/home'>You Cart Is Empty Shop Now</Link></h1>
                            </div>}
                    </div>
                </div>
                {/* Shopping Cart */}
                {/* Wish list Items */}

                <div className="cart_items">
                    <div className="container-fluid">
                        {(authToken && WishlistDataListApiData?.length > 0) &&
                            <>  <div className="row">
                                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                    <div className="cart_items_heading">
                                        <h3>Your Items</h3>
                                        {/* <p>Save for later ({WishlistDataListApiData?.length > 0 ? WishlistDataListApiData?.length : '0'} Items)</p> */}
                                    </div>
                                </div>
                            </div>
                                <div className="row">
                                    <Slider {...settings} className='cart-slider slider'>
                                        {WishlistDataListApiData?.map((data) => (
                                            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12 mb-3">
                                                <div className="card cart_details_inner">
                                                    <img src={data?.image ? `${host}${data?.image}` : ''} className="img-fluid" onClick={() => { handleNavigateClick(data?.slug, data?.productVariant) }} style={{ cursor: 'pointer' }} />
                                                    <div className="card-body">
                                                        <h5>{data?.name}</h5>
                                                        <h6>
                                                            {data?.brandName}
                                                            {data?.avgRating &&
                                                                <span className='px-2'>
                                                                    {data?.avgRating} <i className="fa-solid fa-star" />
                                                                </span>}
                                                        </h6>
                                                        <h2>
                                                            AFN. {data?.sellingPrice}{" "}
                                                            <span>
                                                                <del>AFN. {data?.originalPrice}</del>
                                                            </span>{" "}
                                                            <strong>({data?.discountPercentage}% off)</strong>
                                                        </h2>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </Slider>
                                </div>
                            </>}
                        <MoreOfWhatYouLike />
                    </div>
                </div>
                {/* Cart Items */}
            </>

        </div>

    )
}

export default BuyerCartContent