import { React, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useFormik } from "formik";
import { toast } from 'react-hot-toast';
import * as Yup from "yup";
import axios from 'axios';
// import '../scss/Auth.css'
import '../scss/OtpVerification.css'
import '../OtpVerificationEmail/scss/OtpVerificationEmail.css'
import OtpVerification from '../OtpVerification/OtpVerification';

const host = process.env.REACT_APP_API_SERVER
const ForgetPassword = () => {
    const navigate = useNavigate();
    const triggerModalOpen = () => {
        const openButton = document.getElementById('modal-open');
        if (openButton) {
            openButton.click();
        }
    };
    const triggerModalClose = () => {
        const closeButton = document.getElementById('close-modal');
        if (closeButton) {
            closeButton.click();
        }

    };

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const guestUser = queryParams.get('guest');

    const [thisEmail, setThisEmail] = useState();
    const forgePassFormik = useFormik({
        initialValues: {
            email: "",
        },
        validationSchema: Yup.object().shape({
            email: Yup.string().required("Email is required.").matches(/^\w+[-\.\w]*@(?!(?:)\.com$)\w+[-\.\w]*?\.\w{2,4}$/, "Enter a valid E-mail"),
        }),
        onSubmit: async (values, { setSubmitting, resetForm }) => {
            try {
                setSubmitting(true);

                setThisEmail(values.email);
                await axios.post(`${host}/buyer/buyer-forget-password`, values)
                    .then(function (response) {
                        if (response.status == 200) {
                            setSubmitting(false)
                            toast.success("Otp sent to your mail.Please check");
                            resetForm();
                            const modal = document.getElementById('reset-otp');
                            triggerModalOpen()

                        } else {
                            setSubmitting(false)
                            toast.error(response.data.message);
                        }

                    })
                    .catch(function (error) {
                        setSubmitting(false)
                        toast.error('Error submitting form. Please try again.');
                    })
            }
            catch (error) {
                setSubmitting(false)
                toast.error('Error submitting form. Please try again.');
            }
        }
    })

    const otpVerifyFormik = useFormik({
        initialValues: {
            otp: "",
        },
        validationSchema: Yup.object().shape({
            otp: Yup.string().required("Otp is required.")
        }),
        onSubmit: async (values, { setSubmitting, resetForm }) => {
            try {
                setSubmitting(true);
                values.email = thisEmail;

                await axios.post(`${host}/buyer/buyer-otp-verify`, values)
                    .then(function (response) {
                        if (response.status == 200) {
                            setSubmitting(false)
                            triggerModalClose();
                            toast.success("Otp Verified.Please Reset Your Password");
                            resetForm();
                            if (guestUser && guestUser == 'true') {
                                navigate('/buyer/reset-password?guest=true', { state: { email: thisEmail } })
                            }
                            else {
                                navigate('/buyer/reset-password', { state: { email: thisEmail } });
                            }

                        } else {
                            setSubmitting(false)
                            toast.error(response.data.message);
                        }

                    })
                    .catch(function (error) {
                        setSubmitting(false)
                        console.log(error)
                        toast.error('Error submitting form. Please try again.');
                    })
            }
            catch (error) {
                setSubmitting(false)
                toast.error('Error submitting form. Please try again.');
            }
        }
    })

    const handleResendPassword = async () => {
        try {
            const value = {
                'email': thisEmail
            }

            await axios.post(`${host}/buyer/buyer-forget-password`, value)
                .then(function (response) {
                    if (response.status == 200) {

                        toast.success("Otp sent to your mail.Please Verify");

                    } else {

                        toast.error(response.data.message);
                    }

                })
                .catch(function (error) {

                    toast.error('Error submitting form. Please try again.');
                })
        }
        catch (error) {

            toast.error('Error submitting form. Please try again.');
        }
    }

    return (
        <>
            <div className="otp">
                <div className="forgot_password">
                    <div className="container-fluid">
                        <div className="row align-items-center">
                            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                                <div className="forgot_password_img text-center">
                                    <img src="/assets/images/forgot-password.png" className="img-fluid" />
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12">
                                <div className="forgot_password_img forgot_pass">
                                    <h2>Forgot Your Password ?</h2>
                                    <form method='post' onSubmit={forgePassFormik.handleSubmit}>
                                        <label htmlFor="email">Enter the Email address associated with your Afghan Bazaar account</label>
                                        <input
                                            type="text"
                                            placeholder="Enter your email address"
                                            className="form-control"
                                            name='email'
                                            onChange={forgePassFormik.handleChange}
                                            onBlur={forgePassFormik.handleBlur}
                                            value={forgePassFormik.values.email}
                                        />
                                        <div className="help-block with-errors">
                                            {forgePassFormik.touched.email && forgePassFormik.errors.email ? (
                                                <div className='text-danger'>{forgePassFormik.errors.email}</div>
                                            ) : null}
                                        </div>
                                        <button type='submit' disabled={forgePassFormik.isSubmitting} >Reset Password</button>
                                    </form>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <div className="otp-verification-email">
                {/* Button trigger modal */}
                <button type="button" className="btn btn-primary d-none" id='modal-open' data-bs-toggle="modal" data-bs-target="#exampleModal">
                    Launch demo modal
                </button>
                <div>

                    {/* Modal */}
                    <div className="modal fade forgot_popup" id="exampleModal" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog">
                            <div className="modal-content ">
                                <div className="modal-header">
                                    <h1 className="modal-title fs-5" id="exampleModalLabel">Email Verification</h1>
                                    <button type="button" className="btn-close" id='close-modal' data-bs-dismiss="modal" aria-label="Close" />
                                </div>
                                <div className="modal-body">
                                    <form method='post' onSubmit={otpVerifyFormik.handleSubmit}>
                                        <div className="">
                                            <p className='mb-2'>We have sent the verification code to your email address</p>
                                            <input
                                                type="text"
                                                placeholder="Enter Otp"
                                                className="form-control"
                                                name='otp'
                                                onChange={otpVerifyFormik.handleChange}
                                                onBlur={otpVerifyFormik.handleBlur}
                                                value={otpVerifyFormik.values.otp}
                                            />
                                            <div className="help-block with-errors">
                                                {otpVerifyFormik.touched.otp && otpVerifyFormik.errors.otp ? (
                                                    <div className='text-danger'>{otpVerifyFormik.errors.otp}</div>
                                                ) : null}
                                            </div>
                                            <h5 className='mt-3 mb-3 resend_otp_heading'>Didn't receive OTP?</h5>
                                            <div style={{ cursor: 'pointer' }} onClick={handleResendPassword}><span className='resend_otp fw-bold'>Resend Code</span></div>
                                            <div className="verify-btn">
                                                {/* <Link to="/buyer/reset-password"> */}
                                                <button type='submit' disabled={otpVerifyFormik.isSubmitting}>Verify</button>
                                                {/* </Link> */}
                                            </div>
                                        </div>
                                    </form>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}

export default ForgetPassword