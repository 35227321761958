import React from 'react'
// import './scss/BuyerTechnicalDetails.css'
import { Link } from 'react-router-dom'

const BuyerTechnicalDetails = ({ features }) => {
    return (
        <>
            <div className="features_section">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                            <div className="features_section_table">
                                <h2>Features</h2>
                                <table>
                                    <tbody>
                                        {features?.map((feature, index) => (
                                            <tr key={index}>
                                                <td>
                                                    <strong>{feature?.title}</strong>
                                                </td>
                                                <td>{feature?.description}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>

    )
}

export default BuyerTechnicalDetails