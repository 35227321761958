import React, { useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';

const SellerSidebar = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const isActive = (path) => {

        return location.pathname === path

    };

    const sellerToken = localStorage.getItem('seller_token');
    useEffect(() => {
        if (!sellerToken) {
            navigate('/seller/sign-in')
        }
    }, [sellerToken])

    return (
        <>
            <Link
                id="show-sidebar"
                className="btn show-sidebar-xs btn-sm btn-dark"
                to="#"
            >
                <i className="fas fa-bars" />
            </Link>
            <nav id="sidebar" className="sidebar-wrapper new_xs">
                <div className="sidebar-content">
                    <div className="sidebar-brand xs-dis-blog">
                        <span id="close-sidebar" className="show-sidebar-xs">
                            <i className="fas fa-times" />
                        </span>
                    </div>
                    <div className="sidebar-header">
                        <Link to="/seller/dashboard">
                            <div className="user-pic">
                                <img
                                    className="img-responsive img-rounded"
                                    src="/assets/images/seller_images/logoipsum.png"
                                    alt="User picture"
                                />
                            </div>
                        </Link>
                    </div>
                    <div className="sidebar-menu das_bord_slider">
                        <ul>
                            <li className={isActive('/seller/dashboard') ? 'active' : ''}>
                                <Link to="/seller/dashboard" className="main_icon_hover">
                                    <i>
                                        <img src="/assets/images/seller_images/side_icon.png" alt="Dashboard" />
                                    </i>
                                    <span>Dashboard</span>
                                </Link>
                            </li>
                            <li className={`sidebar-dropdown ${(isActive('/seller/all_orders') || isActive('/seller/delivered_view_information') || isActive('/seller/pending_view_information') || isActive('/seller/cancelled_view_information') || isActive('/seller/new_order_shipment') || isActive('/seller/exchange_return_order_accept') || isActive('/seller/exchange_return_order_detail') || isActive('/seller/return_exchange')) ? 'active' : ''}`}>
                                <Link to="/seller/all_orders" className="main_icon_hover">
                                    <i>
                                        <img src="/assets/images/seller_images/side_icon_1.png" alt="Orders" />
                                    </i>
                                    <span>Orders</span>
                                </Link>
                                <div className="sidebar-submenu" style={{ display: (isActive('/seller/all_orders') || isActive('/seller/delivered_view_information') || isActive('/seller/pending_view_information') || isActive('/seller/cancelled_view_information') || isActive('/seller/new_order_shipment') || isActive('/seller/exchange_return_order_accept') || isActive('/seller/exchange_return_order_detail') || isActive('/seller/return_exchange')) ? 'block' : 'none' }}>
                                    <ul className="oder_list_detail">
                                        <li className=''>
                                            <Link to="/seller/all_orders">
                                                <span className={(isActive('/seller/all_orders') || isActive('/seller/delivered_view_information') || isActive('/seller/pending_view_information') || isActive('/seller/cancelled_view_information') || isActive('/seller/new_order_shipment')) ? 'active_hover' : ''} > Manage Orders</span>
                                            </Link>
                                        </li>
                                        <li className=''>
                                            <Link to="/seller/return_exchange">
                                                <span className={(isActive('/seller/exchange_return_order_accept') || isActive('/seller/exchange_return_order_detail') || isActive('/seller/return_exchange')) ? 'active_hover' : ''}> Return/Exchange</span>
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            </li>
                            <li className={(isActive('/seller/products') || isActive('/seller/products/view') || isActive('/seller/products/review/edit') || isActive('/seller/products/add/identity') || isActive('/seller/products/add/description') || isActive('/seller/products/add/variation') || isActive('/seller/products/add/details') || isActive('/seller/products/review')) ? 'active' : ''}>
                                <Link to="/seller/products" className="main_icon_hover">
                                    <i>
                                        <img src="/assets/images/seller_images/side_icon_2.png" alt="Products" />
                                    </i>
                                    <span>Products</span>
                                </Link>
                            </li>
                            <li className={isActive('/seller/profile') ? 'active' : ''}>
                                <Link to="/seller/profile" className="main_icon_hover">
                                    <i>
                                        <img src="/assets/images/seller_images/side_icon_4.png" alt="Profile" />
                                    </i>
                                    <span>Profile</span>
                                </Link>
                            </li>
                            <li className={isActive('/seller/inventory_alert_1') ? 'active' : ''}>
                                <Link to="/seller/inventory_alert_1" className="main_icon_hover">
                                    <i>
                                        <img src="/assets/images/seller_images/symbols_inventory.png" alt="Inventory" />
                                    </i>
                                    <span>Inventory</span>
                                </Link>
                            </li>
                            <li className={isActive('/seller/authentication') ? 'active' : ''}>
                                <Link to="/seller/authentication" className="main_icon_hover">
                                    <i>
                                        <img src="/assets/images/seller_images/authentication.png" alt="Authentication" />
                                    </i>
                                    <span>Authentication</span>
                                </Link>
                            </li>
                            <li className={isActive('/seller/contact_us') ? 'active' : ''}>
                                <Link to="/seller/contact_us" className="main_icon_hover">
                                    <i>
                                        <img src="/assets/images/seller_images/support-outline.png" alt="Help & Contact Us" />
                                    </i>
                                    <span>Help & Contact Us</span>
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>

        </>
    );
}

export default SellerSidebar;
