import { React, useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useFormik } from "formik";
import { toast } from 'react-hot-toast';
import * as Yup from "yup";
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { getSellerRegistrationDetails } from '../../Redux/action/SellerAction';

const host = process.env.REACT_APP_API_SERVER

const StoreDetails = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const sellerIncompleteToken = localStorage.getItem('seller_incomplete_token')
    useEffect(() => {
        if (sellerIncompleteToken) {
            dispatch(getSellerRegistrationDetails(sellerIncompleteToken))
        }
    }, [sellerIncompleteToken])

    const {

        loadingSellerRegistrationDataListApi,
        SellerRegistrationDataListApiData,
        SellerRegistrationDataListApiError,

    } = useSelector(state => state?.SellerReducer);




    let [initialValues, setInitialValues] = useState({

        storeCompanyName: "",
        storeName: "",


    })

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: Yup.object().shape({



            storeCompanyName: Yup.string().required("Company name is required"),
            storeName: Yup.string().required("Store name is required"),

        }),
        onSubmit: async (values, { setSubmitting, resetForm }) => {

            try {
                if (sellerIncompleteToken) {

                    const config = {
                        headers: {

                            'content-type': 'application/json',
                            "auth-token": sellerIncompleteToken
                        }
                    };
                    setSubmitting(true);
                    values.fullDetails = 0;
                    await axios.post(`${host}/seller/seller-add-store-details`, values, config)
                        .then(function (response) {
                            if (response.status == 200) {
                                setSubmitting(false)

                                toast.success(response.data.message);
                                resetForm();
                                navigate('/seller/bank-details')

                            } else {

                                setSubmitting(false)

                                toast.error(response.data.message);
                                if (response.data.delAccount) {
                                    localStorage.removeItem('seller_incomplete_token')
                                }

                            }

                        })
                        .catch(function (error) {
                            setSubmitting(false)
                            toast.error(error.message);

                        })


                }
                else {
                    navigate('/seller/create-account')
                }

            }
            catch (error) {


                setSubmitting(false)
                toast.error('Error submitting form. Please try again.');

            }

        }
    });


    useEffect(() => {

        if (SellerRegistrationDataListApiData) {
            formik.setValues({
                storeCompanyName: SellerRegistrationDataListApiData?.storeCompanyName ?? "",
                storeName: SellerRegistrationDataListApiData?.storeName ?? "",

            })
        }
    }, [SellerRegistrationDataListApiData])

    if (loadingSellerRegistrationDataListApi) {
        return <div className="overlay-form-submission">
            {/* <Loader /> */}
        </div>
    }

    if (SellerRegistrationDataListApiError) {
        // return <ErrorLoader message={SellerRegistrationDataListApiError} />;
    }



    return (
        <div className='seller'>
            <main className="main_login_section container-fluid">
                <div className="row">
                    <div className="col-lg-7 main_bg_detail">
                        <div className="main_login_detail detail_form_Shipping">
                            <form method='post' onSubmit={formik.handleSubmit}>
                                <div className="logo_login">
                                    <img src={`${process.env.PUBLIC_URL}/assets/images/seller_images/login_logo.png`} alt="login_logo" />
                                </div>
                                <div className="detail_form_log detail_form_Shipping">
                                    <h3>Store Details</h3>
                                    <p>
                                        Please enter your data to complete your account registration
                                        process
                                    </p>
                                    <div className="main_detail_address mb-5">
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className="form-group log_in_main">
                                                    <label>Company Name</label>
                                                    <input
                                                        type="text"
                                                        defaultValue=""
                                                        placeholder="Enter Company Name"
                                                        className="form-control"
                                                        name='storeCompanyName'
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        value={formik.values.storeCompanyName}
                                                    />
                                                    <div className="help-block with-errors">
                                                        {formik.touched.storeCompanyName && formik.errors.storeCompanyName ? (
                                                            <div className='text-danger'>{formik.errors.storeCompanyName}</div>
                                                        ) : null}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className="form-group log_in_main">
                                                    <label>Store Name</label>
                                                    <input
                                                        type="text"
                                                        defaultValue=""
                                                        placeholder="Enter Store Name"
                                                        className="form-control"
                                                        name='storeName'
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        value={formik.values.storeName}
                                                    />
                                                    <div className="help-block with-errors">
                                                        {formik.touched.storeName && formik.errors.storeName ? (
                                                            <div className='text-danger'>{formik.errors.storeName}</div>
                                                        ) : null}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="detail_address_button mt-2">
                                                <button type='submit' disabled={formik.isSubmitting}>Next</button>
                                                {/* <Link to="/seller/bank-details">Next</Link> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className="col-lg-5 main_login_img">
                        <img src={`${process.env.PUBLIC_URL}/assets/images/seller_images/store_details.png`} alt="login" />
                    </div>
                </div>
            </main>

        </div>
    )
}

export default StoreDetails