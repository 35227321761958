const masterInitialState = {
    //category
    loadingCategoryDataListApi: false,
    CategoryDataListApiData: [],
    CategoryDataListApiError: '',

    //country
    loadingCountryDataListApi: false,
    CountryDataListApiData: [],
    CountryDataListApiError: '',

    //state
    loadingStateDataListApi: false,
    StateDataListApiData: [],
    StateDataListApiError: '',

    //city
    loadingCityDataListApi: false,
    CityDataListApiData: [],
    CityDataListApiError: '',

    //website setting
    loadingWebsiteSettingDataListApi: false,
    WebsiteSettingDataListApiData: [],
    WebsiteSettingDataListApiError: '',

    //colour
    loadingColourDataListApi: false,
    ColourDataListApiData: [],
    ColourDataListApiError: '',

    //brand
    loadingBrandDataListApi: false,
    BrandDataListApiData: [],
    BrandDataListApiError: '',

    //assign size
    loadingAssignSizeDataListApi: false,
    AssignSizeDataListApiData: [],
    AssignSizeDataListApiError: '',

    //cms
    loadingCmsDataListApi: false,
    CmsDataListApiData: [],
    CmsDataListApiError: '',

    //CustomerRatingDetails
    loadingCustomerRatingDetailsListApi: false,
    CustomerRatingDetailsListApiData: [],
    CustomerRatingDetailsListApiError: '',

    //CustomerReviewList
    loadingCustomerReviewListApi: false,
    CustomerReviewListApiData: [],
    CustomerReviewListApiError: '',

    //Faq List
    loadingFaqListApi: false,
    FaqListApiData: [],
    FaqListApiError: '',

    //Product on category List
    loadingProductOnCategoryListApi: false,
    ProductOnCategoryListApiData: [],
    ProductOnCategoryListApiError: '',


}

const BuyerReducer = (state = masterInitialState, action) => {
    switch (action.type) {

        case 'FETCH_CATEGORY_DATA_REQUEST':
            return {
                ...state,
                loadingCategoryDataListApi: true,
                CategoryDataListApiError: '',
            };
        case 'FETCH_CATEGORY_DATA_SUCCESS':
            return {
                ...state,
                loadingCategoryDataListApi: false,
                CategoryDataListApiData: action.payload,
            };
        case 'FETCH_CATEGORY_DATA_FAILURE':

            return {
                ...state,
                loadingCategoryDataListApi: false,
                CategoryDataListApiError: action.payload,
            };

        case 'FETCH_COUNTRY_DATA_REQUEST':
            return {
                ...state,
                loadingCountryDataListApi: true,
                CountryDataListApiError: '',
            };
        case 'FETCH_COUNTRY_DATA_SUCCESS':
            return {
                ...state,
                loadingCountryDataListApi: false,
                CountryDataListApiData: action.payload,
            };
        case 'FETCH_COUNTRY_DATA_FAILURE':

            return {
                ...state,
                loadingCountryDataListApi: false,
                CountryDataListApiError: action.payload,
            };

        case 'FETCH_STATE_DATA_REQUEST':
            return {
                ...state,
                loadingStateDataListApi: true,
                StateDataListApiError: '',
            };
        case 'FETCH_STATE_DATA_SUCCESS':
            return {
                ...state,
                loadingStateDataListApi: false,
                StateDataListApiData: action.payload,
            };
        case 'FETCH_STATE_DATA_FAILURE':

            return {
                ...state,
                loadingStateDataListApi: false,
                StateDataListApiError: action.payload,
            };
        case 'FETCH_CITY_DATA_REQUEST':
            return {
                ...state,
                loadingCityDataListApi: true,
                CityDataListApiError: '',
            };
        case 'FETCH_CITY_DATA_SUCCESS':
            return {
                ...state,
                loadingCityDataListApi: false,
                CityDataListApiData: action.payload,
            };
        case 'FETCH_CITY_DATA_FAILURE':

            return {
                ...state,
                loadingCityDataListApi: false,
                CityDataListApiError: action.payload,
            };

        case 'FETCH_WEBSITE_SETTING_REQUEST':
            return {
                ...state,
                loadingWebsiteSettingDataListApi: true,
                WebsiteSettingDataListApiError: '',
            };
        case 'FETCH_WEBSITE_SETTING_SUCCESS':
            return {
                ...state,
                loadingWebsiteSettingDataListApi: false,
                WebsiteSettingDataListApiData: action.payload,
            };
        case 'FETCH_WEBSITE_SETTING_FAILURE':

            return {
                ...state,
                loadingWebsiteSettingDataListApi: false,
                WebsiteSettingDataListApiError: action.payload,
            };

        case 'FETCH_BRAND_DATA_REQUEST':
            return {
                ...state,
                loadingBrandDataListApi: true,
                BrandDataListApiError: '',
            };
        case 'FETCH_BRAND_DATA_SUCCESS':
            return {
                ...state,
                loadingBrandDataListApi: false,
                BrandDataListApiData: action.payload,
            };
        case 'FETCH_BRAND_DATA_FAILURE':

            return {
                ...state,
                loadingBrandDataListApi: false,
                BrandDataListApiError: action.payload,
            };


        case 'FETCH_COLOUR_DATA_REQUEST':
            return {
                ...state,
                loadingColourDataListApi: true,
                ColourDataListApiError: '',
            };
        case 'FETCH_COLOUR_DATA_SUCCESS':
            return {
                ...state,
                loadingColourDataListApi: false,
                ColourDataListApiData: action.payload,
            };
        case 'FETCH_COLOUR_DATA_FAILURE':

            return {
                ...state,
                loadingColourDataListApi: false,
                ColourDataListApiError: action.payload,
            };

        case 'FETCH_ASSIGN_SIZE_DATA_REQUEST':
            return {
                ...state,
                loadingAssignSizeDataListApi: true,
                AssignSizeDataListApiError: '',
            };
        case 'FETCH_ASSIGN_SIZE_DATA_SUCCESS':
            return {
                ...state,
                loadingAssignSizeDataListApi: false,
                AssignSizeDataListApiData: action.payload,
            };
        case 'FETCH_ASSIGN_SIZE_DATA_FAILURE':

            return {
                ...state,
                loadingAssignSizeDataListApi: false,
                AssignSizeDataListApiError: action.payload,
            };


        case 'FETCH_CMS_DATA_REQUEST':
            return {
                ...state,
                loadingCmsDataListApi: true,
                CmsDataListApiError: '',
            };
        case 'FETCH_CMS_DATA_SUCCESS':
            return {
                ...state,
                loadingCmsDataListApi: false,
                CmsDataListApiData: action.payload,
            };
        case 'FETCH_CMS_DATA_FAILURE':

            return {
                ...state,
                loadingCmsDataListApi: false,
                CmsDataListApiError: action.payload,
            };


        case 'FETCH_CUSTOMER_RATING_DETAIL_REQUEST':
            return {
                ...state,
                loadingCustomerRatingDetailsListApi: true,
                CustomerRatingDetailsListApiError: '',
            };
        case 'FETCH_CUSTOMER_RATING_DETAIL_SUCCESS':
            return {
                ...state,
                loadingCustomerRatingDetailsListApi: false,
                CustomerRatingDetailsListApiData: action.payload,
            };
        case 'FETCH_CUSTOMER_RATING_DETAIL_FAILURE':

            return {
                ...state,
                loadingCustomerRatingDetailsListApi: false,
                CustomerRatingDetailsListApiError: action.payload,
            };

        case 'FETCH_CUSTOMER_REVIEW_LIST_REQUEST':
            return {
                ...state,
                loadingCustomerReviewListApi: true,
                CustomerReviewListApiError: '',
            };
        case 'FETCH_CUSTOMER_REVIEW_LIST_SUCCESS':
            return {
                ...state,
                loadingCustomerReviewListApi: false,
                CustomerReviewListApiData: action.payload,
            };
        case 'FETCH_CUSTOMER_REVIEW_LIST_FAILURE':

            return {
                ...state,
                loadingCustomerReviewListApi: false,
                CustomerReviewListApiError: action.payload,
            };

        case 'FETCH_FAQ_LIST_REQUEST':
            return {
                ...state,
                loadingFaqListApi: true,
                FaqListApiError: '',
            };
        case 'FETCH_FAQ_LIST_SUCCESS':
            return {
                ...state,
                loadingFaqListApi: false,
                FaqListApiData: action.payload,
            };
        case 'FETCH_FAQ_LIST_FAILURE':

            return {
                ...state,
                loadingFaqListApi: false,
                FaqListApiError: action.payload,
            };


        case 'FETCH_PRODUCT_ON_CATEGORY_REQUEST':
            return {
                ...state,
                loadingProductOnCategoryListApi: true,
                ProductOnCategoryListApiError: '',
            };
        case 'FETCH_PRODUCT_ON_CATEGORY_SUCCESS':
            return {
                ...state,
                loadingProductOnCategoryListApi: false,
                ProductOnCategoryListApiData: action.payload,
            };
        case 'FETCH_PRODUCT_ON_CATEGORY_FAILURE':

            return {
                ...state,
                loadingProductOnCategoryListApi: false,
                ProductOnCategoryListApiError: action.payload,
            };



        default:
            return state;
    }
}
export default BuyerReducer;