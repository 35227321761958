import { React, useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useFormik } from "formik";
import { toast } from 'react-hot-toast';
import * as Yup from "yup";
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { getSellerAllBillingAddress, getSellerRegistrationDetails } from '../../Redux/action/SellerAction';

const host = process.env.REACT_APP_API_SERVER

const CreateSellerAccount = () => {

    const [faIcon, setfaIcon] = useState('fa-eye-slash');
    const togglePasswordType = () => {
        let input_pass = document.getElementById('password-field');
        const toggle_pass = document.getElementById("toggle_pass");
        let input_type = input_pass.getAttribute('type');
        if (input_type == "password") {
            setfaIcon('fa-eye');
            input_pass.type = "text";
        } else {
            setfaIcon('fa-eye-slash')
            input_pass.type = "password"
        }
    }

    const [faIcon1, setfaIcon1] = useState('fa-eye-slash');
    const togglePasswordType1 = () => {
        let input_pass = document.getElementById('password-field_1');
        const toggle_pass = document.getElementById("toggle_pass_1");
        let input_type = input_pass.getAttribute('type');
        if (input_type == "password") {
            setfaIcon1('fa-eye');
            input_pass.type = "text";
        } else {
            setfaIcon1('fa-eye-slash')
            input_pass.type = "password"
        }
    }

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const sellerIncompleteToken = localStorage.getItem('seller_incomplete_token')
    useEffect(() => {
        if (sellerIncompleteToken) {
            dispatch(getSellerRegistrationDetails(sellerIncompleteToken))
            dispatch(getSellerAllBillingAddress(sellerIncompleteToken))
        }
    }, [sellerIncompleteToken])

    const {

        loadingSellerRegistrationDataListApi,
        SellerRegistrationDataListApiData,
        SellerRegistrationDataListApiError,

        loadingSellerAllBillingAddressListApi,
        SellerAllBillingAddressListApiData,
        SellerAllBillingAddressListApiError,

    } = useSelector(state => state?.SellerReducer);




    let [initialValues, setInitialValues] = useState({
        firstName: "",
        lastName: "",
        phone: "",
        email: "",
        dateOfBirth: "",
        currentAdd: "",
        password: "",
        cpassword: "",
        fullDetails: "",

    })

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: Yup.object().shape({


            firstName: Yup.string().required("First name is required").matches(/^[a-zA-Z .]+$/, "Name can only contain letters."),
            lastName: Yup.string().matches(/^[a-zA-Z .]+$/, "Name can only contain letters."),
            phone: Yup.string().required("Phone no. is required").matches(/^\+?[0-9]{1,4}?[-\s]?[0-9]{8,15}$/, "Please enter a valid phone number with optional country code."),
            email: Yup.string().required("Email is required.").matches(/^\w+[-\.\w]*@(?!(?:)\.com$)\w+[-\.\w]*?\.\w{2,3}$/, "Enter a valid E-mail"),
            dateOfBirth: Yup.date().required('Date of birth is required'),
            currentAdd: Yup.string().required("Address is required"),
            password: Yup.string()
                .required("Password is required")
                .min(6, "Password must be at least 6 characters long"),
            cpassword: Yup.string()
                .oneOf([Yup.ref('password'), null], 'Passwords must match')
                .required("Confirm Password is required"),

        }),
        onSubmit: async (values, { setSubmitting, resetForm }) => {

            try {
                if (sellerIncompleteToken) {

                    const config = {
                        headers: {

                            'content-type': 'application/json',
                            "auth-token": sellerIncompleteToken
                        }
                    };
                    setSubmitting(true);
                    values.fullDetails = 0;
                    await axios.post(`${host}/seller/seller-add-basic-details`, values, config)
                        .then(function (response) {
                            if (response.status == 200) {
                                setSubmitting(false)

                                toast.success(response.data.message);
                                resetForm();
                                if (SellerAllBillingAddressListApiData && SellerAllBillingAddressListApiData?.length) {
                                    navigate('/seller/billing-address-list')
                                }
                                else {
                                    navigate('/seller/add-billing-address')
                                }

                            } else {

                                setSubmitting(false)

                                toast.error(response.data.message);
                                if (response.data.delAccount) {
                                    localStorage.removeItem('seller_incomplete_token')
                                }

                            }

                        })
                        .catch(function (error) {
                            setSubmitting(false)
                            toast.error(error.message);

                        })


                }
                else {

                    setSubmitting(true);
                    values.fullDetails = 0;
                    await axios.post(`${host}/seller/seller-add-basic-details`, values)
                        .then(function (response) {
                            if (response.status == 200) {
                                setSubmitting(false)

                                toast.success(response.data.message);
                                resetForm();

                                localStorage.setItem("seller_incomplete_token", response.data.authToken);
                                navigate('/seller/add-billing-address')

                            } else {

                                setSubmitting(false)

                                toast.error(response.data.message);

                            }

                        })
                        .catch(function (error) {


                            setSubmitting(false)
                            toast.error('Error submitting form. Please try again.');

                        })

                }
            }
            catch (error) {


                setSubmitting(false)
                toast.error('Error submitting form. Please try again.');

            }

        }
    });

    useEffect(() => {

        if (SellerRegistrationDataListApiData) {
            formik.setValues({
                firstName: SellerRegistrationDataListApiData?.firstName ?? "",
                lastName: SellerRegistrationDataListApiData?.lastName ?? "",
                phone: SellerRegistrationDataListApiData?.phone ?? "",
                email: SellerRegistrationDataListApiData?.email ?? "",
                dateOfBirth: SellerRegistrationDataListApiData?.dateOfBirth ?? "",
                currentAdd: SellerRegistrationDataListApiData?.currentAdd ?? "",
                password: SellerRegistrationDataListApiData?.raw_password ?? "",
            })
        }
    }, [SellerRegistrationDataListApiData])

    if (loadingSellerRegistrationDataListApi) {
        return <div className="overlay-form-submission">
            {/* <Loader /> */}
        </div>
    }

    if (SellerRegistrationDataListApiError) {
        // return <ErrorLoader message={SellerRegistrationDataListApiError} />;
    }


    return (
        <div className='seller'>
            <main className="main_login_section container-fluid">
                <div className="row">
                    <div className="col-lg-7 main_bg_detail">
                        <div className="main_login_detail">
                            <form method='post' onSubmit={formik.handleSubmit}>
                                <div className="logo_login">
                                    <img src={`${process.env.PUBLIC_URL}/assets/images/seller_images/login_logo.png`} alt="login_logo" />
                                </div>
                                <div className="detail_form_log detail_form_careate">
                                    <h3>Create Account</h3>
                                    <p>Welcome to Afghan Bazar! please enter your details</p>
                                    <div className="row">
                                        <div className="col-lg-6 col-md-6 col-sm-6">
                                            <div className="form-group log_in_main">
                                                <label>First Name</label>
                                                <input
                                                    type="text"

                                                    name='firstName'
                                                    className='form-control'
                                                    placeholder='Enter first name'
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.firstName}
                                                />
                                                <div className="help-block with-errors">
                                                    {formik.touched.firstName && formik.errors.firstName ? (
                                                        <div className='text-danger'>{formik.errors.firstName}</div>
                                                    ) : null}
                                                </div>
                                            </div>

                                        </div>
                                        <div className="col-lg-6 col-md-6 col-sm-6">
                                            <div className="form-group log_in_main">
                                                <label>Last Name</label>
                                                <input
                                                    type="text"
                                                    name='lastName'
                                                    className='form-control'
                                                    placeholder='Enter last name'
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.lastName}
                                                />
                                                <div className="help-block with-errors">
                                                    {formik.touched.lastName && formik.errors.lastName ? (
                                                        <div className='text-danger'>{formik.errors.lastName}</div>
                                                    ) : null}
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-12 col-md-12 col-sm-12">
                                            <div className="form-group log_in_main">
                                                <label>Email</label>
                                                <input
                                                    type="text"
                                                    name='email'
                                                    className='form-control'
                                                    placeholder='Enter your email address'
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.email}
                                                />
                                                <div className="help-block with-errors">
                                                    {formik.touched.email && formik.errors.email ? (
                                                        <div className='text-danger'>{formik.errors.email}</div>
                                                    ) : null}
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-6 col-md-6 col-sm-6">
                                            <div className="form-group log_in_main">
                                                <label>Phone Number</label>
                                                <input
                                                    type="text"
                                                    name='phone'
                                                    className='form-control'
                                                    placeholder='Enter your phone number'
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.phone}
                                                />
                                                <div className="help-block with-errors">
                                                    {formik.touched.phone && formik.errors.phone ? (
                                                        <div className='text-danger'>{formik.errors.phone}</div>
                                                    ) : null}
                                                </div>
                                            </div>

                                        </div>
                                        <div className="col-lg-6 col-md-6 col-sm-6">
                                            <div className="form-group log_in_main">
                                                <label>Date Of Birth</label>
                                                <input
                                                    type="date"
                                                    name='dateOfBirth'
                                                    max={new Date().toISOString()?.split('T')[0]}
                                                    className='form-control'
                                                    placeholder='Enter your Date of birth'
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.dateOfBirth}
                                                />
                                                <div className="help-block with-errors">
                                                    {formik.touched.dateOfBirth && formik.errors.dateOfBirth ? (
                                                        <div className='text-danger'>{formik.errors.dateOfBirth}</div>
                                                    ) : null}
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-12 col-md-12 col-sm-12">
                                            <div className="form-group log_in_main">
                                                <label>Enter Full Address</label>
                                                <input
                                                    type="text"
                                                    name='currentAdd' className='form-control text-dark'
                                                    placeholder='Enter your full address'
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.currentAdd}
                                                />
                                                <div className="help-block with-errors">
                                                    {formik.touched.currentAdd && formik.errors.currentAdd ? (
                                                        <div className='text-danger'>{formik.errors.currentAdd}</div>
                                                    ) : null}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-12 col-md-12 col-sm-12">
                                            <div className="form-group log_in_main">
                                                <label>Password</label>
                                                <input
                                                    type="text"
                                                    id="password-field"
                                                    name='password'
                                                    placeholder="Enter New Password"
                                                    className="form-control"
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.password}
                                                />
                                                <a className="main_show_eyes">
                                                    <span
                                                        id="toggle_pass" onClick={togglePasswordType}
                                                        toggle="#password-field" className={`fa-regular ${faIcon} field-icon toggle-password `}
                                                    />
                                                </a>
                                                <div className="help-block with-errors">
                                                    {formik.touched.password && formik.errors.password ? (
                                                        <div className='text-danger'>{formik.errors.password}</div>
                                                    ) : null}
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-12 col-md-12 col-sm-12">
                                            <div className="form-group log_in_main">
                                                <label>Confirm Password</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="password-field_1"
                                                    name="cpassword"
                                                    placeholder="Enter password again"
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.cpassword}
                                                />
                                                <a className="main_show_eyes">
                                                    <span
                                                        id="toggle_pass_1" onClick={togglePasswordType1}
                                                        toggle="#password-field_1" className={`fa-regular ${faIcon1} field-icon toggle-password `}
                                                    />
                                                </a>
                                                <div className="help-block with-errors">
                                                    {formik.touched.cpassword && formik.errors.cpassword ? (
                                                        <div className='text-danger'>{formik.errors.cpassword}</div>
                                                    ) : null}
                                                </div>
                                            </div>


                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-12 col-md-12 col-sm-12">
                                            <div className="log_in_main_button">
                                                {/* <button>Next</button>   */}
                                                <button type='submit' disabled={formik.isSubmitting}>Next</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="text_login_p">
                                    <p>
                                        By continuing you agree to E-Commerce{" "}
                                        <Link to="/seller/confidentiality-policy">Confidentiality Policy</Link> and{" "}
                                        <Link to="/seller/terms-of-use">Terms Of Use.</Link>{" "}
                                    </p>
                                </div>
                                <div className="main_login_newbazar">
                                    <p>
                                        <span>Already have an Account?</span>
                                    </p>
                                </div>
                                <div className="main_login_newbazar">
                                    <Link to="/seller/sign-in">Sign In</Link>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className="col-lg-5 main_login_img">
                        <img src={`${process.env.PUBLIC_URL}/assets/images/seller_images/Create_Account.png`} alt="login" />
                    </div>
                </div>
            </main>

        </div>
    )
}

export default CreateSellerAccount