import { React, useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { useFormik } from "formik";
import { toast } from 'react-hot-toast';
import * as Yup from "yup";
import axios from 'axios';
import './scss/BuyerAddNewAddressContent.css'
import { getCityData, getCountry, getStateData } from '../../../Redux/action/MasterAction';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../../Loader';
import ErrorLoader from '../../../Error/ErrorLoader';

const host = process.env.REACT_APP_API_SERVER
const BuyerAddNewAddressContent = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getCountry());
    }, []);

    const handleCountryChange = (e) => {
        const id = e.target.value

        dispatch(getStateData(id))
    }

    const handleStateChange = (e) => {
        const id = e.target.value

        dispatch(getCityData(id))
    }
    const {
        loadingCountryDataListApi,
        CountryDataListApiData,
        CountryDataListApiError,

        loadingStateDataListApi,
        StateDataListApiData,
        StateDataListApiError,

        loadingCityDataListApi,
        CityDataListApiData,
        CityDataListApiError,


    } = useSelector(state => state?.MasterReducer);



    const config = {
        headers: {

            'content-type': 'application/json',
            "auth-token": localStorage.getItem("buyer_token")
        }
    };

    const formik = useFormik({
        initialValues: {
            firstName: "",
            lastName: "",
            email: "",
            phone: "",
            address: "",
            typeOfAddress: "",
            country: "",
            state: "",
            city: "",
            pincode: "",
            defaultAddress: "",

        },
        validationSchema: Yup.object().shape({
            firstName: Yup.string().required("First name is required").matches(/^[a-zA-Z .]+$/, "Name can only contain letters."),
            lastName: Yup.string().matches(/^[a-zA-Z .]+$/, "Name can only contain letters."),
            phone: Yup.string().required("Phone no. is required").matches(/^[0-9]{8,15}$/, "Please enter a valid Mobile Number."),
            email: Yup.string().required("Email is required.").matches(/^\w+[-\.\w]*@(?!(?:)\.com$)\w+[-\.\w]*?\.\w{2,4}$/, "Enter a valid E-mail"),
            address: Yup.string().required("Address is required"),
            typeOfAddress: Yup.string().required("Type of address is required"),
            country: Yup.string().required("Country is required"),
            state: Yup.string().required("Province is required"),
            city: Yup.string().required("City is required"),
            pincode: Yup.string().required("Zip code is required").matches(/^[0-9]+$/, 'Zip code must contain only numbers')
                .min(4, 'Enter a valid Zip code')
                .required('Zip code is required'),
        }),
        onSubmit: async (values, { setSubmitting, resetForm }) => {
            try {
                setSubmitting(true);

                await axios.post(`${host}/buyer/buyer-add-address`, values, config)
                    .then(function (response) {
                        if (response.status == 200) {
                            setSubmitting(false)
                            toast.success("Address Added successfully");
                            resetForm();
                            navigate('/buyer/your-address')
                        } else {
                            setSubmitting(false)
                            toast.error(response.data.message);
                        }

                    })
                    .catch(function (error) {
                        setSubmitting(false)
                        console.log(error)
                        toast.error(error.response.data.message);
                    })
            }
            catch (error) {
                setSubmitting(false)
                toast.error('Error submitting form. Please try again.');
            }
        }
    })



    // if (loadingCountryDataListApi || loadingStateDataListApi || loadingCityDataListApi) {
    //     return <div className="overlay-form-submission">
    //         <Loader />
    //     </div>
    // }

    if (CountryDataListApiError || StateDataListApiError || CityDataListApiError) {
        return <ErrorLoader message={CountryDataListApiError} />;
    }


    return (
        <div className='buyer-add-new-address'>
            <>
                {/* Breadcrumb */}
                <div className="breadcrumb_section">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                <div className="breadcrumb_section_content">
                                    <ul>
                                        <li>
                                            <Link to="/buyer/your-account"> Your Account</Link>
                                        </li>
                                        <li>
                                            <i className="fa-solid fa-angle-right" />
                                        </li>
                                        <li>
                                            <Link to="/buyer/your-address">Your Address</Link>
                                        </li>
                                        <li>
                                            <i className="fa-solid fa-angle-right" />
                                        </li>
                                        <li>
                                            <Link to="/buyer/add-new-address">Add New Address</Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Breadcrumb */}
                {/* Profile */}
                <div className="profile_section">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                <div className="profile_heading border-bottom pb-2 mb-4">
                                    <h2>Add New Address</h2>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                <div className="add_new_address">
                                    <form onSubmit={formik.handleSubmit} method='post'>

                                        <div className="row">
                                            <div className="col-xl-1 col-lg-1 col-md-1 col-sm-1 col-12" />
                                            <div className="col-xl-10 col-lg-10 col-md-10 col-sm-10 col-12">
                                                <div className='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'>
                                                    <div className="add-new-address_btn">
                                                        {/* <div className="add_new_address_btn_left">
                                                            <Link to="">Edit</Link>
                                                        </div> */}
                                                        <div className="add_new_address_btn_right">
                                                            <button type='submit'>Save Changes</button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 mb-3">
                                                        <div className="add_new_address_right_field">
                                                            <label htmlFor="ether">First Name<span className='text-danger'>*</span></label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                id="firstName"
                                                                placeholder="Enter First Name"
                                                                name="firstName"

                                                                onChange={formik.handleChange}
                                                                onBlur={formik.handleBlur}
                                                                value={formik.values.firstName} />
                                                            <div className="help-block with-errors">
                                                                {formik.touched.firstName && formik.errors.firstName ? (
                                                                    <div className='text-danger'>{formik.errors.firstName}</div>
                                                                ) : null}
                                                            </div>
                                                        </div>

                                                    </div>
                                                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 mb-3">
                                                        <div className="add_new_address_right_field">
                                                            <label htmlFor="ether">Last Name</label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                id="lastName"
                                                                name="lastName"
                                                                placeholder="Enter Last Name"
                                                                onChange={formik.handleChange}
                                                                onBlur={formik.handleBlur}
                                                                value={formik.values.lastName} />
                                                            <div className="help-block with-errors">
                                                                {formik.touched.lastName && formik.errors.lastName ? (
                                                                    <div className='text-danger'>{formik.errors.lastName}</div>
                                                                ) : null}
                                                            </div>
                                                        </div>

                                                    </div>

                                                </div>
                                                <div className="row">
                                                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 mb-3">
                                                        <div className="add_new_address_right_field">
                                                            <label htmlFor="email">Email<span className='text-danger'>*</span></label>
                                                            <input
                                                                type="email"
                                                                className="form-control"
                                                                id="email"
                                                                name="email"
                                                                placeholder="Enter your email"
                                                                onChange={formik.handleChange}
                                                                onBlur={formik.handleBlur}
                                                                value={formik.values.email} />

                                                        </div>
                                                        <div className="help-block with-errors">
                                                            {formik.touched.email && formik.errors.email ? (
                                                                <div className='text-danger'>{formik.errors.email}</div>
                                                            ) : null}
                                                        </div>
                                                    </div>
                                                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 mb-3">
                                                        <div className="add_new_address_right_field">
                                                            <label htmlFor="phone_number">Phone Number<span className='text-danger'>*</span></label>
                                                            <input
                                                                type="tel"
                                                                className="form-control"
                                                                id="phone"
                                                                name="phone"
                                                                placeholder="Enter your phone number"
                                                                onChange={formik.handleChange}
                                                                onBlur={formik.handleBlur}
                                                                value={formik.values.phone} />

                                                        </div>
                                                        <div className="help-block with-errors">
                                                            {formik.touched.phone && formik.errors.phone ? (
                                                                <div className='text-danger'>{formik.errors.phone}</div>
                                                            ) : null}
                                                        </div>
                                                    </div>

                                                </div>
                                                <div className="row">
                                                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mb-3">
                                                        <div className="add_new_address_right_field">
                                                            <label htmlFor="address">Address<span className='text-danger'>*</span></label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                id="address"
                                                                name="address"
                                                                placeholder="Enter your address"
                                                                onChange={formik.handleChange}
                                                                onBlur={formik.handleBlur}
                                                                value={formik.values.address} />

                                                        </div>
                                                        <div className="help-block with-errors">
                                                            {formik.touched.address && formik.errors.address ? (
                                                                <div className='text-danger'>{formik.errors.address}</div>
                                                            ) : null}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 mb-3">
                                                        <div className="add_new_address_right_field">
                                                            <label htmlFor="address">Type Of Address<span className='text-danger'>*</span></label>
                                                            <div className="radio_buttom_section">
                                                                <div className="form-check">
                                                                    <input
                                                                        className="form-check-input"
                                                                        type="radio"

                                                                        id="general_inquiry"
                                                                        value="Office Address"
                                                                        checked={formik.values.typeOfAddress === 'Office Address'}
                                                                        name='typeOfAddress'
                                                                        onChange={formik.handleChange}
                                                                        onBlur={formik.handleBlur}
                                                                    />
                                                                    <label
                                                                        className="form-check-label"
                                                                        htmlFor="general_inquiry"
                                                                    >
                                                                        Office Address
                                                                    </label>
                                                                </div>
                                                                <div className="form-check">
                                                                    <input
                                                                        className="form-check-input"
                                                                        type="radio"

                                                                        id="general_inquiry_1"
                                                                        value="Home Address"
                                                                        checked={formik.values.typeOfAddress === 'Home Address'}
                                                                        name='typeOfAddress'
                                                                        onChange={formik.handleChange}
                                                                        onBlur={formik.handleBlur}
                                                                    />
                                                                    <label
                                                                        className="form-check-label"
                                                                        htmlFor="general_inquiry_1"
                                                                    >
                                                                        Home Address
                                                                    </label>
                                                                </div>


                                                            </div>
                                                            <div className="help-block with-errors">
                                                                {formik.touched.typeOfAddress && formik.errors.typeOfAddress ? (
                                                                    <div className='text-danger'>{formik.errors.typeOfAddress}</div>
                                                                ) : null}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12 mb-3">
                                                        <div className="add_new_address_right_field">
                                                            <label htmlFor="address">Country<span className='text-danger'>*</span></label>
                                                            <select name="country" id=""
                                                                onChange={(e) => {
                                                                    formik.handleChange(e);
                                                                    handleCountryChange(e)
                                                                }}
                                                                onBlur={formik.handleBlur}
                                                                value={formik.values.country}
                                                            >
                                                                <option value="">Select Country</option>
                                                                {CountryDataListApiData?.map((data) => (
                                                                    <option value={data?.id}>{data.name}</option>
                                                                ))}

                                                            </select>
                                                        </div>
                                                        <div className="help-block with-errors">
                                                            {formik.touched.country && formik.errors.country ? (
                                                                <div className='text-danger'>{formik.errors.country}</div>
                                                            ) : null}
                                                        </div>
                                                    </div>
                                                    <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12 mb-3">
                                                        <div className="add_new_address_right_field">
                                                            <label htmlFor="address">Province<span className='text-danger'>*</span></label>
                                                            <select name="state" id=""
                                                                onChange={(e) => {
                                                                    formik.handleChange(e);
                                                                    handleStateChange(e)
                                                                }}
                                                                onBlur={formik.handleBlur}
                                                                value={formik.values.state}
                                                            >
                                                                <option value="">Select Province</option>
                                                                {StateDataListApiData?.map((data) => (
                                                                    <option value={data?.id}>{data?.name}</option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                        <div className="help-block with-errors">
                                                            {formik.touched.state && formik.errors.state ? (
                                                                <div className='text-danger'>{formik.errors.state}</div>
                                                            ) : null}
                                                        </div>
                                                    </div>
                                                    <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12 mb-3">
                                                        <div className="add_new_address_right_field">
                                                            <label htmlFor="address">City<span className='text-danger'>*</span></label>
                                                            <select name="city" id=""
                                                                onChange={formik.handleChange}
                                                                onBlur={formik.handleBlur}
                                                                value={formik.values.city}
                                                            >
                                                                <option value="">Select City</option>
                                                                {CityDataListApiData?.map((data) => (
                                                                    <option value={data?.id}>{data?.name}</option>
                                                                ))}
                                                            </select>
                                                            <div className="help-block with-errors">
                                                                {formik.touched.city && formik.errors.city ? (
                                                                    <div className='text-danger'>{formik.errors.city}</div>
                                                                ) : null}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12 mb-3">
                                                        <div className="add_new_address_right_field">
                                                            <label htmlFor="zip_code">Zip Code<span className='text-danger'>*</span></label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                id="pincode"
                                                                name="pincode"
                                                                placeholder="Enter your zip code"
                                                                onChange={formik.handleChange}
                                                                onBlur={formik.handleBlur}
                                                                value={formik.values.pincode}
                                                            />
                                                        </div>
                                                        <div className="help-block with-errors">
                                                            {formik.touched.pincode && formik.errors.pincode ? (
                                                                <div className='text-danger'>{formik.errors.pincode}</div>
                                                            ) : null}
                                                        </div>
                                                    </div>
                                                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                                        <div className="add_new_address_right_field">
                                                            <div className="form-check">
                                                                <input
                                                                    className="form-check-input"
                                                                    type="checkbox"
                                                                    name='defaultAddress'
                                                                    id="defaultAddress"
                                                                    onChange={formik.handleChange}
                                                                    onBlur={formik.handleBlur}
                                                                    value={formik.values.defaultAddress}
                                                                />
                                                                <label
                                                                    className="form-check-label"
                                                                    htmlFor="defaultAddress"
                                                                >
                                                                    Make this my default Address
                                                                </label>
                                                            </div>
                                                            <div className="help-block with-errors">
                                                                {formik.touched.defaultAddress && formik.errors.defaultAddress ? (
                                                                    <div className='text-danger'>{formik.errors.defaultAddress}</div>
                                                                ) : null}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                            <div className="col-xl-1 col-lg-1 col-md-1 col-sm-1 col-12" />
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Profile */}
            </>




        </div>

    )
}

export default BuyerAddNewAddressContent