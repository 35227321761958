import { React, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useFormik } from "formik";
import { toast } from 'react-hot-toast';
import * as Yup from "yup";
import axios from 'axios';
import '../scss/Auth.css'

const host = process.env.REACT_APP_API_SERVER
const ResetPassword = () => {

    const [faIcon, setfaIcon] = useState('fa-eye-slash');
    const togglePasswordType = () => {
        let input_pass = document.getElementById('password-field');
        const toggle_pass = document.getElementById("toggle_pass");
        let input_type = input_pass.getAttribute('type');
        if (input_type == "password") {
            setfaIcon('fa-eye');
            input_pass.type = "text";
        } else {
            setfaIcon('fa-eye-slash')
            input_pass.type = "password"
        }
    }

    const [faIcon1, setfaIcon1] = useState('fa-eye-slash');
    const togglePasswordType1 = () => {
        let input_pass = document.getElementById('password-field_1');
        const toggle_pass = document.getElementById("toggle_pass_1");
        let input_type = input_pass.getAttribute('type');
        if (input_type == "password") {
            setfaIcon1('fa-eye');
            input_pass.type = "text";
        } else {
            setfaIcon1('fa-eye-slash')
            input_pass.type = "password"
        }
    }

    const navigate = useNavigate();
    const location = useLocation();
    const email = location.state?.email;
    const queryParams = new URLSearchParams(location.search);
    const guestUser = queryParams.get('guest');
    let [initialValues, setInitialValues] = useState({

        email: "",

        password: "",
        cpassword: "",

    })

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: Yup.object().shape({



            password: Yup.string()
                .required("Password is required")
                .min(6, "Password must be at least 6 characters long"),
            cpassword: Yup.string()
                .oneOf([Yup.ref('password'), null], 'Passwords must match')
                .required("Confirm Password is required"),

        }),
        onSubmit: async (values, { setSubmitting, resetForm }) => {

            try {

                setSubmitting(true);
                values.email = email




                await axios.post(`${host}/buyer/buyer-reset-password`, values)
                    .then(function (response) {
                        if (response.status == 200) {
                            setSubmitting(false)

                            toast.success("Password changed successfully");
                            resetForm();
                            if (guestUser && guestUser == 'true') {
                                navigate('/buyer/sign-in?guest=true')
                            }
                            else {
                                navigate('/buyer/sign-in')
                            }




                        } else {

                            setSubmitting(false)

                            toast.error(response.data.message);

                        }

                    })
                    .catch(function (error) {


                        setSubmitting(false)
                        toast.error('Error submitting form. Please try again.');

                    })

            }
            catch (error) {


                setSubmitting(false)
                toast.error('Error submitting form. Please try again.');

            }

        }
    });


    return (
        <div className="auth">
            <section className="login reset_password_section">
                <div className="container-fluid">
                    <div className="row welcome-login-main align-items-center">
                        <div className="col-md-6 px-5 form-section h-100">
                            <form method='post' onSubmit={formik.handleSubmit}>
                                <div className="fields-section">
                                    <div className="welcome-back-sec reset_password text-center pt-3">
                                        <img src="/assets/images/afgan-bazaar-logo.png" alt="logo" />
                                        {/* <h3>Afghan Bazaar</h3> */}
                                        <h1 className="text-strat">Reset Password</h1>
                                        <p className="text-strat">
                                            Welcome to Afghan Bazar! please enter your details
                                        </p>
                                    </div>

                                    <div className="pass-email-field">
                                        <label htmlFor="email">New password</label>
                                        <div className='password_input'>
                                            <input type="password" className="form-control"
                                                name="password"
                                                id="password-field"
                                                placeholder="Enter your password"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.password} />
                                            <i
                                                toggle="#password-field" className={`fa-regular ${faIcon} field-icon toggle-password `} id="toggle_pass" onClick={togglePasswordType}
                                            />
                                            <span />
                                        </div>
                                        <div className="help-block with-errors">
                                            {formik.touched.password && formik.errors.password ? (
                                                <div className='text-danger'>{formik.errors.password}</div>
                                            ) : null}
                                        </div>
                                        <label htmlFor="password">Confirm password</label>
                                        <div className='password_input'>
                                            <input type="password" className="form-control"
                                                id="password-field_1"
                                                name="cpassword"
                                                placeholder="Enter password again"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.cpassword} />
                                            <i
                                                toggle="#password-field_1"
                                                className={`fa-regular ${faIcon1} field-icon toggle-password `} id="toggle_pass_1" onClick={togglePasswordType1}
                                            />
                                            <span />

                                        </div>
                                        <div className="help-block with-errors">
                                            {formik.touched.cpassword && formik.errors.cpassword ? (
                                                <div className='text-danger'>{formik.errors.cpassword}</div>
                                            ) : null}
                                        </div>
                                    </div>
                                    <div className="submit-btn text-center">

                                        <button type='submit' disabled={formik.isSubmitting}>Reset</button>

                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="col-md-6 p-0 h-100">
                            <div className="login-banner-sec">
                                <img
                                    src="/assets/images/resest_pass.png"
                                    alt="banner"
                                    className="img-fluid"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default ResetPassword