import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';
// import AOS from 'aos';
// import 'aos/dist/aos.css'; // Import AOS styles
import { Provider } from 'react-redux';
import store from './Redux/store';

// Initialize AOS globally
// AOS.init({
//   duration: 1000,
//   offset: 0,
//   once: false,
//   // Additional options if needed
// });

// Handle window resize to refresh AOS
// const handleResize = () => {
//   AOS.refresh();
// };

// Add event listener for resizing
// window.addEventListener('resize', handleResize);

// Handle window scroll to add/remove sticky class
// const handleScroll = () => {
//   const header = document.querySelector('.secondary_header');
//   if (header) {
//     if (window.scrollY > 0) {
//       header.classList.add('sticky');
//     } else {
//       header.classList.remove('sticky');
//     }
//   }
// };

// Add event listener for scrolling
// window.addEventListener('scroll', handleScroll);

// Render the React application
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>
);

// Report web vitals (for performance monitoring)
reportWebVitals();

// Force AOS refresh on route change
// window.addEventListener('popstate', () => {
//   AOS.refresh();
// });
