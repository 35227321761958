import React from 'react'
import SellerSidebar from '../../SellerEssentials/SellerSidebar'
import SellerHeader from '../../SellerEssentials/SellerHeader'
import { Link } from 'react-router-dom'

const ProductReviewEdit = () => {
    return (
        <>
            <div className="seller">
                <div className="page-wrapper chiller-theme toggled">
                    <SellerSidebar />
                    {/* sidebar-wrapper  */}
                    <main className="page-content container-fluid">
                        {/* ==================header================== */}
                        <SellerHeader />
                        {/* ==================header================== */}
                        <div className="row ">
                            <div className="col-md-12">
                                <div className="main-nav-Order">
                                    <div className="dis-detail-new">
                                        <h2>
                                            {" "}
                                            <Link to="/seller/products">
                                                <img src={`${process.env.PUBLIC_URL}/assets/images/seller_images/left_side.png`} alt="left" />
                                            </Link>{" "}
                                            <span>Products </span>/ Edit
                                        </h2>
                                        <Link to="/seller/products">Edit Details</Link>
                                    </div>
                                    <div className="card_order_main">
                                        <div className="row pb-3">
                                            <form id="" action="#!">
                                                <div className="col-lg-12">
                                                    <div className="product_identity mb-4">
                                                        <h4>Product Identity</h4>
                                                        <div className="detail_product_ident">
                                                            <div className="main_detail_identity pt-0">
                                                                <div className="please_fill_main">
                                                                    <h6>
                                                                        Please Fill the below details to complete Product
                                                                        Identity:
                                                                    </h6>
                                                                </div>
                                                                <div className="row mt-3">
                                                                    <div className="col-lg-4 col-md-6 col-sm-6">
                                                                        <div className="form-group log_in_main">
                                                                            <label>Item Name</label>
                                                                            <input
                                                                                type="text"
                                                                                defaultValue=""
                                                                                oninput="this.className = ''"
                                                                                className="form-control"
                                                                                placeholder="Enter Item Name"
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-4 col-md-6 col-sm-6">
                                                                        <div className="form-group log_in_main">
                                                                            <label>Category</label>
                                                                            <div className="main_select">
                                                                                <select name="#" id="#">
                                                                                    <option value="#">Select Category</option>
                                                                                    <option value="#">Man</option>
                                                                                    <option value="#">Women</option>
                                                                                </select>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-4 col-md-6 col-sm-6">
                                                                        <div className="form-group log_in_main">
                                                                            <label>Sub Category</label>
                                                                            <div className="main_select">
                                                                                <select name="#" id="#">
                                                                                    <option value="#">Select Sub Category</option>
                                                                                </select>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-4 col-md-6 col-sm-6">
                                                                        <div className="form-group log_in_main">
                                                                            <label>Child Category</label>
                                                                            <div className="main_select">
                                                                                <select name="#" id="#">
                                                                                    <option value="#">
                                                                                        Select Child Category
                                                                                    </option>
                                                                                    <option value="#">Empower</option>
                                                                                    <option value="#">Pursue</option>
                                                                                </select>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-4 col-md-6 col-sm-6">
                                                                        <div className="form-group log_in_main">
                                                                            <label>Model Number</label>
                                                                            <input
                                                                                type="text"
                                                                                defaultValue=""
                                                                                oninput="this.className = ''"
                                                                                className="form-control"
                                                                                placeholder="Enter Model Number"
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-4 col-md-6 col-sm-6">
                                                                        <div className="form-group log_in_main">
                                                                            <label>Brand Name</label>
                                                                            <div className="main_select">
                                                                                <select name="#" id="#">
                                                                                    <option value="#">Select Brand Name</option>
                                                                                    <option value="#">Innovate</option>
                                                                                    <option value="#">Empower</option>
                                                                                    <option value="#">Pursue</option>
                                                                                </select>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="product_identity mb-4">
                                                        <h4>Product Description</h4>
                                                        <div className="detail_product_ident">
                                                            <div className="main_detail_identity pt-0">
                                                                <div className="please_fill_main">
                                                                    <h6>
                                                                        Please Fill the below details to complete Product
                                                                        Description:
                                                                    </h6>
                                                                </div>
                                                                <div className="row mt-3">
                                                                    <div className="col-lg-12">
                                                                        <div className="form-group log_in_main input_box_section">
                                                                            <label>Description</label>
                                                                            <div id="summernote" />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-12">
                                                                        <div className="form-group log_in_main input_box_section">
                                                                            <label>Addifianal Informetion</label>
                                                                            <div id="summernote_next" />
                                                                        </div>
                                                                    </div>
                                                                    <div className="add_detail_listing">
                                                                        <div className="row">
                                                                            <div className="col-lg-12 col-md-12">
                                                                                <div className="form-group log_in_main input_box_section">
                                                                                    <label>Feature</label>
                                                                                </div>
                                                                                <div className="card-add-product">
                                                                                    <div className="card-body">
                                                                                        <div className="table-responsive">
                                                                                            <table
                                                                                                id="faqs"
                                                                                                className="table table-hover main_detail_section"
                                                                                            >
                                                                                                <thead>
                                                                                                    <tr>
                                                                                                        <th>Key</th>
                                                                                                        <th>Value</th>
                                                                                                        <th>Status</th>
                                                                                                    </tr>
                                                                                                </thead>
                                                                                                <tbody>
                                                                                                    <tr>
                                                                                                        <td>
                                                                                                            <input
                                                                                                                type="text"
                                                                                                                className="form-control"
                                                                                                                placeholder="User name"
                                                                                                            />
                                                                                                        </td>
                                                                                                        <td>
                                                                                                            <input
                                                                                                                type="text"
                                                                                                                placeholder="Product name"
                                                                                                                className="form-control"
                                                                                                            />
                                                                                                        </td>
                                                                                                        <td className="mt-10">
                                                                                                            <button
                                                                                                                onclick="addfaqs();"
                                                                                                                className="badge_add badge-success"
                                                                                                            >
                                                                                                                <i className="fa fa-plus" /> ADD
                                                                                                                NEW
                                                                                                            </button>
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                    <tr id="faqs-rowundefined">
                                                                                                        <td>
                                                                                                            <input
                                                                                                                type="text"
                                                                                                                className="form-control"
                                                                                                                placeholder="User name"
                                                                                                            />
                                                                                                        </td>
                                                                                                        <td>
                                                                                                            <input
                                                                                                                type="text"
                                                                                                                placeholder="Product name"
                                                                                                                className="form-control"
                                                                                                            />
                                                                                                        </td>
                                                                                                        <td className="mt-10">
                                                                                                            <Link
                                                                                                                className="badge badge-danger"
                                                                                                                onclick="$('#faqs-rowundefined').remove();"
                                                                                                            >
                                                                                                                <i className="fa fa-trash" />{" "}
                                                                                                                Delete
                                                                                                            </Link>
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                </tbody>
                                                                                            </table>
                                                                                        </div>
                                                                                        <div className="text-center" />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="product_identity mb-4">
                                                        <h4>Product Details</h4>
                                                        <div className="detail_product_ident">
                                                            <div className="main_detail_identity pt-0">
                                                                <div className="please_fill_main">
                                                                    <h6>
                                                                        Please Fill the below details to complete Product
                                                                        Details
                                                                    </h6>
                                                                </div>
                                                                <div className="row mt-3">
                                                                    <div className="col-lg-3">
                                                                        <div className="form-group log_in_main">
                                                                            <label>Model Name</label>
                                                                            <input
                                                                                type="text"
                                                                                defaultValue=""
                                                                                className="form-control"
                                                                                placeholder="Enter Model Name"
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-3">
                                                                        <div className="form-group log_in_main">
                                                                            <label>Exchange Days</label>
                                                                            <input
                                                                                type="text"
                                                                                defaultValue=""
                                                                                className="form-control"
                                                                                placeholder="Exchange Days"
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-3">
                                                                        <div className="form-group log_in_main">
                                                                            <label>Retun Days</label>
                                                                            <input
                                                                                type="text"
                                                                                defaultValue=""
                                                                                className="form-control"
                                                                                placeholder="Retun Days"
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-3">
                                                                        <div className="form-group log_in_main">
                                                                            <label>Delivery Charge</label>
                                                                            <input
                                                                                type="text"
                                                                                defaultValue=""
                                                                                className="form-control"
                                                                                placeholder="Delivery Charge"
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-12">
                                                                        <div className="form-group log_in_main">
                                                                            <label>Manufacturing Informetion</label>
                                                                            <div id="manufacturing_next" />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-6">
                                                                        <div className="form-group log_in_main">
                                                                            <label>Size Chart Image</label>
                                                                            <div className="file-input">
                                                                                <input
                                                                                    type="file"
                                                                                    name="userfile"
                                                                                    id="userfile"
                                                                                />
                                                                                <button className="folder_main_img label_1">
                                                                                    <span>
                                                                                        <img src={`${process.env.PUBLIC_URL}/assets/images/seller_images/Upload.png`} alt="file" />
                                                                                    </span>{" "}
                                                                                    Upload Image
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="product_identity mb-4">
                                                        <h4>Variations</h4>
                                                        <div className="detail_product_ident">
                                                            <div className="variations">
                                                                <div className="tab-container pt-3">
                                                                    <div className="row pt-2 pb-2">
                                                                        <div className="col-lg-12">
                                                                            <div id="tab-1" className="tab-content_Products">
                                                                                <div className="row">
                                                                                    <div className="col-md-12">
                                                                                        <div className="table_detail_tariff table-responsive  border bg-light">
                                                                                            <table className="posts-table geeks-new_detail">
                                                                                                <thead>
                                                                                                    <tr className="users-table-tariff text-cen">
                                                                                                        <th>
                                                                                                            <div className="round_Products">
                                                                                                                <input
                                                                                                                    type="checkbox"
                                                                                                                    id="checkbox_main"
                                                                                                                />
                                                                                                                <label htmlFor="checkbox_main" />
                                                                                                            </div>
                                                                                                        </th>
                                                                                                        <th>Color</th>
                                                                                                        <th>Size</th>
                                                                                                        <th>Product Image</th>
                                                                                                        <th>Quantity</th>
                                                                                                        <th>Orignal Price</th>
                                                                                                        <th>Discount %</th>
                                                                                                        <th>Selling Price</th>
                                                                                                    </tr>
                                                                                                </thead>
                                                                                                <tbody className="text-cen">
                                                                                                    <tr className="main_table_row_new gfg">
                                                                                                        <td>
                                                                                                            <div className="round_Products">
                                                                                                                <input
                                                                                                                    type="checkbox"
                                                                                                                    id="checkbox-1"
                                                                                                                />
                                                                                                                <label htmlFor="checkbox-1" />
                                                                                                            </div>
                                                                                                        </td>
                                                                                                        <td>Red</td>
                                                                                                        <td>Xs</td>
                                                                                                        <td>
                                                                                                            <Link
                                                                                                                type="button"
                                                                                                                className="product_cemra"
                                                                                                                data-bs-toggle="modal"
                                                                                                                data-bs-target="#staticBackdrop"
                                                                                                            >
                                                                                                                <img
                                                                                                                    src={`${process.env.PUBLIC_URL}/assets/images/seller_images/cemra.png`}
                                                                                                                    alt="product"
                                                                                                                />
                                                                                                                {/* <input type="file" > */}
                                                                                                                Add Images
                                                                                                            </Link>
                                                                                                        </td>
                                                                                                        <td>
                                                                                                            <Link
                                                                                                                to="javascript:void(0)"
                                                                                                                className="input_price_main "
                                                                                                            >
                                                                                                                <input
                                                                                                                    type="text"
                                                                                                                    defaultValue=""
                                                                                                                />
                                                                                                            </Link>
                                                                                                        </td>
                                                                                                        <td width="10%">
                                                                                                            <Link
                                                                                                                to="javascript:void(0)"
                                                                                                                className="main_input_price"
                                                                                                            >
                                                                                                                <span>AFN$</span>
                                                                                                                <input
                                                                                                                    type="text"
                                                                                                                    defaultValue=""
                                                                                                                />
                                                                                                            </Link>
                                                                                                        </td>
                                                                                                        <td>
                                                                                                            <Link
                                                                                                                to="javascript:void(0)"
                                                                                                                className="input_price_main"
                                                                                                            >
                                                                                                                <input
                                                                                                                    type="text"
                                                                                                                    defaultValue=""
                                                                                                                    className="w_100"
                                                                                                                />
                                                                                                            </Link>
                                                                                                        </td>
                                                                                                        <td>
                                                                                                            <Link
                                                                                                                to="javascript:void(0)"
                                                                                                                className="main_input_price"
                                                                                                            >
                                                                                                                <span>AFN$</span>
                                                                                                                <input
                                                                                                                    type="text"
                                                                                                                    defaultValue=""
                                                                                                                    className="w_100"
                                                                                                                />
                                                                                                            </Link>
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                </tbody>
                                                                                            </table>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-footer-button">
                                                    {/* <Link to="/seller/products/add/details" className="btn Back-button"> */}
                                                    <Link to={-1} className="btn Back-button me-1">
                                                        Back
                                                    </Link>
                                                    <Link
                                                        type="button"
                                                        data-bs-toggle="modal"
                                                        data-bs-target="#send_approval"
                                                        className="btn send-approval-butt ms-1"
                                                    >
                                                        Save
                                                    </Link>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            className="modal fade"
                            id="staticBackdrop"
                            data-bs-backdrop="static"
                            data-bs-keyboard="false"
                            tabIndex={-1}
                            aria-labelledby="staticBackdropLabel"
                            aria-hidden="true"
                        >
                            <div className="modal-dialog dasbord-main_model">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title" id="staticBackdropLabel">
                                            Edit images
                                        </h5>
                                        <button
                                            type="button"
                                            className="btn-close"
                                            data-bs-dismiss="modal"
                                            aria-label="Close"
                                        />
                                    </div>
                                    <div className="modal-body">
                                        <div className="main_detail_head">
                                            <h6>Product images style guideline</h6>
                                            <p>
                                                Uploaded: 0 of 9 images. Maximum 9 images are allowed. you can
                                                arrange the order after uploading.
                                            </p>
                                        </div>
                                        <div className="upload-box-main">
                                            <div className="upload-box-inner">
                                                <span>
                                                    <span className="file-name" />
                                                    <input type="file" className="file-element" />
                                                    <img
                                                        className="main-img-icon"
                                                        src={`${process.env.PUBLIC_URL}/assets/images/seller_images/cemra.png`}
                                                        alt=""
                                                    />
                                                    <h3>Update</h3>
                                                </span>
                                                <div className="main_hading_popup">
                                                    <h3>MAIN</h3>
                                                </div>
                                            </div>
                                            <div className="upload-box-inner">
                                                <span>
                                                    <span className="file-name" />
                                                    <input type="file" className="file-element" />
                                                    <img
                                                        className="main-img-icon"
                                                        src={`${process.env.PUBLIC_URL}/assets/images/seller_images/cemra.png`}
                                                        alt=""
                                                    />
                                                    <h3>Update</h3>
                                                </span>
                                            </div>
                                            <div className="upload-box-inner">
                                                <span>
                                                    <span className="file-name" />
                                                    <input type="file" className="file-element" />
                                                    <img
                                                        className="main-img-icon"
                                                        src={`${process.env.PUBLIC_URL}/assets/images/seller_images/cemra.png`}
                                                        alt=""
                                                    />
                                                    <h3>Update</h3>
                                                </span>
                                            </div>
                                            <div className="upload-box-inner">
                                                <span>
                                                    <span className="file-name" />
                                                    <input type="file" className="file-element" />
                                                    <img
                                                        className="main-img-icon"
                                                        src={`${process.env.PUBLIC_URL}/assets/images/seller_images/cemra.png`}
                                                        alt=""
                                                    />
                                                    <h3>Update</h3>
                                                </span>
                                            </div>
                                            <div className="upload-box-inner">
                                                <span>
                                                    <span className="file-name" />
                                                    <input type="file" className="file-element" />
                                                    <img
                                                        className="main-img-icon"
                                                        src={`${process.env.PUBLIC_URL}/assets/images/seller_images/cemra.png`}
                                                        alt=""
                                                    />
                                                    <h3>Update</h3>
                                                </span>
                                            </div>
                                            <div className="upload-box-inner">
                                                <span>
                                                    <span className="file-name" />
                                                    <input type="file" className="file-element" />
                                                    <img
                                                        className="main-img-icon"
                                                        src={`${process.env.PUBLIC_URL}/assets/images/seller_images/cemra.png`}
                                                        alt=""
                                                    />
                                                    <h3>Update</h3>
                                                </span>
                                            </div>
                                            <div className="upload-box-inner">
                                                <span>
                                                    <span className="file-name" />
                                                    <input type="file" className="file-element" />
                                                    <img
                                                        className="main-img-icon"
                                                        src={`${process.env.PUBLIC_URL}/assets/images/seller_images/cemra.png`}
                                                        alt=""
                                                    />
                                                    <h3>Update</h3>
                                                </span>
                                            </div>
                                            <div className="upload-box-inner">
                                                <span>
                                                    <span className="file-name" />
                                                    <input type="file" className="file-element" />
                                                    <img
                                                        className="main-img-icon"
                                                        src={`${process.env.PUBLIC_URL}/assets/images/seller_images/cemra.png`}
                                                        alt=""
                                                    />
                                                    <h3>Update</h3>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="modal-footer">
                                        <button
                                            type="button"
                                            className="btn btn-secondary"
                                            data-bs-dismiss="modal"
                                        >
                                            Close
                                        </button>
                                        <button type="button" className="btn btn-primary">
                                            Add to Listing
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            className="modal fade"
                            id="send_approval"
                            data-bs-backdrop="static"
                            data-bs-keyboard="false"
                            tabIndex={-1}
                            aria-labelledby="staticBackdropLabel"
                            aria-hidden="true"
                        >
                            <div className="modal-dialog">
                                <div className="modal-content">
                                    <div className="modal-body">
                                        <div className="request_send_for_modul">
                                            <h6>Add Product Request Send for Approval</h6>
                                            <p>
                                                Congratulations! We have sent the Add Product Request to the
                                                Afghan Bazar Admin, Admin Will Connect you soon
                                            </p>
                                            <button
                                                type="button"
                                                className="btn send-approval-butt"
                                                data-bs-dismiss="modal"
                                            >
                                                Okay
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>
                </div>

            </div>
        </>
    )
}

export default ProductReviewEdit