import React, { useEffect } from 'react'
// import './scss/BuyerCustomerRatingReviews.css'
import { Link } from 'react-router-dom'
import { getCustomerRatingDetails, getCustomerReviewList } from '../../../Redux/action/MasterAction';
import { useDispatch, useSelector } from 'react-redux';
import { createImageFromInitials } from '../../../Utils';
const BuyerCustomerRatingReviews = ({ variantId, productId }) => {

    const dispatch = useDispatch();
    useEffect(() => {
        if (productId != undefined) {
            dispatch(getCustomerRatingDetails(productId));
            dispatch(getCustomerReviewList(productId));
        }
    }, [productId]);
    const {
        //CustomerRatingDetails
        loadingCustomerRatingDetailsListApi,
        CustomerRatingDetailsListApiData,
        CustomerRatingDetailsListApiError,

        //CustomerReviewList
        loadingCustomerReviewListApi,
        CustomerReviewListApiData,
        CustomerReviewListApiError,

    } = useSelector(state => state?.MasterReducer);


    const totalStars = 5
    return (
        <>
            <div className="customer_reviews">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-xl-4 col-lg-4 col-md-5 col-sm-5 col-12">
                            <div className="customer_reviews_left">
                                <h2>Customer Rating</h2>
                                <ul>
                                    {Array.from({ length: Math.floor(CustomerRatingDetailsListApiData?.overall_rating) }).map((_, index) => (
                                        <i className="fa fa-star rated" />
                                    ))}

                                    {/* Render unfilled stars */}
                                    {Array.from({ length: totalStars - Math.floor(CustomerRatingDetailsListApiData?.overall_rating) }).map((_, index) => (
                                        <i className="fa fa-star" />
                                    ))}
                                    <li>{CustomerRatingDetailsListApiData?.overall_rating ? Math.floor(CustomerRatingDetailsListApiData?.overall_rating) : "0"} out of 5</li>
                                </ul>
                                <p>{CustomerRatingDetailsListApiData?.total_ratings ? CustomerRatingDetailsListApiData?.total_ratings : '0'} global ratings</p>
                                <div className="progress_bar">
                                    <table>
                                        <tbody>
                                            <tr>
                                                <td>5 Star</td>
                                                <td>
                                                    <div className="progress">
                                                        <div
                                                            className="progress-bar bg-warning"
                                                            role="progressbar"
                                                            style={{ width: `${CustomerRatingDetailsListApiData?.five_star_ratings ? (CustomerRatingDetailsListApiData?.five_star_ratings / CustomerRatingDetailsListApiData?.total_ratings) * 100 : '0'}%` }}
                                                            aria-valuenow={75}
                                                            aria-valuemin={0}
                                                            aria-valuemax={100}
                                                        />
                                                    </div>
                                                </td>
                                                <td>{CustomerRatingDetailsListApiData?.five_star_ratings ? (CustomerRatingDetailsListApiData?.five_star_ratings / CustomerRatingDetailsListApiData?.total_ratings) * 100 : '0'}%</td>
                                            </tr>
                                            <tr>
                                                <td>4 Star</td>
                                                <td>
                                                    <div className="progress">
                                                        <div
                                                            className="progress-bar bg-warning"
                                                            role="progressbar"
                                                            style={{ width: `${CustomerRatingDetailsListApiData?.four_star_ratings ? (CustomerRatingDetailsListApiData?.four_star_ratings / CustomerRatingDetailsListApiData?.total_ratings) * 100 : '0'}%` }}
                                                            aria-valuenow={75}
                                                            aria-valuemin={0}
                                                            aria-valuemax={100}
                                                        />
                                                    </div>
                                                </td>
                                                <td>{CustomerRatingDetailsListApiData?.four_star_ratings ? (CustomerRatingDetailsListApiData?.four_star_ratings / CustomerRatingDetailsListApiData?.total_ratings) * 100 : '0'}%</td>
                                            </tr>
                                            <tr>
                                                <td>3 Star</td>
                                                <td>
                                                    <div className="progress">
                                                        <div
                                                            className="progress-bar bg-warning"
                                                            role="progressbar"
                                                            style={{ width: `${CustomerRatingDetailsListApiData?.three_star_ratings ? (CustomerRatingDetailsListApiData?.three_star_ratings / CustomerRatingDetailsListApiData?.total_ratings) * 100 : '0'}%` }}
                                                            aria-valuenow={75}
                                                            aria-valuemin={0}
                                                            aria-valuemax={100}
                                                        />
                                                    </div>
                                                </td>
                                                <td>{CustomerRatingDetailsListApiData?.three_star_ratings ? (CustomerRatingDetailsListApiData?.three_star_ratings / CustomerRatingDetailsListApiData?.total_ratings) * 100 : '0'}%</td>
                                            </tr>
                                            <tr>
                                                <td>2 Star</td>
                                                <td>
                                                    <div className="progress">
                                                        <div
                                                            className="progress-bar bg-warning"
                                                            role="progressbar"
                                                            style={{ width: `${CustomerRatingDetailsListApiData?.two_star_ratings ? (CustomerRatingDetailsListApiData?.two_star_ratings / CustomerRatingDetailsListApiData?.total_ratings) * 100 : '0'}%` }}
                                                            aria-valuenow={75}
                                                            aria-valuemin={0}
                                                            aria-valuemax={100}
                                                        />
                                                    </div>
                                                </td>
                                                <td>{CustomerRatingDetailsListApiData?.two_star_ratings ? (CustomerRatingDetailsListApiData?.two_star_ratings / CustomerRatingDetailsListApiData?.total_ratings) * 100 : '0'}%</td>
                                            </tr>
                                            <tr>
                                                <td>1 Star</td>
                                                <td>
                                                    <div className="progress">
                                                        <div
                                                            className="progress-bar bg-warning"
                                                            role="progressbar"
                                                            style={{ width: `${CustomerRatingDetailsListApiData?.one_star_ratings ? (CustomerRatingDetailsListApiData?.one_star_ratings / CustomerRatingDetailsListApiData?.total_ratings) * 100 : '0'}%` }}
                                                            aria-valuenow={75}
                                                            aria-valuemin={0}
                                                            aria-valuemax={100}
                                                        />
                                                    </div>
                                                </td>
                                                <td>{CustomerRatingDetailsListApiData?.one_star_ratings ? (CustomerRatingDetailsListApiData?.one_star_ratings / CustomerRatingDetailsListApiData?.total_ratings) * 100 : '0'}%</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <h2 className="mt-2 mb-2">Review this product</h2>
                                <p>Share your thoughts with other customers</p>
                                <div className="reviews_btn">
                                    <Link to={`/buyer/write-review/${productId}${variantId ? `?variantId=${variantId}` : ''}`}>Write a product review</Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-8 col-lg-8 col-md-7 col-sm-7 col-12">
                            <div className="customer_says">
                                <h2>Customers Reviews</h2>
                                {CustomerReviewListApiData?.length > 0 && CustomerReviewListApiData?.slice(0, 2)?.map((data) => (
                                    <div className="customer_reviews_user">
                                        <div className="customer_reviews_main">
                                            <div className="customer_reviews_user_left">
                                                <img
                                                    src={createImageFromInitials(500, `${data?.buyerDetails?.name}`)}
                                                    className="img-fluid"
                                                    style={{ width: "15%" }}
                                                    alt='user'
                                                />
                                            </div>
                                            <div className="customer_reviews_user_right">
                                                <ul>
                                                    {Array.from({ length: Math.floor(data?.rating) }).map((_, index) => (
                                                        <i className="fa fa-star rated" />
                                                    ))}

                                                    {/* Render unfilled stars */}
                                                    {Array.from({ length: totalStars - Math.floor(data?.rating) }).map((_, index) => (
                                                        <i className="fa fa-star" />
                                                    ))}

                                                </ul>
                                            </div>
                                        </div>
                                        <h3>{data?.buyerDetails?.name}</h3>
                                        <p>
                                            {data?.review}
                                        </p>

                                    </div>
                                ))}

                                {CustomerReviewListApiData?.length > 2 &&
                                    <h6>
                                        <Link to={`/buyer/write-review/${productId}${variantId ? `?variantId=${variantId}` : ''}`} className='view-more'>
                                            View More <i className="fa-solid fa-arrow-right" />
                                        </Link>
                                    </h6>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>

    )
}

export default BuyerCustomerRatingReviews