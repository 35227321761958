import React from 'react'
import BuyerContactUsContent from './BuyerContactUsContent'
import BuyerHeader from '../../BuyerHeader/BuyerHeader'
import BuyerFooter from '../../BuyerFooter/BuyerFooter'
import BuyerHeaderWithoutLogin from '../../BuyerHeader/BuyerHeaderWithoutLogin'

const BuyerContactUs = () => {
    const authToken = localStorage.getItem("buyer_token");
    return (
        <>
            {authToken ?

                <BuyerHeader />
                :
                <BuyerHeaderWithoutLogin />
            }
            <BuyerContactUsContent />
            <BuyerFooter />
        </>
    )
}

export default BuyerContactUs