import React from 'react'
import SellerSidebar from '../SellerEssentials/SellerSidebar'
import SellerHeader from '../SellerEssentials/SellerHeader'
import { Link } from 'react-router-dom'
const SellerProfileBankDetails = () => {
    return (
        <div className='seller'>
            <div className="page-wrapper chiller-theme toggled">
                <SellerSidebar />
                {/* sidebar-wrapper  */}
                <main className="page-content container-fluid">
                    {/* ==================header================== */}
                    <SellerHeader />
                    {/* ==================header================== */}
                    <div className="row">
                        <div className="col-md-12">
                            <div className="main-nav-Order">
                                <div className="main_detail_head">
                                    <h2>
                                        {" "}
                                        <Link to="/seller/profile">
                                            <img src="/assets/images/seller_images/left_side.png" alt="left" /> Bank Details{" "}
                                        </Link>
                                    </h2>
                                </div>
                                <div className="detail_heading">
                                    <h4>Bank Account Information</h4>
                                    <p>
                                        For a successful bank verification account name must match with
                                        the registered GSTIN number.{" "}
                                    </p>
                                </div>
                                <div className="card_order_main">
                                    <div className="row pb-100 main_border_padd">
                                        <div className="col-lg-4">
                                            <div className="form-group log_in_main">
                                                <label>Full Name</label>
                                                <input
                                                    type="text"
                                                    defaultValue=""
                                                    placeholder="Enter Here"
                                                    className="form-control"
                                                />
                                            </div>
                                        </div>
                                        <div className="col-lg-4">
                                            <div className="form-group log_in_main">
                                                <label>Account Number</label>
                                                <input
                                                    type="text"
                                                    defaultValue=""
                                                    placeholder="Enter Here"
                                                    className="form-control"
                                                />
                                            </div>
                                        </div>
                                        <div className="col-lg-4">
                                            <div className="form-group log_in_main">
                                                <label>Confirm Account Number</label>
                                                <input
                                                    type="text"
                                                    defaultValue=""
                                                    placeholder="Enter Here"
                                                    className="form-control"
                                                />
                                            </div>
                                        </div>
                                        <div className="col-lg-4">
                                            <div className="form-group log_in_main">
                                                <label>Branch Name</label>
                                                <input
                                                    type="text"
                                                    defaultValue=""
                                                    placeholder="Enter Here"
                                                    className="form-control"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-12 mt-3">
                                        <div className="text-right main_left_space">
                                            <Link to="/seller/#" className="btn btn-primary next-step">
                                                Submit
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
            <div
                className="modal fade"
                id="send_approval"
                data-bs-backdrop="static"
                data-bs-keyboard="false"
                tabIndex={-1}
                aria-labelledby="staticBackdropLabel"
                style={{ display: "none" }}
                aria-hidden="true"
            >
                <div className="modal-dialog">
                    <div className="modal-content main-in-popup-space">
                        <div className="btn-close-into">
                            <button
                                type="button"
                                className="btn-close "
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            />
                        </div>
                        <div className="modal-body">
                            <div className="request_send_for_modul">
                                <h6>Edit Details</h6>
                            </div>
                            <div className="main_edit_form">
                                <form action="#">
                                    <div className="input_detail_section">
                                        <h6>Display Name</h6>
                                        <input
                                            type="text"
                                            defaultValue=""
                                            placeholder="Enter Name"
                                            className="form-control"
                                        />
                                    </div>
                                    <div className="input_detail_section">
                                        <h6>Mobile Number</h6>
                                        <input
                                            type="number"
                                            defaultValue=""
                                            placeholder="+91 0000000000"
                                            className="form-control"
                                        />
                                    </div>
                                    <div className="input_detail_section">
                                        <h6>Email Address</h6>
                                        <input
                                            type="text"
                                            defaultValue=""
                                            placeholder="Enter Email Address"
                                            className="form-control"
                                        />
                                    </div>
                                    <div className="text-right">
                                        <button type="button" className="btn send-approval-butt">
                                            Save
                                        </button>{" "}
                                        <button
                                            type="button"
                                            className="btn btn-secondary"
                                            data-bs-dismiss="modal"
                                        >
                                            Cancel
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* ==============script linking start=============== */}
            {/* ==============script linking end=============== */}
        </div>

    )
}

export default SellerProfileBankDetails