import { React, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useFormik } from "formik";
import { toast } from 'react-hot-toast';
import * as Yup from "yup";
import axios from 'axios';

import {
    LoginSocialGoogle, LoginSocialApple, IResolveParams,
} from 'reactjs-social-login'

import {
    GoogleLoginButton, AppleLoginButton,
} from 'react-social-login-buttons'
import '../scss/Auth.css';
const host = process.env.REACT_APP_API_SERVER
const client_id = process.env.REACT_APP_GG_APP_CLIENT_ID;
const REDIRECT_URI = 'https://afgan.alobhadev.com/buyer/create-account'
const SignUp = () => {
    const guestToken = localStorage.getItem('guest_token');
    const [faIcon, setfaIcon] = useState('fa-eye-slash');
    const togglePasswordType = () => {
        let input_pass = document.getElementById('password-field');
        const toggle_pass = document.getElementById("toggle_pass");
        let input_type = input_pass.getAttribute('type');
        if (input_type == "password") {
            setfaIcon('fa-eye');
            input_pass.type = "text";
        } else {
            setfaIcon('fa-eye-slash')
            input_pass.type = "password"
        }
    }
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const guestUser = queryParams.get('guest');


    const [provider, setProvider] = useState('')
    const [profile, setProfile] = useState(null)

    const navigate = useNavigate();
    let [initialValues, setInitialValues] = useState({

        emailMob: "",
        password: "",

    })

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: Yup.object().shape({

            emailMob: Yup.string().required("Email is required.").matches(/^\w+[-\.\w]*@(?!(?:)\.com$)\w+[-\.\w]*?\.\w{2,4}$/, "Enter a valid E-mail"),

            password: Yup.string().required("Password is required"),

        }),
        onSubmit: async (values, { setSubmitting, resetForm }) => {

            try {

                setSubmitting(true);

                await axios.post(`${host}/buyer/buyer-sign-in-direct`, values)
                    .then(function (response) {
                        if (response.status == 200) {
                            setSubmitting(false)

                            toast.success("Buyer Signed In Succesfully");
                            resetForm();


                            localStorage.setItem("buyer_token", response.data.authToken);
                            if (guestToken) {
                                updateBuyerIdFromGuestId();
                                localStorage.removeItem("guest_token");
                                if (guestUser && guestUser == 'true') {
                                    navigate('/buyer/cart')
                                }
                                else {
                                    navigate('/buyer')
                                }
                            }
                            else {
                                navigate('/buyer')
                            }

                        } else {

                            setSubmitting(false)

                            toast.error(response.data.message);

                        }

                    })
                    .catch(function (error) {


                        setSubmitting(false)
                        toast.error('Error submitting form. Please try again.');

                    })

            }
            catch (error) {


                setSubmitting(false)
                toast.error('Error submitting form. Please try again.');

            }

        }
    })


    if (profile?.email_verified) {


        try {
            const formdata = {
                firstName: profile?.given_name,
                lastName: profile?.family_name,
                email: profile?.email,
            }
            axios.post(`${host}/buyer/buyer-google-login-web`, formdata).
                then((response) => {
                    if (response.status == 200) {

                        localStorage.setItem("buyer_token", response.data.authToken);
                        toast(response?.data?.message);
                        if (guestToken) {
                            updateBuyerIdFromGuestId();
                            localStorage.removeItem("guest_token");
                            if (guestUser && guestUser == 'true') {
                                navigate('/buyer/cart')
                            }
                            else {
                                navigate('/buyer')
                            }
                        }
                        else {
                            navigate('/buyer')
                        }

                    }
                    else {

                        toast(response?.data?.message);
                    }
                })
        }
        catch (error) {

            toast("Error loggin In");

        }
    }

    const updateBuyerIdFromGuestId = () => {
        try {
            const config = {
                headers: {

                    'content-type': 'application/json',
                    "auth-token": localStorage.getItem("buyer_token")
                }
            };
            axios.post(`${host}/buyer/update-guest-token-to-buyer-token`, { guestToken }, config).
                then((response) => {
                    if (response.status == 200) {
                        // toast(response?.data?.message);
                    }
                    else {

                        toast(response?.data?.message);
                    }
                })
        }
        catch (error) {

            toast("Error loggin In");

        }
    }

    const handleCreateAccount = () => {
        if (guestUser && guestUser == 'true') {
            navigate('/buyer/create-account?guest=true')
        }
        else {
            navigate('/buyer/create-account')
        }
    }

    const handleMobileNavigate = () => {
        if (guestUser && guestUser == 'true') {
            navigate('/buyer/sign-in-with-otp?guest=true')
        }
        else {
            navigate('/buyer/sign-in-with-otp')
        }
    }

    const handleForgetPassNavigate = () => {
        if (guestUser && guestUser == 'true') {
            navigate('/buyer/forget-password?guest=true')
        }
        else {
            navigate('/buyer/forget-password')
        }
    }

    return (
        <div className='auth'>
            <section className="login">
                <div className="container-fluid">
                    <div className="row welcome-login-main align-items-center">
                        <div className="col-md-6 px-5 form-section h-100">
                            <div className="fields-section">
                                <div className="welcome-back-sec text-center pt-3 pb-3">
                                    <img src="/assets/images/afgan-bazaar-logo.png" alt="logo" />
                                    {/* <h3>Afghan Bazaar</h3> */}
                                    <h1 className='pt-2 pb-2'>Sign in</h1>
                                    <p>Welcome back to Afghan Bazaar-Your favorites are waiting</p>
                                </div>
                                <form method='post' onSubmit={formik.handleSubmit}>
                                    <div className="pass-email-field">
                                        <label htmlFor="email">Email<sup className='text-danger'>*</sup></label>
                                        <input
                                            type="text"
                                            placeholder="Email"
                                            className="form-control"
                                            name='emailMob'
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.emailMob}
                                        />
                                        <div className="help-block with-errors">
                                            {formik.touched.emailMob && formik.errors.emailMob ? (
                                                <div className='text-danger'>{formik.errors.emailMob}</div>
                                            ) : null}
                                        </div>
                                        <label htmlFor="password">Password<sup className='text-danger'>*</sup></label>
                                        <input
                                            id="password-field"
                                            type="password"
                                            className="form-control"
                                            name="password"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.password}
                                        />
                                        <i
                                            toggle="#password-field" className={`fa-regular ${faIcon} field-icon toggle-password `} id="toggle_pass" onClick={togglePasswordType}
                                        />
                                        <div className="help-block with-errors">
                                            {formik.touched.password && formik.errors.password ? (
                                                <div className='text-danger'>{formik.errors.password}</div>
                                            ) : null}
                                        </div>
                                        <div className="forget-pass pt-3 d-flex justify-content-between">
                                            <p>
                                                <a onClick={handleMobileNavigate} style={{ cursor: 'pointer' }}> Login with Phone Number</a>
                                            </p>
                                            <h6>
                                                <a onClick={handleForgetPassNavigate} style={{ cursor: 'pointer' }}>Forgot password?</a>
                                            </h6>
                                        </div>
                                    </div>

                                    <div className="submit-btn text-center">

                                        <button type='submit' disabled={formik.isSubmitting}>Continue</button>

                                    </div>
                                </form>
                                <div className="create_your_account">
                                    <p>
                                        New to Afghan Bazaar?
                                        <a onClick={handleCreateAccount} style={{ cursor: 'pointer' }}> Create your account</a>
                                    </p>
                                </div>
                                <div className="single-line pt-3">
                                    <div className="or-sec">
                                        <hr />
                                        <p>or</p>
                                    </div>
                                </div>
                                <div className="submit-btn-google">
                                    {/* <a href="#">
                                        <button>
                                            <div className="d-flex ">
                                                <div>
                                                    <img
                                                        src="/assets/images/Google.png"
                                                        alt="google"
                                                        className="img-fluid"
                                                    />
                                                </div>{" "}
                                                <span>Sign up with Google</span>
                                            </div>
                                        </button>
                                    </a> */}
                                    <LoginSocialGoogle
                                        client_id={client_id}
                                        scope="openid profile email"
                                        className='signin-goog-login-cls'
                                        onResolve={({ provider, data }) => {
                                            setProvider(provider)
                                            setProfile(data)


                                        }}
                                        onReject={(err) => {

                                        }}
                                    >
                                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                                            <GoogleLoginButton style={{ display: 'flex', justifyContent: 'center' }} />
                                        </div>
                                        {/* <GoogleLoginButton style={{ width: '46px', borderRadius: '50px' }} text='' /> */}
                                    </LoginSocialGoogle>
                                </div>
                                <div className="submit-btn-apple">
                                    {/* <a href="#">
                                        <button>
                                            <div className="d-flex">
                                                <div>
                                                    <img
                                                        src="/assets/images/Apple.png"
                                                        alt="google"
                                                        className="img-fluid"
                                                    />
                                                </div>
                                                <span>Sign up with Apple</span>
                                            </div>
                                        </button>
                                    </a> */}
                                    {/* <LoginSocialApple
                                        client_id={process.env.REACT_APP_APPLE_ID || ''}
                                        scope={'name email'}
                                        redirect_uri={REDIRECT_URI}

                                        onResolve={({ provider, data }) => {

                                            setProfile(data);
                                        }}
                                        onReject={err => {
                                            console.log(err);
                                        }}
                                    >
                                        <AppleLoginButton />
                                    </LoginSocialApple> */}
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 p-0  h-100">
                            <div className="login-banner-sec">
                                <img
                                    src="/assets/images/sign-in-img.png"
                                    alt="banner"
                                    className="img-fluid"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </div>
    )
}

export default SignUp