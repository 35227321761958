import { React, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { useFormik } from "formik";
import { toast } from 'react-hot-toast';
import * as Yup from "yup";
import axios from 'axios';
import './scss/BuyerProfileChangePasswordContent.css'

const host = process.env.REACT_APP_API_SERVER

const BuyerProfileChangePasswordContent = () => {

    const [faIcon, setfaIcon] = useState('fa-eye-slash');
    const togglePasswordType = () => {
        let input_pass = document.getElementById('password-field');
        const toggle_pass = document.getElementById("toggle_pass");
        let input_type = input_pass.getAttribute('type');
        if (input_type == "password") {
            setfaIcon('fa-eye');
            input_pass.type = "text";
        } else {
            setfaIcon('fa-eye-slash')
            input_pass.type = "password"
        }
    }

    const [faIcon1, setfaIcon1] = useState('fa-eye-slash');
    const togglePasswordType1 = () => {
        let input_pass = document.getElementById('password-field-1');
        const toggle_pass = document.getElementById("toggle_pass_1");
        let input_type = input_pass.getAttribute('type');
        if (input_type == "password") {
            setfaIcon1('fa-eye');
            input_pass.type = "text";
        } else {
            setfaIcon1('fa-eye-slash')
            input_pass.type = "password"
        }
    }

    const [faIcon2, setfaIcon2] = useState('fa-eye-slash');
    const togglePasswordType2 = () => {
        let input_pass = document.getElementById('password-field-2');
        const toggle_pass = document.getElementById("toggle_pass_2");
        let input_type = input_pass.getAttribute('type');
        if (input_type == "password") {
            setfaIcon2('fa-eye');
            input_pass.type = "text";
        } else {
            setfaIcon2('fa-eye-slash')
            input_pass.type = "password"
        }
    }


    const navigate = useNavigate();
    const config = {
        headers: {

            'content-type': 'application/json',
            "auth-token": localStorage.getItem("buyer_token")
        }
    };

    const formik = useFormik({
        initialValues: {
            currentPassword: "",
            newPassword: "",
            cpassword: "",
        },
        validationSchema: Yup.object().shape({
            currentPassword: Yup.string().required("Current password is required"),
            newPassword: Yup.string().required("New password is required"),
            cpassword: Yup.string()
                .oneOf([Yup.ref('newPassword'), null], 'Passwords must match')
                .required("Confirm password is required"),
        }),
        onSubmit: async (values, { setSubmitting, resetForm }) => {
            try {
                setSubmitting(true);

                await axios.post(`${host}/buyer/buyer-change-profile-password`, values, config)
                    .then(function (response) {
                        if (response.status == 200) {
                            setSubmitting(false)
                            toast.success("Password changed successfully");
                            resetForm();
                            navigate('/buyer/profile')
                        } else {
                            setSubmitting(false)
                            toast.error(response.data.message);
                        }

                    })
                    .catch(function (error) {
                        setSubmitting(false)
                        console.log(error)
                        toast.error(error.response.data.message);
                    })
            }
            catch (error) {
                setSubmitting(false)
                toast.error('Error submitting form. Please try again.');
            }
        }
    })

    return (
        <div className='buyer-profile-change-password'>
            <>
                {/* Breadcrumb */}
                <div className="breadcrumb_section">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                <div className="breadcrumb_section_content">
                                    <ul>
                                        <li>
                                            <Link to="/buyer/your-account"> Your Account</Link>
                                        </li>
                                        <li>
                                            <i className="fa-solid fa-angle-right" />
                                        </li>
                                        <li>
                                            <Link to="/buyer/profile">Profile</Link>
                                        </li>
                                        <li>
                                            <i className="fa-solid fa-angle-right" />
                                        </li>
                                        <li>
                                            <Link to="/buyer/change-password">Secure Your Account</Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Breadcrumb */}
                {/* Profile */}
                <div className="profile_section">
                    <div className="container-fluid">

                        <div className="row">
                            <div className="col-xl-2 col-lg-2 col-md-2 col-sm-12 col-12" />
                            <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12 pt-3 pb-3">
                                <div className='card edit_profile_main'>
                                    <div className="profile_change_name text-center">
                                        {/* <p>
                                        Use the form below to change the password for your Afghanbazaar
                                        account
                                    </p> */}
                                        <div className="profile_heading border-bottom pb-2 mb-4">
                                            <h3>Secure Your Account</h3>
                                        </div>
                                        <h2>Refresh your password now!</h2>
                                        <div className="change_password_profile">
                                            <form method='post' onSubmit={formik.handleSubmit}>
                                                <div className='change_pass_rel'>
                                                    <label htmlFor="password">Current Password</label>
                                                    <input
                                                        id="password-field"
                                                        type="password"
                                                        className="form-control"
                                                        name="currentPassword"
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        value={formik.values.currentPassword} />

                                                    <i
                                                        toggle="#password-field" className={`fa-regular ${faIcon} field-icon toggle-password `} id="toggle_pass" onClick={togglePasswordType}
                                                    />
                                                    <div className="help-block with-errors">
                                                        {formik.touched.currentPassword && formik.errors.currentPassword ? (
                                                            <div className='text-danger'>{formik.errors.currentPassword}</div>
                                                        ) : null}
                                                    </div>
                                                </div>
                                                <div className='change_pass_rel'>
                                                    <label htmlFor="password">New Password</label>
                                                    <input
                                                        id="password-field-1"
                                                        type="password"
                                                        className="form-control"
                                                        name="newPassword"
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        value={formik.values.newPassword} />

                                                    <i
                                                        toggle="#password-field_1"
                                                        className={`fa-regular ${faIcon1} field-icon toggle-password `} id="toggle_pass_1" onClick={togglePasswordType1}
                                                    />
                                                    <div className="help-block with-errors">
                                                        {formik.touched.newPassword && formik.errors.newPassword ? (
                                                            <div className='text-danger'>{formik.errors.newPassword}</div>
                                                        ) : null}
                                                    </div>
                                                </div>
                                                <div className='change_pass_rel'>
                                                    <label htmlFor="password">Confirm Password</label>
                                                    <input
                                                        id="password-field-2"
                                                        type="password"
                                                        className="form-control"
                                                        name="cpassword"
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        value={formik.values.cpassword}
                                                    />
                                                    <i
                                                        toggle="#password-field_2"
                                                        className={`fa-regular ${faIcon2} field-icon toggle-password `} id="toggle_pass_2" onClick={togglePasswordType2}
                                                    />
                                                    <div className="help-block with-errors">
                                                        {formik.touched.cpassword && formik.errors.cpassword ? (
                                                            <div className='text-danger'>{formik.errors.cpassword}</div>
                                                        ) : null}
                                                    </div>
                                                </div>
                                                <h6>
                                                    <button className='submit-btn' type='submit' disabled={formik.isSubmitting}>
                                                        Save Changes
                                                    </button>
                                                </h6>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-2 col-lg-2 col-md-2 col-sm-12 col-12" />
                        </div>

                    </div>
                </div>
                {/* Profile */}
            </>




        </div>

    )
}

export default BuyerProfileChangePasswordContent