import React from 'react'
import { Link } from 'react-router-dom'
import HomeFooter from '../HomeFooter'
import HomeHeader from '../HomeHeader'
import Testimonial from '../Testimonial'
import "../Scss/Homepage.css";

const ConfidentialityPolicy = () => {
    return (
        <>
            <>
                {/* ===========header start============ */}
                <HomeHeader />
                {/* ==========header end=========== */}
                {/* ==========main start=========== */}
                <main>
                    <div className="privacy_policy_section">
                        <div className="container-fluid">
                            <div className="row align-items-center">
                                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                                    <div className="page_titile">
                                        <h2>Confidentiality Policy</h2>
                                        <p>Lorem Ipsum&nbsp;is simply dummy text of the printing and</p>
                                    </div>
                                </div>
                                <div className="col-xl-5 col-lg-5 col-md-5 col-sm-5 col-5">
                                    <div className="page_titile_img text-center">
                                        <img src={`${process.env.PUBLIC_URL}/assets/images/seller_images/privacy_policy.png`} className="img-fluid" />
                                    </div>
                                </div>
                                <div className="col-xl-1 col-lg-1 col-md-1 col-sm-1 col-1" />
                            </div>
                        </div>
                    </div>
                    <div className="policy_content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-xl-3 col-lg-3 col-md-4 col-sm-4 col-12">
                                    <div className="policy_content_inner">
                                        <h2>Legal Information</h2>
                                        <ul>
                                            <li>
                                                <Link to="/confidentiality-policy">
                                                    <img
                                                        src={`${process.env.PUBLIC_URL}/assets/images/seller_images/policy_icon-1.png`}
                                                        className="policy_img"
                                                    />{" "}
                                                    <img
                                                        src={`${process.env.PUBLIC_URL}/assets/images/seller_images/policy_icon-1_1.png`}
                                                        className="policy_img2"
                                                    />{" "}
                                                    Confidentiality Policy
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="/terms-of-use">
                                                    <img
                                                        src={`${process.env.PUBLIC_URL}/assets/images/seller_images/policy_icon-2.png`}
                                                        className="policy_img"
                                                    />
                                                    <img
                                                        src={`${process.env.PUBLIC_URL}/assets/images/seller_images/policy_icon-2_1.png`}
                                                        className="policy_img2"
                                                    />
                                                    Terms of Use
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-xl-9 col-lg-9 col-md-8 col-sm-8 col-12">
                                    <div className="policy_content_inner">
                                        <p>
                                            Lorem Ipsum is simply dummy text of the printing and typesetting
                                            industry. Lorem Ipsum has been the industry's standard dummy
                                            text ever since the 1500s, when an unknown printer took a galley
                                            of type and scrambled it to make a type specimen book. It has
                                            survived not only five centuries, but also the leap into
                                            electronic typesetting, remaining essentially unchanged. It was
                                            popularised in the 1960s with the release of Letraset sheets
                                            containing Lorem Ipsum passages, and more recently with desktop
                                            publishing software like Aldus PageMaker including versions of
                                            Lorem Ipsum.
                                        </p>
                                        <p>
                                            Lorem Ipsum is simply dummy text of the printing and typesetting
                                            industry. Lorem Ipsum has been the industry's standard dummy
                                            text ever since the 1500s, when an unknown printer took a galley
                                            of type and scrambled it to make a type specimen book. It has
                                            survived not only five centuries, but also the leap into
                                            electronic typesetting, remaining essentially unchanged. It was
                                            popularised in the 1960s with the release of Letraset sheets
                                            containing Lorem Ipsum passages, and more recently with desktop
                                            publishing software like Aldus PageMaker including versions of
                                            Lorem Ipsum.
                                        </p>
                                        <p>
                                            Lorem Ipsum is simply dummy text of the printing and typesetting
                                            industry. Lorem Ipsum has been the industry's standard dummy
                                            text ever since the 1500s, when an unknown printer took a galley
                                            of type and scrambled it to make a type specimen book. It has
                                            survived not only five centuries, but also the leap into
                                            electronic typesetting, remaining essentially unchanged. It was
                                            popularised in the 1960s with the release of Letraset sheets
                                            containing Lorem Ipsum passages, and more recently with desktop
                                            publishing software like Aldus PageMaker including versions of
                                            Lorem Ipsum.
                                        </p>
                                        <p>
                                            Lorem Ipsum is simply dummy text of the printing and typesetting
                                            industry. Lorem Ipsum has been the industry's standard dummy
                                            text ever since the 1500s, when an unknown printer took a galley
                                            of type and scrambled it to make a type specimen book. It has
                                            survived not only five centuries, but also the leap into
                                            electronic typesetting, remaining essentially unchanged. It was
                                            popularised in the 1960s with the release of Letraset sheets
                                            containing Lorem Ipsum passages, and more recently with desktop
                                            publishing software like Aldus PageMaker including versions of
                                            Lorem Ipsum.
                                        </p>
                                        <p>
                                            Lorem Ipsum is simply dummy text of the printing and typesetting
                                            industry. Lorem Ipsum has been the industry's standard dummy
                                            text ever since the 1500s, when an unknown printer took a galley
                                            of type and scrambled it to make a type specimen book. It has
                                            survived not only five centuries, but also the leap into
                                            electronic typesetting, remaining essentially unchanged. It was
                                            popularised in the 1960s with the release of Letraset sheets
                                            containing Lorem Ipsum passages, and more recently with desktop
                                            publishing software like Aldus PageMaker including versions of
                                            Lorem Ipsum.
                                        </p>
                                        <p>
                                            Lorem Ipsum is simply dummy text of the printing and typesetting
                                            industry. Lorem Ipsum has been the industry's standard dummy
                                            text ever since the 1500s, when an unknown printer took a galley
                                            of type and scrambled it to make a type specimen book. It has
                                            survived not only five centuries, but also the leap into
                                            electronic typesetting, remaining essentially unchanged. It was
                                            popularised in the 1960s with the release of Letraset sheets
                                            containing Lorem Ipsum passages, and more recently with desktop
                                            publishing software like Aldus PageMaker including versions of
                                            Lorem Ipsum.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* ===========Seller Success Stories========== */}
                    <Testimonial />
                    {/* ===========Seller Success Stories========== */}
                </main>
                {/* ==========main end=========== */}
                {/* ============footer========== */}
                <HomeFooter />
            </>
        </>
    )
}

export default ConfidentialityPolicy