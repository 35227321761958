import { React, useEffect } from 'react'
import { toast } from 'react-hot-toast';

import { Link, useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import './scss/BuyerTrackOrderContent.css'
import { getBuyerOrderDetail } from '../../Redux/action/BuyerAction';

const BuyerTrackOrderContent = () => {
    const { id } = useParams();
    const authToken = localStorage.getItem('buyer_token');
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const OrderStatus = {
        ORDER_PLACED: 1,
        SELLER_CONFIRMED: 2,
        DISPATCHED: 3,
        PICKED_UP_BY_COURIER_PARTNER: 4,
        OUT_FOR_DELIVERY: 5,
        DELIVERED: 6,
        RETURN: 7,
        EXCHANGE: 8,
        RETURN_PARCEL_RECEIVED: 9,
        EXCHANGE_PARCEL_RECEIVED: 10,
        CANCELED: 11,
        RETURN_REQUESTED: 12,
        CANCEL_REQUESTED: 13
    };

    const statusMapping = {
        [OrderStatus.ORDER_PLACED]: { text: 'Order Placed', icon: 'fa-book' },
        [OrderStatus.SELLER_CONFIRMED]: { text: 'Seller Confirmed', icon: 'fa-check' },
        [OrderStatus.DISPATCHED]: { text: 'Dispatched', icon: 'fa-box-open' },
        [OrderStatus.PICKED_UP_BY_COURIER_PARTNER]: { text: 'Picked Up by Courier', icon: 'fa-truck-loading' },
        [OrderStatus.OUT_FOR_DELIVERY]: { text: 'Out for Delivery', icon: 'fa-truck' },
        [OrderStatus.DELIVERED]: { text: 'Delivered', icon: 'fa-handshake' },
        [OrderStatus.RETURN]: { text: 'Return', icon: 'fa-undo' },
        [OrderStatus.EXCHANGE]: { text: 'Exchange', icon: 'fa-exchange-alt' },
        [OrderStatus.RETURN_PARCEL_RECEIVED]: { text: 'Return Parcel Received', icon: 'fa-archive' },
        [OrderStatus.EXCHANGE_PARCEL_RECEIVED]: { text: 'Exchange Parcel Received', icon: 'fa-archive' },
        [OrderStatus.CANCELED]: { text: 'Canceled', icon: 'fa-times' },
    };

    useEffect(() => {
        dispatch(getBuyerOrderDetail(authToken, id));
    }, [id, authToken, dispatch]);

    const {
        loadingBuyerOrderDetailListApi,
        BuyerOrderDetailListApiData,
        BuyerOrderDetailListApiError,
    } = useSelector(state => state?.BuyerReducer);

    if (loadingBuyerOrderDetailListApi) return <div>Loading...</div>;
    if (BuyerOrderDetailListApiError) return <div>Error: {BuyerOrderDetailListApiError}</div>;

    const order = BuyerOrderDetailListApiData;

    // Define the order of statuses to display
    const trackingSteps = [
        OrderStatus.ORDER_PLACED,
        OrderStatus.SELLER_CONFIRMED,
        OrderStatus.DISPATCHED,
        OrderStatus.PICKED_UP_BY_COURIER_PARTNER,
        OrderStatus.OUT_FOR_DELIVERY,
        OrderStatus.DELIVERED,
    ];

    return (
        <div className='track-order'>
            <div className="breadcrumb_section">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                            <div className="breadcrumb_section_content">
                                <ul>
                                    <li>
                                        <Link to="/buyer/your-account"> Your Account</Link>
                                    </li>
                                    <li>
                                        <i className="fa-solid fa-angle-right" />
                                    </li>
                                    <li>
                                        <Link to="/buyer/your-orders">Your Orders</Link>
                                    </li>
                                    <li>
                                        <i className="fa-solid fa-angle-right" />
                                    </li>
                                    <li>
                                        <Link to="/buyer/track-order">Track Order</Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Track Order */}
            <div className="track_order_section mb-4">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                            <div className="track_order_heading border-bottom pb-2">
                                <h2>Delivery Tracking</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-1 col-lg-1 col-md-1 col-sm-1 col-12" />
                        <div className="col-xl-10 col-lg-10 col-md-10 col-sm-10 col-12">
                            <div className="track_order_box">
                                <div className="track_order_box_left">
                                    <h5>#{order?.orderNumber}</h5>
                                    <p>
                                        {order?.quantity} Products{" "}
                                        <span>
                                            <i className="fa-solid fa-circle" />
                                        </span>{" "}
                                        Order Placed on {new Date(order?.orderPlacedDate).toDateString()}
                                    </p>
                                </div>
                                <div className="track_order_box_left">
                                    <h2>AFN.{order?.total_amount?.toFixed(2)}</h2>
                                </div>
                            </div>
                            <div className="track_order_box_inner mt-2">
                                <p>
                                    Order expected arrival <strong>{new Date(order?.expectedArrivalDate).toDateString()}</strong>
                                </p>
                            </div>
                            <div className="row justify-content-between">
                                {trackingSteps.map((status) => (
                                    <div className={`order-tracking ${order?.orderStatus >= status ? 'completed' : ''}`} key={status}>
                                        <span className={`is-complete ${order?.orderStatus >= status ? 'is-complete' : ''}`} />
                                        <p>
                                            <i className={`fa-solid ${statusMapping[status].icon}`} />
                                            <br />
                                            <span>{statusMapping[status].text}</span>
                                        </p>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="col-xl-1 col-lg-1 col-md-1 col-sm-1 col-12" />
                    </div>
                </div>
            </div>
            {/* Track Order */}
        </div>
    );
}

export default BuyerTrackOrderContent;
