import { React, useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { useFormik } from "formik";
import { toast } from 'react-hot-toast';
import * as Yup from "yup";
import axios from 'axios';
import './scss/BuyerProfileChangeAddressContent.css'
import { getBuyerProfileDetails } from '../../../Redux/action/BuyerAction';
import { useDispatch, useSelector } from 'react-redux';

const host = process.env.REACT_APP_API_SERVER
const BuyerProfileChangeAddressContent = () => {

    const authToken = localStorage.getItem('buyer_token');
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {

        if (authToken) {
            dispatch(getBuyerProfileDetails(authToken))
        }
        else {
            // toast.error('please sign in to continue')
            navigate('/buyer/sign-in')
        }

    }, [authToken]);
    const {

        loadingBuyerProfileDataListApi,
        BuyerProfileDataListApiData,
        BuyerProfileDataListApiError,

    } = useSelector(state => state?.BuyerReducer);
    const config = {
        headers: {

            'content-type': 'application/json',
            "auth-token": localStorage.getItem("buyer_token")
        }
    };

    const formik = useFormik({
        initialValues: {
            currentAdd: "",
        },
        validationSchema: Yup.object().shape({
            currentAdd: Yup.string().required("Current address is required"),
        }),
        onSubmit: async (values, { setSubmitting, resetForm }) => {
            try {
                setSubmitting(true);


                await axios.post(`${host}/buyer/buyer-change-profile-current-address`, values, config)
                    .then(function (response) {
                        if (response.status == 200) {
                            setSubmitting(false)
                            toast.success("Profile current address changed successfully");
                            resetForm();
                            navigate('/buyer/profile')
                        } else {
                            setSubmitting(false)
                            toast.error(response.data.message);
                        }

                    })
                    .catch(function (error) {
                        setSubmitting(false)
                        console.log(error)
                        toast.error(error.response.data.message);
                    })
            }
            catch (error) {
                setSubmitting(false)
                toast.error('Error submitting form. Please try again.');
            }
        }
    })
    useEffect(() => {

        if (BuyerProfileDataListApiData) {
            formik.setValues({
                currentAdd: BuyerProfileDataListApiData?.data?.currentAdd ?? "",
            })
        }
    }, [BuyerProfileDataListApiData])
    return (
        <div className='buyer-profile-change-address'>
            <>
                {/* Breadcrumb */}
                <div className="breadcrumb_section">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                <div className="breadcrumb_section_content">
                                    <ul>
                                        <li>
                                            <Link to="/buyer/your-account"> Your Account</Link>
                                        </li>
                                        <li>
                                            <i className="fa-solid fa-angle-right" />
                                        </li>
                                        <li>
                                            <Link to="/buyer/profile">Profile</Link>
                                        </li>
                                        <li>
                                            <i className="fa-solid fa-angle-right" />
                                        </li>
                                        <li>
                                            <Link to="/buyer/profile-change-name">Update Your Address</Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Breadcrumb */}
                {/* Profile */}
                <div className="profile_section">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-xl-2 col-lg-2 col-md-2 col-sm-12 col-12" />
                            <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12 pt-3 pb-3">
                                <div className='card edit_profile_main'>
                                    <div className="profile_change_name text-center">
                                        <div className="profile_heading border-bottom pb-2 mb-4">
                                            <h3>Update Your Address</h3>
                                        </div>
                                        <h2>Ensure hassle-free deliveries!</h2>
                                        <form method='post' onSubmit={formik.handleSubmit}>
                                            {/* <p>
                                            If you want to change the name associated with your Afghanbazaar
                                            customer account, you may do so below. Be sure to click
                                            the&nbsp;Save Changes&nbsp;button when you are done.
                                        </p> */}
                                            <div className='change_pass_rel'>
                                                <label>Current Address</label>
                                                <input type="text" placeholder="Enter Current Address" name='currentAdd'
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.currentAdd} />
                                                <div className="help-block with-errors">
                                                    {formik.touched.currentAdd && formik.errors.currentAdd ? (
                                                        <div className='text-danger'>{formik.errors.currentAdd}</div>
                                                    ) : null}
                                                </div>
                                            </div>
                                            <h6>
                                                {/* <Link to="/buyer/profile" className="active"> */}
                                                <button type='submit' disabled={formik.isSubmitting}>
                                                    Save Changes
                                                </button>
                                                {/* </Link> */}
                                            </h6>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-2 col-lg-2 col-md-2 col-sm-12 col-12" />
                        </div>
                    </div>
                </div>
                {/* Profile */}
            </>



        </div>

    )
}

export default BuyerProfileChangeAddressContent