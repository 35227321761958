import React, { useEffect } from 'react'
import BuyerHeader from '../BuyerHeader/BuyerHeader'
import BuyerFooter from '../BuyerFooter/BuyerFooter'
import BuyerWishlistContent from './BuyerWishlistContent'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { getBuyerWishList } from '../../Redux/action/BuyerAction'
import toast from 'react-hot-toast'

const BuyerWishlist = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const authToken = localStorage.getItem("buyer_token")
    useEffect(() => {


        if (authToken) {
            dispatch(getBuyerWishList(authToken));
        }
        else {
            // toast.error('please sign in to continue')
            navigate('/buyer/sign-in')
        }

    }, [authToken]);
    const {

        // Wishlist
        loadingWishlistDataListApi,
        WishlistDataListApiData,
        WishlistDataListApiError,

    } = useSelector(state => state?.BuyerReducer);
    const data = WishlistDataListApiData?.data;
    // useEffect(() => {
    //     if (WishlistDataListApiData?.message == 'Your account is inactivated.') {
    //         toast.error(WishlistDataListApiData?.message)
    //     }
    // }, [WishlistDataListApiData])
    return (
        <>
            <BuyerHeader />
            {WishlistDataListApiData?.message == 'Your account is inactivated.' ?
                <div className=" container-fluid my-5 py-5 main-wish-list">

                    <div className="d-flex justify-content-center pt-4 py-5 heading-sec px-4">
                        <img src="/assets/images/inactive-account.png" width={200} alt="" srcSet="" />

                    </div>

                    <h1 className='text-center text-danger'>Your account is inacitvated</h1>
                </div>

                :
                <BuyerWishlistContent WishlistDataListApiData={data} />}
            <BuyerFooter />
        </>
    )
}

export default BuyerWishlist