import React, { useEffect } from 'react'
import './scss/BuyerOrderItAgainContent.css'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import { getBuyerBuyItAgainList } from '../../Redux/action/BuyerAction';
import toast from 'react-hot-toast';

const BuyerOrderItAgainContent = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const authToken = localStorage.getItem("buyer_token")

    useEffect(() => {
        if (authToken) {
            dispatch(getBuyerBuyItAgainList(authToken, ''));

        }
        else {
            // toast.error('Please Sign In To Continue');
            navigate('/Buyer/sign-in');
        }

    }, [authToken]);


    const {
        //Buyer order list
        loadingBuyerBuyItAgainListApi,
        BuyerBuyItAgainListApiData,
        BuyerBuyItAgainListApiError,

    } = useSelector(state => state?.BuyerReducer);

    console.log(BuyerBuyItAgainListApiData)

    const handleNavigateClick = (slug, colourId, sizeId) => {


        if (colourId && sizeId) {

            navigate(`/buyer/product-details/${slug}?colorId=${colourId}&sizeId=${sizeId}`)
        }
        else if (colourId == null && sizeId) {

            navigate(`/buyer/product-details/${slug}?sizeId=${sizeId}`)
        }
        else if (colourId && sizeId == null) {

            navigate(`/buyer/product-details/${slug}?colorId=${colourId}`)
        }
        else {

            navigate(`/buyer/product-details/${slug}`)

        }
    }

    const handleSearch = (e) => {
        console.log(e.target.value);
        const searchString = e.target.value;
        if (searchString !== '' || searchString !== undefined || searchString !== null) {
            dispatch(getBuyerBuyItAgainList(authToken, searchString));
        }
        else {
            dispatch(getBuyerBuyItAgainList(authToken, ''));
        }
    }
    return (
        <div className='order-it-again'>
            <>
                {/* Breadcrumb */}
                <div className="breadcrumb_section">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                <div className="breadcrumb_section_content">
                                    <ul>
                                        <li>
                                            <Link to="/buyer/your-account"> Your Account</Link>
                                        </li>
                                        <li>
                                            <i className="fa-solid fa-angle-right" />
                                        </li>
                                        <li>
                                            <Link to="/buyer/your-orders">Your Orders</Link>
                                        </li>
                                        <li>
                                            <i className="fa-solid fa-angle-right" />
                                        </li>
                                        <li>
                                            <Link to="/buyer/buy-it-again">Buy it again</Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Breadcrumb */}
                {/* Your order */}
                <div className="order_section">
                    <div className="container-fluid">
                        <div className="row mb-4">
                            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                <div className="cancel_order_heading border-bottom buy_it_again">
                                    <div className="cancel_order_heading_left">
                                        <h2>Buy it again</h2>
                                        <p>
                                            Price and other details may vary based on product size and
                                            colour.
                                        </p>
                                    </div>
                                    <div className="cancel_order_heading_left">
                                        <div className="cancel_order_search">
                                            <input
                                                className="search-txt"
                                                type="text"
                                                name=""
                                                placeholder="Search"
                                                onChange={handleSearch}
                                            />
                                            <i className="fa-solid fa-magnifying-glass" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {BuyerBuyItAgainListApiData?.length > 0 ? <div className="row">
                            {BuyerBuyItAgainListApiData?.map((data) => (
                                <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12 mb-5">
                                    <div className="buy_again_box" onClick={() => { handleNavigateClick(data?.slug, data?.colourId, data?.sizeId) }}>
                                        <Link to="/buyer/product-details">
                                            <h4>{data?.name}</h4>
                                            <img src="/assets/images/buy_again-1.png" />
                                            <p>
                                                {data?.description}
                                            </p>
                                        </Link>
                                        <p>
                                            <del>AFN.{data?.originalPrice}</del> <span>AFN.{data?.sellingPrice}</span>
                                        </p>
                                        <ul>
                                            <li>
                                                <span>3.9</span>
                                            </li>
                                            <li>
                                                <i className="fa fa-star" />
                                            </li>
                                            <li>
                                                <i className="fa fa-star" />
                                            </li>
                                            <li>
                                                <i className="fa fa-star" />
                                            </li>
                                            <li>
                                                <i className="fa-solid fa-star-half-stroke" />
                                            </li>
                                            <li>
                                                <i className="fa-solid fa-star-half-stroke" />
                                            </li>
                                            <li>(738)</li>
                                        </ul>
                                        {/* <h5>
                                            <Link to="" className="see_more_btn">
                                                See more
                                            </Link>
                                        </h5> */}
                                    </div>
                                </div>
                            ))}

                        </div>
                            :
                            <div className=" container-fluid my-5 py-5 main-wish-list">
                                <Link className='text-decoration-none' to='/buyer/home'>
                                    <div className="d-flex justify-content-center pt-4 py-5 heading-sec px-4">
                                        <img src="/assets/images/purchase-protection.png" alt="" srcSet="" />


                                    </div>
                                </Link>
                                <h1 className='text-center'><Link className='text-decoration-none' to='/buyer/home'>No Products Shop Now</Link></h1>
                            </div>
                        }
                    </div>
                </div>
                {/* Your order */}
            </>


        </div>

    )
}

export default BuyerOrderItAgainContent