import React, { useEffect } from 'react'
import { v4 as uuidv4 } from 'uuid';
import BuyerHeader from '../BuyerHeader/BuyerHeader'
import BuyerFooter from '../BuyerFooter/BuyerFooter'
import BuyerCartContent from './BuyerCartContent'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import toast from 'react-hot-toast'
import { getBuyerCartProductList, getBuyerWishList } from '../../Redux/action/BuyerAction'
import BuyerHeaderWithoutLogin from '../BuyerHeader/BuyerHeaderWithoutLogin'

const BuyerCart = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const authToken = localStorage.getItem("buyer_token");
    const guestToken = localStorage.getItem("guest_token");

    useEffect(() => {
        if (authToken) {
            dispatch(getBuyerCartProductList('auth-token', authToken));
            dispatch(getBuyerWishList(authToken));
        }

        else {
            if (guestToken) {
                dispatch(getBuyerCartProductList('guest-token', guestToken));
            }
            else {
                const guestId = uuidv4();
                localStorage.setItem('guest_token', guestId);
                dispatch(getBuyerCartProductList('guest-token', guestToken));
                // toast.error('Please Sign In To Continue from cart');
                // navigate('/Buyer/sign-in');
            }
        }

    }, [authToken]);


    const {
        loadingBuyerCartProductListApi,
        BuyerCartProductListApiData,
        BuyerCartProductListApiError,

        loadingWishlistDataListApi,
        WishlistDataListApiData,
        WishlistDataListApiError,

    } = useSelector(state => state?.BuyerReducer);



    return (
        <>
            {authToken ?

                <BuyerHeader />
                :
                <BuyerHeaderWithoutLogin />
            }
            {BuyerCartProductListApiData?.message == 'Your account is inactivated.' ?
                <div className=" container-fluid my-5 py-5 main-wish-list">

                    <div className="d-flex justify-content-center pt-4 py-5 heading-sec px-4">
                        <img src="/assets/images/inactive-account.png" width={200} alt="" srcSet="" />

                    </div>

                    <h1 className='text-center text-danger'>Your account is inacitvated</h1>
                </div>

                :
                <BuyerCartContent BuyerCartProductListApiData={BuyerCartProductListApiData?.data} WishlistDataListApiData={WishlistDataListApiData?.data} />}
            <BuyerFooter />
        </>
    )
}

export default BuyerCart