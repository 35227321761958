import { React, useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { useFormik } from "formik";
import { toast } from 'react-hot-toast';
import * as Yup from "yup";
import axios from 'axios';
import './scss/BuyerProfileChangeMobileContent.css'
import { PhoneInput } from 'react-international-phone';
import { getBuyerProfileDetails } from '../../../Redux/action/BuyerAction';
import { useDispatch, useSelector } from 'react-redux';
import 'react-international-phone/style.css';
const host = process.env.REACT_APP_API_SERVER
const BuyerProfileChangeMobileContent = () => {

    const [cphone, setPhone] = useState('');
    // Remove non-digit characters
    const cleanPhoneNumber = cphone.replace(/\D/g, '');

    // Check if there are digits after the fourth digit
    const hasDigitsAfterFourth = cleanPhoneNumber.length > 8;

    const authToken = localStorage.getItem('buyer_token');
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {

        if (authToken) {
            dispatch(getBuyerProfileDetails(authToken))
        }
        else {
            // toast.error('please sign in to continue')
            navigate('/buyer/sign-in')
        }

    }, [authToken]);
    const {

        loadingBuyerProfileDataListApi,
        BuyerProfileDataListApiData,
        BuyerProfileDataListApiError,

    } = useSelector(state => state?.BuyerReducer);
    const config = {
        headers: {

            'content-type': 'application/json',
            "auth-token": localStorage.getItem("buyer_token")
        }
    };

    const formik = useFormik({
        initialValues: {
            phone: ""
        },
        validationSchema: Yup.object().shape({
            phone: Yup.string().required("Mobile Number is required").matches(/^[0-9]{8,15}$/, "Please enter a valid Mobile Number."),
        }),
        onSubmit: async (values, { setSubmitting, resetForm }) => {
            try {
                setSubmitting(true);


                await axios.post(`${host}/buyer/buyer-change-profile-phone`, values, config)
                    .then(function (response) {
                        if (response.status == 200) {

                            toast.success("Profile phone number changed successfully");
                            resetForm()
                            navigate('/buyer/profile')
                        } else {

                            toast.error(response.data.message);
                        }

                    })
                    .catch(function (error) {

                        console.log(error)
                        toast.error(error.response.data.message);
                    })
            }
            catch (error) {
                setSubmitting(false)
                toast.error('Error submitting form. Please try again.');
            }
        }
    })

    const handleClick = async () => {
        try {

            const value = {
                'phone': cphone
            }


            await axios.post(`${host}/buyer/buyer-change-profile-phone`, value, config)
                .then(function (response) {
                    if (response.status == 200) {

                        toast.success("Profile phone number changed successfully");
                        setPhone('');
                        navigate('/buyer/profile')
                    } else {

                        toast.error(response.data.message);
                    }

                })
                .catch(function (error) {

                    console.log(error)
                    toast.error(error.response.data.message);
                })
        }
        catch (error) {

            toast.error('Error submitting form. Please try again.');
        }
    }

    useEffect(() => {
        if (BuyerProfileDataListApiData) {
            formik.setValues({
                phone: BuyerProfileDataListApiData?.data?.phone ?? "",
            })
        }
    }, [BuyerProfileDataListApiData])
    return (
        <div className='buyer-profile-change-mobile'>
            <>
                {/* Breadcrumb */}
                <div className="breadcrumb_section">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                <div className="breadcrumb_section_content">
                                    <ul>
                                        <li>
                                            <Link to="/buyer/your-account"> Your Account</Link>
                                        </li>
                                        <li>
                                            <i className="fa-solid fa-angle-right" />
                                        </li>
                                        <li>
                                            <Link to="/buyer/profile">Profile</Link>
                                        </li>
                                        <li>
                                            <i className="fa-solid fa-angle-right" />
                                        </li>
                                        <li>
                                            <Link to="/buyer/profile-change-name">Change Your Number</Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Breadcrumb */}
                <>
                    <div className="profile_section">
                        <div className="container-fluid">

                            <div className="row">
                                <div className="col-xl-2 col-lg-2 col-md-2 col-sm-12 col-12" />
                                <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12 pt-3 pb-3">
                                    <div className='card edit_profile_main'>
                                        <div className="profile_change_name text-center">
                                            <div className="profile_heading border-bottom pb-2 mb-4">
                                                <h3>Change Your Number</h3>
                                            </div>
                                            <h2>Stay reachable for all updates!</h2>
                                            {/* <p>
                                            If you want to change the Mobile Number associated with your
                                            Afghanbazaar customer account, you may do so below. Be sure to
                                            click the&nbsp;Save Changes&nbsp;button when you are done.
                                        </p> */}

                                            <form method='post' onSubmit={formik.handleSubmit} className="change-primary-number">
                                                <div className="form-group">
                                                    <div className='change_pass_rel'>
                                                        <label>Mobile Number</label>
                                                        <input type="text" placeholder="Enter Mobile Number" name='phone'
                                                            onChange={formik.handleChange}
                                                            onBlur={formik.handleBlur}
                                                            value={formik.values.phone} />
                                                        <div className="help-block with-errors">
                                                            {formik.touched.phone && formik.errors.phone ? (
                                                                <div className='text-danger'>{formik.errors.phone}</div>
                                                            ) : null}
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* <div className="form-group">
                                                <PhoneInput
                                                    className='border-0'
                                                    placeholder='Phone no.'
                                                    required
                                                    defaultCountry="in"
                                                    value={cphone}
                                                    onChange={(cphone) => setPhone(cphone)}
                                                />
                                                <div className="ms-2 help-block with-errors">
                                                    {!hasDigitsAfterFourth && (
                                                        <div className="text-danger text-start">Phone no. is required</div>
                                                    )}
                                                </div>
                                            </div> */}

                                                <h6>
                                                    <button className='submit-btn' type='submit' disabled={formik.isSubmitting} >
                                                        Save Changes
                                                    </button>
                                                </h6>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-2 col-lg-2 col-md-2 col-sm-12 col-12" />
                            </div>
                        </div>
                    </div>
                    {/* Profile */}
                </>

            </>



        </div>

    )
}

export default BuyerProfileChangeMobileContent