import React from 'react'
import './scss/BuyerProfileContent.css'
import { Link } from 'react-router-dom'

const BuyerProfileContent = ({ BuyerProfileDataListApiData }) => {
    return (
        <div className='buyer-profile'>
            <>
                {/* Breadcrumb */}
                <div className="breadcrumb_section">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                <div className="breadcrumb_section_content">
                                    <ul>
                                        <li>
                                            <Link to="/buyer/your-account"> Your Account</Link>
                                        </li>
                                        <li>
                                            <i className="fa-solid fa-angle-right" />
                                        </li>
                                        <li>
                                            <Link to="/buyer/profile">Profile</Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Breadcrumb */}
                {/* Profile */}
                <div className="profile_section">
                    <div className="container-fluid">

                        <div className="row">
                            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                <div className='row'>
                                    <div className='col-xl-1 col-lg-1 col-md-1 col-sm-12 col-12'></div>
                                    <div className='col-xl-10 col-lg-10 col-md-10 col-sm-12 col-12'>
                                        <div className="profile_man_box">
                                            <div className="profile_heading border-bottom pb-2 mb-4">
                                                <h2>Profile</h2>
                                            </div>
                                            <div className='profile_inner_new'>
                                                <div className="profile_inner_box_section">
                                                    <div className="profile_inner_box">
                                                        <h6>
                                                            <i className="fa-solid fa-user" />
                                                            Name
                                                            <br />
                                                            <span>{BuyerProfileDataListApiData?.name}</span>
                                                        </h6>
                                                    </div>
                                                    <div className="profile_inner_box">
                                                        <Link to="/buyer/profile-change-name">Edit</Link>
                                                    </div>
                                                </div>
                                                <div className="profile_inner_box_section">
                                                    <div className="profile_inner_box">
                                                        <h6>
                                                            <i className="fa-solid fa-envelope" />
                                                            Email
                                                            <br />
                                                            <span>{BuyerProfileDataListApiData?.email}</span>
                                                        </h6>
                                                    </div>
                                                    <div className="profile_inner_box">
                                                        <Link to="/buyer/profile-change-email" className="">
                                                            Edit
                                                        </Link>
                                                    </div>
                                                </div>
                                                <div className="profile_inner_box_section">
                                                    <div className="profile_inner_box">
                                                        <h6>
                                                            <i className="fa-solid fa-phone" />
                                                            Primary mobile number
                                                            <br />
                                                            <span>{BuyerProfileDataListApiData?.phone ? BuyerProfileDataListApiData?.phone : 'Set phone number'}</span>
                                                        </h6>
                                                    </div>
                                                    <div className="profile_inner_box">
                                                        <Link to="/buyer/change-primary-number" className="">
                                                            Edit
                                                        </Link>
                                                    </div>
                                                </div>
                                                <div className="profile_inner_box_section">
                                                    <div className="profile_inner_box">
                                                        <h6>
                                                            <i className="fa fa-map-signs" />
                                                            Current Address
                                                            <br />
                                                            <span>{BuyerProfileDataListApiData?.currentAdd ? BuyerProfileDataListApiData?.currentAdd : 'Set Current Address'}</span>
                                                        </h6>
                                                    </div>
                                                    <div className="profile_inner_box">
                                                        <Link to="/buyer/change-address" className="">
                                                            Edit
                                                        </Link>
                                                    </div>
                                                </div>
                                                <div className="profile_inner_box_section">
                                                    <div className="profile_inner_box">
                                                        <h6>
                                                            <i className="fa-solid fa-lock" />
                                                            Password
                                                            <br />
                                                            {BuyerProfileDataListApiData?.password === 'Yes' ? <span>**************</span> : "Set password"}
                                                        </h6>
                                                    </div>
                                                    <div className="profile_inner_box">
                                                        <Link to="/buyer/change-password" className="">
                                                            Edit
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Profile */}
            </>



        </div>

    )
}

export default BuyerProfileContent