import React from 'react'
import './scss/BuyerCompletedOrdersContent.css'
import { Link, useNavigate } from 'react-router-dom'
import { jsPDF } from 'jspdf';
let host = process.env.REACT_APP_API_SERVER;

const BuyerCompletedOrdersContent = ({ CompletedOrdersData }) => {
    const generateInvoice = (invoice_Data) => {
        const doc = new jsPDF();
        const invoiceData = JSON.parse(invoice_Data)
        doc.text(`Customer Name: ${invoiceData.CustomerName}`, 10, 10);
        doc.text(`Order ID: ${invoiceData.order_item_id}`, 10, 20);
        doc.text(`Order Date: ${invoiceData.orderDate}`, 10, 30);
        doc.text(`Order Time: ${invoiceData.orderTime}`, 10, 40);
        doc.text(`Address: ${invoiceData.address}`, 10, 50);
        doc.text(`Total Discount: AFN ${invoiceData.total_discount}`, 10, 60);
        doc.text(`Total Amount: AFN ${invoiceData.total_amount}`, 10, 70);
        doc.text(`Payable Amount: AFN ${invoiceData.payable_amount}`, 10, 80);
        doc.text(`Shipping Cost: ${invoiceData.shipping_cost}`, 10, 90);
        doc.text(`Total Tax: AFN ${invoiceData.total_tax}`, 10, 100);

        doc.save('invoice.pdf');
    };
    return (
        <div className='buyer-completed-orders'>
            <>

                {/* Your order */}

                <div className="order_section">

                    {CompletedOrdersData?.map((data) => (
                        <div className="row">
                            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                <div className="order_section_inner mb-5">

                                    <div className="track_order_section_1">
                                        <div className="track_order_section_right">
                                            <Link to="buyer/order-details" className=' text-center'>
                                                Order Placed <br /> <span className='heading-sm'>{data?.orderPlacedDate ? new Date(data.orderPlacedDate).toDateString() : 'No date available'}</span>
                                            </Link>
                                            <Link to={`/buyer/order-details/${data?.orderItemNumber}`} className='text-center'>
                                                Total <br /> <span className='heading-sm'>AFN.{data?.orderItemTotalPrice}</span>
                                            </Link>
                                            <Link to={`/buyer/order-details/${data?.orderItemNumber}`} className='text-center'>
                                                Shipping To <br /> <span className='heading-sm'>{data?.buyerName}</span>
                                            </Link>
                                        </div>
                                        <div className="track_order_section_left">
                                            <Link to={`/buyer/order-details/${data?.orderItemNumber}`}>
                                                Order Number : <span>{data?.orderItemNumber}</span>
                                            </Link>
                                            <p><span><Link to={`/buyer/order-details/${data?.orderItemNumber}`}>View order details</Link></span>|<span><a onClick={() => generateInvoice(data?.invoice_data)} style={{ cursor: 'pointer' }}>Invoice</a></span></p>
                                        </div>
                                    </div>
                                    <div className='inner_sec_heading review_section_main'>
                                        <div className="review_section_left">
                                            {/* <h5><strong>Order Item Number : {data?.orderItemNumber}</strong></h5> */}
                                        </div>
                                        <div className="review_section_btn">
                                            <Link className="cancel_order my-2" to={`/buyer/write-review/${data?.productId}${data?.productVarientId ? `?variantId=${data?.productVarientId}` : ''}`}><strong>Write a product review</strong></Link>
                                        </div>
                                    </div>

                                    <div className="track_order_details">

                                        <div className="track_order_details_main">

                                            <img src={data?.image ? `${host}${data?.image}` : ''} className="img-fluid" />
                                        </div>
                                        <div className="track_order_details_main">
                                            <p>
                                                {data?.name}
                                            </p>
                                            <p className='main_date'>Return window closed on {data?.returnDate ? new Date(data.returnDate).toDateString() : 'No date available'}</p>
                                            {/* <p className='main_date'>Exchange window closed on {data?.exchangeDate ? new Date(data.exchangeDate).toDateString() : 'No date available'}</p> */}
                                            <div className='main_buttons'>
                                                <Link to={`/buyer/return-order/${data?.orderItemNumber}`}> <button type="button" className='button-white'>Return Order</button></Link>
                                                <Link to="/buyer/buy-it-again"><button type="button" className='button-white'>Buy Again</button></Link>
                                            </div>
                                        </div>
                                        <div className="track_order_details_main text-center">
                                            <p>Quantity</p>
                                            <h6>{data?.quantity}</h6>
                                        </div>
                                        <div className="track_order_details_main text-center">
                                            <p>
                                                Status
                                            </p>
                                            <h6>Completed</h6>
                                        </div>


                                    </div>

                                </div>

                            </div>
                        </div>

                    ))}
                </div>

                {/* Your order */}
            </>



        </div>

    )
}

export default BuyerCompletedOrdersContent