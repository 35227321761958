import React from 'react'
import { Link } from 'react-router-dom'
import SellerHeader from '../../SellerEssentials/SellerHeader'
import SellerSidebar from '../../SellerEssentials/SellerSidebar'

import '../../Scss/Seller.css'
const SellerReturnExchangeList = () => {
    return (
        <div className='seller'>
            <div className="page-wrapper chiller-theme toggled">
                <SellerSidebar />
                {/* sidebar-wrapper  */}
                <main className="page-content container-fluid">
                    {/* ==================header================== */}
                    <SellerHeader />
                    {/* ==================header================== */}
                    <div className="row">
                        <div className="col-md-12">
                            <div className="main-nav-Order">
                                <div className="row pt-3">
                                    <div className="col-md-6 col-sm-6">
                                        <h2> Return/Exchange</h2>
                                    </div>
                                    <div className="col-md-6 col-sm-6">
                                        <div className="search-container">
                                            <input type="text" placeholder="Search..." />
                                            <span>
                                                <i className="fas fa-search" />
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="card_order_main">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <nav>
                                                <div
                                                    className="nav nav-tabs mb-3 all_order_main"
                                                    id="nav-tab"
                                                    role="tablist"
                                                >
                                                    <button
                                                        className="nav-link active"
                                                        id="nav-all-orders-tab"
                                                        data-bs-toggle="tab"
                                                        data-bs-target="#nav-all-orders"
                                                        type="button"
                                                        role="tab"
                                                        aria-controls="nav-all-orders"
                                                        aria-selected="true"
                                                    >
                                                        In Progress
                                                    </button>
                                                    <button
                                                        className="nav-link"
                                                        id="nav-New-Order"
                                                        data-bs-toggle="tab"
                                                        data-bs-target="#nav-New-Orders"
                                                        type="button"
                                                        role="tab"
                                                        aria-controls="nav-New-Order"
                                                        aria-selected="false"
                                                    >
                                                        Completed
                                                    </button>
                                                </div>
                                            </nav>
                                        </div>
                                        {/* <div className="col-lg-3">
                    <div className="filter_button_section">
                      <Link to=""><span><img src="/assets/images/seller_images/Filter.png" alt="filter"></span>Filter</a>
                    </div>
                  </div> */}
                                    </div>
                                    <div className="tab-content" id="nav-tabContent">
                                        <div
                                            className="tab-pane fade active show"
                                            id="nav-all-orders"
                                            role="tabpanel"
                                            aria-labelledby="nav-all-orders-tab"
                                        >
                                            <div className="table_mar_retun table-responsive  border bg-light">
                                                <table className="posts-table geeks">
                                                    <thead>
                                                        <tr className="users-table-tariff text-cen">
                                                            <th>Image</th>
                                                            <th>Date</th>
                                                            <th>Details</th>
                                                            <th>Quantity</th>
                                                            <th>Price</th>
                                                            <th>Order Status</th>
                                                            <th>Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody className="text-cen">
                                                        <tr className="main_table_row_new gfg">
                                                            <td>
                                                                <div className="product_images_sec">
                                                                    <img src="/assets/images/seller_images/images_1.png" alt="product" />
                                                                </div>
                                                            </td>
                                                            <td>06-06-2024</td>
                                                            <td>Book</td>
                                                            <td>1</td>
                                                            <td>AFN 123</td>
                                                            <td>
                                                                <Link to="" className="main_detail_butt">
                                                                    Shipped
                                                                </Link>
                                                            </td>
                                                            <td>
                                                                <div className="main_detail_order">
                                                                    <Link
                                                                        to="/seller/exchange_return_order_accept"
                                                                        className="return_order"
                                                                    >
                                                                        Return Order
                                                                    </Link>
                                                                    <Link
                                                                        to="/seller/exchange_return_order_detail"
                                                                        className="view_details"
                                                                    >
                                                                        View Details
                                                                    </Link>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <tr className="main_table_row_new gfg">
                                                            <td>
                                                                <div className="product_images_sec">
                                                                    <img src="/assets/images/seller_images/images_1.png" alt="product" />
                                                                </div>
                                                            </td>
                                                            <td>06-06-2024</td>
                                                            <td>Book</td>
                                                            <td>1</td>
                                                            <td>AFN 123</td>
                                                            <td>
                                                                <Link to="" className="main_detail_butt">
                                                                    Shipped
                                                                </Link>
                                                            </td>
                                                            <td>
                                                                <div className="main_detail_order">
                                                                    <Link
                                                                        to="/seller/exchange_return_order_accept"
                                                                        className="return_order"
                                                                    >
                                                                        Return Order
                                                                    </Link>
                                                                    <Link
                                                                        to="/seller/exchange_return_order_detail"
                                                                        className="view_details"
                                                                    >
                                                                        View Details
                                                                    </Link>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <tr className="main_table_row_new gfg">
                                                            <td>
                                                                <div className="product_images_sec">
                                                                    <img src="/assets/images/seller_images/images_1.png" alt="product" />
                                                                </div>
                                                            </td>
                                                            <td>06-06-2024</td>
                                                            <td>Book</td>
                                                            <td>1</td>
                                                            <td>AFN 123</td>
                                                            <td>
                                                                <Link to="" className="main_detail_butt">
                                                                    Shipped
                                                                </Link>
                                                            </td>
                                                            <td>
                                                                <div className="main_detail_order">
                                                                    <Link
                                                                        to="/seller/exchange_return_order_accept"
                                                                        className="return_order"
                                                                    >
                                                                        Return Order
                                                                    </Link>
                                                                    <Link
                                                                        to="/seller/exchange_return_order_detail"
                                                                        className="view_details"
                                                                    >
                                                                        View Details
                                                                    </Link>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <tr className="main_table_row_new gfg">
                                                            <td>
                                                                <div className="product_images_sec">
                                                                    <img src="/assets/images/seller_images/images_1.png" alt="product" />
                                                                </div>
                                                            </td>
                                                            <td>06-06-2024</td>
                                                            <td>Book</td>
                                                            <td>1</td>
                                                            <td>AFN 123</td>
                                                            <td>
                                                                <Link to="" className="main_detail_butt">
                                                                    Shipped
                                                                </Link>
                                                            </td>
                                                            <td>
                                                                <div className="main_detail_order">
                                                                    <Link
                                                                        to="/seller/exchange_return_order_accept"
                                                                        className="return_order"
                                                                    >
                                                                        Return Order
                                                                    </Link>
                                                                    <Link
                                                                        to="/seller/exchange_return_order_detail"
                                                                        className="view_details"
                                                                    >
                                                                        View Details
                                                                    </Link>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <div
                                            className="tab-pane fade"
                                            id="nav-New-Orders"
                                            role="tabpanel"
                                            aria-labelledby="nav-Order-tab"
                                        >
                                            <div className="table_mar_retun table-responsive  border bg-light">
                                                <table className="posts-table geeks">
                                                    <thead>
                                                        <tr className="users-table-tariff text-cen">
                                                            <th>Image</th>
                                                            <th>Date</th>
                                                            <th>Details</th>
                                                            <th>Quantity</th>
                                                            <th>Price</th>
                                                            <th>Order Status</th>
                                                            <th>Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody className="text-cen">
                                                        <tr className="main_table_row_new gfg">
                                                            <td>
                                                                <div className="product_images_sec">
                                                                    <img src="/assets/images/seller_images/images_1.png" alt="product" />
                                                                </div>
                                                            </td>
                                                            <td>06-06-2024</td>
                                                            <td>Book</td>
                                                            <td>1</td>
                                                            <td>AFN 123</td>
                                                            <td>
                                                                <Link to="" className="main_detail_butt">
                                                                    Shipped
                                                                </Link>
                                                            </td>
                                                            <td>
                                                                <div className="main_detail_order">
                                                                    <Link
                                                                        to="/seller/exchange_return_order_detail"
                                                                        className="view_details"
                                                                    >
                                                                        View Details
                                                                    </Link>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <tr className="main_table_row_new gfg">
                                                            <td>
                                                                <div className="product_images_sec">
                                                                    <img src="/assets/images/seller_images/images_1.png" alt="product" />
                                                                </div>
                                                            </td>
                                                            <td>06-06-2024</td>
                                                            <td>Book</td>
                                                            <td>1</td>
                                                            <td>AFN 123</td>
                                                            <td>
                                                                <Link to="" className="main_detail_butt">
                                                                    Shipped
                                                                </Link>
                                                            </td>
                                                            <td>
                                                                <div className="main_detail_order">
                                                                    <Link
                                                                        to="/seller/exchange_return_order_detail"
                                                                        className="view_details"
                                                                    >
                                                                        View Details
                                                                    </Link>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <tr className="main_table_row_new gfg">
                                                            <td>
                                                                <div className="product_images_sec">
                                                                    <img src="/assets/images/seller_images/images_1.png" alt="product" />
                                                                </div>
                                                            </td>
                                                            <td>06-06-2024</td>
                                                            <td>Book</td>
                                                            <td>1</td>
                                                            <td>AFN 123</td>
                                                            <td>
                                                                <Link to="" className="main_detail_butt">
                                                                    Shipped
                                                                </Link>
                                                            </td>
                                                            <td>
                                                                <div className="main_detail_order">
                                                                    <Link
                                                                        to="/seller/exchange_return_order_detail"
                                                                        className="view_details"
                                                                    >
                                                                        View Details
                                                                    </Link>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <tr className="main_table_row_new gfg">
                                                            <td>
                                                                <div className="product_images_sec">
                                                                    <img src="/assets/images/seller_images/images_1.png" alt="product" />
                                                                </div>
                                                            </td>
                                                            <td>06-06-2024</td>
                                                            <td>Book</td>
                                                            <td>1</td>
                                                            <td>AFN 123</td>
                                                            <td>
                                                                <Link to="" className="main_detail_butt">
                                                                    Shipped
                                                                </Link>
                                                            </td>
                                                            <td>
                                                                <div className="main_detail_order">
                                                                    <Link
                                                                        to="/seller/exchange_return_order_detail"
                                                                        className="view_details"
                                                                    >
                                                                        View Details
                                                                    </Link>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>

            {/* ==============script linking start=============== */}
            {/* ==============script linking end=============== */}
        </div>

    )
}

export default SellerReturnExchangeList