import React from 'react'
import './scss/BuyerLeaveSellerFeedbackContent.css'
import { Link } from 'react-router-dom'

const BuyerLeaveSellerFeedbackContent = () => {
    return (
        <div className='buyer-leave-seller-feedback'>
            <>
                {/* Breadcrumb */}
                <div className="breadcrumb_section">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                <div className="breadcrumb_section_content">
                                    <ul>
                                        <li>
                                            <Link to="/buyer/your-account"> Your Account</Link>
                                        </li>
                                        <li>
                                            <i className="fa-solid fa-angle-right" />
                                        </li>
                                        <li>
                                            <Link to="/buyer/leave-seller-feedback">Leave Seller Feedback</Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Breadcrumb */}
                {/* Your order */}
                <div className="order_section">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                <div className="track_order_heading border-bottom pb-2 mb-4">
                                    <h2>Rate your experince</h2>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                <div className="order_section_inner mb-4">
                                    <div className="track_order_section_1">
                                        <div className="track_order_section_left">
                                            <a href="">
                                                Order Number : <span>#124-5660-9008</span>
                                            </a>
                                        </div>
                                    </div>
                                    <div className="cancel_order_section seller_feedback">
                                        <div className="track_order_details_main">
                                            <img
                                                src="/assets/images/shoping_cart_1.png"
                                                className="img-fluid"
                                            />
                                        </div>
                                        <div className="track_order_details_main">
                                            <p>
                                                Divine Trends Brass Antique Finish Table Lamp Sleek Bedroom
                                            </p>
                                            <h6>Order placed on 4 June 2024</h6>
                                        </div>
                                        <div className="track_order_details_main">
                                            <p>Shipping To</p>
                                            <h6>Ethen</h6>
                                        </div>
                                        <div className="track_order_details_main">
                                            <p>Payment</p>
                                            <h6>AFN.00</h6>
                                        </div>
                                        <div className="track_order_details_main">
                                            <p>Status</p>
                                            <h6 className="status_color">Delivered</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Your order */}
                {/* Seller Feedback */}
                <div className="seller_feedback_main">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                                <div className="seller_feedback_inner">
                                    <h2>Delivery Feedback</h2>
                                    <p>
                                        Thanks for ordering with us. Please tell us about your experience
                                    </p>
                                    <textarea
                                        placeholder="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,"
                                        defaultValue={""}
                                    />
                                    <ul>
                                        <li>4.4</li>
                                        <li>
                                            <i className="fa fa-star" />
                                        </li>
                                        <li>
                                            <i className="fa fa-star" />
                                        </li>
                                        <li>
                                            <i className="fa fa-star" />
                                        </li>
                                        <li>
                                            <i className="fa fa-star" />
                                        </li>
                                        <li>
                                            <i className="fa-regular fa-star" />
                                        </li>
                                    </ul>
                                    <h4>Was your parcel delivered in good condition?&nbsp;*</h4>
                                    <div className="form-check">
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            defaultValue=""
                                            id="feedback_1"
                                            defaultChecked=""
                                        />
                                        <label className="form-check-label" htmlFor="feedback_1">
                                            Yes, it was delivered in perfect condition.
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            defaultValue=""
                                            id="feedback_2"
                                        />
                                        <label className="form-check-label" htmlFor="feedback_2">
                                            Yes, but the packaging was slightly damaged.
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            defaultValue=""
                                            id="feedback_3"
                                        />
                                        <label className="form-check-label" htmlFor="feedback_3">
                                            No, it was damaged upon arrival.
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            defaultValue=""
                                            id="feedback_4"
                                        />
                                        <label className="form-check-label" htmlFor="feedback_4">
                                            I'm not sure, I haven't received it yet.
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            defaultValue=""
                                            id="feedback_5"
                                        />
                                        <label className="form-check-label" htmlFor="feedback_5">
                                            The parcel was missing some items.
                                        </label>
                                    </div>
                                    <a href="" data-bs-toggle="modal" data-bs-target="#exampleModal">
                                        Submit{" "}
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>



        </div>

    )
}

export default BuyerLeaveSellerFeedbackContent