import React from 'react'
import { Link } from 'react-router-dom'
const SellerCancelledOrders = () => {
    return (
        <><div className="table_detail_tariff table-responsive  border bg-light">
            <table className="posts-table geeks">
                <thead>
                    <tr className="users-table-tariff text-cen">
                        <th>Product Image</th>
                        <th>Product Name</th>
                        <th>Category</th>
                        <th>Quantity</th>
                        <th>Status</th>
                        <th>Quantity</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody className="text-cen">
                    <tr className="main_table_row_new gfg">
                        <td>
                            <div className="product_images_sec">
                                <img src="/assets/images/seller_images/images_1.png" alt="product" />
                            </div>
                        </td>
                        <td>Product Name</td>
                        <td>Category</td>
                        <td>Sub Category</td>
                        <td>
                            <Link to="#" className="main_detail_butt_2">
                                {" "}
                                <span /> Cancelled
                            </Link>
                        </td>
                        <td>233</td>
                        <td>
                            <Link to="/seller/cancelled_view_information">
                                <img src="/assets/images/seller_images/hugeicons.png" alt="hugeicons" />
                            </Link>
                        </td>
                    </tr>
                    <tr className="main_table_row_new gfg">
                        <td>
                            <div className="product_images_sec">
                                <img src="/assets/images/seller_images/images_2.png" alt="product" />
                            </div>
                        </td>
                        <td>Product Name</td>
                        <td>Category</td>
                        <td>Sub Category</td>
                        <td>
                            <Link to="#" className="main_detail_butt_2">
                                {" "}
                                <span /> Cancelled
                            </Link>
                        </td>
                        <td>233</td>
                        <td>
                            <Link to="/seller/cancelled_view_information">
                                <img src="/assets/images/seller_images/hugeicons.png" alt="hugeicons" />
                            </Link>
                        </td>
                    </tr>
                    <tr className="main_table_row_new gfg">
                        <td>
                            <div className="product_images_sec">
                                <img src="/assets/images/seller_images/images_3.png" alt="product" />
                            </div>
                        </td>
                        <td>Product Name</td>
                        <td>Category</td>
                        <td>Sub Category</td>
                        <td>
                            <Link to="#" className="main_detail_butt_2">
                                {" "}
                                <span /> Cancelled
                            </Link>
                        </td>
                        <td>233</td>
                        <td>
                            <Link to="/seller/cancelled_view_information">
                                <img src="/assets/images/seller_images/hugeicons.png" alt="hugeicons" />
                            </Link>
                        </td>
                    </tr>
                    <tr className="main_table_row_new gfg">
                        <td>
                            <div className="product_images_sec">
                                <img src="/assets/images/seller_images/images_4.png" alt="product" />
                            </div>
                        </td>
                        <td>Product Name</td>
                        <td>Category</td>
                        <td>Sub Category</td>
                        <td>
                            <Link to="#" className="main_detail_butt_2">
                                {" "}
                                <span /> Cancelled
                            </Link>
                        </td>
                        <td>233</td>
                        <td>
                            <Link to="/seller/cancelled_view_information">
                                <img src="/assets/images/seller_images/hugeicons.png" alt="hugeicons" />
                            </Link>
                        </td>
                    </tr>
                    <tr className="main_table_row_new gfg">
                        <td>
                            <div className="product_images_sec">
                                <img src="/assets/images/seller_images/images_5.png" alt="product" />
                            </div>
                        </td>
                        <td>Product Name</td>
                        <td>Category</td>
                        <td>Sub Category</td>
                        <td>
                            <Link to="#" className="main_detail_butt_2">
                                {" "}
                                <span /> Cancelled
                            </Link>
                        </td>
                        <td>233</td>
                        <td>
                            <Link to="/seller/cancelled_view_information">
                                <img src="/assets/images/seller_images/hugeicons.png" alt="hugeicons" />
                            </Link>
                        </td>
                    </tr>
                    <tr className="main_table_row_new gfg">
                        <td>
                            <div className="product_images_sec">
                                <img src="/assets/images/seller_images/images_1.png" alt="product" />
                            </div>
                        </td>
                        <td>Product Name</td>
                        <td>Category</td>
                        <td>Sub Category</td>
                        <td>
                            <Link to="#" className="main_detail_butt_2">
                                {" "}
                                <span /> Cancelled
                            </Link>
                        </td>
                        <td>233</td>
                        <td>
                            <Link to="/seller/cancelled_view_information">
                                <img src="/assets/images/seller_images/hugeicons.png" alt="hugeicons" />
                            </Link>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div><div className="main_detail_numbar">
                <ul className="pagination_main">
                    <li className="disabled">
                        <Link to="#">«</Link>
                    </li>
                    <li className="active">
                        <Link to="#">
                            1 <span className="sr-only">(current)</span>
                        </Link>
                    </li>
                    <li>
                        <Link to="#">2</Link>
                    </li>
                    <li>
                        <Link to="#">3</Link>
                    </li>
                    <li>
                        <Link to="#">»</Link>
                    </li>
                </ul>
            </div></>
    )
}

export default SellerCancelledOrders