import React from 'react';
import { Link } from 'react-router-dom';

let host = process.env.REACT_APP_API_SERVER;

const NewProduct = ({ HomeSecondSectionDataListApiData }) => {
  const products = [
    {
      title: HomeSecondSectionDataListApiData?.title3,
      subtitle: HomeSecondSectionDataListApiData?.subtitle3,
      slug: HomeSecondSectionDataListApiData?.slug3,
      image: HomeSecondSectionDataListApiData?.image3,
    },
    {
      title: HomeSecondSectionDataListApiData?.title4,
      subtitle: HomeSecondSectionDataListApiData?.subtitle4,
      slug: HomeSecondSectionDataListApiData?.slug4,
      image: HomeSecondSectionDataListApiData?.image4,
    },
  ].filter(product => product.image); // Filter out products without images

  return (
    <div className="new_product sell_product_inner_main">
      <div className="container-fluid">
        <div className="row">
          {products.map((product, index) => (
            <div
              key={index}
              className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12"
              data-aos={index % 2 === 0 ? 'fade-up' : 'fade-up'} // Changed to 'fade-up'
              data-aos-delay={index * 500} // Adjust delay for better staggered effect
              data-aos-duration="1000" // Set duration for smooth animation
            >
              <div className={`card product_section${index % 2 !== 0 ? '_black' : ''} h-100`}>
                <div className={`card-body new_product_inner ${index % 2 !== 0 ? 'new_product_right new_product_main_right' : 'new_product_inner_main'}`}>
                  <div className="product_section_gray_inner_left">
                    <h6>{index % 2 === 0 ? 'INTRODUCING' : 'INTRODUCING NEW'}</h6>
                    <h2 className="mb-3">{product.title}</h2>
                    <p>{product.subtitle}</p>
                    <Link
                      to={`/buyer/product-listing?childslug=${product.slug}`}
                      className="btn_primary btn btn_orange"
                    >
                      Shop Now <i className="fa fa-long-arrow-right" aria-hidden="true" />
                    </Link>
                  </div>
                  <div className={`product_section_black_inner_right ${index % 2 !== 0 ? 'new_product_img' : ''}`}>
                    <img
                      src={product.image ? `${host}${product.image}` : ""}
                      className="img-fluid"
                      alt="Product"
                    />
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default NewProduct;
