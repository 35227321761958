import React, { useEffect } from 'react'
import './scss/BuyerYourAddressContent.css'
import { Link, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import { getBuyerAddressList } from '../../../Redux/action/BuyerAction';
import Loader from '../../../Loader';
import ErrorLoader from '../../../Error/ErrorLoader';
import axios from 'axios'
import toast from 'react-hot-toast';

let host = process.env.REACT_APP_API_SERVER;
const BuyerYourAddressContent = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const authToken = localStorage.getItem("buyer_token")
    useEffect(() => {
        if (authToken) {
            dispatch(getBuyerAddressList(authToken));
        }
        else {
            navigate('/buyer/sign-in')
        }

    }, []);
    const {
        loadingBuyerAddressListDataListApi,
        BuyerAddressListDataListApiData,
        BuyerAddressListDataListApiError,


    } = useSelector(state => state?.BuyerReducer);

    const addressData = BuyerAddressListDataListApiData?.data


    const handleDeleteClick = async (slug) => {
        try {
            const config = {
                headers: {

                    'content-type': 'application/json',
                    "auth-token": authToken
                }
            };

            await axios.get(`${host}/buyer/buyer-delete-address?slug=${slug}`, config)
                .then(function (response) {
                    if (response.status == 200) {

                        toast.success("Address deleted successfully");

                        dispatch(getBuyerAddressList(authToken));


                    } else {

                        toast.error(response.data.message);
                    }

                })
                .catch(function (error) {

                    console.log(error)
                    toast.error(error.response.data.message);
                })
        } catch (error) {
            toast.error('Error updating cart:', error.message);
        }
    }


    if (loadingBuyerAddressListDataListApi) {
        return <div className="overlay-form-submission">
            <Loader />
        </div>
    }

    if (BuyerAddressListDataListApiError) {
        return <ErrorLoader message={BuyerAddressListDataListApiError} />;
    }

    return (
        <div className='buyer-your-address'>
            <>
                {/* Breadcrumb */}
                <div className="breadcrumb_section">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                <div className="breadcrumb_section_content">
                                    <ul>
                                        <li>
                                            <Link to="/buyer/your-account"> Your Account</Link>
                                        </li>
                                        <li>
                                            <i className="fa-solid fa-angle-right" />
                                        </li>
                                        <li>
                                            <Link to="/buyer/your-address">Your Address</Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Breadcrumb */}
                {/* Your order */}
                {BuyerAddressListDataListApiData?.message == 'Your account is inactivated.' ?
                    <div className=" container-fluid my-5 py-5 main-wish-list">

                        <div className="d-flex justify-content-center pt-4 py-5 heading-sec px-4">
                            <img src="/assets/images/inactive-account.png" width={200} alt="" srcSet="" />

                        </div>

                        <h1 className='text-center text-danger'>Your account is inacitvated</h1>
                    </div>

                    : <div className="order_section mb-4">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                    <div className="cancel_order_heading border-bottom pb-2">
                                        <div className="cancel_order_heading_left">
                                            <h2>Your Address</h2>
                                        </div>
                                        <div className="cancel_order_heading_left">
                                            <div className="cancel_order_search">
                                                <input
                                                    className="search-txt"
                                                    type="text"
                                                    name=""
                                                    placeholder="Find an Address..."
                                                />
                                                <i className="fa-solid fa-magnifying-glass" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                    <div className="your_address">
                                        <div className="your_address_box">
                                            <Link to="/buyer/add-new-address">
                                                <i className="fa-solid fa-plus" />
                                            </Link>
                                            <h2>Add New Address</h2>
                                            <p>
                                                Simplify your shipping with just a few clicks!
                                            </p>
                                        </div>
                                        {addressData?.length > 0 && addressData?.map((data) => (
                                            <div className="your_address_box_1">
                                                <h4>
                                                    <img src="/assets/images/office_building.png" /> {data?.typeOfAddress}
                                                </h4>
                                                <p>
                                                    {data?.address}
                                                </p>
                                                <h6>{data?.phone}</h6>
                                                <div className="address_edit">
                                                    <div className="address_edit_left">
                                                        <Link to={`/buyer/update-address/${data?.slug}`}>Edit</Link>
                                                    </div>
                                                    <div className="address_edit_right">
                                                        <a onClick={() => { handleDeleteClick(data?.slug) }} style={{ cursor: 'pointer' }}>
                                                            <i className="fa-solid fa-trash" />
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>}
                {/* Your order */}
            </>




        </div>

    )
}

export default BuyerYourAddressContent