import React from 'react'
import { Link } from 'react-router-dom'
import SellerSidebar from '../../SellerEssentials/SellerSidebar'
import SellerHeader from '../../SellerEssentials/SellerHeader'

const ProductList = () => {
    return (
        <>
            <div className="seller">
                <div className="page-wrapper chiller-theme toggled">
                    <SellerSidebar />
                    {/* sidebar-wrapper  */}
                    <main className="page-content container-fluid">
                        {/* ==================header================== */}
                        <SellerHeader />
                        {/* ==================header================== */}
                        <div className="row">
                            <div className="col-md-12">
                                <div className="main-nav-Order">
                                    <h2>Products</h2>
                                    <div className="card_order_main">
                                        <div className="row">
                                            <div className="col-lg-9 col-md-8 col-sm-6">
                                                <nav>
                                                    <div
                                                        className="nav nav-tabs mb-3 all_order_main"
                                                        id="nav-tab"
                                                        role="tablist"
                                                    >
                                                        <button
                                                            className="nav-link active"
                                                            id="nav-all-orders-tab"
                                                            data-bs-toggle="tab"
                                                            data-bs-target="#nav-all-orders"
                                                            type="button"
                                                            role="tab"
                                                            aria-controls="nav-all-orders"
                                                            aria-selected="true"
                                                        >
                                                            All Products (50)
                                                        </button>
                                                        <button
                                                            className="nav-link"
                                                            id="nav-New-Order"
                                                            data-bs-toggle="tab"
                                                            data-bs-target="#nav-New-Orders"
                                                            type="button"
                                                            role="tab"
                                                            aria-controls="nav-New-Order"
                                                            aria-selected="false"
                                                        >
                                                            Active (10)
                                                        </button>
                                                        <button
                                                            className="nav-link"
                                                            id="nav-delivered-tab"
                                                            data-bs-toggle="tab"
                                                            data-bs-target="#nav-delivered"
                                                            type="button"
                                                            role="tab"
                                                            aria-controls="nav-delivered"
                                                            aria-selected="false"
                                                        >
                                                            Inactive (10)
                                                        </button>
                                                        <button
                                                            className="nav-link"
                                                            id="nav-pending-tab"
                                                            data-bs-toggle="tab"
                                                            data-bs-target="#nav-pending"
                                                            type="button"
                                                            role="tab"
                                                            aria-controls="nav-pending"
                                                            aria-selected="false"
                                                        >
                                                            Pending (10)
                                                        </button>
                                                    </div>
                                                </nav>
                                            </div>
                                            <div className="col-lg-3 col-md-4 col-sm-6">
                                                <div className="add_button_product">
                                                    <Link to="/seller/products/add/identity">
                                                        <span>
                                                            <img src={`${process.env.PUBLIC_URL}/assets/images/seller_images/place_w.png`} alt="add" />
                                                        </span>
                                                        Add Product
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="tab-content" id="nav-tabContent">
                                            <div
                                                className="tab-pane fade active show"
                                                id="nav-all-orders"
                                                role="tabpanel"
                                                aria-labelledby="nav-all-orders-tab"
                                            >
                                                <div className="table_detail_tariff table-responsive border bg-light">
                                                    <table className="posts-table geeks">
                                                        <thead>
                                                            <tr className="users-table-tariff text-cen">
                                                                <th>
                                                                    <div className="round_Products">
                                                                        <input type="checkbox" id="checkbox" />
                                                                        <label htmlFor="checkbox" />
                                                                    </div>
                                                                </th>
                                                                <th>Product Image</th>
                                                                <th>Product Name</th>
                                                                <th>Category</th>
                                                                <th>Inventory</th>
                                                                <th>Vendor</th>
                                                                <th>Status</th>
                                                                <th>Action</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody className="text-cen">
                                                            <tr className="main_table_row_new gfg">
                                                                <td>
                                                                    <div className="round_Products">
                                                                        <input type="checkbox" id="checkbox-2" />
                                                                        <label htmlFor="checkbox-2" />
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div className="product_images_sec">
                                                                        <img src={`${process.env.PUBLIC_URL}/assets/images/seller_images/images_1.png`} alt="product" />
                                                                    </div>
                                                                </td>
                                                                <td>Product Name</td>
                                                                <td>Category</td>
                                                                <td>15 In Stock</td>
                                                                <td>Vendor</td>
                                                                <td>
                                                                    <Link to="#" className="main_detail_butt">
                                                                        {" "}
                                                                        <span /> Active
                                                                    </Link>
                                                                </td>
                                                                <td className="img_detail_new">
                                                                    <Link to="/seller/products/view">
                                                                        <img src={`${process.env.PUBLIC_URL}/assets/images/seller_images/hugeicons.png`} alt="hugeicons" />
                                                                    </Link>
                                                                    <Link to="/seller/products/review/edit">
                                                                        <img src={`${process.env.PUBLIC_URL}/assets/images/seller_images/Edit.png`} alt="Edit" />
                                                                    </Link>
                                                                    <Link
                                                                        type="button"
                                                                        data-bs-toggle="modal"
                                                                        data-bs-target="#send_approval"
                                                                    >
                                                                        {" "}
                                                                        <img src={`${process.env.PUBLIC_URL}/assets/images/seller_images/delete.png`} alt="delete" />
                                                                    </Link>
                                                                </td>
                                                            </tr>
                                                            <tr className="main_table_row_new gfg">
                                                                <td>
                                                                    <div className="round_Products">
                                                                        <input type="checkbox" id="checkbox-3" />
                                                                        <label htmlFor="checkbox-3" />
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div className="product_images_sec">
                                                                        <img src={`${process.env.PUBLIC_URL}/assets/images/seller_images/images_2.png`} alt="product" />
                                                                    </div>
                                                                </td>
                                                                <td>Product Name</td>
                                                                <td>Category</td>
                                                                <td>15 In Stock</td>
                                                                <td>Vendor</td>
                                                                <td>
                                                                    <Link to="#" className="main_detail_butt_1">
                                                                        {" "}
                                                                        <span /> Pending
                                                                    </Link>
                                                                </td>
                                                                <td className="img_detail_new">
                                                                    <Link to="/seller/products/view">
                                                                        <img src={`${process.env.PUBLIC_URL}/assets/images/seller_images/hugeicons.png`} alt="hugeicons" />
                                                                    </Link>
                                                                    <Link to="/seller/products/review/edit">
                                                                        <img src={`${process.env.PUBLIC_URL}/assets/images/seller_images/Edit.png`} alt="Edit" />
                                                                    </Link>
                                                                    <Link
                                                                        type="button"
                                                                        data-bs-toggle="modal"
                                                                        data-bs-target="#send_approval"
                                                                    >
                                                                        {" "}
                                                                        <img src={`${process.env.PUBLIC_URL}/assets/images/seller_images/delete.png`} alt="delete" />
                                                                    </Link>
                                                                </td>
                                                            </tr>
                                                            <tr className="main_table_row_new gfg">
                                                                <td>
                                                                    <div className="round_Products">
                                                                        <input type="checkbox" id="checkbox-4" />
                                                                        <label htmlFor="checkbox-4" />
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div className="product_images_sec">
                                                                        <img src={`${process.env.PUBLIC_URL}/assets/images/seller_images/images_3.png`} alt="product" />
                                                                    </div>
                                                                </td>
                                                                <td>Product Name</td>
                                                                <td>Category</td>
                                                                <td>15 In Stock</td>
                                                                <td>Vendor</td>
                                                                <td>
                                                                    <Link to="#" className="main_detail_butt_2">
                                                                        {" "}
                                                                        <span /> Inactive
                                                                    </Link>
                                                                </td>
                                                                <td className="img_detail_new">
                                                                    <Link to="/seller/products/view">
                                                                        <img src={`${process.env.PUBLIC_URL}/assets/images/seller_images/hugeicons.png`} alt="hugeicons" />
                                                                    </Link>
                                                                    <Link to="/seller/products/review/edit">
                                                                        <img src={`${process.env.PUBLIC_URL}/assets/images/seller_images/Edit.png`} alt="Edit" />
                                                                    </Link>
                                                                    <Link
                                                                        type="button"
                                                                        data-bs-toggle="modal"
                                                                        data-bs-target="#send_approval"
                                                                    >
                                                                        {" "}
                                                                        <img src={`${process.env.PUBLIC_URL}/assets/images/seller_images/delete.png`} alt="delete" />
                                                                    </Link>
                                                                </td>
                                                            </tr>
                                                            <tr className="main_table_row_new gfg">
                                                                <td>
                                                                    <div className="round_Products">
                                                                        <input type="checkbox" id="checkbox-5" />
                                                                        <label htmlFor="checkbox-5" />
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div className="product_images_sec">
                                                                        <img src={`${process.env.PUBLIC_URL}/assets/images/seller_images/images_4.png`} alt="product" />
                                                                    </div>
                                                                </td>
                                                                <td>Product Name</td>
                                                                <td>Category</td>
                                                                <td>15 In Stock</td>
                                                                <td>Vendor</td>
                                                                <td>
                                                                    <Link to="#" className="main_detail_butt">
                                                                        {" "}
                                                                        <span /> Active
                                                                    </Link>
                                                                </td>
                                                                <td className="img_detail_new">
                                                                    <Link to="/seller/products/view">
                                                                        <img src={`${process.env.PUBLIC_URL}/assets/images/seller_images/hugeicons.png`} alt="hugeicons" />
                                                                    </Link>
                                                                    <Link to="/seller/products/review/edit">
                                                                        <img src={`${process.env.PUBLIC_URL}/assets/images/seller_images/Edit.png`} alt="Edit" />
                                                                    </Link>
                                                                    <Link
                                                                        type="button"
                                                                        data-bs-toggle="modal"
                                                                        data-bs-target="#send_approval"
                                                                    >
                                                                        {" "}
                                                                        <img src={`${process.env.PUBLIC_URL}/assets/images/seller_images/delete.png`} alt="delete" />
                                                                    </Link>
                                                                </td>
                                                            </tr>
                                                            <tr className="main_table_row_new gfg">
                                                                <td>
                                                                    <div className="round_Products">
                                                                        <input type="checkbox" id="checkbox-6" />
                                                                        <label htmlFor="checkbox-6" />
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div className="product_images_sec">
                                                                        <img src={`${process.env.PUBLIC_URL}/assets/images/seller_images/images_5.png`} alt="product" />
                                                                    </div>
                                                                </td>
                                                                <td>Product Name</td>
                                                                <td>Category</td>
                                                                <td>15 In Stock</td>
                                                                <td>Vendor</td>
                                                                <td>
                                                                    <Link to="#" className="main_detail_butt">
                                                                        {" "}
                                                                        <span /> Active
                                                                    </Link>
                                                                </td>
                                                                <td className="img_detail_new">
                                                                    <Link to="/seller/products/view">
                                                                        <img src={`${process.env.PUBLIC_URL}/assets/images/seller_images/hugeicons.png`} alt="hugeicons" />
                                                                    </Link>
                                                                    <Link to="/seller/products/review/edit">
                                                                        <img src={`${process.env.PUBLIC_URL}/assets/images/seller_images/Edit.png`} alt="Edit" />
                                                                    </Link>
                                                                    <Link
                                                                        type="button"
                                                                        data-bs-toggle="modal"
                                                                        data-bs-target="#send_approval"
                                                                    >
                                                                        {" "}
                                                                        <img src={`${process.env.PUBLIC_URL}/assets/images/seller_images/delete.png`} alt="delete" />
                                                                    </Link>
                                                                </td>
                                                            </tr>
                                                            <tr className="main_table_row_new gfg">
                                                                <td>
                                                                    <div className="round_Products">
                                                                        <input type="checkbox" id="checkbox-1" />
                                                                        <label htmlFor="checkbox-1" />
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div className="product_images_sec">
                                                                        <img src={`${process.env.PUBLIC_URL}/assets/images/seller_images/images_1.png`} alt="product" />
                                                                    </div>
                                                                </td>
                                                                <td>Product Name</td>
                                                                <td>Category</td>
                                                                <td>15 In Stock</td>
                                                                <td>Vendor</td>
                                                                <td>
                                                                    <Link to="#" className="main_detail_butt">
                                                                        {" "}
                                                                        <span /> Active
                                                                    </Link>
                                                                </td>
                                                                <td className="img_detail_new">
                                                                    <Link to="/seller/products/view">
                                                                        <img src={`${process.env.PUBLIC_URL}/assets/images/seller_images/hugeicons.png`} alt="hugeicons" />
                                                                    </Link>
                                                                    <Link to="/seller/products/review/edit">
                                                                        <img src={`${process.env.PUBLIC_URL}/assets/images/seller_images/Edit.png`} alt="Edit" />
                                                                    </Link>
                                                                    <Link
                                                                        type="button"
                                                                        data-bs-toggle="modal"
                                                                        data-bs-target="#send_approval"
                                                                    >
                                                                        {" "}
                                                                        <img src={`${process.env.PUBLIC_URL}/assets/images/seller_images/delete.png`} alt="delete" />
                                                                    </Link>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <div className="main_detail_numbar">
                                                    <ul className="pagination_main">
                                                        <li className="disabled">
                                                            <Link to="#">«</Link>
                                                        </li>
                                                        <li className="active">
                                                            <Link to="#">
                                                                1 <span className="sr-only">(current)</span>
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <Link to="#">2</Link>
                                                        </li>
                                                        <li>
                                                            <Link to="#">3</Link>
                                                        </li>
                                                        <li>
                                                            <Link to="#">»</Link>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div
                                                className="tab-pane fade"
                                                id="nav-New-Orders"
                                                role="tabpanel"
                                                aria-labelledby="nav-delivered-tab"
                                            >
                                                <div className="table_detail_tariff table-responsive  border bg-light">
                                                    <table className="posts-table geeks">
                                                        <thead>
                                                            <tr className="users-table-tariff text-cen">
                                                                <th>
                                                                    <div className="round_Products">
                                                                        <input type="checkbox" id="checkbox" />
                                                                        <label htmlFor="checkbox" />
                                                                    </div>
                                                                </th>
                                                                <th>Product Image</th>
                                                                <th>Product Name</th>
                                                                <th>Category</th>
                                                                <th>Inventory</th>
                                                                <th>Vendor</th>
                                                                <th>Status</th>
                                                                <th>Action</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody className="text-cen">
                                                            <tr className="main_table_row_new gfg">
                                                                <td>
                                                                    <div className="round_Products">
                                                                        <input type="checkbox" id="checkbox-2" />
                                                                        <label htmlFor="checkbox-2" />
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div className="product_images_sec">
                                                                        <img src={`${process.env.PUBLIC_URL}/assets/images/seller_images/images_1.png`} alt="product" />
                                                                    </div>
                                                                </td>
                                                                <td>Product Name</td>
                                                                <td>Category</td>
                                                                <td>15 In Stock</td>
                                                                <td> Vendor</td>
                                                                <td>
                                                                    <Link to="#" className="main_detail_butt">
                                                                        {" "}
                                                                        <span /> Active
                                                                    </Link>
                                                                </td>
                                                                <td className="img_detail_new">
                                                                    <Link to="/seller/products/view">
                                                                        <img src={`${process.env.PUBLIC_URL}/assets/images/seller_images/hugeicons.png`} alt="hugeicons" />
                                                                    </Link>
                                                                    <Link to="/seller/products/review/edit">
                                                                        <img src={`${process.env.PUBLIC_URL}/assets/images/seller_images/Edit.png`} alt="Edit" />
                                                                    </Link>
                                                                    <Link
                                                                        type="button"
                                                                        data-bs-toggle="modal"
                                                                        data-bs-target="#send_approval"
                                                                    >
                                                                        {" "}
                                                                        <img src={`${process.env.PUBLIC_URL}/assets/images/seller_images/delete.png`} alt="delete" />
                                                                    </Link>
                                                                </td>
                                                            </tr>
                                                            <tr className="main_table_row_new gfg">
                                                                <td>
                                                                    <div className="round_Products">
                                                                        <input type="checkbox" id="checkbox-2" />
                                                                        <label htmlFor="checkbox-2" />
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div className="product_images_sec">
                                                                        <img src={`${process.env.PUBLIC_URL}/assets/images/seller_images/images_1.png`} alt="product" />
                                                                    </div>
                                                                </td>
                                                                <td>Product Name</td>
                                                                <td>Category</td>
                                                                <td>15 In Stock</td>
                                                                <td> Vendor</td>
                                                                <td>
                                                                    <Link to="#" className="main_detail_butt">
                                                                        {" "}
                                                                        <span /> Active
                                                                    </Link>
                                                                </td>
                                                                <td className="img_detail_new">
                                                                    <Link to="/seller/products/view">
                                                                        <img src={`${process.env.PUBLIC_URL}/assets/images/seller_images/hugeicons.png`} alt="hugeicons" />
                                                                    </Link>
                                                                    <Link to="/seller/products/review/edit">
                                                                        <img src={`${process.env.PUBLIC_URL}/assets/images/seller_images/Edit.png`} alt="Edit" />
                                                                    </Link>
                                                                    <Link
                                                                        type="button"
                                                                        data-bs-toggle="modal"
                                                                        data-bs-target="#send_approval"
                                                                    >
                                                                        {" "}
                                                                        <img src={`${process.env.PUBLIC_URL}/assets/images/seller_images/delete.png`} alt="delete" />
                                                                    </Link>
                                                                </td>
                                                            </tr>
                                                            <tr className="main_table_row_new gfg">
                                                                <td>
                                                                    <div className="round_Products">
                                                                        <input type="checkbox" id="checkbox-2" />
                                                                        <label htmlFor="checkbox-2" />
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div className="product_images_sec">
                                                                        <img src={`${process.env.PUBLIC_URL}/assets/images/seller_images/images_1.png`} alt="product" />
                                                                    </div>
                                                                </td>
                                                                <td>Product Name</td>
                                                                <td>Category</td>
                                                                <td>15 In Stock</td>
                                                                <td> Vendor</td>
                                                                <td>
                                                                    <Link to="#" className="main_detail_butt">
                                                                        {" "}
                                                                        <span /> Active
                                                                    </Link>
                                                                </td>
                                                                <td className="img_detail_new">
                                                                    <Link to="/seller/products/view">
                                                                        <img src={`${process.env.PUBLIC_URL}/assets/images/seller_images/hugeicons.png`} alt="hugeicons" />
                                                                    </Link>
                                                                    <Link to="/seller/products/review/edit">
                                                                        <img src={`${process.env.PUBLIC_URL}/assets/images/seller_images/Edit.png`} alt="Edit" />
                                                                    </Link>
                                                                    <Link
                                                                        type="button"
                                                                        data-bs-toggle="modal"
                                                                        data-bs-target="#send_approval"
                                                                    >
                                                                        {" "}
                                                                        <img src={`${process.env.PUBLIC_URL}/assets/images/seller_images/delete.png`} alt="delete" />
                                                                    </Link>
                                                                </td>
                                                            </tr>
                                                            <tr className="main_table_row_new gfg">
                                                                <td>
                                                                    <div className="round_Products">
                                                                        <input type="checkbox" id="checkbox-2" />
                                                                        <label htmlFor="checkbox-2" />
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div className="product_images_sec">
                                                                        <img src={`${process.env.PUBLIC_URL}/assets/images/seller_images/images_1.png`} alt="product" />
                                                                    </div>
                                                                </td>
                                                                <td>Product Name</td>
                                                                <td>Category</td>
                                                                <td>15 In Stock</td>
                                                                <td> Vendor</td>
                                                                <td>
                                                                    <Link to="#" className="main_detail_butt">
                                                                        {" "}
                                                                        <span /> Active
                                                                    </Link>
                                                                </td>
                                                                <td className="img_detail_new">
                                                                    <Link to="/seller/products/view">
                                                                        <img src={`${process.env.PUBLIC_URL}/assets/images/seller_images/hugeicons.png`} alt="hugeicons" />
                                                                    </Link>
                                                                    <Link to="/seller/products/review/edit">
                                                                        <img src={`${process.env.PUBLIC_URL}/assets/images/seller_images/Edit.png`} alt="Edit" />
                                                                    </Link>
                                                                    <Link
                                                                        type="button"
                                                                        data-bs-toggle="modal"
                                                                        data-bs-target="#send_approval"
                                                                    >
                                                                        {" "}
                                                                        <img src={`${process.env.PUBLIC_URL}/assets/images/seller_images/delete.png`} alt="delete" />
                                                                    </Link>
                                                                </td>
                                                            </tr>
                                                            <tr className="main_table_row_new gfg">
                                                                <td>
                                                                    <div className="round_Products">
                                                                        <input type="checkbox" id="checkbox-2" />
                                                                        <label htmlFor="checkbox-2" />
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div className="product_images_sec">
                                                                        <img src={`${process.env.PUBLIC_URL}/assets/images/seller_images/images_1.png`} alt="product" />
                                                                    </div>
                                                                </td>
                                                                <td>Product Name</td>
                                                                <td>Category</td>
                                                                <td>15 In Stock</td>
                                                                <td> Vendor</td>
                                                                <td>
                                                                    <Link to="#" className="main_detail_butt">
                                                                        {" "}
                                                                        <span /> Active
                                                                    </Link>
                                                                </td>
                                                                <td className="img_detail_new">
                                                                    <Link to="/seller/products/view">
                                                                        <img src={`${process.env.PUBLIC_URL}/assets/images/seller_images/hugeicons.png`} alt="hugeicons" />
                                                                    </Link>
                                                                    <Link to="/seller/products/review/edit">
                                                                        <img src={`${process.env.PUBLIC_URL}/assets/images/seller_images/Edit.png`} alt="Edit" />
                                                                    </Link>
                                                                    <Link
                                                                        type="button"
                                                                        data-bs-toggle="modal"
                                                                        data-bs-target="#send_approval"
                                                                    >
                                                                        {" "}
                                                                        <img src={`${process.env.PUBLIC_URL}/assets/images/seller_images/delete.png`} alt="delete" />
                                                                    </Link>
                                                                </td>
                                                            </tr>
                                                            <tr className="main_table_row_new gfg">
                                                                <td>
                                                                    <div className="round_Products">
                                                                        <input type="checkbox" id="checkbox-2" />
                                                                        <label htmlFor="checkbox-2" />
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div className="product_images_sec">
                                                                        <img src={`${process.env.PUBLIC_URL}/assets/images/seller_images/images_1.png`} alt="product" />
                                                                    </div>
                                                                </td>
                                                                <td>Product Name</td>
                                                                <td>Category</td>
                                                                <td>15 In Stock</td>
                                                                <td> Vendor</td>
                                                                <td>
                                                                    <Link to="#" className="main_detail_butt">
                                                                        {" "}
                                                                        <span /> Active
                                                                    </Link>
                                                                </td>
                                                                <td className="img_detail_new">
                                                                    <Link to="/seller/products/view">
                                                                        <img src={`${process.env.PUBLIC_URL}/assets/images/seller_images/hugeicons.png`} alt="hugeicons" />
                                                                    </Link>
                                                                    <Link to="/seller/products/review/edit">
                                                                        <img src={`${process.env.PUBLIC_URL}/assets/images/seller_images/Edit.png`} alt="Edit" />
                                                                    </Link>
                                                                    <Link
                                                                        type="button"
                                                                        data-bs-toggle="modal"
                                                                        data-bs-target="#send_approval"
                                                                    >
                                                                        {" "}
                                                                        <img src={`${process.env.PUBLIC_URL}/assets/images/seller_images/delete.png`} alt="delete" />
                                                                    </Link>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <div className="main_detail_numbar">
                                                    <ul className="pagination_main">
                                                        <li className="disabled">
                                                            <Link to="#">«</Link>
                                                        </li>
                                                        <li className="active">
                                                            <Link to="#">
                                                                1 <span className="sr-only">(current)</span>
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <Link to="#">2</Link>
                                                        </li>
                                                        <li>
                                                            <Link to="#">3</Link>
                                                        </li>
                                                        <li>
                                                            <Link to="#">»</Link>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div
                                                className="tab-pane fade"
                                                id="nav-delivered"
                                                role="tabpanel"
                                                aria-labelledby="nav-delivered-tab"
                                            >
                                                <div className="table_detail_tariff table-responsive  border bg-light">
                                                    <table className="posts-table geeks">
                                                        <thead>
                                                            <tr className="users-table-tariff text-cen">
                                                                <th>
                                                                    <div className="round_Products">
                                                                        <input type="checkbox" id="checkbox" />
                                                                        <label htmlFor="checkbox" />
                                                                    </div>
                                                                </th>
                                                                <th>Product Image</th>
                                                                <th>Product Name</th>
                                                                <th>Category</th>
                                                                <th>Inventory</th>
                                                                <th>Vendor</th>
                                                                <th>Status</th>
                                                                <th>Action</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody className="text-cen">
                                                            <tr className="main_table_row_new gfg">
                                                                <td>
                                                                    <div className="round_Products">
                                                                        <input type="checkbox" id="checkbox-2" />
                                                                        <label htmlFor="checkbox-2" />
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div className="product_images_sec">
                                                                        <img src={`${process.env.PUBLIC_URL}/assets/images/seller_images/images_1.png`} alt="product" />
                                                                    </div>
                                                                </td>
                                                                <td>Product Name</td>
                                                                <td>Category</td>
                                                                <td>15 In Stock</td>
                                                                <td> Vendor</td>
                                                                <td>
                                                                    <Link to="#" className="main_detail_butt_2">
                                                                        {" "}
                                                                        <span /> Inactive
                                                                    </Link>
                                                                </td>
                                                                <td className="img_detail_new">
                                                                    <Link to="/seller/products/view">
                                                                        <img src={`${process.env.PUBLIC_URL}/assets/images/seller_images/hugeicons.png`} alt="hugeicons" />
                                                                    </Link>
                                                                    <Link to="/seller/products/review/edit">
                                                                        <img src={`${process.env.PUBLIC_URL}/assets/images/seller_images/Edit.png`} alt="Edit" />
                                                                    </Link>
                                                                    <Link
                                                                        type="button"
                                                                        data-bs-toggle="modal"
                                                                        data-bs-target="#send_approval"
                                                                    >
                                                                        {" "}
                                                                        <img src={`${process.env.PUBLIC_URL}/assets/images/seller_images/delete.png`} alt="delete" />
                                                                    </Link>
                                                                </td>
                                                            </tr>
                                                            <tr className="main_table_row_new gfg">
                                                                <td>
                                                                    <div className="round_Products">
                                                                        <input type="checkbox" id="checkbox-2" />
                                                                        <label htmlFor="checkbox-2" />
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div className="product_images_sec">
                                                                        <img src={`${process.env.PUBLIC_URL}/assets/images/seller_images/images_1.png`} alt="product" />
                                                                    </div>
                                                                </td>
                                                                <td>Product Name</td>
                                                                <td>Category</td>
                                                                <td>15 In Stock</td>
                                                                <td> Vendor</td>
                                                                <td>
                                                                    <Link to="#" className="main_detail_butt_2">
                                                                        {" "}
                                                                        <span /> Inactive
                                                                    </Link>
                                                                </td>
                                                                <td className="img_detail_new">
                                                                    <Link to="/seller/products/view">
                                                                        <img src={`${process.env.PUBLIC_URL}/assets/images/seller_images/hugeicons.png`} alt="hugeicons" />
                                                                    </Link>
                                                                    <Link to="/seller/products/review/edit">
                                                                        <img src={`${process.env.PUBLIC_URL}/assets/images/seller_images/Edit.png`} alt="Edit" />
                                                                    </Link>
                                                                    <Link
                                                                        type="button"
                                                                        data-bs-toggle="modal"
                                                                        data-bs-target="#send_approval"
                                                                    >
                                                                        {" "}
                                                                        <img src={`${process.env.PUBLIC_URL}/assets/images/seller_images/delete.png`} alt="delete" />
                                                                    </Link>
                                                                </td>
                                                            </tr>
                                                            <tr className="main_table_row_new gfg">
                                                                <td>
                                                                    <div className="round_Products">
                                                                        <input type="checkbox" id="checkbox-2" />
                                                                        <label htmlFor="checkbox-2" />
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div className="product_images_sec">
                                                                        <img src={`${process.env.PUBLIC_URL}/assets/images/seller_images/images_1.png`} alt="product" />
                                                                    </div>
                                                                </td>
                                                                <td>Product Name</td>
                                                                <td>Category</td>
                                                                <td>15 In Stock</td>
                                                                <td> Vendor</td>
                                                                <td>
                                                                    <Link to="#" className="main_detail_butt_2">
                                                                        {" "}
                                                                        <span /> Inactive
                                                                    </Link>
                                                                </td>
                                                                <td className="img_detail_new">
                                                                    <Link to="/seller/products/view">
                                                                        <img src={`${process.env.PUBLIC_URL}/assets/images/seller_images/hugeicons.png`} alt="hugeicons" />
                                                                    </Link>
                                                                    <Link to="/seller/products/review/edit">
                                                                        <img src={`${process.env.PUBLIC_URL}/assets/images/seller_images/Edit.png`} alt="Edit" />
                                                                    </Link>
                                                                    <Link
                                                                        type="button"
                                                                        data-bs-toggle="modal"
                                                                        data-bs-target="#send_approval"
                                                                    >
                                                                        {" "}
                                                                        <img src={`${process.env.PUBLIC_URL}/assets/images/seller_images/delete.png`} alt="delete" />
                                                                    </Link>
                                                                </td>
                                                            </tr>
                                                            <tr className="main_table_row_new gfg">
                                                                <td>
                                                                    <div className="round_Products">
                                                                        <input type="checkbox" id="checkbox-2" />
                                                                        <label htmlFor="checkbox-2" />
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div className="product_images_sec">
                                                                        <img src={`${process.env.PUBLIC_URL}/assets/images/seller_images/images_1.png`} alt="product" />
                                                                    </div>
                                                                </td>
                                                                <td>Product Name</td>
                                                                <td>Category</td>
                                                                <td>15 In Stock</td>
                                                                <td> Vendor</td>
                                                                <td>
                                                                    <Link to="#" className="main_detail_butt_2">
                                                                        {" "}
                                                                        <span /> Inactive
                                                                    </Link>
                                                                </td>
                                                                <td className="img_detail_new">
                                                                    <Link to="/seller/products/view">
                                                                        <img src={`${process.env.PUBLIC_URL}/assets/images/seller_images/hugeicons.png`} alt="hugeicons" />
                                                                    </Link>
                                                                    <Link to="/seller/products/review/edit">
                                                                        <img src={`${process.env.PUBLIC_URL}/assets/images/seller_images/Edit.png`} alt="Edit" />
                                                                    </Link>
                                                                    <Link
                                                                        type="button"
                                                                        data-bs-toggle="modal"
                                                                        data-bs-target="#send_approval"
                                                                    >
                                                                        {" "}
                                                                        <img src={`${process.env.PUBLIC_URL}/assets/images/seller_images/delete.png`} alt="delete" />
                                                                    </Link>
                                                                </td>
                                                            </tr>
                                                            <tr className="main_table_row_new gfg">
                                                                <td>
                                                                    <div className="round_Products">
                                                                        <input type="checkbox" id="checkbox-2" />
                                                                        <label htmlFor="checkbox-2" />
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div className="product_images_sec">
                                                                        <img src={`${process.env.PUBLIC_URL}/assets/images/seller_images/images_1.png`} alt="product" />
                                                                    </div>
                                                                </td>
                                                                <td>Product Name</td>
                                                                <td>Category</td>
                                                                <td>15 In Stock</td>
                                                                <td> Vendor</td>
                                                                <td>
                                                                    <Link to="#" className="main_detail_butt_2">
                                                                        {" "}
                                                                        <span /> Inactive
                                                                    </Link>
                                                                </td>
                                                                <td className="img_detail_new">
                                                                    <Link to="/seller/products/view">
                                                                        <img src={`${process.env.PUBLIC_URL}/assets/images/seller_images/hugeicons.png`} alt="hugeicons" />
                                                                    </Link>
                                                                    <Link to="/seller/products/review/edit">
                                                                        <img src={`${process.env.PUBLIC_URL}/assets/images/seller_images/Edit.png`} alt="Edit" />
                                                                    </Link>
                                                                    <Link
                                                                        type="button"
                                                                        data-bs-toggle="modal"
                                                                        data-bs-target="#send_approval"
                                                                    >
                                                                        {" "}
                                                                        <img src={`${process.env.PUBLIC_URL}/assets/images/seller_images/delete.png`} alt="delete" />
                                                                    </Link>
                                                                </td>
                                                            </tr>
                                                            <tr className="main_table_row_new gfg">
                                                                <td>
                                                                    <div className="round_Products">
                                                                        <input type="checkbox" id="checkbox-2" />
                                                                        <label htmlFor="checkbox-2" />
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div className="product_images_sec">
                                                                        <img src={`${process.env.PUBLIC_URL}/assets/images/seller_images/images_1.png`} alt="product" />
                                                                    </div>
                                                                </td>
                                                                <td>Product Name</td>
                                                                <td>Category</td>
                                                                <td>15 In Stock</td>
                                                                <td> Vendor</td>
                                                                <td>
                                                                    <Link to="#" className="main_detail_butt_2">
                                                                        {" "}
                                                                        <span /> Inactive
                                                                    </Link>
                                                                </td>
                                                                <td className="img_detail_new">
                                                                    <Link to="/seller/products/view">
                                                                        <img src={`${process.env.PUBLIC_URL}/assets/images/seller_images/hugeicons.png`} alt="hugeicons" />
                                                                    </Link>
                                                                    <Link to="/seller/products/review/edit">
                                                                        <img src={`${process.env.PUBLIC_URL}/assets/images/seller_images/Edit.png`} alt="Edit" />
                                                                    </Link>
                                                                    <Link
                                                                        type="button"
                                                                        data-bs-toggle="modal"
                                                                        data-bs-target="#send_approval"
                                                                    >
                                                                        {" "}
                                                                        <img src={`${process.env.PUBLIC_URL}/assets/images/seller_images/delete.png`} alt="delete" />
                                                                    </Link>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <div className="main_detail_numbar">
                                                    <ul className="pagination_main">
                                                        <li className="disabled">
                                                            <Link to="#">«</Link>
                                                        </li>
                                                        <li className="active">
                                                            <Link to="#">
                                                                1 <span className="sr-only">(current)</span>
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <Link to="#">2</Link>
                                                        </li>
                                                        <li>
                                                            <Link to="#">3</Link>
                                                        </li>
                                                        <li>
                                                            <Link to="#">»</Link>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div
                                                className="tab-pane fade"
                                                id="nav-pending"
                                                role="tabpanel"
                                                aria-labelledby="nav-pending-tab"
                                            >
                                                <div className="table_detail_tariff table-responsive  border bg-light">
                                                    <table className="posts-table geeks">
                                                        <thead>
                                                            <tr className="users-table-tariff text-cen">
                                                                <th>
                                                                    <div className="round_Products">
                                                                        <input type="checkbox" id="checkbox" />
                                                                        <label htmlFor="checkbox" />
                                                                    </div>
                                                                </th>
                                                                <th>Product Image</th>
                                                                <th>Product Name</th>
                                                                <th>Category</th>
                                                                <th>Inventory</th>
                                                                <th>Vendor</th>
                                                                <th>Status</th>
                                                                <th>Action</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody className="text-cen">
                                                            <tr className="main_table_row_new gfg">
                                                                <td>
                                                                    <div className="round_Products">
                                                                        <input type="checkbox" id="checkbox-2" />
                                                                        <label htmlFor="checkbox-2" />
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div className="product_images_sec">
                                                                        <img src={`${process.env.PUBLIC_URL}/assets/images/seller_images/images_1.png`} alt="product" />
                                                                    </div>
                                                                </td>
                                                                <td>Product Name</td>
                                                                <td>Category</td>
                                                                <td>15 In Stock</td>
                                                                <td> Vendor</td>
                                                                <td>
                                                                    <Link to="#" className="main_detail_butt_1">
                                                                        {" "}
                                                                        <span /> Pending
                                                                    </Link>
                                                                </td>
                                                                <td className="img_detail_new">
                                                                    <Link to="/seller/products/view">
                                                                        <img src={`${process.env.PUBLIC_URL}/assets/images/seller_images/hugeicons.png`} alt="hugeicons" />
                                                                    </Link>
                                                                    <Link to="/seller/products/review/edit">
                                                                        <img src={`${process.env.PUBLIC_URL}/assets/images/seller_images/Edit.png`} alt="Edit" />
                                                                    </Link>
                                                                    <Link
                                                                        type="button"
                                                                        data-bs-toggle="modal"
                                                                        data-bs-target="#send_approval"
                                                                    >
                                                                        {" "}
                                                                        <img src={`${process.env.PUBLIC_URL}/assets/images/seller_images/delete.png`} alt="delete" />
                                                                    </Link>
                                                                </td>
                                                            </tr>
                                                            <tr className="main_table_row_new gfg">
                                                                <td>
                                                                    <div className="round_Products">
                                                                        <input type="checkbox" id="checkbox-2" />
                                                                        <label htmlFor="checkbox-2" />
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div className="product_images_sec">
                                                                        <img src={`${process.env.PUBLIC_URL}/assets/images/seller_images/images_1.png`} alt="product" />
                                                                    </div>
                                                                </td>
                                                                <td>Product Name</td>
                                                                <td>Category</td>
                                                                <td>15 In Stock</td>
                                                                <td> Vendor</td>
                                                                <td>
                                                                    <Link to="#" className="main_detail_butt_1">
                                                                        {" "}
                                                                        <span /> Pending
                                                                    </Link>
                                                                </td>
                                                                <td className="img_detail_new">
                                                                    <Link to="/seller/products/view">
                                                                        <img src={`${process.env.PUBLIC_URL}/assets/images/seller_images/hugeicons.png`} alt="hugeicons" />
                                                                    </Link>
                                                                    <Link to="/seller/products/review/edit">
                                                                        <img src={`${process.env.PUBLIC_URL}/assets/images/seller_images/Edit.png`} alt="Edit" />
                                                                    </Link>
                                                                    <Link
                                                                        type="button"
                                                                        data-bs-toggle="modal"
                                                                        data-bs-target="#send_approval"
                                                                    >
                                                                        {" "}
                                                                        <img src={`${process.env.PUBLIC_URL}/assets/images/seller_images/delete.png`} alt="delete" />
                                                                    </Link>
                                                                </td>
                                                            </tr>
                                                            <tr className="main_table_row_new gfg">
                                                                <td>
                                                                    <div className="round_Products">
                                                                        <input type="checkbox" id="checkbox-2" />
                                                                        <label htmlFor="checkbox-2" />
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div className="product_images_sec">
                                                                        <img src={`${process.env.PUBLIC_URL}/assets/images/seller_images/images_1.png`} alt="product" />
                                                                    </div>
                                                                </td>
                                                                <td>Product Name</td>
                                                                <td>Category</td>
                                                                <td>15 In Stock</td>
                                                                <td> Vendor</td>
                                                                <td>
                                                                    <Link to="#" className="main_detail_butt_1">
                                                                        {" "}
                                                                        <span /> Pending
                                                                    </Link>
                                                                </td>
                                                                <td className="img_detail_new">
                                                                    <Link to="/seller/products/view">
                                                                        <img src={`${process.env.PUBLIC_URL}/assets/images/seller_images/hugeicons.png`} alt="hugeicons" />
                                                                    </Link>
                                                                    <Link to="/seller/products/review/edit">
                                                                        <img src={`${process.env.PUBLIC_URL}/assets/images/seller_images/Edit.png`} alt="Edit" />
                                                                    </Link>
                                                                    <Link
                                                                        type="button"
                                                                        data-bs-toggle="modal"
                                                                        data-bs-target="#send_approval"
                                                                    >
                                                                        {" "}
                                                                        <img src={`${process.env.PUBLIC_URL}/assets/images/seller_images/delete.png`} alt="delete" />
                                                                    </Link>
                                                                </td>
                                                            </tr>
                                                            <tr className="main_table_row_new gfg">
                                                                <td>
                                                                    <div className="round_Products">
                                                                        <input type="checkbox" id="checkbox-2" />
                                                                        <label htmlFor="checkbox-2" />
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div className="product_images_sec">
                                                                        <img src={`${process.env.PUBLIC_URL}/assets/images/seller_images/images_1.png`} alt="product" />
                                                                    </div>
                                                                </td>
                                                                <td>Product Name</td>
                                                                <td>Category</td>
                                                                <td>15 In Stock</td>
                                                                <td> Vendor</td>
                                                                <td>
                                                                    <Link to="#" className="main_detail_butt_1">
                                                                        {" "}
                                                                        <span /> Pending
                                                                    </Link>
                                                                </td>
                                                                <td className="img_detail_new">
                                                                    <Link to="/seller/products/view">
                                                                        <img src={`${process.env.PUBLIC_URL}/assets/images/seller_images/hugeicons.png`} alt="hugeicons" />
                                                                    </Link>
                                                                    <Link to="/seller/products/review/edit">
                                                                        <img src={`${process.env.PUBLIC_URL}/assets/images/seller_images/Edit.png`} alt="Edit" />
                                                                    </Link>
                                                                    <Link
                                                                        type="button"
                                                                        data-bs-toggle="modal"
                                                                        data-bs-target="#send_approval"
                                                                    >
                                                                        {" "}
                                                                        <img src={`${process.env.PUBLIC_URL}/assets/images/seller_images/delete.png`} alt="delete" />
                                                                    </Link>
                                                                </td>
                                                            </tr>
                                                            <tr className="main_table_row_new gfg">
                                                                <td>
                                                                    <div className="round_Products">
                                                                        <input type="checkbox" id="checkbox-2" />
                                                                        <label htmlFor="checkbox-2" />
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div className="product_images_sec">
                                                                        <img src={`${process.env.PUBLIC_URL}/assets/images/seller_images/images_1.png`} alt="product" />
                                                                    </div>
                                                                </td>
                                                                <td>Product Name</td>
                                                                <td>Category</td>
                                                                <td>15 In Stock</td>
                                                                <td> Vendor</td>
                                                                <td>
                                                                    <Link to="#" className="main_detail_butt_1">
                                                                        {" "}
                                                                        <span /> Pending
                                                                    </Link>
                                                                </td>
                                                                <td className="img_detail_new">
                                                                    <Link to="/seller/products/view">
                                                                        <img src={`${process.env.PUBLIC_URL}/assets/images/seller_images/hugeicons.png`} alt="hugeicons" />
                                                                    </Link>
                                                                    <Link to="/seller/products/review/edit">
                                                                        <img src={`${process.env.PUBLIC_URL}/assets/images/seller_images/Edit.png`} alt="Edit" />
                                                                    </Link>
                                                                    <Link
                                                                        type="button"
                                                                        data-bs-toggle="modal"
                                                                        data-bs-target="#send_approval"
                                                                    >
                                                                        {" "}
                                                                        <img src={`${process.env.PUBLIC_URL}/assets/images/seller_images/delete.png`} alt="delete" />
                                                                    </Link>
                                                                </td>
                                                            </tr>
                                                            <tr className="main_table_row_new gfg">
                                                                <td>
                                                                    <div className="round_Products">
                                                                        <input type="checkbox" id="checkbox-2" />
                                                                        <label htmlFor="checkbox-2" />
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div className="product_images_sec">
                                                                        <img src={`${process.env.PUBLIC_URL}/assets/images/seller_images/images_1.png`} alt="product" />
                                                                    </div>
                                                                </td>
                                                                <td>Product Name</td>
                                                                <td>Category</td>
                                                                <td>15 In Stock</td>
                                                                <td> Vendor</td>
                                                                <td>
                                                                    <Link to="#" className="main_detail_butt_1">
                                                                        {" "}
                                                                        <span /> Pending
                                                                    </Link>
                                                                </td>
                                                                <td className="img_detail_new">
                                                                    <Link to="/seller/products/view">
                                                                        <img src={`${process.env.PUBLIC_URL}/assets/images/seller_images/hugeicons.png`} alt="hugeicons" />
                                                                    </Link>
                                                                    <Link to="/seller/products/review/edit">
                                                                        <img src={`${process.env.PUBLIC_URL}/assets/images/seller_images/Edit.png`} alt="Edit" />
                                                                    </Link>
                                                                    <Link
                                                                        type="button"
                                                                        data-bs-toggle="modal"
                                                                        data-bs-target="#send_approval"
                                                                    >
                                                                        {" "}
                                                                        <img src={`${process.env.PUBLIC_URL}/assets/images/seller_images/delete.png`} alt="delete" />
                                                                    </Link>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <div className="main_detail_numbar">
                                                    <ul className="pagination_main">
                                                        <li className="disabled">
                                                            <Link to="#">«</Link>
                                                        </li>
                                                        <li className="active">
                                                            <Link to="#">
                                                                1 <span className="sr-only">(current)</span>
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <Link to="#">2</Link>
                                                        </li>
                                                        <li>
                                                            <Link to="#">3</Link>
                                                        </li>
                                                        <li>
                                                            <Link to="#">»</Link>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>
                </div>
                <div
                    className="modal fade"
                    id="send_approval"
                    data-bs-backdrop="static"
                    data-bs-keyboard="false"
                    tabIndex={-1}
                    aria-labelledby="staticBackdropLabel"
                    aria-hidden="true"
                >
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-body">
                                <div className="request_send_for_modul padd_none">
                                    <div className="main_img_order">
                                        <Link to="#">
                                            <img src={`${process.env.PUBLIC_URL}/assets/images/seller_images/dlate.png`} alt="delete" />
                                        </Link>
                                    </div>
                                    <h6>Are you sure you want to reject order ?</h6>
                                    <p>
                                        Do you really want to reject this record? This process can not be
                                        undone.
                                    </p>
                                    <div className="main_acsept_butt">
                                        <button
                                            type="button"
                                            className="btn btn-secondary me-1"
                                            data-bs-dismiss="modal"
                                        >
                                            Cancel
                                        </button>
                                        <button type="button" className="main_acsept_butt_1 btn ">
                                            Delete
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    className="modal fade"
                    id="logout_main"
                    data-bs-backdrop="static"
                    data-bs-keyboard="false"
                    tabIndex={-1}
                    aria-labelledby="staticBackdropLabel"
                    aria-hidden="true"
                >
                    <div className="modal-dialog classmain_log_modul pt-100">
                        <div className="modal-content mt-100 ">
                            <div className="modal-body">
                                <div className="request_send_for_modul padd_none classmain_log">
                                    <h6>Log out ?</h6>
                                    <p>Are you sure want to log out ? </p>
                                    <div className="main_acsept_butt">
                                        <button
                                            type="button"
                                            className="btn btn-secondary me-1"
                                            data-bs-dismiss="modal"
                                        >
                                            Cancel
                                        </button>
                                        <button type="button" className="classmain_log_button btn ">
                                            Log out
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>

    )
}

export default ProductList