import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Slider from "react-slick";

let host = process.env.REACT_APP_API_SERVER;

const Bestsellers = ({ title, HomeLastSectionDataListApiData }) => {

    const navigate = useNavigate();

    const handleNavigateClick = (slug, productVariant) => {
        if (productVariant?.colourId && productVariant?.sizeId) {
            navigate(`/buyer/product-details/${slug}?colorId=${productVariant?.colourId}&sizeId=${productVariant?.sizeId}`);
        } else if (productVariant?.colourId == null && productVariant?.sizeId) {
            navigate(`/buyer/product-details/${slug}?sizeId=${productVariant?.sizeId}`);
        } else if (productVariant?.colourId && productVariant?.sizeId == null) {
            navigate(`/buyer/product-details/${slug}?colorId=${productVariant?.colourId}`);
        } else {
            navigate(`/buyer/product-details/${slug}`);
        }
    };

    const settings = {
        dots: true,
        arrows: false,
        infinite: HomeLastSectionDataListApiData?.length > 6,
        autoplay: true,
        autoplaySpeed: 3000,
        slidesToShow: 6,
        slidesToScroll: 1,
        centerMode: false,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 780,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true,
                    arrows: false
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true,
                    arrows: false
                }
            }
        ]
    };

    return (
        <div className="bestsellers">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                        <div className="bestsellers_heading">
                            <h2>
                                {title}
                                <span>
                                    <Link to={`/buyer/home-last-product-listing`}>See all offers</Link>
                                </span>
                            </h2>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                        <Slider {...settings} className='slider bestsellers_product'>
                            {HomeLastSectionDataListApiData?.map((productData) => (
                                <div className="slider__item" key={productData?.slug} style={{ height: '300px', overflow: 'hidden' }}>
                                    <div
                                        style={{
                                            height: '100%',
                                            width: '100%',
                                            backgroundColor: '#f7f7f7', // Greyish background
                                            borderRadius: '8px',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            overflow: 'hidden',
                                            position: 'relative',
                                            padding: '10px', // Add padding to avoid image touching edges
                                            boxSizing: 'border-box' // Ensure padding is included in the height calculation
                                        }}
                                    >
                                        <img
                                            src={productData?.image ? `${host}${productData?.image}` : ''}
                                            className="img-fluid"
                                            onClick={() => handleNavigateClick(productData?.slug, productData?.productVariant)}
                                            style={{
                                                cursor: 'pointer',
                                                transition: 'transform 0.3s ease',
                                                height: '157px',
                                                width: '350px', // Maintain aspect ratio
                                                maxWidth: '100%', // Ensure image does not exceed container width
                                                borderRadius: '8px', // Match card corners
                                                objectFit:'fill',
                                            }}
                                            onMouseEnter={(e) => {
                                                e.target.style.transform = 'scale(1.1)';
                                            }}
                                            onMouseLeave={(e) => {
                                                e.target.style.transform = 'scale(1)';
                                            }}
                                        />
                                    </div>
                                </div>
                            ))}
                        </Slider>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Bestsellers;
